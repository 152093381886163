import Colors from "./Colors";

export const CommissionType = [
    {
        value: 'MODERNIZATION',
        label: 'Modernizacja',
    },
    {
        value: 'REPAIR',
        label: 'Naprawa',
    },
    {
        value: 'ONE_TIME_OVERVIEW',
        label: 'Przegląd jednorazowy',
    },
    {
        value: 'CYCLICAL_OVERVIEW',
        label: 'Przegląd cykliczny',
    },
    {
        value: 'INSPECTION',
        label: 'Oględziny',
    },
    {
        value: 'RECLAMATION',
        label: 'Reklamacja',
    },
];
CommissionType.forEach(item => item['text'] = item['label'])

export const CommissionTypeColors = {
    'MODERNIZATION': Colors.blue,
    'REPAIR': Colors.yellow,
    'ONE_TIME_OVERVIEW': Colors.fountain_blue,
    'CYCLICAL_OVERVIEW': Colors.green,
    'INSPECTION': Colors.orange,
    'RECLAMATION': Colors.red,
}

export const CommissionTypeColor = (type) => {
    var styles = {
        height: 30,
        width: 130,
        textAlign: "center",
        lineHeight: '30px',
        color: "white",
        fontWeight: "bold",
        borderRadius: '3px'
    }
    styles.backgroundColor = CommissionTypeColors[type];
    return styles;
}

export const CommissionTypeToLabel = () => {
    let CommissionTypeToLabelDict = {}
    for (let item of CommissionType) {
        CommissionTypeToLabelDict[item.value] = item.label;
    }
    return CommissionTypeToLabelDict;
}

export default CommissionType
