export const SolutionClasses = [
    {
        value: 'A',
        label: 'Rozwiązanie A',
    },
    {
        value: 'B',
        label: 'Rozwiązanie B',
    }
];

export default SolutionClasses;
