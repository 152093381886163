import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, notification, Table} from 'antd';
import {InfoCircleOutlined,} from '@ant-design/icons';
import {MakeRoute, Routes} from "../../../../config/Routes";
import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";
import Permissions from '../../../../config/Permissions';
import TenderStatuses, {GetTenderStatusFromValue, GetTenderStatusStyle} from "../../../../config/TenderStatuses"

import styles from '../../../../style/dashboard/view/user/UserList.module.scss';
import TableHelper from "../../../../utils/TableHelper";
import TenderTypes, {
    GetTenderTypeColorStyle,
    GetTenderTypeFromValue,
    GetTenderTypeStyle
} from "../../../../config/TenderTypes";
import MapComponent from "../../../../component/MapComponent";
import HasMadeOffer, {GetHasMadeOfferFromValue, GetHasMadeOfferStyle} from "../../../../config/TenderHasMadeOffer";

let dateFormat = require("dateformat");

class ManagerTendersList extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            sortedInfo: null,
            pagination: {
                pageSize: 10,
            },
            tenders: [],
            visible: false,
            selectedId: null,
        };
        ShareState.setValue('breadcrumb', 'Lista przetargów')
    }

    componentDidMount() {
        this.getTendersList()
    }

    getTendersList() {
        authFetch({
            endpointName: "managerTendersList",
            successFunction: data => {
                if (!data.tenders) return;
                for (let tender of data.tenders) {
                    tender['facility_name'] = [tender.facility.name, tender.facility.address.address]
                    tender['status_pl'] = GetTenderStatusFromValue(tender.status).label;
                    tender['realizationDate'] = new Date(tender.realizationDate)
                    tender['realizationDateStr'] = tender.realizationDate ? dateFormat(tender.realizationDate, "dd-mm-yyyy") : '-'

                    // for map:
                    tender['text'] = 'Nr przetargu: ' + tender.documentNumber
                    tender['linkAddress'] = MakeRoute(Routes.TenderDetail, {'id': tender.id})
                    tender['latitude'] = tender.facility?.address?.latitude
                    tender['longitude'] = tender.facility?.address?.longitude
                    tender['markerColor'] = GetTenderTypeColorStyle(tender.tenderType)
                }

                let sortedTenders = data.tenders;
                sortedTenders = sortedTenders.sort(function (a, b) {
                    return b.id - a.id
                })

                this.setState({
                    tenders: sortedTenders,
                    tendersFiltered: sortedTenders
                })
            }
        })
    }

    showModal = (id) => {
        this.setState({
            selectedId: id,
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
        this.removeTender(this.state.selectedId);
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    removeTender(id) {
        authFetch({
            endpointName: "tenderDelete",
            endpointParameters: {'id': id},
            config: {method: 'delete'},
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Usunięto przetarg',
                });
                this.getTendersList();
            }
        })
    }

    AddTenderButton = () => {
        if (Permissions.canDo('TenderAdd')) {
            return (
                <Button type="primary">
                    <Link to={Routes.TenderAdd}>
                        Dodaj przetarg
                    </Link>
                </Button>
            )
        }
    }

    render() {
        const columns = [
            {
                title: 'Obiekt',
                dataIndex: 'facility_name',
                key: 'facility_name',
                width: '45%',

                ...this.tableHelper.getColumnSearchProps('facility_name'),
                render: (item) => <div>
                    <span style={{fontWeight: "bold"}}>{item[0]}</span> {item[1]}
                </div>,
            },
            {
                title: 'Data',
                dataIndex: 'realizationDateStr',
                key: 'realizationDateStr',
                width: '10%',
                ...this.tableHelper.getColumnSearchProps('realizationDateStr'),
                sorter: {
                    compare: (a, b) => a.realizationDate?.getTime() - b.realizationDate?.getTime(),
                    multiple: 1,
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: "12%",
                filters: TenderStatuses,
                onFilter: (value, record) => record.status === value,
                render: (item) => <div>

                    <div
                        style={GetTenderStatusStyle(item)}>
                        {GetTenderStatusFromValue(item).label}
                    </div>
                </div>,
            },
            {
                title: 'Rodzaj zlecenia',
                dataIndex: 'tenderType',
                key: 'tenderType',
                width: "12%",
                filters: TenderTypes,
                onFilter: (value, record) => record.tenderType === value,
                render: (item) => <div>

                    <div
                        style={GetTenderTypeStyle(item)}>
                        {GetTenderTypeFromValue(item).label}
                    </div>
                </div>,
            },
            {
                title: 'Oferta złożona',
                dataIndex: 'hasMadeOffer',
                key: 'hasMadeOffer',
                width: "12%",
                filters: HasMadeOffer,
                onFilter: (value, record) => record.hasMadeOffer === value,
                render: (item) => <div>

                    <div
                        style={GetHasMadeOfferStyle(item)}>
                        {GetHasMadeOfferFromValue(item).label}
                    </div>
                </div>,
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 150,
                render: (item) => <div className={styles.iconHolder}>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.ManagerTenderDetail, {'id': item.id})}>
                        <InfoCircleOutlined/>
                    </Link>
                </div>,
            },
        ];
        return (
            <div>
                <Card title="Lista przetargów" bordered={true}
                      extra={this.AddTenderButton()}>

                    <MapComponent
                        data={this.state.tendersFiltered}
                        clickable={true}
                    />

                    <Table
                        pagination={this.state.pagination}
                        columns={columns}
                        dataSource={this.state.tenders}
                        onChange={(a, b, c, dataBase) => this.setState({
                            tendersFiltered: dataBase.currentDataSource.sort(function (a, b) {
                                return b.id - a.id
                            })
                        })}
                        scroll={{x: 1200}}
                        rowKey="id"
                    />
                </Card>
            </div>
        );
    }
}

export default withRouter(ManagerTendersList);
