import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, notification, Table} from 'antd';
import {FormOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {MakeRoute, Routes} from "../../../../config/Routes";
import {Voivodeship} from "../../../../config/Voivodeship";
import {FacilityStatus, FacilityStatusToLabel} from "../../../../config/FacilityStatus";
import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";
import Permissions from '../../../../config/Permissions';

import styles from '../../../../style/dashboard/view/user/UserList.module.scss';
import DeleteConfirm from "../../../../component/DeleteConfirm";
import TableHelper from "../../../../utils/TableHelper";
import MapComponent from "../../../../component/MapComponent";

class FacilityList extends React.Component {
    Voivodeship = Voivodeship.map(item => item['text'] = item['label'])
    FacilityStatus = FacilityStatus.map(item => item['text'] = item['label'])
    FacilityStatusDict = FacilityStatusToLabel()
    colors = {
        'NOT_SERVICED': '#fe437e',
        'SERVICED': '#53c3b3',
        'NOT_ACTIVE': '#a3a3a3',
        'OFFERED': '#008c3f',
    }
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            sortedInfo: null,
            pagination: {
                pageSize: 10,
            },
            facilities: [],
            facilitiesFiltered: [],

            voivodeshipLookUp: {}
        };
        ShareState.setValue('breadcrumb', 'Lista obiektów')
    }

    componentDidMount() {
        this.getFacilityList()
    }

    getFacilityList() {
        authFetch({
            endpointName: "FacilityList",
            successFunction: data => {
                if (!data) return;
                for (let item of data.facilities) {
                    item['adress_city'] = item.address.city
                    item['adress_voivodeship'] = item.address.voivodeship
                    item['facilityStatus_label'] = this.FacilityStatusDict[item.facilityStatus]

                    // for map
                    item['text'] = item.name
                    item['linkAddress'] = MakeRoute(Routes.FacilityDetail, {'id': item.id})
                    item['latitude'] = item.address?.latitude
                    item['longitude'] = item.address?.longitude
                    item['markerColor'] = this.colors[item.facilityStatus]


                    item['salesman_label'] = item.salesman ? item.salesman.firstName + " " + item.salesman.lastName : "brak"
                    item['realizer_label'] = item.realizer ? item.realizer.firstName + " " + item.realizer.lastName : "brak"
                }
                this.setState({
                    facilities: data.facilities,
                    facilitiesFiltered: data.facilities,
                })
            }
        });
    }

    removeFacility(id) {
        authFetch({
            endpointName: "FacilityDelete",
            endpointParameters: {id: id},
            config: {
                method: 'delete'
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Usunięto obiekt',
                });
                this.getFacilityList()
            }
        })
    }

    statusColor = (status) => {
        var styles = {
            height: 30,
            width: 140,
            textAlign: "center",
            lineHeight: '30px',
            color: "white",
            fontWeight: "bold",
            borderRadius: '3px'
        }
        styles.backgroundColor = this.colors[status];
        return styles;
    }

    render() {
        const columns = [
            {
                title: 'Nazwa obiektu',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnExcelSearchProps('name', this.state.facilities.map(x => x.name)),
            },
            {
                title: 'Numer obiektu AX',
                dataIndex: 'axFacilityNumber',
                key: 'axFacilityNumber',
                width: '20%',
                sorter: {
                    compare: (a, b) => a.axFacilityNumber.localeCompare(b.axFacilityNumber),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnExcelSearchProps('axFacilityNumber', this.state.facilities.map(x => x.axFacilityNumber)),
            },
            {
                title: 'Miasto',
                dataIndex: 'adress_city',
                key: 'adress_city',
                width: '20%',
                ...this.tableHelper.getColumnExcelSearchProps('adress_city', this.state.facilities.map(x => x.adress_city)),
            },
            {
                title: 'Województwo',
                dataIndex: 'adress_voivodeship',
                key: 'adress_voivodeship',
                width: '15%',
                filters: Voivodeship,
                onFilter: (value, record) => record.address.voivodeship.toLowerCase() === value,
            },
            {
                title: 'Handlowiec HS',
                dataIndex: 'salesman_label',
                key: 'salesman_label',
                width: '15%',
                ...this.tableHelper.getColumnExcelSearchProps('salesman_label', this.state.facilities.map(x => x.salesman_label)),
            },
            {
                title: 'Realizator HS',
                dataIndex: 'realizer_label',
                key: 'realizer_label',
                width: '15%',
                ...this.tableHelper.getColumnExcelSearchProps('realizer_label', this.state.facilities.map(x => x.realizer_label)),
            },
            {
                title: 'Status',
                dataIndex: 'facilityStatus',
                key: 'facilityStatus',
                width: '16%',
                filters: FacilityStatus,
                onFilter: (value, record) => record.facilityStatus === value,
                render: (item) =>
                    <div>
                        <div style={this.statusColor(item)}>
                            {this.FacilityStatusDict[item]}
                        </div>
                    </div>
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 150,
                render: (item) => <div className={styles.iconHolder}>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.FacilityDetail, {'id': item.id})}>
                        <InfoCircleOutlined/>
                    </Link>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.FacilityEdit, {'id': item.id})}>
                        <FormOutlined/>
                    </Link>
                    {Permissions.canDo("FacilityDelete") &&

                    <DeleteConfirm
                        onDelete={() => this.removeFacility(item.id)}
                        confirmText={"Czy na pewno chcesz usunąć ten obiekt?"}
                    />

                    }
                </div>,
            },
        ];

        return (
            <div>
                <Card title="Obiekty" bordered={true}
                      extra={
                          Permissions.canDo("FacilityAdd") &&
                          <Button type="primary">
                              <Link to={Routes.FacilityAdd}>Nowy obiekt</Link>
                          </Button>


                      }
                >

                    <MapComponent
                        data={this.state.facilitiesFiltered}
                        clickable={true}
                    />

                    <Table
                        pagination={this.state.pagination}
                        columns={columns}
                        dataSource={this.state.facilities}
                        onChange={(a, b, c, dataBase) => this.setState({facilitiesFiltered: dataBase.currentDataSource})}
                        scroll={{x: 1000}}
                        rowKey="id"
                    />
                </Card>
            </div>
        );
    }
}

export default withRouter(FacilityList);
