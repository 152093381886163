import React from "react";
import {withRouter} from 'react-router-dom';
import '../../../../style/dashboard/view/user/UserAdd.antd.scss';

import TenderForm from "./component/TenderForm";


class TenderAdd extends React.Component {

    render() {
        return (<TenderForm/>)

    }
}

export default withRouter(TenderAdd);
