const ErrorMessages = {
    'InvalidVoivodeshipsForUserType': 'Wybrano niepoprawne województwo',
    'UniqueEmailException': 'Email użytkownika musi być unikalny, podany email już istnieje w bazie danych',
    'UniqueNickException': 'Nick użytkownika musi być unikalny, podany nick już istnieje w bazie danych',
    'UserNotFound': 'Nie znaleziono użytkownika',
    'UserWrongSubcontractorException': 'Wybrany podwykonawca nie jest poprawny',

    'FacilityInfoAlreadySet': 'Informacje o obiekcie już zostały dodane wcześniej',
    'FacilityInfoNotFound': 'Nie udało odnaleźć się informacji o tym obiekcie',
    'FacilityNotFound': 'Nie udało odnaleźć się wybranego obiektu',
    'FacilityNotServiced': 'Obiekt nie jest serwisowany',
    'FacilityStatusModify': 'Niepoprawne dane', //what does mean this exception?

    'FileNotFound': 'Wybrany plik nie istnieje',
    'FileReadException': 'Nie udało się otworzyć wybranego pliku',
    'StorageException': 'Błąd infrastruktury serwerowej, wykryto problem z dyskiem',
    'StorageFileNotFound': 'Nie udało się odnaleźć pliku', //what is different between this and FileNotFound?

    'TenderNotFound': 'Nie znaleziono wybranego przetargu',
    'TenderSubcontractorsIsEmpty': 'Podwykonawca musi zostać przypisany',
    'WrongTenderStatus': 'Nieprawidłowy status',
    'SubcontractorIsNotAssignedToThisTender': 'Wybrany podwykonawca nie jest przypisany do tego przetargu',

    'OfferNotFound': 'Nie znaleziono wybranej oferty',

    'SubcontractorHasManagerException': 'Wybrany podwykonawca ma już przypisanego menedżera',
    'SubcontractorNotFound': 'Nie odnaleziono wybranego podwykonawcy',
    'SubcontractorAlreadyMadeOffer': 'Oferta została już złożona przez wybranego podwykonawcę',

    'GoogleApiException': 'Podany adres nie jest poprawny',

    'CommissionNotFound': 'Nie znaleziono wybranego zlecenia',
    'CommissionAlreadyHasService': "Dane zlecenie posiada już serwis",
    'CommissionDoesNotHaveWorker': "Dane zlecenie nie posiada przypisanego brygadzisty",
    'CommissionHasWrongStatus': "Dane zlecenie ma status niepozwalający na wykonanie tej operacji",
    'UniqueCommissionHsProjectNumber': "Zlecenie o takim numerze HS już istnieje",

    'MethodArgumentNotValidException': 'Niepoprawne dane',
    'AuthenticationException': 'Nie udało się autoryzować użytkownika',
    'InternalServerError': 'Błąd serwera',
    'AccessDeniedException': 'Nie masz wystarczających uprawnień w systemie aby wykonać tą akcję',

    'ProtocolAlreadyExist': "Ta konfiguracja protokołu już istnieje",
    'DeviceEfficiencyIsReturn': "Najpierw uzupełnij wszystkie sprawności urządzeń, które są ustawione na \"Wróć później\"",


    'ActivityListWrongMadeWith': "Uzupełnij \"przegląd wykonano z użyciem\""
}

export const GetErrorDescription = (errorData) => {
    if (errorData.key in ErrorMessages) return ErrorMessages[errorData.key]
    return errorData.errorMessage
}
