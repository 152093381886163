import React from "react";
import {Button, Col, Descriptions, Divider, notification, Row} from "antd";
import {VDatePicker, VInput, VSelect} from "../../../../component/form/VForm";
import {authFetch, getApiUrl, getAuthToken} from "../../../../utils/ApiHelper";
import ActivityListCheckedList from "./ActivityListCheckedList";
import ControlTypesActivity1 from "../../../../config/Protocol/controlTypes/ControlTypesActivity1";
import {GetSystemTypeFromValue} from "../../../../config/Protocol/SystemTypes";
import {DoesActivityHasControl, DoesActivityHasCurtains} from "../../../../config/Protocol/ActivityTypes";
import CurtainsTypes from "../../../../config/Protocol/CurtainsTypes";

class ActivityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getEmptyState()
    }

    getEmptyState() {
        return {
            activityListId: this.props.activityListId,
            facilityName: this.props.facilityName,
            activityValuesIds: null,
            realizationDate: new Date(),
            disabledDate: false,
            validTriggerContainer: [],

            controlTypes: [],

            curtainsTypeVSelect: null,
            curtainsTypeVInput: null,

            hasCurtains: DoesActivityHasCurtains(this.props.activityTypeName),
            hasControl: DoesActivityHasControl(this.props.activityTypeName),

            isInEdit: this.props.activityListId !== null,

        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.activityTypeName !== this.props.activityTypeName) {
            this.setState({
                hasCurtains: DoesActivityHasCurtains(this.props.activityTypeName),
                hasControl: DoesActivityHasControl(this.props.activityTypeName),
            })
        }

        if (this.props.activityListId !== this.state.activityListId) {
            this.setState(this.getEmptyState(), () => this.getActivityList())
        }
    }

    getActivityList(id = this.state.activityListId) {
        if (id) {
            authFetch({
                endpointName: "ActivityListGet",
                endpointParameters: {
                    id: this.state.activityListId
                },

                successFunction: data => {
                    let controlTypes = []

                    let curtainsTypeVSelect = null
                    let curtainsTypeVInput = null

                    if (this.state.hasControl) {
                        controlTypes = data.controlTypes;
                    }

                    if (this.state.hasCurtains) {
                        let curtainsType = data.curtainsType;
                        curtainsTypeVInput = null
                        curtainsTypeVSelect = "inne"
                        if (CurtainsTypes.find(v => v.value === curtainsType)) {
                            curtainsTypeVSelect = curtainsType
                        } else {
                            curtainsTypeVInput = curtainsType
                        }
                    }

                    this.setState({
                        controlTypes: controlTypes,

                        curtainsTypeVSelect: curtainsTypeVSelect,
                        curtainsTypeVInput: curtainsTypeVInput,

                        realizationDate: data.realizationDate,
                        isInEdit: true,
                        activityValuesIds: this.convertActivityValuesToIds(data.activityValues),
                    })

                }
            })
        }
    }

    componentDidMount() {
        this.getActivityList()
    }

    convertActivityValuesToIds(activityValues) {
        let activityIds = []

        for (var key in activityValues) {
            activityIds.push(...activityValues[key]
                .map(val => val.id))
        }

        return activityIds
    }

    getBodyParameters(activityValuesId) {
        let body = {
            activityValuesId: activityValuesId,
            realizationDate: this.state.realizationDate
        }
        if (this.state.hasControl) {
            body['controlTypes'] = this.state.controlTypes
        }
        if (this.state.hasCurtains) {
            let curtainsType = this.state.curtainsTypeVSelect;
            if (this.state.curtainsTypeVSelect === 'inne') {
                curtainsType = this.state.curtainsTypeVInput
            }

            body['curtainsType'] = curtainsType
        }

        return body
    }


    addActivityList(activityValuesId) {
        let body = this.getBodyParameters(activityValuesId)

        authFetch({
            endpointName: "ActivityListAdd",
            endpointParameters: {protocolId: this.props.protocolId},
            config: {
                method: 'post',
                body: JSON.stringify(body)
            },
            successFunction: data => {
                this.setState({
                    isInEdit: true,
                    activityListId: data.id,
                    activityValuesIds: activityValuesId
                }, () => this.props.addSuccessFunction(data))

            }
        })
    }

    editActivityList(activityValuesId) {
        let body = this.getBodyParameters(activityValuesId)

        authFetch({
            endpointName: "ActivityListEdit",
            endpointParameters: {id: this.state.activityListId},
            config: {
                method: 'post',
                body: JSON.stringify(body)
            },
            successFunction: data => {

                notification['success']({
                    message: 'Udało się uaktualnić listę czynności',
                });

                this.setState({
                    isInEdit: true,
                    activityListId: data.id,
                    activityValuesIds: activityValuesId
                })

            }
        })
    }

    downloadPdf(activityListId, facilityName) {
        fetch(getApiUrl("GenerateActivityListPdf", {activityListId: activityListId}), {
            method: 'get',
            headers: {
                'Authorization': getAuthToken(),
                'Content-Type': 'application/json'
            },

        })
            .then(response => response.blob())
            .then(_blob => {
                var blob = new Blob([_blob]);
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                const date = new Date().toLocaleString("pl-PL")
                const fileName = `lista_czynnosci_${facilityName}_${date}.pdf`
                link.download = fileName;
                link.click();
            })
    }


    renderControlTypes() {
        return (
            <>
                <Col span={8}>
                    <VSelect
                        value={this.state.controlTypes}
                        style={{width: '80%'}}
                        disabled={!this.props.isActive}
                        mode="tags"
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={(value) => this.setState({controlTypes: value})}
                        options={ControlTypesActivity1}/>
                </Col>
            </>
        )
    }

    renderCurtainsTypes() {
        return (
            <>
                <Col span={8}>
                    <VSelect
                        value={this.state.curtainsTypeVSelect}
                        style={{width: '80%'}}
                        rules={{required: true}}
                        disabled={!this.props.isActive}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={(value) => this.setState({curtainsTypeVSelect: value})}
                        options={CurtainsTypes}/>
                </Col>
                {this.state.curtainsTypeVSelect === 'inne' &&
                <Col span={8}>
                    <VInput
                        placeholder="Typ kurtyn"
                        value={this.state.curtainsTypeVInput}
                        disabled={!this.props.isActive}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={(e) => this.setState({curtainsTypeVInput: e.target.value})}/>
                </Col>
                }
            </>
        )
    }

    render() {
        return (
            <>
                <Row>
                    <Col span={3}><Descriptions title="Lista czynności"/></Col>
                    {this.state.activityListId && this.props.hasFullAccess &&
                    <Col span={8}><Button
                        justify="end"
                        onClick={() => {
                            this.downloadPdf(this.state.activityListId, this.state.facilityName)
                        }}
                        type="primary">
                        Pobierz PDF
                    </Button>
                    </Col>
                    }
                </Row>


                Załącznik do protokołu <b>{GetSystemTypeFromValue(this.props.systemTypeName)}</b>
                <Divider/>
                <Row>
                    <Col span={8}>Czynność wykonano dnia</Col>

                    {this.state.hasControl &&
                    <Col span={8}>Typ sterowania</Col>}

                    {this.state.hasCurtains &&
                    <Col span={8}>Typ kurtyn</Col>}
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <VDatePicker
                            placeholder="Czyność wykonano dnia"
                            value={this.state.realizationDate}
                            rules={{required: true}}
                            disabled={!this.props.isActive}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(date, dateString) => this.setState({realizationDate: date})}/>
                    </Col>

                    {this.state.hasControl &&
                    this.renderControlTypes()}

                    {this.state.hasCurtains &&
                    this.renderCurtainsTypes()}
                </Row>

                {this.state.isInEdit &&
                <ActivityListCheckedList
                    systemTypeName={this.props.systemTypeName}
                    activityTypeName={this.props.activityTypeName}
                    selectedValuesIds={this.state.activityValuesIds}
                    addActivityCallback={(x) => this.editActivityList(x)}
                    isActive={this.props.isActive}
                />

                }

                {!this.state.isInEdit && <ActivityListCheckedList
                    systemTypeName={this.props.systemTypeName}
                    activityTypeName={this.props.activityTypeName}
                    addActivityCallback={(x) => this.addActivityList(x)}
                    isActive={this.props.isActive}
                />
                }
            </>
        );
    }
}

export default ActivityList;
