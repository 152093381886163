import React from "react";
import {Descriptions} from "antd";

class SubcontractorDetailsDisplay extends React.Component {

    getByName(name) {
        return this.props.data ? this.props.data[name] : null
    }


    render() {
        return (

            <div>
                <Descriptions bordered column={2}>
                    <Descriptions.Item
                        label="Nazwa">{this.getByName('name')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Adres">{this.getByName('address')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Telefon">{this.getByName('phone')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Email">{this.getByName('mail')}
                    </Descriptions.Item>
                    {!this.props.essentialOnly &&
                    <Descriptions.Item
                        label="Nr. umowy">{this.getByName('dealNumber')}
                    </Descriptions.Item>
                    }

                </Descriptions>

            </div>
        );
    }
}

export default SubcontractorDetailsDisplay
