import {ShareState} from "../utils/ShareState";
import {notification} from "antd";
import Routes from "../config/Routes";
import history from "../history"

export const LogOut = () => {
    ShareState.setValue('user', null);
    ShareState.setValue('auth-token', null);
    window.localStorage.setItem('user', '');
    window.localStorage.setItem('auth-token', '');
    notification['success']({
        message: 'OK',
        description: 'Zostałeś wylogowany',
    });
    ShareState.setValue('NotificationServiceState', false);

    history.push(Routes.Login);
}

export const AuthKeyExpired = () => {
    ShareState.setValue('user', null);
    ShareState.setValue('auth-token', null);
    window.localStorage.setItem('user', '');
    window.localStorage.setItem('auth-token', '');
    notification['warn']({
        message: 'Sesja wygasła',
        description: 'Twoja sesja wygasła, zaloguj się ponownie',
    });
    ShareState.setValue('NotificationServiceState', false);

    let currentPath = history.location.pathname
    if (currentPath !== '/') {
        history.push({
            pathname: Routes.Login,
            search: '?redirect=' + currentPath
        })
    } else {
        history.push({
            pathname: Routes.Login
        })
    }

}
