import React from "react";
import {withRouter} from 'react-router-dom';
import {Button, Card, Col, notification, Row} from 'antd';
import {ShareState} from '../../../../utils/ShareState';
import {AccountTypesPermutation} from "../../../../config/AccountTypesPermutation";
import {Voivodeship} from "../../../../config/Voivodeship";
import '../../../../style/dashboard/view/user/UserAdd.antd.scss';
import {authFetch} from "../../../../utils/ApiHelper";
import {VInput, VInputPassword, VSelect} from '../../../../component/form/VForm'

class UserAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: null,
            lastName: null,
            userType: null,
            email: null,
            phone: null,
            nick: null,
            password: null,
            voivodeships: [],
            subcontractorId: null,

            subcontractorList: [],
            subcontractorListForManager: [],
            validTriggerContainer: []
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Dodaj użytkownika')
        this.getSubcontractorList()
    }

    getSubcontractorList() {
        authFetch({
            endpointName: "GetSubcontractorList",
            successFunction: data => {
                let subcontractorList = []

                for (let subcontractor of data.subcontractors) {
                    subcontractorList.push({
                        value: subcontractor.id,
                        label: subcontractor.name,
                        disabled: false
                    })
                }

                this.setState({
                    subcontractorList: subcontractorList,
                })
            }
        })


        authFetch({
            endpointName: "GetSubcontractorWithoutManagersList",
            successFunction: data => {
                let availableSubcontractorList = []

                for (let subcontractor of data.subcontractors) {
                    availableSubcontractorList.push({
                        value: subcontractor.id,
                        label: subcontractor.name,
                        disabled: false
                    });
                }
                this.setState({
                    subcontractorListForManager: availableSubcontractorList
                })
            }
        })

    }

    voivodeshipsComponentShow() {
        if (this.state.accountTypesPermutation != null) {
            if (AccountTypesPermutation[this.state.accountTypesPermutation].type === "ROLE_SALES") {
                return true;
            }
        }
        return false;
    }

    isManagerOrWorker() {
        if (this.state.accountTypesPermutation != null) {
            if (AccountTypesPermutation[this.state.accountTypesPermutation].type === "ROLE_MANAGER"
                || AccountTypesPermutation[this.state.accountTypesPermutation].type === "ROLE_WORKER") {
                return true;
            }
        }
        return false;
    }

    addUser() {
        if (!this.isValid()) {
            notification['error']({
                message: 'Błąd',
                description: 'Nie uzupełniono wszystkich wymaganych pól formularza',
            });
        } else {
            var json = {
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                nick: this.state.nick,
                password: this.state.password,
                phone: this.state.phone,
                userSubType: AccountTypesPermutation[this.state.accountTypesPermutation].subtype,
                userType: AccountTypesPermutation[this.state.accountTypesPermutation].type,
            }
            if (this.voivodeshipsComponentShow()) json['voivodeships'] = this.state.voivodeships
            if (this.isManagerOrWorker()) json['subcontractorId'] = this.state.subcontractorId

            authFetch({
                endpointName: "userAdd",
                config: {
                    method: 'post',
                    body: JSON.stringify(json)
                },
                successFunction: data => {
                    notification['success']({
                        message: 'OK',
                        description: 'Dodano nowego użytkoniwka',
                    })
                    this.clearState()
                }
            })
        }
    }

    clearState() {
        this.setState({
            firstName: null,
            lastName: null,
            userType: null,
            email: null,
            phone: null,
            nick: null,
            password: null,
            voivodeships: [],
            subcontractorId: null,
            accountTypesPermutation: null,

            // subcontractorList: [],
            // subcontractorListForManager: [],
            validTriggerContainer: []
        });
    }

    isValid() {
        this.validTrigger()

        if (this.isManagerOrWorker() && this.state['subcontractorId'] == null)
            return false;

        for (let key in this.state) {
            if (key !== 'userSubType' && key !== 'userType' && key !== 'voivodeships' && key !== 'subcontractorId') {
                if (this.state[key] == null) return false;
            }
        }
        return true;
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    validTrigger() {
        for (let item of this.state.validTriggerContainer) item()
    }

    render() {
        return (
            <Card title="Dodaj użytkownika" bordered={true}>
                <Row>
                    <Col span={8}>Imię</Col>
                    <Col span={8}>Nazwisko</Col>
                    <Col span={8}>Adres e-mail</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <VInput
                            placeholder="Imię"
                            value={this.state.firstName}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.inputHandler('firstName', e)}/>
                    </Col>
                    <Col span={8}>
                        <VInput
                            placeholder="Nazwisko"
                            value={this.state.lastName}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.inputHandler('lastName', e)}/>
                    </Col>
                    <Col span={8}>
                        <VInput
                            placeholder="Adres e-mail"
                            value={this.state.email}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.inputHandler('email', e)}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>Telefon kontaktowy</Col>
                    <Col span={8}>Typ konta</Col>
                    {this.voivodeshipsComponentShow() && <Col span={8}>Województwa</Col>}
                    {this.isManagerOrWorker() && <Col span={8}>Podwykonawca</Col>}
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <VInput
                            placeholder="Telefon kontaktowy"
                            value={this.state.phone}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.inputHandler('phone', e)}/>
                    </Col>
                    <Col span={8}>
                        <VSelect
                            value={this.state.accountTypesPermutation}
                            style={{width: '70%'}}
                            placeholder="Typ konta"
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(value) => this.setState({accountTypesPermutation: value})}
                            options={AccountTypesPermutation}/>
                    </Col>

                    {this.voivodeshipsComponentShow() &&
                    <Col span={8}>
                        <VSelect
                            mode="multiple"
                            value={this.state.voivodeships}
                            style={{width: '70%'}}
                            placeholder="Województwa"
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(value) => this.setState({voivodeships: value})}
                            options={Voivodeship}/>
                    </Col>}

                    {this.isManagerOrWorker() &&
                    <Col span={8}>
                        <VSelect
                            style={{width: '70%'}}
                            placeholder="Wybierz podwykonawcę"
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            value={this.state.subcontractorId}
                            onChange={(value) => this.setState({subcontractorId: value})}
                            options={AccountTypesPermutation[this.state.accountTypesPermutation].type === 'ROLE_MANAGER'
                                ? this.state.subcontractorListForManager : this.state.subcontractorList}/>
                    </Col>}

                </Row>

                <Row>
                    <Col span={8}>Nick</Col>
                    <Col span={8}>Hasło</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <VInput
                            placeholder="Nick"
                            value={this.state.nick}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.inputHandler('nick', e)}/>
                    </Col>
                    <Col span={8}>
                        <VInputPassword
                            placeholder="Hasło"
                            autoComplete="new-password"
                            value={this.state.password}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.inputHandler('password', e)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Button type="primary" onClick={() => this.addUser()}>Dodaj użytkownika</Button>
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default withRouter(UserAdd);
