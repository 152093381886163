import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, Modal, notification, Table} from 'antd';
import {DeleteOutlined, FormOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {MakeRoute, Routes} from "../../../../config/Routes";
import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";
import {AccountTypesPermutation, GetAccountFromTypeAndSubType} from '../../../../config/AccountTypesPermutation'

import styles from '../../../../style/dashboard/view/user/UserList.module.scss';
import TableHelper from "../../../../utils/TableHelper";
import UserActiveStatus, {
    GetUserActiveStatusFromDisabled,
    UserActiveStatusColor
} from "../../../../config/UserActiveStatus";

class UserList extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            sortedInfo: null,
            pagination: {
                pageSize: 10,
            },
            users: [],
            visible: false,
            selectedId: null,
        };
        ShareState.setValue('breadcrumb', 'Lista użytkowników')
    }

    componentDidMount() {
        this.getUserList()
    }

    getUserList() {
        authFetch({
            endpointName: "usersList",
            successFunction: data => {
                if (!data.users) return;
                for (let user of data.users) {
                    user['name_surname'] = user.firstName + ' ' + user.lastName
                    user['type_concatenation'] = GetAccountFromTypeAndSubType(user.userType, user.userSubType).label
                    user['userTypeValue'] = GetAccountFromTypeAndSubType(user.userType, user.userSubType).value
                    user['activeStatus'] = GetUserActiveStatusFromDisabled(user.isDeleted)
                }
                this.setState({
                    users: data.users
                })
            }
        });
    }

    showModal = (id) => {
        this.setState({
            selectedId: id,
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
        this.deactivateUser(this.state.selectedId);
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    deactivateUser(id) {
        authFetch({
            endpointName: "userDelete",
            endpointParameters: {id: id},
            config: {
                method: 'delete',
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Deaktywowano użytkownika',
                })
                this.getUserList()
            }
        })
    }

    render() {
        const columns = [
            {
                title: 'Imię i nazwisko',
                dataIndex: 'name_surname',
                key: 'name_surname',
                width: '25%',
                sorter: {
                    compare: (a, b) => a.name_surname.localeCompare(b.name_surname),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnSearchProps('name_surname'),
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('email'),
            },
            {
                title: 'Nick',
                dataIndex: 'nick',
                key: 'nick',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('nick'),
            },
            {
                title: 'Typ konta',
                width: '20%',
                dataIndex: 'type_concatenation',
                key: 'type_concatenation',
                filters: AccountTypesPermutation,
                onFilter: (value, record) => record.userTypeValue === value,
            },
            {
                title: 'Aktywny',
                width: '10%',
                dataIndex: 'activeStatus',
                key: 'activeStatus',
                filters: UserActiveStatus,
                render: (item) =>
                    <div>
                        <div style={UserActiveStatusColor(item.value)}>
                            {item.label}
                        </div>
                    </div>,
                onFilter: (value, record) => record.activeStatus.value === value,
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 150,
                render: (item) => <div className={styles.iconHolder}>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.UserDetail, {'id': item.id})}>
                        <InfoCircleOutlined/>
                    </Link>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.UserEdit, {'id': item.id})}>
                        <FormOutlined/>
                    </Link>
                    {!item.isDeleted &&
                    <DeleteOutlined
                        style={{color: 'red'}}
                        className={styles.iconItem}
                        onClick={() => this.showModal(item.id)}/>
                    }
                </div>,
            },
        ];
        return (
            <div>
                <Card title="Lista użytkowników" bordered={true}
                      extra={
                          <Button type="primary">
                              <Link to={Routes.UserAdd}>
                                  Dodaj użytkownika
                              </Link>
                          </Button>
                      }>
                    <Table
                        pagination={this.state.pagination}
                        columns={columns}
                        dataSource={this.state.users}
                        scroll={{x: 1200}}
                        rowKey="id"
                    />
                </Card>
                <Modal
                    title="Uwaga"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p>Czy napewno chcesz deaktywować tego użytkownika?</p>
                </Modal>
            </div>
        );
    }
}

export default withRouter(UserList);
