import {authFetch} from "../../../../utils/ApiHelper";

export const getSubcontractorList = (cb) => {
    authFetch({
        endpointName: "subcontractorList",
        successFunction: data => {
            if (!data.subcontractors) return;

            for (var subcontractor in data.subcontractors) {
                data.subcontractors[subcontractor].label = data.subcontractors[subcontractor].name
                data.subcontractors[subcontractor].value = data.subcontractors[subcontractor].id
            }
            cb(data.subcontractors)
        }
    });
}