import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";
import {Button, Card, Col, Descriptions, Divider, Modal, notification, Row} from 'antd';
import {MakeRoute, Routes} from "../../../../config/Routes";
import {CommissionTypeToLabel} from '../../../../config/CommissionType'
import {CommissionStatusToLabel} from '../../../../config/CommissionStatus'
import Permissions from '../../../../config/Permissions';
import {VDatePicker, VSelect, VTextArea} from '../../../../component/form/VForm'
import ServicesTable from "../service/ServicesTable";
import VFilesEdit from '../../../../component/VFilesEdit'
import {StatusColor} from "./CommissionStatus";
import SubcontractorDetailsDisplay from "../../displayInfos/SubcontractorDetailsDisplay";
import UserDetailsDisplay from "../../displayInfos/UserDetailsDisplay";
import FacilitySimpleDisplay from "../../displayInfos/facility/FacilitySimpleDisplay";
import AddressDisplay from "../../displayInfos/AddressDisplay";
import CommissionDetailsDisplay from "../../displayInfos/CommissionDetailsDisplay";
import CommissionForWorkerDisplay from "../../displayInfos/CommissionForWorkerDisplay";


const stateModel = {
    id: null,
    commissionNumber: null,
    commissionSendDate: null,
    commissionStatus: null,
    commissionType: null,
    hsProjectNumber: null,
    nextReviewDate: null,
    note: null,
    realizationDate: null,
    reclamationNote: null,
    salary: null,
    scope: null,
    worker: {
        id: null
    },
    workerId: null,
    workerRealizationDate: null,
    facility: {id: 1, address: {city: "a4d53392-009c-44e0-bff7-8b0a7f003188", voivodeship: "Świętokrzyskie"}},
    subcontractor: {
        id: 4,
        name: "b0bae071-1984-49a6-b05e-2a75249262a9",
        address: "a0676015-1cb6-448d-acbc-6087f5d82ec9"
    },
    realizer: {id: 32, firstName: "firstName9realizer", lastName: "lastName9realizer", nick: "realizer9"},

    visible: false,
    selectWorker: false,
    showAddService: false,

    addServiceNote: null,
    validTriggerContainer: []
}

class CommissionDetail extends React.Component {
    CommissionTypeToLabel = CommissionTypeToLabel()
    CommissionStatusToLabel = CommissionStatusToLabel()
    commissionStatusDict = CommissionStatusToLabel()

    constructor(props) {
        super(props);
        this.state = stateModel;
        this.state['id'] = props.match.params.id || props.commissionId;
        this.state['isManager'] = ShareState.container.user.userType === 'ROLE_MANAGER'
        this.state['isWorker'] = ShareState.container.user.userType === 'ROLE_WORKER'
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Szczegóły zlecenia')
        this.getDetails()
    }

    getDetails() {
        let endpointName = 'CommissionDetail'
        if (this.state.isWorker) {
            endpointName = 'CommissionDetailForWorker'
        }
        authFetch({
            endpointName: endpointName,
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                this.setState(data)
                this.getFileList()
                if (this.state.workerId) this.getWorker()
                this.getWorkerList()
            }
        });
    }

    getFileList() {
        authFetch({
            endpointName: "CommissionFileList",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                this.setState({
                    fileList: data.files
                })
            }
        });
    }

    getWorkerList() {

        let successFunction = data => {
            let workerList = []
            let list = data.users ? data.users : data.workers
            for (let item of list) {
                workerList.push({
                    value: item.id,
                    label: item.firstName + " " + item.lastName + " (" + item.nick + ")"
                })
            }
            this.setState({
                workerList: workerList
            })
        }

        if (ShareState.container.user.userType === 'ROLE_MANAGER') {
            authFetch({
                endpointName: "managerWorkersList",
                successFunction: successFunction
            });
        } else if (Permissions.canDo("SubcontractorDetail")) {
            authFetch({
                endpointName: "subcontractorDetail",
                endpointParameters: {id: this.state.subcontractor.id},
                successFunction: successFunction
            });
        }
    }

    getWorker() {
        authFetch({
            endpointName: "CommissionWorker",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                this.setState({
                    worker: data,
                    selectWorker: false,
                })
            }
        });
    }

    cancelCommission() {
        authFetch({
            endpointName: "CommissionCancel",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Zamknięto zlecenie',
                })
                this.getDetails()
            }
        });
    }

    restoreCommission() {
        authFetch({
            endpointName: "CommissionRestore",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Przywrócono zlecenie',
                })
                this.getDetails()
            }
        });
    }

    handleOk = e => {
        this.setState({
            visible: false,
        });
        this.removeCommission(this.state.id);
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    removeCommission(id) {

        authFetch({
            endpointName: "CommissionDelete",
            endpointParameters: {id: id},
            config: {
                method: 'delete',
                body: JSON.stringify({
                    CommissionId: id
                })
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Usunięto zlecenie',
                })
            }
        })
    }

    goToListAllCommissions() {
        this.props.history.push((Routes.CommissionList));
    }

    assignWorker() {
        authFetch({
            endpointName: "CommissionAssignToWorker",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'post',
                body: JSON.stringify({
                    workerId: this.state.workerId,
                    workerRealizationDate: this.state.workerRealizationDate,
                })
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Przypisano zlecenie',
                })
                this.getWorker()
            }
        })
    }

    isOverview() {
        return this.state.commissionType === 'CYCLICAL_OVERVIEW'
            || this.state.commissionType === 'ONE_TIME_OVERVIEW'
    }

    renderStandardCommissionForWorker() {
        return (<CommissionForWorkerDisplay data={this.state}/>)
    }

    renderStandardCommissionForNotWorker() {
        return (
            <CommissionDetailsDisplay data={this.state}/>
        )
    }

    renderStandardCommission() {
        if (this.state.isWorker) {
            return this.renderStandardCommissionForWorker()
        } else {
            return this.renderStandardCommissionForNotWorker()
        }

    }

    renderFacilityCard() {
        return (this.state.facility && <>
            <Card style={{marginTop: 20}} title="Obiekt" extra={
                Permissions.canDo("FacilityDetail") &&
                <Button type="primary" style={{marginRight: 10}}>
                    <Link to={MakeRoute(Routes.FacilityDetail, {'id': this.state.facility.id})}>
                        Szczegóły
                    </Link>
                </Button>
            }>

                <FacilitySimpleDisplay data={this.state.facility} noLinks={!Permissions.canDo('FacilityDetail')}/>
                <Divider dashed/>
                <AddressDisplay data={this.state.facility?.address}/>
            </Card>
        </>)
    }

    renderRealizerCard() {
        return (this.state.realizer && <>
            <Card style={{marginTop: 20}} title="Realizator" extra={
                <Button type="primary" style={{marginRight: 10}}>
                    <Link to={MakeRoute(Routes.UserDetail, {'id': this.state.realizer.id})}>
                        Szczegóły
                    </Link>
                </Button>
            }>
                <UserDetailsDisplay data={this.state.realizer} essentialOnly/>
            </Card>
        </>)
    }

    renderSubcontractorCard() {
        return (this.state.subcontractor && <>
            <Card
                style={
                    {
                        marginTop: 20
                    }
                }
                title="Podwykonawca"
                extra={
                    <Button type="primary" style={{marginRight: 10}}>
                        <Link to={MakeRoute(Routes.SubcontractorDetail, {'id': this.state.subcontractor.id})}>
                            Szczegóły
                        </Link>
                    </Button>
                }>
                <SubcontractorDetailsDisplay data={this.state.subcontractor} essentialOnly/>
            </Card>
        </>)
    }

    renderWorkerAssignCard() {
        return (<>
            <Card style={{marginTop: 20}} title="Przypisz brygadzistę">
                <Row>
                    <Col span={16}>Brygadzista</Col>
                    <Col span={8}>Oczekiwana data realizacji</Col>
                </Row>
                <Row>
                    <Col span={16}>
                        <VSelect
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.state.workerId}
                            style={{width: '70%'}}
                            onChange={(value) => this.setState({workerId: value})}
                            options={this.state.workerList}/>
                    </Col>
                    <Col span={8}>
                        <VDatePicker
                            placeholder="Oczekiwana data realizacji"
                            value={this.state.workerRealizationDate}
                            onChange={(date, dateString) => this.setState({workerRealizationDate: dateString})}/>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col span={8}><Button onClick={() => this.assignWorker()}>Zapisz</Button></Col>
                </Row>
            </Card>
        </>)
    }

    renderWorkerCard() {
        return (!this.state.isWorker && <>
            <Card style={{marginTop: 20}} title="Brygadzista" extra={
                <>
                    {this.state.worker &&
                    <Button type="primary" style={{marginRight: 10}}>
                        <Link to={
                            this.state.isManager ?
                                MakeRoute(Routes.WorkerDetail, {'id': this.state.worker?.id}) :
                                MakeRoute(Routes.UserDetail, {'id': this.state.worker?.id})
                        }
                        >
                            Szczegóły
                        </Link>
                    </Button>
                    }
                    {
                        Permissions.canDo("CommissionSetWorker") && this.state.commissionStatus === 'ACTIVE' &&
                        <Button type="primary" style={{marginRight: 10}}
                                onClick={() => this.setState({selectWorker: true})}>
                            Przypisz brygadzistę
                        </Button>
                    }
                </>
            }>
                {
                    !this.state.worker?.id && "Brak"
                }
                {
                    this.state.worker?.id &&
                    <>

                        <UserDetailsDisplay data={this.state.worker} essentialOnly extra={
                            <Descriptions.Item
                                label="Oczekiwana data realizacji">{this.state.workerRealizationDate}
                            </Descriptions.Item>
                        }/>
                    </>
                }
            </Card>
        </>)
    }

    renderServicesHistory() {
        return (
            <Card
                title={"Inne zakończone serwisy w obiekcie"}
                style={{
                    marginTop: 20
                }}
            >
                <ServicesTable
                    getEndpoint={{
                        endpointName: 'ServicesListForCommissionFacility',
                        parameters: {commissionId: this.state.id}
                    }}
                    canDelete={false}
                    detailRouteCallback={
                        (item) => {
                            return MakeRoute(Routes.ServiceDetailForCommissionHistory,
                                {
                                    id: item.id,
                                    commissionId: this.state.id
                                })
                        }
                    }/>
            </Card>
        )
    }

    showAddServiceModal() {
        this.setState({
            showAddService: true
        })
    }

    addService() {
        authFetch({
            endpointName: "ServiceAddWithoutLocation",
            config: {
                method: 'post',
                body: JSON.stringify({
                    commissionId: this.state.id,
                    note: this.state.addServiceNote,
                })
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Dodano serwis z tego zlecenia',
                })
                this.setState({
                    showAddService: false
                })

                this.props.history.push((Routes.ServicesList))

            }
        })
    }

    cancelAddService() {
        this.setState({showAddService: false})
    }

    renderAddServiceModal() {
        return <Modal
            width={600}
            title={"Dodaj serwis"}
            visible={this.state.showAddService}
            okText={"Dodaj"}
            cancelText={"Anuluj"}
            onOk={() => this.addService()}
            onCancel={() => this.cancelAddService()}
        >

            <VTextArea
                value={this.state.addServiceNote}
                rules={{required: false}}
                validTriggerContainer={this.state.validTriggerContainer}
                onChange={(e) => this.setState({addServiceNote: e.target.value})}
                placeholder="Notatka"
                autoSize={{minRows: 3, maxRows: 5}}/>

        </Modal>
    }

    getCommissionStatusStyle() {
        let style = StatusColor(this.state.commissionStatus)
        style['display'] = 'inline-block'
        style['marginLeft'] = '15px'
        return style
    }

    render() {
        return (
            <>
                <Card
                    title={
                        <>
                            Zlecenie
                            {this.state.commissionStatus &&
                            <div style={this.getCommissionStatusStyle()}>
                                {this.commissionStatusDict[this.state.commissionStatus]}
                            </div>
                            }
                        </>
                    }
                    bordered={true} extra={
                    this.state.id &&
                    <>
                        {
                            this.state.commissionStatus === 'ACTIVE' && Permissions.canDo("ServiceAdd") &&
                            <Button style={{marginRight: 10}} type="primary"
                                    onClick={() => this.showAddServiceModal()}>
                                Dodaj serwis
                            </Button>
                        }
                        {
                            Permissions.canDo("CommissionEdit") &&
                            <Button type="primary" disabled={this.state.commissionStatus !== "ACTIVE"}
                                    style={{marginRight: 10}}>
                                <Link to={MakeRoute(Routes.CommissionEdit, {'id': this.state.id})}>
                                    Edytuj
                                </Link>
                            </Button>
                        }
                        {
                            this.state.commissionStatus === 'ACTIVE' && Permissions.canDo("CommissionClose") ?
                                <Button
                                    type="primary"
                                    style={{marginRight: 10}}
                                    onClick={() => this.cancelCommission()}>
                                    Anuluj zlecenie
                                </Button>
                                : ''
                        }
                                                {
                            this.state.commissionStatus === 'CANCELLED' && Permissions.canDo("CommissionRestore") ?
                                <Button
                                    type="primary"
                                    style={{marginRight: 10}}
                                    onClick={() => this.restoreCommission()}>
                                    Przywróć zlecenie
                                </Button>
                                : ''
                        }
                        {Permissions.canDo("CommissionDelete") &&
                    <Button type="danger" onClick={() => this.setState({visible: true})}>
                        Usuń
                    </Button>
                    }
                    </>
                }>


                    {this.renderStandardCommission()}

                    <Modal
                        title="Uwaga"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <p>Czy napewno chcesz usunać to zlecenie?</p>
                    </Modal>
                </Card>
                {this.renderFacilityCard()}
                {Permissions.canDo("UserDetail") && this.renderRealizerCard()}
                {Permissions.canDo("UserDetail") && this.renderSubcontractorCard()}
                {this.state.selectWorker && this.state.isManager && this.state.commissionStatus === 'ACTIVE' ? this.renderWorkerAssignCard() : this.renderWorkerCard()}
                <br></br>
                <Card title={"Pliki zlecenie"}>
                    <VFilesEdit editable={false} viewEnabled={true} viewName={"Commission"} viewId={this.state.id}/>
                </Card>

                {Permissions.canDo('ServiceListAllForCommissionFacility') && this.renderServicesHistory()}
                {this.renderAddServiceModal()}
            </>
        );
    }
}

export default withRouter(CommissionDetail);
