import React from "react";
import {Button, Col, InputNumber, notification, Row, Switch, Table} from "antd";
import {VSelect, VTextArea} from "../../../../../component/form/VForm";
import {authFetch} from "../../../../../utils/ApiHelper";
import TableHelper from "../../../../../utils/TableHelper";
import styles from "../../../../../style/dashboard/view/user/UserList.module.scss";
import {DeleteOutlined} from "@ant-design/icons";
import DeviceTypes from "../../../../../config/Protocol/v2/Protocol2DeviceTypes";
import ControlTypes from "../../../../../config/Protocol/v2/Protocol2ControlTypes";
import LocalizationTypes from "../../../../../config/Protocol/v2/Protocol2LocalizationTypes";
import DeviceEfficiencies from "../../../../../config/Protocol/v2/Protocol2DeviceEfficiencies";
import NotesTypes from "../../../../../config/Protocol/v2/Protocol2Notes";
import ProtocolRowFile from "../ProtocolRowFile";

class AddProtocol2 extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            validTriggerContainer: [],

            pagination: {
                pageSize: 10,
            },

            overviewResultsList: [],
            showOnlyReturn: false,

            tableFreeIndex: 0
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.fetchProtocol()
        }
    }

    componentDidMount() {
        this.fetchProtocol()
    }

    fetchProtocol(data = this.props.data) {
        let cnt = 0
        let overviewResults = []
        if (data && data.overviewResults) {
            data.overviewResults.forEach(x => x.index = cnt++);
            overviewResults = data.overviewResults
        }

        this.setState({
            overviewResultsList: overviewResults,
            tableFreeIndex: cnt
        })
    }

    validTrigger() {
        for (let item of this.state.validTriggerContainer) item()
    }

    valid() {
        this.validTrigger()
    }

    setOverviewResultsListByIndex(index, key, value) {
        let listCopy = [...this.state.overviewResultsList]
        listCopy.forEach(x => {
            if (x.index === index) x[key] = value
        })

        this.setState({
            overviewResultsList: listCopy
        })
    }

    isProtocolValid() {
        return true
    }

    deleteReportRow(record) {
        let index = record.index;
        const result = this.state.overviewResultsList.filter(x => x.index !== index);
        this.setState({
            overviewResultsList: result
        })
    }

    addProtocol() {
        if (this.isProtocolValid()) {
            authFetch({
                endpointName: this.props.protocolId ? "ProtocolSpecificVersionEdit" : "ProtocolSpecificVersionAdd",
                endpointParameters: {
                    serviceId: this.props.serviceId,
                    protocolId: this.props.protocolId,
                    version: 2
                },
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        systemType: this.props.systemTypeName,
                        serviceType: this.props.activityTypeName,
                        overviewResults: this.state.overviewResultsList,
                        endDate: this.props.endDate,
                        scope: this.props.scope
                    })
                },
                successFunction: data => {

                    if (this.props.protocolId) {
                        notification['success']({
                            message: 'Udało się zmodyfikować protokół',
                        });

                    } else if (this.props.addSuccessFunction) {
                        this.props.addSuccessFunction({
                            protocolId: data.simpleProtocol?.protocolId
                        })
                    }
                    this.fetchProtocol(data)
                }
            })

        } else {
            notification['error']({
                message: 'Błąd',
                description: 'Najpierw uzupełnij wymagane dane',
            });
        }
    }

    renderList() {
        let columns = [
            {
                title: 'Typ urządzenia',
                dataIndex: 'deviceType',
                key: '',
                width: '26%',
                ...this.tableHelper.getColumnSearchProps('deviceType'),
                render: (text, record, index) => <VSelect
                    value={text}
                    disabled={!this.props.isActive}
                    style={{width: '100%'}}
                    onChange={(e) => {
                        this.setOverviewResultsListByIndex(record.index, "deviceType", e)
                    }}
                    canAddNew={true}
                    options={DeviceTypes}/>,
            },
            {
                title: 'Rodzaj sterowania',
                dataIndex: 'controlType',
                key: '',
                width: '26%',
                ...this.tableHelper.getColumnSearchProps('controlType'),
                render: (text, record, index) => <VSelect
                    value={text}
                    disabled={!this.props.isActive}
                    style={{width: '100%'}}
                    onChange={(e) => {
                        this.setOverviewResultsListByIndex(record.index, "controlType", e)
                    }}
                    canAddNew={true}
                    options={ControlTypes}/>,
            },
            {
                title: 'Kondygnacja',
                dataIndex: 'level',
                key: '',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('level'),
                render: (text, record, index) => <InputNumber
                    placeholder="Kondygnacja"
                    value={text}
                    disabled={!this.props.isActive}
                    precision={0}
                    onChange={(e) => {
                        this.setOverviewResultsListByIndex(record.index, "level", e)
                    }
                    }/>,
            },
            {
                title: 'Lokalizacja',
                dataIndex: 'localization',
                key: '',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('localization'),
                render: (text, record, index) => <div>
                    <VSelect
                        value={text}
                        disabled={!this.props.isActive}
                        style={{width: '100%'}}
                        canAddNew={true}
                        onChange={(e) => {
                            this.setOverviewResultsListByIndex(record.index, "localization", e)
                        }}
                        options={LocalizationTypes}/>
                </div>,
            },
            {
                title: 'Nazwa',
                dataIndex: 'name',
                key: '',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('name'),
                render: (text, record, index) => <VTextArea
                    placeholder="Nazwa"
                    value={text}
                    disabled={!this.props.isActive}
                    canAddNew={true}
                    autoSize={{minRows: 1, maxRows: 3}}
                    onChange={(e) => {
                        this.setOverviewResultsListByIndex(record.index, "name", e.target.value)
                    }
                    }/>,
            },
            {
                title: 'Ilość',
                dataIndex: 'quantity',
                key: '',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('quantity'),
                render: (text, record, index) => <InputNumber
                    placeholder="Ilośc"
                    value={text}
                    disabled={!this.props.isActive}
                    precision={0}
                    min={1}
                    onChange={(e) => {
                        this.setOverviewResultsListByIndex(record.index, "quantity", e)
                    }
                    }/>,
            },
            {
                title: 'Sprawność urządzenia',
                dataIndex: 'deviceEfficiency',
                key: '',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('deviceEfficiency'),
                render: (text, record, index) => <div>
                    <VSelect
                        value={text}
                        disabled={!this.props.isActive}
                        style={{width: '100%'}}
                        canAddNew={false}
                        onChange={(e) => {
                            this.setOverviewResultsListByIndex(record.index, "deviceEfficiency", e)
                        }}
                        options={DeviceEfficiencies}/>
                </div>,
            },
            {
                title: 'Uwagi',
                dataIndex: 'notes',
                key: '',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('notes'),
                render: (text, record, index) => <div>
                    <VSelect
                        value={text}
                        disabled={!this.props.isActive}
                        style={{width: '100%'}}
                        canAddNew={true}
                        onChange={(e) => {
                            this.setOverviewResultsListByIndex(record.index, "notes", e)
                        }}
                        options={NotesTypes}/>
                </div>,
            },

            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 125,
                render: (text, record, index) =>
                    <div className={styles.iconHolder}>
                        <ProtocolRowFile
                            protocolVersion="2"
                            rowId={record.id}
                            fileResponse={record.fileInfoResponse}
                            isActive={this.props.isActive}
                            hasFullAccess={this.props.hasFullAccess}
                            addFile={(fResponse) => this.setOverviewResultsListByIndex(record.index, 'fileInfoResponse', fResponse)}
                        />

                        {this.props.isActive &&

                        <DeleteOutlined
                            style={{color: 'red'}}
                            className={styles.iconItem}
                            onClick={() => this.deleteReportRow(record)}/>

                        }

                    </div>,
            }
        ];


        let data = this.state.overviewResultsList

        if (this.state.showOnlyReturn) {
            data = data.filter(x => x.deviceEfficiency === 'RETURN')
        }

        return <Table
            pagination={this.state.pagination}
            columns={columns}
            dataSource={data}
            scroll={{x: 1500}}
            rowKey="index"
        />

    }

    addNewItemToList() {
        let newList = this.state.overviewResultsList.concat([{
            index: this.state.tableFreeIndex,
            deviceType: null,
            controlType: null,
            level: null,
            localization: null,
            name: null,
            quantity: null,
            deviceEfficiency: null,
            notes: null,
        }])


        this.setState({
            overviewResultsList: newList,
            tableFreeIndex: this.state.tableFreeIndex + 1
        })
    }

    render() {
        return (
            <>
                <br/>
                <Row>
                    <Col span={10}>Wyniki przeglądu:</Col>
                </Row>

                {this.props.isActive &&
                <Row className="inputRow">

                    <Col>
                        Pokaż tylko &quot;wróć później&quot;
                        <Switch style={{marginLeft: 10}} onChange={checked =>
                            this.setState({
                                showOnlyReturn: checked
                            })
                        }/>
                    </Col>
                    {!this.state.showOnlyReturn &&
                    <Col style={{marginLeft: 10}}>
                        <Button
                            onClick={() => {
                                this.addNewItemToList()
                            }}
                            type="primary">
                            Dodaj element
                        </Button>
                    </Col>
                    }


                </Row>
                }

                {this.renderList()}

                <Row justify={"end"}>
                    {this.props.isActive &&
                    <Col>
                        <Button
                            justify="end"
                            onClick={() => {
                                this.addProtocol()
                            }}
                            type="primary">
                            {this.props.protocolId ? <>Uaktualnij protokół</> : <>Dodaj protokół</>}
                        </Button>
                    </Col>
                    }
                </Row>

            </>
        );
    }

}

export default AddProtocol2;
