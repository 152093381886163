import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, Col, DatePicker, Divider, Input, Modal, notification, Row, Select, Table} from 'antd';
import {CheckCircleOutlined, CloseOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import moment from "moment";

import {ShareState} from '../../../../utils/ShareState';
import {MakeRoute, Routes} from "../../../../config/Routes";
import Permissions from '../../../../config/Permissions';
import {authFetch} from "../../../../utils/ApiHelper";
import NumberFormat from 'react-number-format';

import styles from '../../../../style/dashboard/view/user/UserList.module.scss';

import TableHelper from "../../../../utils/TableHelper";
import TenderDetailDisplay from "../../displayInfos/TenderDetailDisplay";
import FacilitySimpleDisplay from "../../displayInfos/facility/FacilitySimpleDisplay";
import AddressDisplay from "../../displayInfos/AddressDisplay";
import UserDetailsDisplay from "../../displayInfos/UserDetailsDisplay";
import {getSubcontractorList} from "./getSubcontractorList";

const {TextArea} = Input;


class TenderDetail extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            facility: null,
            realizationDate: null,
            status: null,
            realizer: [],
            facilityName: null,
            facilityAddress: null,
            facilityId: null,
            facilityVoivodeship: null,
            note: null,
            reviewDate: null,
            complaintNotice: null,
            submissionBids: null,
            scope: null,
            topic: null,
            tenderType: null,
            offers: null,
            allowedSubcontractors: [],

            subcontractorsList: null,
            subcontractorsChoosen: [],
            pagination: {
                pageSize: 10,
            },
            finalSalaries: {},
            salaries: {},
            notes: {},
            realizationDates: {},

            currentOfferEditing: -1,
            isAddOfferModalVisible: false,

            addOfferSubcontractorId: null,
            addOfferSalary: null,
            addOfferRealizationDate: null,
            addOfferNote: null,
            isSelectedOffer: false,
        };
    }

    getSubcontractorList() {
        getSubcontractorList((subcontractorsList) => {
            this.setState({
                subcontractorsList
            })
        })
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Informacje o przetargu')
        this.getDetailed();
        this.getSubcontractorList();
    }

    getDetailed() {
        authFetch({
            endpointName: "tenderDetail",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                for (let item of data.allowedSubcontractors) {
                    if (item.manager != null) {
                        item['manager_name'] = item.manager.firstName + " " + item.manager.lastName;
                    } else {
                        item['manager_name'] = "Nie posiada przypisanego";
                    }
                }

                this.setState({
                    facility: data.facility,
                    realizationDate: data.realizationDate,
                    salesman: data.facility.salesman,
                    realizer: data.realizer,
                    facilityVoivodeship: data.facility.address.voivodeship,
                    facilityName: data.facility.name,
                    facilityAddress: data.facility.address.address,
                    facilityCity: data.facility.address.city,
                    facilityStreet: data.facility.address.street,
                    facilityPostalCode: data.facility.address.postalCode,
                    facilityId: data.facility.id,
                    note: data.note,
                    reviewDate: data.reviewDate,
                    topic: data.topic,
                    tenderType: data.tenderType,
                    scope: data.scope,
                    status: data.tenderStatus,
                    complaintNotice: data.complaintNotice,
                    submissionBids: data.submissionBids,
                    allowedSubcontractors: data.allowedSubcontractors,
                    offers: data.offers,
                    documentNumber: data.documentNumber
                })

                this.checkSelectedOffer(data)
            }
        })
        this.getSubcontractorsList();
    }

    checkSelectedOffer(data) {
        for (let offer of data.offers) {
            if (offer.chosen) {
                this.setState({selectedOffer: offer})
                return;
            }
        }
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };

    addOffer() {
        if (this.isAddOfferValid()) {
            authFetch({
                endpointName: "TenderAddOfferForSubcontractor",
                endpointParameters: {tender_id: this.state.id, subcontractor_id: this.state.addOfferSubcontractorId},
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        note: this.state.addOfferNote,
                        salary: this.state.addOfferSalary,
                        realizationDate: this.state.addOfferRealizationDate
                    })
                },
                successFunction: data => {
                    notification['success']({
                        message: 'OK',
                        description: 'wysłano ofertę',
                    });
                    this.getDetailed();
                    this.setState({
                        addOfferNote: null,
                        addOfferRealizationDate: null,
                        addOfferSubcontractorId: null,
                        addOfferSalary: '',
                        isAddOfferModalVisible: false
                    })
                }
            })
        } else {
            notification['error']({
                message: 'Niepowodzenie',
                description: 'Pola "Kwota" oraz "Data realizacji" nie mogą być puste.',
            });
        }
    }

    getSubcontractorsList() {
        authFetch({
            endpointName: "GetSubcontractorWithManagersList",
            successFunction: data => {
                if (!data.subcontractors) return;

                var subcontractors = []
                for (let subcontractor in data.subcontractors) {
                    subcontractors.push({
                        label: data.subcontractors[subcontractor].name,
                        value: data.subcontractors[subcontractor].id,
                        id: data.subcontractors[subcontractor].id
                    })
                }
                this.setState({
                    subcontractorsList: subcontractors
                })
            }
        })
    }

    sendToSubcontractors(addToExists = false) {
        if (!this.isValid()) {
            notification['error']({
                message: 'Błąd',
                description: 'Nie wybrano podwykonawców',
            });
        } else {
            let endpintName = addToExists ? "SendToNewSubcontractors" : "SendToSubcontractors"
            authFetch({
                endpointName: endpintName,
                endpointParameters: {"id": this.state.id},
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        subcontractorsIds: this.state.subcontractorsChoosen
                    })
                },
                successFunction: data => {
                    this.clearState();
                    notification['success']({
                        message: 'OK',
                        description: 'Wysłano do wykonawców',
                    });
                    this.getSubcontractorsList();
                    this.getDetailed();
                }
            })
        }
    }

    deleteSubcontractor(id, name) {
        authFetch({
            endpointName: "TenderDetachSubcontractors",
            endpointParameters: {"id": this.state.id},
            config: {
                method: 'post',
                body: JSON.stringify({
                    subcontractorsIds: [id]
                })
            },
            successFunction: data => {
                this.clearState();
                notification['success']({
                    message: 'OK',
                    description: 'Cofnięto wysłanie przetargu do wykonawcy ' + name,
                });
                this.getSubcontractorsList();
                this.getDetailed();
            }
        })
    }

    choseOffer(id, name, offer) {
        authFetch({
            endpointName: "TenderChooseOffer",
            endpointParameters: {"id": id},
            config: {
                method: 'get'
            },
            successFunction: data => {
                this.clearState();
                notification['success']({
                    message: 'OK',
                    description: 'Wybrano ofertę od podwykonawcy: ' + name,
                });

                this.getSubcontractorsList();
                this.getDetailed();
                this.setState({
                    selectedOffer: offer
                })
            }
        })
    }

    deselectOffer(item) {
        authFetch({
            endpointName: "TenderDeselectOffer",
            endpointParameters: {id: item.offerId},
            config: {
                method: 'post'
            },
            successFunction: data => {
                this.clearState();
                notification['success']({
                    message: 'OK',
                    description: 'Cofnięto przypisanie oferty do podwykonawcy',
                });

                this.getSubcontractorsList();
                this.getDetailed();
                this.setState({
                    selectedOffer: null
                })
            }
        })
    }

    modifyOffer(id, json) {
        if (Object.keys(json).length !== 0) {

            authFetch({
                endpointName: "OfferModifyByRealizer",
                endpointParameters: {"id": id},
                config: {
                    method: 'post',
                    body: JSON.stringify(json)
                },
                successFunction: data => {
                    this.clearState();
                    notification['success']({
                        message: 'OK',
                        description: 'Zaktualizowano dane oferty'
                    });
                    this.getSubcontractorsList();
                    this.getDetailed();
                }
            })
        }
    }

    updateFinalSalary(id, salary, newSalary) {
        if (salary !== newSalary) {

            authFetch({
                endpointName: "OfferUpdateFinalSalary",
                endpointParameters: {"id": id},
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        finalSalary: newSalary,
                    })
                },
                successFunction: data => {
                    this.clearState();
                    notification['success']({
                        message: 'OK',
                        description: 'Zaktualizowano kwotę ostateczną'
                    });
                    this.getSubcontractorsList();
                    this.getDetailed();
                }
            })
        }
    }

    disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    handleAddOfferOk() {
    }

    handleAddOfferCancel() {
        this.setState({
            isAddOfferModalVisible: false,
            addOfferSubcontractorId: null,
            addOfferRealizationDate: null,
            addOfferSalary: '',
            addOfferNote: null
        })
    }

    convertToISO(date, time) {
        return date + "T" + time + "Z";
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    datePickerHandler(date, dateString, property) {
        let state = this.state
        state[property] = dateString
        this.setState(state);
    }

    clearState() {
        this.setState({
            subcontractorsChoosen: []
        });
    }

    isAddOfferValid() {
        return this.state.addOfferRealizationDate != null && this.state.addOfferSalary != null && this.state.addOfferSubcontractorId != null;
    }

    isValid() {
        return !(this.state.subcontractorsChoosen === [] || this.state.subcontractorsChoosen.length <= 0);
    }

    getUnchoosenSubcontractors() {
        if (!this.state.allowedSubcontractors || !this.state.subcontractorsList) return []
        let selectedIds = []
        let resultSet = []
        for (let item of this.state.allowedSubcontractors) {
            selectedIds.push(item.id)
        }
        for (let item of this.state.subcontractorsList) {
            if (!selectedIds.includes(item.id)) {
                resultSet.push(item)
            }
        }
        return resultSet
    }

    getAllowedSubcontractorsForAddOffer() {
        let subcontractors = this.state.allowedSubcontractors
        if (!subcontractors) return []


        return subcontractors.map(x => {
                return {
                    'label': x.name,
                    'value': x.id
                }
            }
        )
    }

    render() {
        const columns = [
            {
                title: 'Nazwa',
                dataIndex: '',
                key: 'name',
                width: '30%',

                ...this.tableHelper.getColumnSearchProps('name'),
                render: (item) => <a href={MakeRoute(Routes.SubcontractorDetail, {'id': item.id})}>
                    <div style={{fontWeight: "bold"}}>
                        {item.name}
                    </div>
                </a>,
            },
            {
                title: 'Adres',
                dataIndex: 'address',
                key: 'address',
                width: '35%',
                ...this.tableHelper.getColumnSearchProps('address'),
            },
            {
                title: 'Kierownik',
                dataIndex: '',
                key: 'manager_name',
                width: '25%',
                ...this.tableHelper.getColumnSearchProps('manager_name'),
                render: (item) =>
                    <div>
                        {item.manager &&
                        <a href={MakeRoute(Routes.UserDetail, {'id': item.manager.id})}>
                            <div style={{fontWeight: "bold"}}>
                                {item.manager_name}
                            </div>
                        </a>} {!item.manager &&
                    <span>{"Nie posiada przypisanego"}</span>
                    }
                    </div>
            },
            {
                title: 'Telefon',
                dataIndex: 'phone',
                key: 'phone',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('phone'),
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 80,
                render: (item) => <div className={styles.iconHolder}>

                    <DeleteOutlined
                        style={{color: 'red'}}
                        className={styles.iconItem}
                        onClick={() => this.deleteSubcontractor(item.id, item.name)}/>
                </div>,
            },

        ];

        const offerColumns = [
            {
                title: 'Podwykonawca',
                dataIndex: '',
                key: 'name',
                width: '25%',

                ...this.tableHelper.getColumnSearchProps('name'),
                render: (item) => <a href={MakeRoute(Routes.SubcontractorDetail, {'id': item.subcontractor.id})}>
                    <div style={{fontWeight: "bold"}}>
                        {item.subcontractor.name}
                    </div>
                </a>,
            },
            {
                title: 'Wycena',
                dataIndex: '',
                key: 'salary',
                width: '10%',
                ...this.tableHelper.getColumnSearchProps('salary'),
                render: (item) => <div>
                    {/*  {(this.state.currentOfferEditing === item.offerId) &&*/}
                    {/*  <NumberFormat value={item.salary} style={{height: "100%"}} thousandSeparator={true} prefix={'PLN '}*/}
                    {/*                customInput={Input} onValueChange={(values) => {*/}
                    {/*    const {floatValue} = values;*/}
                    {/*    item.newSalary = floatValue*/}
                    {/*  }}/>*/}
                    {/*  } {(this.state.currentOfferEditing !== item.offerId) &&*/}
                    {/*<NumberFormat value={item.salary} style={{height: "100%"}} thousandSeparator={true} prefix={'PLN '}*/}
                    {/*              displayType={"text"}/>*/}
                    {/*}*/}


                    <NumberFormat value={item.salary} style={{height: "100%"}} thousandSeparator={true} prefix={'PLN '}
                                  displayType={"text"}/>
                </div>,

            },
            {
                title: 'Termin',
                dataIndex: '',
                key: 'realizationDate',
                width: '12%',
                ...this.tableHelper.getColumnSearchProps('address'),
                render: (item) => <div>
                    {(this.state.currentOfferEditing === item.offerId) &&
                    <DatePicker style={{float: "left", width: "100%", height: "100%"}}
                                format="YYYY-MM-DD"
                                defaultValue={moment(item.realizationDate)}
                                disabledDate={this.disabledDate}
                                onChange={(date, dateString) => (item.newRealizationDate = dateString)}>
                    </DatePicker>
                    } {(this.state.currentOfferEditing !== item.offerId) &&
                <div>{item.realizationDate}</div>
                }
                </div>,
            },
            {
                title: 'Uwagi',
                dataIndex: '',
                key: '',
                width: '26%',
                ...this.tableHelper.getColumnSearchProps('note'),
                render: (item) => <div>
                    {(this.state.currentOfferEditing === item.offerId) &&
                    <Input defaultValue={item.note} style={{height: "100%"}} onChange={(e) => {
                        item.newNote = e.target.value
                    }}/>
                    } {(this.state.currentOfferEditing !== item.offerId) &&
                <div>{item.note}</div>
                }
                </div>,
            },
            {
                title: 'Wygrana',
                dataIndex: 'chosen',
                key: 'chosen',
                width: '7%',
                render: (item) => <div>
                    {item && <b>{"Tak"}</b>}
                    {!item && <span>{"Nie"}</span>}
                </div>
            },
            {
                title: 'Kwota ostateczna',
                dataIndex: '',
                key: 'finalSalary',
                width: '10%',
                render: (item) => <div>
                    {(this.state.currentOfferEditing === item.offerId) &&
                    <NumberFormat placeholder={item.finalSalary} defaultValue={item.finalSalary ? item.finalSalary : ""}
                                  style={{height: "100%"}} thousandSeparator={true} prefix={'PLN '} customInput={Input}
                                  onValueChange={(values) => {
                                      const {floatValue} = values;
                                      var finalSalaries = this.state.finalSalaries;
                                      finalSalaries[item.offerId] = floatValue;
                                      this.setState({finalSalaries: finalSalaries});
                                  }}/>
                    } {(this.state.currentOfferEditing !== item.offerId) &&
                <NumberFormat value={item.finalSalary ? item.finalSalary : ""} style={{height: "100%"}}
                              thousandSeparator={true}
                              prefix={'PLN '} displayType={"text"}/>
                }
                </div>
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 150,
                render: (item) => <div className={styles.iconHolder}>
                    {(this.state.currentOfferEditing !== item.offerId) &&
                    <div>
                        {this.state.status !== 'SETTLED' &&
                        <EditOutlined
                            className={styles.iconItem}
                            onClick={() => {
                                this.setState({currentOfferEditing: item.offerId})
                            }}/>
                        }
                        {!item.chosen && this.state.status !== 'SETTLED' &&
                        <CheckCircleOutlined
                            style={{color: 'green'}}
                            className={styles.iconItem}
                            onClick={() => {
                                this.choseOffer(item.offerId, item.subcontractor.name, item)
                            }}/>
                        }
                        {item.chosen &&
                        <CheckCircleOutlined
                            style={{color: 'gray'}}
                            className={styles.iconItem}/>
                        }
                        {item.chosen &&
                        <CloseOutlined
                            onClick={() => this.deselectOffer(item)}
                            style={{color: 'red'}}
                            className={styles.iconItem}/>
                        }
                    </div>
                    }
                    {(this.state.currentOfferEditing === item.offerId) &&
                    <div>
                        <Button type="primary" onClick={() => {
                            this.setState({currentOfferEditing: -1});
                            var json = {};
                            if (item.newNote && item.note !== item.newNote) {
                                json.note = item.newNote;
                            }
                            if (item.newRealizationDate && item.realizationDate !== item.newRealizationDate) {
                                json.realizationDate = item.newRealizationDate
                            }
                            if (item.newSalary && item.salary !== item.newSalary) {
                                json.salary = item.newSalary
                            }
                            this.modifyOffer(item.offerId, json)
                            if (this.state.finalSalaries[item.offerId] && item.finalSalary !== this.state.finalSalaries[item.offerId]) {
                                this.updateFinalSalary(item.offerId, item.finalSsalary, this.state.finalSalaries[item.offerId]);
                            }

                        }}>{"Ok"}</Button>
                    </div>
                    }
                </div>,
            },

        ];

        return (
            <div>
                <div>
                    <Modal width={1200} title="Dodaj ofertę za Podwykonawcę" visible={this.state.isAddOfferModalVisible}
                           onOk={() => this.handleAddOfferOk()} onCancel={() => this.handleAddOfferCancel()} footer={[
                        <Button key="back" onClick={() => this.handleAddOfferCancel()}>
                            Anuluj
                        </Button>,
                        <Button disabled={!this.isAddOfferValid()} key="submit" type="primary"
                                onClick={() => this.addOffer()}>
                            Dodaj
                        </Button>,
                    ]}>
                        <div>
                            <Row>
                                Podwykonawca
                            </Row>
                            <Row className="inputRow">
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    options={this.getAllowedSubcontractorsForAddOffer()}
                                    value={this.state.addOfferSubcontractorId}
                                    style={{width: '100%'}}
                                    onChange={(value, subcontractor) => this.setState({addOfferSubcontractorId: value})}
                                />
                            </Row>
                            <Row className="inputRow">
                                <Col span={3}>
                                    Kwota
                                </Col>
                                <Col span={5}>
                                    Data realizacji
                                </Col>
                                <Col span={16}>
                                    Uwagi
                                </Col>
                            </Row>
                            <Row className="inputRow">
                                <Col span={3} style={{paddingRight: 16}}>
                                    <NumberFormat

                                        value={this.state.addOfferSalary}
                                        style={{height: "100%"}}
                                        thousandSeparator={true}
                                        prefix={'PLN '}
                                        customInput={Input}
                                        onValueChange={(values) => {
                                            const {floatValue} = values;
                                            this.setState({addOfferSalary: floatValue})
                                        }}

                                    />
                                </Col>
                                <Col span={5} style={{paddingRight: 16}}>
                                    <DatePicker style={{float: "left", width: "100%", height: "100%"}}
                                                format="YYYY-MM-DD"
                                                defaultValue={this.state.addOfferRealizationDate ? moment(this.state.addOfferRealizationDate) : null}
                                                disabledDate={this.disabledDate}
                                                value={this.state.addOfferRealizationDate ? moment(this.state.addOfferRealizationDate) : null}
                                                onChange={(date, dateString) => this.datePickerHandler(date, dateString, "addOfferRealizationDate")}>
                                    </DatePicker>
                                </Col>
                                <Col span={16}>
                                    <TextArea
                                        style={{width: "100%", resize: "none"}}
                                        rows="2"
                                        placeholder="Uwagi"
                                        value={this.state.addOfferNote}
                                        onChange={(e) => this.inputHandler('addOfferNote', e)}/>
                                </Col>

                            </Row>
                        </div>
                    </Modal>
                </div>
                <div>
                    <Card title={"Przetarg"}>
                        <TenderDetailDisplay data={this.state}/>

                    </Card>
                    <br></br>
                    <Card title="Obiekt" bordered={true} extra={
                        <Button type="primary">
                            {this.state.facility != null &&
                            <Link to={MakeRoute(Routes.FacilityDetail, {'id': this.state.facility.id})}>
                                Wyświetl
                            </Link>
                            }
                        </Button>
                    }>
                        <FacilitySimpleDisplay data={this.state.facility}/>

                        <Divider dashed/>

                        <AddressDisplay data={this.state.facility?.address}/>

                    </Card>
                    <br></br>
                    <Card title={"Realizator"} extra={
                        <Button type="primary">
                            {this.state.realizer != null &&
                            <Link to={MakeRoute(Routes.UserDetail, {'id': this.state.realizer.id})}>
                                Wyświetl
                            </Link>
                            }
                        </Button>
                    }>
                        <UserDetailsDisplay data={this.state.realizer}/>

                    </Card>

                    <br></br>

                    {Permissions.canDo("TenderSendToSubcontractors") &&
                    <div>
                        {this.state.status === "CREATED" &&
                        <Card title={"Wyślij do podwykonawców"}>
                            <Row>
                                <Col span={24}>
                                    <Select
                                        mode="multiple"
                                        value={this.state.subcontractorsChoosen}
                                        style={{width: '100%'}}
                                        onChange={(value) => this.setState({subcontractorsChoosen: value})}
                                        options={this.state.subcontractorsList}>
                                    </Select>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Button type="primary" onClick={() => this.sendToSubcontractors()}>Wyślij przetarg do
                                    podwykonawców</Button>
                            </Row>
                        </Card>
                        }
                        {this.state.status !== "CREATED" &&
                        <Card title={"Podwykonawcy, którzy otrzymali przetarg"}>

                            {this.state.allowedSubcontractors.length > 0 &&

                            <Table
                                pagination={this.state.pagination}
                                columns={columns}
                                dataSource={this.state.allowedSubcontractors}
                                scroll={{x: 1200}}/>
                            }
                            {this.state.status !== "SETTLED" &&
                            <>
                                <Row>
                                    <Col span={24}>
                                        <Select
                                            mode="multiple"
                                            value={this.state.subcontractorsChoosen}
                                            style={{width: '100%'}}
                                            onChange={(value) => this.setState({subcontractorsChoosen: value})}
                                            options={this.getUnchoosenSubcontractors()}>
                                        </Select>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Button type="primary" onClick={() => this.sendToSubcontractors(true)}>Wyślij
                                        przetarg do
                                        podwykonawców</Button>
                                </Row>
                            </>
                            }

                        </Card>
                        }
                        <br></br>
                        {this.state.status !== "CREATED" &&
                        <Card title={"Oferty od podwykonawców"}>

                            {(this.state.offers != null && this.state.offers.length > 0) &&

                            <Table
                                pagination={this.state.pagination}
                                columns={offerColumns}
                                dataSource={this.state.offers}
                                scroll={{x: 1200}}/>
                            }

                            <Row>
                                {this.state.status !== "SETTLED" &&
                                <Button style={{marginRight: 16}} type="primary"
                                        onClick={() => (this.setState({isAddOfferModalVisible: true}))}>Dodaj
                                    ofertę</Button>
                                }
                                {
                                    this.state.selectedOffer
                                    && <Link
                                        to={MakeRoute(Routes['CommissionAddFromOffer'], {fromOfferId: this.state.selectedOffer.offerId})}>
                                        <Button type="primary">Dodaj zlecenie z wybranej oferty</Button>
                                    </Link>
                                }
                            </Row>

                        </Card>
                        }
                    </div>

                    }
                </div>
            </div>
        );
    }
}

export default withRouter(TenderDetail);
