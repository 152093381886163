import React from "react";
import {Table} from 'antd';
import TableHelper from '../../../../../utils/TableHelper'
import {InfoCircleOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import {MakeRoute, Routes} from "../../../../../config/Routes";
import {Voivodeship} from "../../../../../config/Voivodeship";
import styles from '../../../../../style/dashboard/view/user/UserList.module.scss';

class OfferTable extends React.Component {
    tableHelper = new TableHelper(this);
    Voivodeship = Voivodeship.map(item => {
        item['text'] = item['label'];
        return item
    })
    FacilityStatus = this.props.statuses.map(item => {
        item['text'] = item['label'];
        return item
    })
    FacilityStatusDict = this.props.statusesDict

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            pagination: {
                pageSize: 10,
            },
        };
    }

    statusColor = (status) => {
        var styles = {
            height: 30,
            width: 130,
            textAlign: "center",
            lineHeight: '30px',
            color: "white",
            fontWeight: "bold",
            borderRadius: '3px'
        }
        styles.backgroundColor = this.props.statusesColor[status];
        return styles;
    }


    render() {
        let columns = [
            {
                title: 'Nazwa obiektu',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnExcelSearchProps('name', this.props.data.map(x => x.name)),
                render: (data, record) =>
                    <Link to={MakeRoute(Routes.FacilityDetail, {id: record.id})}>{data}</Link>,
            },
            {
                title: 'Miasto',
                dataIndex: 'adress_city',
                key: 'adress_city',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('adress_city'),
            },
            {
                title: 'Województwo',
                dataIndex: 'adress_voivodeship',
                key: 'adress_voivodeship',
                width: '15%',
                filters: Voivodeship,
                onFilter: (value, record) => record.address.voivodeship.toLowerCase() === value,
            },
            {
                title: 'Rodzaj oferty',
                dataIndex: 'offerType',
                key: 'offerType',
                width: '20%',
                filters: this.FacilityStatus,
                onFilter: (value, record) => record.offerType === value,
                render: (item) =>
                    <div>
                        <div style={this.statusColor(item)}>
                            {this.FacilityStatusDict[item]}
                        </div>
                    </div>
            },
            {
                title: 'Handlowiec HS',
                dataIndex: 'salesman_label',
                key: 'salesman_label',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('salesman_label'),
            },
            {
                title: 'Data złożenia',
                dataIndex: 'offerDate',
                key: 'offerDate',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('offerDate'),
            },

            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 130,
                render: (item) => <div className={styles.iconHolder}>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.FacilityDetail, {'id': item.id})}>
                        <InfoCircleOutlined/>
                    </Link>
                </div>,
            },
        ];

        return (
            <Table
                pagination={this.state.pagination}
                columns={columns}
                dataSource={this.props.data}
                onChange={(a, b, c, dataBase) => this.props.udater(dataBase.currentDataSource)}
                scroll={{x: 1000}}/>
        );
    }
}

export default OfferTable;
