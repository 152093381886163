import React from "react"
import {withRouter} from 'react-router-dom'
import {ShareState} from '../../../../utils/ShareState'
import FacilityForm from './component/FacilityForm'

class FacilityEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
        }
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Edytuj informacje o obiekcie')
    }

    render() {
        return (<FacilityForm facilityId={this.state.id} loadInfo={true}/>)
    }
}

export default withRouter(FacilityEdit)
