export const FacilityColors = {
    'NOT_SERVICED': '#fe437e',
    'SERVICED': '#53c3b3',
    'NOT_ACTIVE': '#a3a3a3',
    'OFFERED': '#008c3f',
    'ACTIVE': '#54f542',
}

export const FacilityStatusColor = (status) => {
    var styles = {
        height: 30,
        width: 130,
        textAlign: "center",
        lineHeight: '30px',
        color: "white",
        fontWeight: "bold",
        borderRadius: '3px'
    }
    styles.backgroundColor = FacilityColors[status];
    return styles;
} 