import React from "react";
import AddProtocol1 from "./protocols/AddProtocol1";
import AddProtocol4 from "./protocols/AddProtocol4";
import AddProtocol5 from "./protocols/AddProtocol5";
import {Button, Checkbox, Col, Descriptions, Row} from "antd";
import {VDatePicker} from "../../../../component/form/VForm";
import {DownloadPdf} from "./protocols/Utils";
import {GetSystemTypeFromValue} from "../../../../config/Protocol/SystemTypes";
import {GetActivityTypeFromValue} from "../../../../config/Protocol/ActivityTypes";
import AddProtocol2 from "./protocols/AddProtocol2";
import AddProtocol3 from "./protocols/AddProtocol3";
import {VTextArea} from "../../../../component/form/VForm";
import AddProtocol6 from "./protocols/AddProtocol6";
import {authFetch} from "../../../../utils/ApiHelper";

class AddProtocol extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            polishProtocolName: this.getPolishProtocolName(props.protocolVersion),
            validTriggerContainer: [],
            scope: props.scope,
            endDate: props.endDate,

            protocolId: props.protocolId,

            protocolData: null,
            facilityName: props.facilityName
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let isDifferentId = this.props.protocolId !== prevProps.protocolId
            && this.state.protocolId !== this.props.protocolId
        if (isDifferentId || prevProps.protocolVersion !== this.props.protocolVersion) {
            this.setState({
                scope: this.props.scope,
                protocolData: null,
                protocolId: this.props.protocolId,
                polishProtocolName: this.getPolishProtocolName(this.props.protocolVersion),
            }, () => this.fetchProtocol())

        }

        if (prevProps.scope !== this.props.scope) {
            this.setState({
                scope: this.props.scope
            })
        }
    }

    componentDidMount() {
        this.fetchProtocol()
    }

    fetchProtocol() {
        if (this.state.protocolId) {
            authFetch({
                endpointName: "ProtocolSpecificVersionGet",
                endpointParameters: {
                    protocolId: this.state.protocolId,
                    version: this.getNumericVersionFromProtocolVersion(this.props.protocolVersion)
                },

                successFunction: data => {
                    this.setState({
                        scope: data.simpleProtocol?.scope,
                        endDate: data.simpleProtocol?.endDate,
                        protocolData: data
                    })

                }
            })
        }
    }


    getPolishProtocolName(protocolVersion) {
        switch (protocolVersion) {
            case "ProtocolVersion1":
                return "Protokół 1"
            case "ProtocolVersion2":
                return "Protokół 2"
            case "ProtocolVersion3":
                return "Protokół 3"
            case "ProtocolVersion4":
                return "Protokół 4"
            case "ProtocolVersion5":
                return "Protokół 5"
            case "ProtocolVersion6":
                return "Protokół wykonania prac serwisowych"
            default:
                return "Protokół"
        }
    }

    addSuccessFunction(data) {
        this.setState({
            protocolId: data.protocolId
        }, () => this.props.addSuccessFunction(data))
    }

    getNumericVersionFromProtocolVersion(protocolVersion) {
        switch (protocolVersion) {
            case "ProtocolVersion1":
                return 1
            case "ProtocolVersion2":
                return 2
            case "ProtocolVersion3":
                return 3
            case "ProtocolVersion4":
                return 4
            case "ProtocolVersion5":
                return 5
            case "ProtocolVersion6":
                return 6
            default:
                return 0
        }
    }

    autoEndDateCheckboxChanged = (checkboxValue) => {
        if (checkboxValue.target.checked) {
            this.setState({ endDate: null })
        } else {
            this.setState({ endDate: new Date()})
        }
    }

    renderProtocol1() {
        return (
            <AddProtocol1
                scope={this.state.scope}
                endDate={this.state.endDate}
                data={this.state.protocolData}
                serviceId={this.props.serviceId}
                systemTypeName={this.props.systemTypeName}
                protocolId={this.state.protocolId}
                activityTypeName={this.props.activityTypeName}
                addSuccessFunction={(value) => this.addSuccessFunction(value)}
                isActive={this.props.isActive}
                hasFullAccess={this.props.hasFullAccess}
            />
        )
    }

    renderProtocol2() {
        return (
            <AddProtocol2
                scope={this.state.scope}
                endDate={this.state.endDate}
                data={this.state.protocolData}
                serviceId={this.props.serviceId}
                systemTypeName={this.props.systemTypeName}
                protocolId={this.state.protocolId}
                activityTypeName={this.props.activityTypeName}
                addSuccessFunction={(value) => this.addSuccessFunction(value)}
                isActive={this.props.isActive}
                hasFullAccess={this.props.hasFullAccess}
            />
        )
    }

    renderProtocol3() {
        return (
            <AddProtocol3
                scope={this.state.scope}
                endDate={this.state.endDate}
                data={this.state.protocolData}
                serviceId={this.props.serviceId}
                systemTypeName={this.props.systemTypeName}
                protocolId={this.state.protocolId}
                activityTypeName={this.props.activityTypeName}
                addSuccessFunction={(value) => this.addSuccessFunction(value)}
                isActive={this.props.isActive}
                hasFullAccess={this.props.hasFullAccess}
            />
        )
    }

    renderProtocol4() {
        return (
            <AddProtocol4
                scope={this.state.scope}
                endDate={this.state.endDate}
                data={this.state.protocolData}
                serviceId={this.props.serviceId}
                systemTypeName={this.props.systemTypeName}
                protocolId={this.state.protocolId}
                activityTypeName={this.props.activityTypeName}
                addSuccessFunction={(value) => this.addSuccessFunction(value)}
                isActive={this.props.isActive}
                hasFullAccess={this.props.hasFullAccess}
            />
        )
    }

    renderProtocol5() {
        return (
            <AddProtocol5
                data={this.state.protocolData}
                scope={this.state.scope}
                endDate={this.state.endDate}
                serviceId={this.props.serviceId}
                systemTypeName={this.props.systemTypeName}
                protocolId={this.state.protocolId}
                activityTypeName={this.props.activityTypeName}
                addSuccessFunction={(value) => this.addSuccessFunction(value)}
                isActive={this.props.isActive}
                hasFullAccess={this.props.hasFullAccess}
            />
        )
    }

    renderProtocol6() {
        return (
            <AddProtocol6
                scope={this.state.scope}
                endDate={this.state.endDate}
                data={this.state.protocolData}
                serviceId={this.props.serviceId}
                protocolId={this.state.protocolId}
                addSuccessFunction={(value) => this.addSuccessFunction(value)}
                isActive={this.props.isActive}
                hasFullAccess={this.props.hasFullAccess}
            />
        )
    }

    render() {
        return (
            <>
                <Row>
                    <Col flex="50px"><Descriptions title={this.state.polishProtocolName}/></Col>

                    {this.state.protocolId && this.props.hasFullAccess &&
                    <Col flex="auto"> <Button
                        justify="end"
                        style={{marginLeft: "10px"}}
                        onClick={() => {
                            DownloadPdf(this.state.protocolId, this.state.facilityName)
                        }}
                        type="primary">
                        Pobierz PDF
                    </Button>
                    </Col>
                    }
                </Row>
                {
                    this.props.protocolVersion !== 'ProtocolVersion6' &&
                    <Row>
                        <Col span={10}>Typ Systemu: {GetSystemTypeFromValue(this.props.systemTypeName)}</Col>
                        <Col span={10}>Typ czynności: {GetActivityTypeFromValue(this.props.activityTypeName)}</Col>
                    </Row>
                }
                <Row className="inputRow">
                    <Col span={12}>
                        <Checkbox
                        checked={!this.state.endDate}
                        disabled={!this.props.isActive}
                        onChange={this.autoEndDateCheckboxChanged}>
                            Automatycznie wypełnij datę protokołu na podstawie daty końca serwisu
                        </Checkbox>
                        {this.state.endDate && (
                            <VDatePicker
                                placeholder="Data zakończenia"
                                value={this.state.endDate}
                                rules={{ required: true }}
                                disabled={!this.props.isActive}
                                validTriggerContainer={this.state.validTriggerContainer}
                                onChange={(date, dateString) =>
                                this.setState({ endDate: date })
                                }
                            />
                        )}
                    </Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>Zakres</Col>
                </Row>
                <Row className="inputRow">
                    <VTextArea
                        value={this.state.scope}
                        style={{width: '100%'}}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={({target: {value}}) => this.setState({scope: value})}
                        placeholder="Zakres"
                        autoSize={{minRows: 3, maxRows: 5}}
                        disabled={!this.props.isActive}
                    />
                </Row>

                {
                    this.props.protocolVersion === 'ProtocolVersion1' &&
                    this.renderProtocol1()
                }
                {
                    this.props.protocolVersion === 'ProtocolVersion2' &&
                    this.renderProtocol2()
                }
                {
                    this.props.protocolVersion === 'ProtocolVersion3' &&
                    this.renderProtocol3()
                }
                {
                    this.props.protocolVersion === 'ProtocolVersion4' &&
                    this.renderProtocol4()
                }
                {
                    this.props.protocolVersion === 'ProtocolVersion5' &&
                    this.renderProtocol5()
                }
                {
                    this.props.protocolVersion === 'ProtocolVersion6' &&
                    this.renderProtocol6()
                }
            </>
        )
    }


}

export default AddProtocol;


