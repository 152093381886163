import {ShareState} from '../utils/ShareState';

let allProfiles = ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_SALES', 'ROLE_REALIZER']
let mercorProfiles = ['ROLE_ADMIN', 'ROLE_SALES', 'ROLE_REALIZER']
let managerProfile = ['ROLE_MANAGER']
let tendersManageProfiles = ['ROLE_ADMIN', 'ROLE_REALIZER']
let commissionsManageProfiles = ['ROLE_ADMIN', 'ROLE_REALIZER']
let workerProfile = ['ROLE_WORKER']

export default class Permissions {
    static ViewsList = {
        "MainView": mercorProfiles,
        "FacilityView": mercorProfiles,
        "FacilityAdd": mercorProfiles,
        "TenderView": mercorProfiles,
        "ProfileView": mercorProfiles,
        "UserView": mercorProfiles,
        "SubcontractorView": mercorProfiles,
        "WorkerView": managerProfile,
        "AlertView": ['ROLE_ADMIN', 'ROLE_SALES', 'ROLE_REALIZER', 'ROLE_MANAGER', 'ROLE_WORKER'],
        "ServiceView": allProfiles,
        "ServiceDetail": allProfiles,
        "ServiceDetailForCommissionHistory": allProfiles,
        "CommissionView": ['ROLE_ADMIN', 'ROLE_REALIZER', 'ROLE_SALES'],
        "CommissionAdd": ['ROLE_ADMIN', 'ROLE_REALIZER'],
        "ManagerTenderView": managerProfile,
        "ManagerCommissionView": managerProfile,
        "WorkerCommissionView": workerProfile
    }
    static ActionList = {
        "UsersList": mercorProfiles,
        "UserDetail": mercorProfiles,
        "UserAdd": mercorProfiles,
        "UserDelete": mercorProfiles,
        "UserUnDelete": mercorProfiles,
        "UserEdit": mercorProfiles,

        "SubcontractorsList": mercorProfiles,
        "SubcontractorsWithManagerList": mercorProfiles,
        "SubcontractorswithoutManagerList": mercorProfiles,
        "SubcontractorDetail": mercorProfiles,
        "SubcontractorAdd": mercorProfiles,
        "SubcontractorDelete": mercorProfiles,
        "SubcontractorEdit": mercorProfiles,

        "TendersList": mercorProfiles,
        "TenderDetail": mercorProfiles,
        "TenderAdd": tendersManageProfiles,
        "TenderDelete": tendersManageProfiles,
        "TenderEdit": tendersManageProfiles,
        "TenderSendToSubcontractors": tendersManageProfiles,

        "ManagerTendersList": managerProfile,
        "ManagerTenderDetail": managerProfile,

        "WorkersList": managerProfile,
        "WorkerDetail": managerProfile,
        "WorkerAdd": managerProfile,
        "WorkerDelete": managerProfile,
        "WorkerEdit": managerProfile,

        "CommissionEdit": commissionsManageProfiles,
        "CommissionAdd": commissionsManageProfiles,
        "CommissionDelete": commissionsManageProfiles,
        "CommissionClose": commissionsManageProfiles,
        "CommissionRestore": commissionsManageProfiles,
        "CommissionSetWorker": managerProfile,

        "FacilityDetail": mercorProfiles,
        "FacilityDelete": ['ROLE_ADMIN'],
        "FacilityAdd": mercorProfiles,

        "ServiceListAll": mercorProfiles,
        "ServiceListAllForFacility": allProfiles,
        "ServiceListAllForCommissionFacility": allProfiles,
        "ServiceAdd": ['ROLE_ADMIN', 'ROLE_MANAGER'],
        "ServiceCopy": ['ROLE_ADMIN', 'ROLE_MANAGER'],
        "ServiceDelete": ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_REALIZER'],
        "ServiceStop": ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_WORKER', 'ROLE_REALIZER'],
        "ServiceCancelStop": ['ROLE_ADMIN', 'ROLE_MANAGER'],
    }

    static isAllow(view) {
        let user = ShareState.container['user'];

        return user && Permissions.ViewsList[view].includes(user['userType']);
    }

    static canDo(action) {
        let user = ShareState.container['user'];
        return user && Permissions.ActionList[action].includes(user['userType']);
    }
}
