import React from "react";
import {Col, Descriptions, Row} from "antd";
import MapComponent from "../../../component/MapComponent";

class AddressDisplay extends React.Component {

    getByName(name) {
        return this.props.data ? this.props.data[name] : null
    }

    getAddressForMapView() {
        return [{'latitude': this.getByName('latitude'), 'longitude': this.getByName('longitude')}]
    }

    render() {
        return (
            <Row gutter={{xs: 8, sm: 16, md: 24}}>
                <Col span={8}>
                    <Descriptions title="Szczegóły adresu" bordered column={1}>
                        <Descriptions.Item
                            label="Pełna nazwa">{this.getByName('address')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Województwo">{
                            this.getByName('voivodeship')
                        }
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Miasto">{
                            this.getByName('city')
                        }
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Ulica">{
                            this.getByName('street')
                        }
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Kod pocztowy">{
                            this.getByName('postalCode')
                        }
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={16}>
                    <MapComponent
                        data={this.getAddressForMapView()}
                    />
                </Col>
            </Row>
        );
    }
}

export default AddressDisplay
