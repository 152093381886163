import {getApiUrl, getAuthToken} from "../../../../../utils/ApiHelper";

export let DownloadPdf = (protocolId, facilityName) => {
    fetch(getApiUrl("GenerateProtocolPdf", {protocolId: protocolId}), {
        method: 'get',
        headers: {
            'Authorization': getAuthToken(),
            'Content-Type': 'application/json'
        },

    })
        .then(response => response.blob())
        .then(_blob => {
            var blob = new Blob([_blob]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const date = new Date().toLocaleString("pl-PL");
            const fileName = `protokol_${facilityName}_${date}.pdf`;
            link.download = fileName;
            link.click();
        })
}