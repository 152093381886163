import React from "react";
import {Descriptions} from "antd";
import {convertDate} from "./Utils";

class ServiceDetailsDisplay extends React.Component {
    getByName(name) {
        return this.props.data ? this.props.data[name] : null
    }


    render() {
        return (
            <div>

                <Descriptions bordered column={2}>
                    <Descriptions.Item
                        label="Data rozpoczęcia serwisu">{
                        convertDate(this.getByName('startDate'), false)
                    }
                    </Descriptions.Item>

                    <Descriptions.Item span={3}
                                       label="Data zakończenia serwisu">{
                        convertDate(this.getByName('endDate'), false)
                    }
                    </Descriptions.Item>


                    <Descriptions.Item
                        span={3}
                        label="Uwagi"
                        style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                    >
                        {this.getByName('note')}
                    </Descriptions.Item>

                </Descriptions>

            </div>
        );
    }
}

export default ServiceDetailsDisplay
