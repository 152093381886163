import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, notification, Table} from 'antd';

import {FormOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {MakeRoute, Routes} from "../../../../config/Routes";
import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";
import Permissions from '../../../../config/Permissions';
import TenderStatuses, {GetTenderStatusFromValue, GetTenderStatusStyle} from "../../../../config/TenderStatuses"

import styles from '../../../../style/dashboard/view/user/UserList.module.scss';
import TableHelper from "../../../../utils/TableHelper";
import TenderTypes, {
    GetTenderTypeColorStyle,
    GetTenderTypeFromValue,
    GetTenderTypeStyle
} from "../../../../config/TenderTypes";
import DeleteConfirm from "../../../../component/DeleteConfirm";
import MapComponent from "../../../../component/MapComponent";

let dateFormat = require("dateformat");

class TendersList extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            sortedInfo: null,
            pagination: {
                pageSize: 10,
            },
            tenders: [],
        };
        ShareState.setValue('breadcrumb', 'Lista przetargów')
    }

    componentDidMount() {
        this.getTendersList()
    }

    getTendersList() {
        authFetch({
            endpointName: "tendersList",
            successFunction: data => {
                if (!data.tenders) return;
                for (let tender of data.tenders) {
                    tender['facility_name'] = [tender.facility.name, tender.facility.address.address]
                    tender['status_pl'] = GetTenderStatusFromValue(tender.status).label;
                    tender['realizationDate'] = new Date(tender.realizationDate)
                    tender['realizationDateStr'] = tender.realizationDate ? dateFormat(tender.realizationDate, "dd-mm-yyyy") : '-'


                    // for map:
                    tender['text'] = tender.facility.name
                    tender['linkAddress'] = MakeRoute(Routes.TenderDetail, {'id': tender.id})
                    tender['latitude'] = tender.facility?.address?.latitude
                    tender['longitude'] = tender.facility?.address?.longitude
                    tender['markerColor'] = GetTenderTypeColorStyle(tender.tenderType)
                }

                let sortedTenders = data.tenders;
                sortedTenders = sortedTenders.sort(function (a, b) {
                    return b.id - a.id
                })

                this.setState({
                    tenders: sortedTenders,
                    tendersFiltered: sortedTenders
                })
            }
        })
    }

    removeTender(id) {
        authFetch({
            endpointName: "tenderDelete",
            endpointParameters: {'id': id},
            config: {method: 'delete'},
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Usunięto przetarg',
                });
                this.getTendersList();
            }
        })
    }

    AddTenderButton = () => {
        if (Permissions.canDo('TenderAdd')) {
            return (
                <Button type="primary">
                    <Link to={Routes.TenderAdd}>
                        Dodaj przetarg
                    </Link>
                </Button>
            )
        }
    }

    render() {
        const columns = [
            {
                title: 'Obiekt',
                dataIndex: 'facility_name',
                key: 'facility_name',
                width: '55%',

                ...this.tableHelper.getColumnExcelSearchProps('facility_name', this.state.tenders.map(x => x.facility.name)),
                render: (item) => <div>
                    <span style={{fontWeight: "bold"}}>{item[0]}</span> {item[1]}
                </div>,
            },
            {
                title: 'Data realizacji do',
                dataIndex: 'realizationDateStr',
                key: 'realizationDateStr',
                width: '10%',
                ...this.tableHelper.getColumnSearchProps('realizationDateStr'),
                sorter: {
                    compare: (a, b) => a.realizationDate?.getTime() - b.realizationDate?.getTime(),
                    multiple: 1,
                },
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: "10%",
                filters: TenderStatuses,
                onFilter: (value, record) => record.status === value,
                render: (item) => <div>

                    <div
                        style={GetTenderStatusStyle(item)}>
                        {GetTenderStatusFromValue(item).label}
                    </div>
                </div>,
            },
            {
                title: 'Rodzaj zlecenia',
                dataIndex: 'tenderType',
                key: 'tenderType',
                width: "10%",
                filters: TenderTypes,
                onFilter: (value, record) => record.tenderType === value,
                render: (item) => <div>

                    <div
                        style={GetTenderTypeStyle(item)}>
                        {GetTenderTypeFromValue(item).label}
                    </div>
                </div>,
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 150,
                render: (item) => <div className={styles.iconHolder}>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.TenderDetail, {'id': item.id})}>
                        <InfoCircleOutlined/>
                    </Link>
                    {Permissions.canDo("TenderEdit") &&
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.TenderEdit, {'id': item.id})}>
                        <FormOutlined/>
                    </Link>
                    }
                    {Permissions.canDo("TenderDelete") &&
                    <DeleteConfirm
                        onDelete={() => this.removeTender(item.id)}
                        confirmText={"Czy napewno chcesz usunać ten przetarg?"}
                    />
                    }
                </div>,
            },
        ];
        return (
            <div>
                <Card title="Lista przetargów" bordered={true}
                      extra={this.AddTenderButton()}>

                    <MapComponent
                        data={this.state.tendersFiltered}
                        clickable={true}
                    />


                    <Table
                        pagination={this.state.pagination}
                        columns={columns}
                        dataSource={this.state.tenders}
                        onChange={(a, b, c, dataBase) => this.setState({
                            tendersFiltered: dataBase.currentDataSource.sort(function (a, b) {
                                return b.id - a.id
                            })
                        })}
                        scroll={{x: 1200}}
                        rowKey="id"
                    />
                </Card>
            </div>
        );
    }
}

export default withRouter(TendersList);
