import React from "react";
import styles from '../../style/login/LoginView.module.scss';
import {ShareState} from '../../utils/ShareState';
import {getApiUrl} from "../../utils/ApiHelper";
import {withRouter} from 'react-router-dom';
import logo from '../../img/logo.png';
import {notification, Spin} from 'antd';
import Permissions from "../../config/Permissions";
import {MakeRoute, Routes} from "../../config/Routes";

class LoginView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            credentialData: {
                nick: "",
                password: ""
            },
            loading: false,
        };

    }

    componentDidMount() {
        if (ShareState.container['auth-token']) {
            this.goToAfterLoginView()
        }
    }

    goToAfterLoginView() {
        const query = new URLSearchParams(this.props.location.search);
        const redirectUrl = query.get('redirect')

        if (redirectUrl) {
            this.props.history.push({
                pathname: redirectUrl
            });
        } else if (Permissions.isAllow("MainView")) {
            this.props.history.push({
                pathname: MakeRoute(Routes.MainView, {subPath: 'overview'})
            });
        } else {
            this.props.history.push({pathname: Routes.CommissionList});

        }
    }

    inputHandler(property, event) {
        this.setState({
            username: event.target.value
        });
    }

    login() {
        this.setState({loading: true})
        fetch(getApiUrl("login"), {
            method: "post",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.credentialData),
        })
            .then((response) => {
                if (response.status !== 200) {
                    notification['error']({
                        message: 'Błąd',
                        description: 'Nie poprawny login lub hasło',
                    });
                    return null;
                }
                return response.json();
            })
            .then((data) => {
                if (data == null) return;
                notification['success']({
                    message: 'OK',
                    description: 'Udało się zalogować',
                });
                window.localStorage.setItem('auth-token', data.token);
                window.localStorage.setItem('user', JSON.stringify(data));
                ShareState.setValue("user", data);
                ShareState.setValue("auth-token", data.token);
                ShareState.setValue('NotificationServiceState', true);
                this.goToAfterLoginView()
            })
            .catch((e) => {
                notification['error']({
                    message: 'Błąd',
                    description: 'Nieoczekiwany błąd podczas logowania',
                });
            })
            .finally(() => this.setState({loading: false}));
    }

    setNick(e) {
        this.setState({
            credentialData: {
                ...this.state.credentialData,
                nick: e.target.value
            }
        })
    }

    setPassword(e) {
        this.setState({
            credentialData: {
                ...this.state.credentialData,
                password: e.target.value
            }
        })
    }

    trySubmit(e) {
        if (e.key === 'Enter') this.login()
    }

    render() {
        return (
            <Spin spinning={this.state.loading} size="large" style={{height: '100vh', maxHeight: 'none'}}>
                <div className={styles.container}>
                    <div className={styles.logoHolder}>
                        <img src={logo} alt="logo"/>
                    </div>
                    <div className={styles.formHolder}>
                        <div className={styles.header}>
                            Zaloguj
                        </div>
                        <div className={styles.contentFormHolder}>
                            <div className={styles.inputHolder}>
                                <div className={styles.inputHeader}>
                                    Login lub adress e-mail<span>*</span>
                                </div>
                                <input
                                    type="text"
                                    data-testid="login-input"
                                    className={styles.loginInput}
                                    placeholder="Wpisz login lub e-mail"
                                    onChange={(e) => this.setNick(e)}
                                />
                            </div>
                            <div className={styles.inputHolder}>
                                <div className={`${styles.inputHeader} ${styles.flexHeader}`}>
                                    <div>Twoje hasło<span>*</span></div>
                                    <div className={styles.remindPassword}>Zapomniałeś hasła?</div>
                                </div>
                                <input
                                    type="password"
                                    data-testid="password-input"
                                    className={styles.loginInput}
                                    onChange={(e) => this.setPassword(e)}
                                    onKeyPress={(e) => this.trySubmit(e)}
                                />
                            </div>
                            <div data-testid="login-button" className={styles.button} onClick={() => this.login()}>
                                Zaloguj
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        );
    }
}

export default withRouter(LoginView);
