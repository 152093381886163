import {authFetch} from "../utils/ApiHelper";

export const getSubcontractorsListForSelect = async () => {
    return new Promise((resolve, reject) => {
        authFetch({
            endpointName: "GetSubcontractorList",
            successFunction: data => {
                let subcontractorList = []
                for (let subcontractor of data.subcontractors) {
                    subcontractorList.push({
                        value: subcontractor.id,
                        label: subcontractor.name
                    });
                }
                resolve(subcontractorList)
            },
            onFail: e => {
                reject()
            }
        })
    })
}