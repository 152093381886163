import React from "react";
import {withRouter} from 'react-router-dom';
import {authFetch} from "../../../../../utils/ApiHelper";
import {FacilityStatus} from "../../../../../config/FacilityStatus";
import {OffertType} from "../../../../../config/OffertType";
import {Button, Card, Col, Divider, notification, Row, Tooltip} from 'antd';
import {VDatePicker, VInput, VSelect, VTextArea} from '../../../../../component/form/VForm'
import Permissions from '../../../../../config/Permissions';
import {Routes} from "../../../../../config/Routes";
import MapPickerComponent from './MapPickerComponent';
import {ShareState} from "../../../../../utils/ShareState";
import DeleteConfirm from "../../../../../component/DeleteConfirm";
import FacilityCategory from "../../../../../config/FacilityCategory";
import {FacilityInfoForm} from "./FacilityInfoForm";
import {getRealizersListForSelect, getSalesmenListForSelect} from "../../../../../apiOperations/UsersApiOperations";
import {getSubcontractorsListForSelect} from "../../../../../apiOperations/SubcontractorApiOperations";
import {getDetailedFacility} from "../../../../../apiOperations/FacilityApiOperations";
import {Voivodeship} from "../../../../../config/Voivodeship";


const facilityInfoProps = {
    id: null,
    contactName: null,
    contactSurname: null,
    contactEmail: null,
    contactPhone: null,
    note: null,
    scope: null,
    realizerId: null,
    salesmanId: null,
    subcontractorId: null
};

class FacilityForm extends React.Component {
    searchAddressTimeout = null

    constructor(props) {
        super(props);
        this.state = {
            //facility
            name: null,
            address: null,
            axProjectNumbers: [],
            generalContractor: null,
            realizerId: null,
            salesmanId: null,
            salesmanHsId: null,
            subcontractors: [],
            projectFinishDate: null,
            warranty: null,
            status: null,
            offerType: null,
            offerDate: null,
            axFacilityNumber: null,
            axProjectId: null,
            salesmanFromAx: null,
            realizerFromAx: null,
            assemblersFromAx: [],
            category: null,

            //facility info
            facilityInfo: facilityInfoProps,

            latitude: 50.061357,
            longitude: 19.937422,
            city: null,
            postalCode: null,
            street: null,
            voivodeship: null,

            realizerList: [],
            salesmanList: [],
            salesmanHsList: [],
            subcontractorList: [],

            formSent: false,
            isEdit: this.props.loadInfo,
            facilityId: this.props.facilityId,
            isValid: false,
            validTriggerContainer: [],

            showFacilitySaveTooltip: false,
            lastFacilityStatus: null,

        };
    }

    async getAllLists() {
        const realizerList = await getRealizersListForSelect()
        const [salesmanList, salesmanHsList] = await getSalesmenListForSelect()
        const subcontractorList = await getSubcontractorsListForSelect()
        this.setState({
            realizerList: realizerList,
            salesmanList: salesmanList,
            salesmanHsList: salesmanHsList,
            subcontractorList: subcontractorList
        })
    }

    componentDidMount() {
        this.getAllLists()
        if (this.state.isEdit) {
            this.loadInfo()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                facilityId: this.props.match.params.id,
            }, () => this.loadInfo())
        }
    }

    async loadInfo() {
        const detailedFacility = await getDetailedFacility(this.state.facilityId)

        if (!detailedFacility) return;
        let subcontractors = []
        for (let item of detailedFacility.subcontractors) {
            subcontractors.push(item.id)
        }
        if (detailedFacility.facilityInfoResponse) {
            detailedFacility.facilityInfoResponse.realizerId = detailedFacility.facilityInfoResponse.realizer?.id
            detailedFacility.facilityInfoResponse.salesmanId = detailedFacility.facilityInfoResponse.salesman?.id
            detailedFacility.facilityInfoResponse.subcontractorId = detailedFacility.facilityInfoResponse.subcontractor?.id
        }

        let facilityInfo = detailedFacility.facilityInfoResponse || facilityInfoProps

        this.setState({
            name: detailedFacility.name,
            latitude: detailedFacility.address.latitude,
            longitude: detailedFacility.address.longitude,
            address: detailedFacility.address.address,
            city: detailedFacility.address.city,
            postalCode: detailedFacility.address.postalCode,
            street: detailedFacility.address.street,
            voivodeship: detailedFacility.address.voivodeship,
            axProjectNumbers: detailedFacility.axProjectNumbers,
            generalContractor: detailedFacility.generalContractor,
            realizerId: detailedFacility.realizer ? detailedFacility.realizer.id : null,
            salesmanId: detailedFacility.salesman ? detailedFacility.salesman.id : null,
            salesmanHsId: detailedFacility.salesmanHs != null ? detailedFacility.salesmanHs.id : null,
            subcontractors: subcontractors,
            projectFinishDate: detailedFacility.projectFinishDate,
            warranty: detailedFacility.warranty,
            status: detailedFacility.status,
            offerType: detailedFacility.offerType,
            offerDate: detailedFacility.offerDate,
            axFacilityNumber: detailedFacility.axFacilityNumber,
            axProjectId: detailedFacility.axProjectId,
            salesmanFromAx: detailedFacility.salesmanFromAx,
            realizerFromAx: detailedFacility.realizerFromAx,
            assemblersFromAx: detailedFacility.assemblersFromAx,
            category: detailedFacility.category,

            facilityInfo,
            lastFacilityStatus: detailedFacility.status,
        })
    }

    reset() {
        this.setState({
            name: null,
            address: null,
            axProjectNumbers: [],
            generalContractor: null,
            realizerId: null,
            salesmanId: null,
            salesmanHsId: null,
            subcontractors: [],
            projectFinishDate: null,
            warranty: null,
            status: null,
            offerType: null,
            offerDate: null,
            axFacilityNumber: null,
            axProjectId: null,
            salesmanFromAx: null,
            realizerFromAx: null,
            assemblersFromAx: null,
            category: null,

            facilityInfo: facilityInfoProps,

            formSent: false,
            isEdit: this.props.loadInfo,
            facilityId: this.props.facilityId,
            isValid: false,
        })
    }

    addFacility() {
        if (this.state.formSent) return;
        this.facilityFetch("FacilityAdd", "Dodano nowy obiekt", (id) => {
            this.setState({
                facilityId: id, isEdit: true
            }, () => this.props.history.push(Routes.FacilityList))
        });
    }

    editFacility() {
        this.facilityFetch("FacilityEdit", "Zaktualizowano informacje o obiekcie");
    }

    facilityFetch(endpointName, successMessage, successFunction = (id) => {
    }) {
        if (!this.validFacility()) return;

        let data = {
            facilityId: this.state.facilityId,
            name: this.state.name,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            city: this.state.city,
            postalCode: this.state.postalCode,
            street: this.state.street,
            voivodeship: this.state.voivodeship,

            axProjectNumbers: this.state.axProjectNumbers,
            generalContractor: this.state.generalContractor,
            realizerId: this.state.realizerId,
            salesmanId: this.state.salesmanId,
            subcontractors: this.state.subcontractors,
            projectFinishDate: this.state.projectFinishDate,
            warranty: this.state.warranty,
            status: this.state.status,
            axFacilityNumber: this.state.axFacilityNumber,
            axProjectId: this.state.axProjectId,
            salesmanFromAx: this.state.salesmanFromAx,
            realizerFromAx: this.state.realizerFromAx,
            assemblersFromAx: this.state.assemblersFromAx,
            category: this.state.category,
        }
        if (this.state.status === 'OFFERED') {
            data['offerType'] = this.state.offerType;
            data['offerDate'] = this.state.offerDate;
            data['salesmanHsId'] = this.state.salesmanHsId;
        }
        authFetch({
            endpointName: endpointName, config: {
                method: 'post', body: JSON.stringify(data)
            }, successFunction: data => {
                notification['success']({
                    message: 'OK', description: successMessage,
                });

                this.setState({
                    facilityId: data.facilityId,
                    formSent: true,
                    showFacilitySaveTooltip: false,
                    lastFacilityStatus: this.state.status,
                }, () => successFunction(data.facilityId))
            }
        });
    }

    showInfoUnsavedChanges() {
        notification['warning']({
            message: 'Błąd', description: "Najpierw zapisz zmiany statusu obiektu, żeby móc dodać informacje serwisowe",
        })
        this.setState({
            showFacilitySaveTooltip: true
        })
        setTimeout(() => this.setState({showFacilitySaveTooltip: false}), 5000)
    }

    checkCorrectFacilityStatus() {
        if ((this.state.lastFacilityStatus !== this.state.status && this.state.status === 'SERVICED')) {
            this.showInfoUnsavedChanges()
            return false;
        }
        return true;
    }


    validTriggerFacility() {
        for (let item of this.state.validTriggerContainer) item()
    }

    validFacility() {
        this.validTriggerFacility()
        let required = ['name', 'status', 'axFacilityNumber', 'category']
        if (this.state.status === 'OFFERED') {
            required.push('offerType')
            required.push('offerDate')
            required.push('salesmanHsId')
        }
        return this.checkRequired(required)
    }

    checkRequired(requiredList) {
        for (let item of requiredList) {
            if (!(item in this.state)) return false;
            if (this.state[item] === null) return false;
        }
        return true;
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    deleteFacility() {
        authFetch({
            endpointName: "FacilityDelete",
            endpointParameters: {id: this.state.facilityId},
            config: {method: 'delete'},
            successFunction: data => {
                notification['success']({
                    message: 'OK', description: 'Usunięto obiekt',
                })
                this.setState({
                    facilityId: null
                }, () => this.props.history.push((Routes.FacilityList)))
            }
        })
    }

    onFacilityStatusChange(value) {
        if (this.state.facilityId && this.state.facilityInfo?.id && this.state.status === 'SERVICED') {
            notification['error']({
                message: 'Błąd',
                description: 'Zanim zmienisz status obiektu z "Serwisowany" na inny najpierw usuń obecne informacje serwisowe',
            })
            return
        }
        let user = ShareState.container['user'];
        let salesmanHsId = this.state.salesmanHsId
        if (value === 'OFFERED' && user['userType'] === 'ROLE_SALES') {
            salesmanHsId = user['id']
        }

        this.setState({
            status: value, salesmanHsId: salesmanHsId
        })
    }

    changeAddressCallback({latitude, longitude}) {
        this.setState({
            latitude: latitude, longitude: longitude
        })
    }

    searchForAddress(address) {
        authFetch({
            endpointName: "AddressGeocode", config: {
                method: 'post', body: JSON.stringify({
                    address: address
                })
            }, successFunction: data => {
                this.setState({
                    latitude: data.latitude,
                    longitude: data.longitude,
                    address: data.address,
                    city: data.city,
                    street: data.street,
                    postalCode: data.postalCode,
                    voivodeship: data.voivodeship
                })

            }
        })
    }

    onFacilityInfoSave(facilityInfoId) {
        let facilityInfo = this.state.facilityInfo
        facilityInfo.id = facilityInfoId
        this.setState({
            facilityInfo
        })
    }

    renderAddress() {
        return (<>
            <Row>
                <Col span={8}>Szukaj adresu</Col>
            </Row>
            <Row className="inputRow">
                <Col span={8}>
                    <Row>
                        <Col span={18}><VInput
                            placeholder="Adres obiektu"
                            value={this.state.address}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.setState({address: e.target.value})}/></Col>
                        <Col span={4}><Button type="primary"
                                     onClick={() => this.searchForAddress(this.state.address)}>Szukaj</Button></Col>

                    </Row>
                    <br/><br/>
                    <Row>
                        <Col span={8}> <b>Województwo:</b> </Col>
                        <Col span={15}> <VSelect
                            value={this.state.voivodeship}
                            style={{width: '100%'}}
                            onChange={(value) => this.setState({voivodeship: value})}
                            options={Voivodeship}
                            showSearch
                        /> </Col>
                    </Row>

                    <Row>
                        <Col span={8}> <b>Miasto:</b> </Col>
                        <Col span={15}> <VInput
                            placeholder="Miasto"
                            value={this.state.city}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.setState({city: e.target.value})}
                        /> </Col>
                    </Row>

                    <Row>
                        <Col span={8}> <b>Ulica:</b> </Col>
                        <Col span={15}> <VInput
                            placeholder="Ulica"
                            value={this.state.street}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.setState({street: e.target.value})}
                        /> </Col>
                    </Row>

                    <Row>
                        <Col span={8}> <b>Kod pocztowy:</b> </Col>
                        <Col span={15}> <VInput
                            placeholder="Kod pocztowy"
                            value={this.state.postalCode}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(e) => this.setState({postalCode: e.target.value})}
                        /> </Col>
                    </Row>
                </Col>
                <Col span={16}>
                    <MapPickerComponent
                        location={{latitude: this.state.latitude, longitude: this.state.longitude}}
                        addressChangeCallback={(address) => this.changeAddressCallback(address)}
                    />
                </Col>
            </Row>
        </>)
    }

    render() {
        return (<>
            <>
                <Card title={this.state.isEdit ? "Edytuj informcje o obiekcie" : "Dodaj nowy obiekt"}
                      bordered={true} extra={<>
                    <Button type="primary" onClick={() => this.reset()}>Reset</Button>
                    {this.state.isEdit && Permissions.canDo("FacilityDelete") ? <DeleteConfirm
                        onDelete={() => this.deleteFacility()}
                        confirmText={"Czy na pewno chcesz usunąć ten obiekt?"}
                    >
                        <Button style={{marginLeft: 10}} type="danger">Usuń</Button>
                    </DeleteConfirm> : ''}
                </>}>
                    <Row>
                        <Col span={8}>Nazwa obiektu</Col>
                    </Row>
                    <Row className="inputRow">
                        <Col span={8}>
                            <VInput
                                placeholder="Nazwa obiektu"
                                value={this.state.name}
                                rules={{required: true}}
                                validTriggerContainer={this.state.validTriggerContainer}
                                onChange={(e) => this.inputHandler('name', e)}/>
                        </Col>
                    </Row>

                    {this.renderAddress()}


                    <Row>
                        <Col span={8}>Data zamknięcia projektu</Col>
                        <Col span={8}>Gwarancja/Rękojma</Col>
                        <Col span={8}>Status obiektu</Col>
                    </Row>
                    <Row className="inputRow">
                        <Col span={8}>
                            <VDatePicker
                                format="YYYY-MM-DD"
                                placeholder="Data zamknięcia projektu"
                                value={this.state.projectFinishDate}
                                onChange={(date, dateString) => this.setState({projectFinishDate: dateString})}/>
                        </Col>
                        <Col span={8}>
                            <VTextArea
                                value={this.state.warranty}
                                style={{width: '70%'}}
                                onChange={({target: {value}}) => this.setState({warranty: value})}
                                placeholder="Gwarancja/Rękojma"
                                autoSize={{minRows: 3, maxRows: 5}}/>
                        </Col>
                        <Col span={8}>
                            <VSelect
                                value={this.state.status}
                                style={{width: '70%'}}
                                rules={{required: true}}
                                validTriggerContainer={this.state.validTriggerContainer}
                                onChange={(value) => this.onFacilityStatusChange(value)}
                                options={FacilityStatus}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>Kategoria obiektu</Col>
                    </Row>
                    <Row className="inputRow">
                        <Col span={8}>
                            <VSelect
                                value={this.state.category}
                                style={{width: '70%'}}
                                rules={{required: true}}
                                validTriggerContainer={this.state.validTriggerContainer}
                                onChange={(value) => this.setState({category: value})}
                                options={FacilityCategory}/>
                        </Col>
                    </Row>
                    {this.state.status === 'OFFERED' ? <>
                        <Row>
                            <Col span={8}>Rodzaj oferty</Col>
                            <Col span={8}>Data złożenia oferty</Col>
                            <Col span={8}>Handlowiec HS</Col>
                        </Row>

                        <Row className="inputRow">
                            <Col span={8}>
                                <VSelect
                                    value={this.state.offerType}
                                    style={{width: '70%'}}
                                    rules={{required: true}}
                                    validTriggerContainer={this.state.validTriggerContainer}
                                    onChange={(value) => this.setState({offerType: value})}
                                    options={OffertType}/>
                            </Col>
                            <Col span={8}>
                                <VDatePicker
                                    format="YYYY-MM-DD"
                                    placeholder="Data złożenia oferty"
                                    value={this.state.offerDate}
                                    rules={{required: true}}
                                    validTriggerContainer={this.state.validTriggerContainer}
                                    onChange={(date, dateString) => this.setState({offerDate: dateString})}/>
                            </Col>
                            <Col span={8}>
                                <VSelect
                                    value={this.state.salesmanHsId}
                                    style={{width: '70%'}}
                                    rules={{required: true}}
                                    validTriggerContainer={this.state.validTriggerContainer}
                                    onChange={(value) => this.setState({salesmanHsId: value})}
                                    options={this.state.salesmanHsList}/>
                            </Col>
                        </Row>
                    </> : ''}
                    <Divider dashed/>

                    <Row>
                        <Col span={8}>Numer obiektu AX</Col>
                    </Row>

                    <Row className="inputRow">
                        <Col span={8}>
                            <VInput
                                placeholder="Numer obiektu AX"
                                value={this.state.axFacilityNumber}
                                rules={{required: true}}
                                validTriggerContainer={this.state.validTriggerContainer}
                                onChange={(e) => this.inputHandler('axFacilityNumber', e)}/>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={8}>Realizator z AX</Col>
                        <Col span={8}>Handlowiec z AX</Col>
                    </Row>
                    <Row className="inputRow">
                        <Col span={8}>
                            <VInput
                                placeholder="Realizator z AX"
                                value={this.state.realizerFromAx}
                                onChange={(e) => this.inputHandler('realizerFromAx', e)}/>
                        </Col>
                        <Col span={8}>
                            <VInput
                                placeholder="Handlowiec z AX"
                                value={this.state.salesmanFromAx}
                                onChange={(e) => this.inputHandler('salesmanFromAx', e)}/>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={8}>Identyfikator projektu AX</Col>
                        <Col span={8}>Numery projektów AX</Col>
                    </Row>
                    <Row className="inputRow">
                        <Col span={8}>
                            <VInput
                                placeholder="Identyfikator projektu AX"
                                value={this.state.axProjectId}
                                onChange={(e) => this.inputHandler('axProjectId', e)}/>
                        </Col>


                        <Col span={8}>
                            <VSelect
                                mode="tags"
                                style={{width: '70%'}}
                                placeholder="Numery projektów z AX"
                                value={this.state.axProjectNumbers}
                                onChange={(value) => this.setState({axProjectNumbers: value})}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            {this.state.isEdit ? <Tooltip title="Najpierw zapisz zmiany"
                                                          visible={this.state.showFacilitySaveTooltip}
                                                          placement="bottom">
                                <Button type="primary" onClick={() => this.editFacility()}>Zapis
                                    zmiany</Button>
                            </Tooltip> : <Button
                                type="primary"
                                disabled={this.state.formSent}
                                onClick={() => this.addFacility()}>
                                Dodaj obiekt
                            </Button>}
                        </Col>
                    </Row>
                </Card>

                <div className="space"/>

                {this.state.status === 'SERVICED' ? <FacilityInfoForm
                    facilityId={this.state.facilityId}
                    initialState={this.state.facilityInfo}
                    realizerList={this.state.realizerList}
                    salesmanList={this.state.salesmanList}
                    subcontractorList={this.state.subcontractorList}
                    isEdit={this.state.isEdit}
                    checkCanAddFunction={() => this.checkCorrectFacilityStatus()}
                    onSave={(id) => this.onFacilityInfoSave(id)}
                /> : ''}
            </>
        </>);
    }

}

export default withRouter(FacilityForm);
