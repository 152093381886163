import React from "react";
import {withRouter} from 'react-router-dom';
import {Button, Card, Col, notification, Row} from 'antd';
import '../../../../style/dashboard/view/user/UserAdd.antd.scss';
import {authFetch} from "../../../../utils/ApiHelper";
import {MakeRoute, Routes} from "../../../../config/Routes";
import VInput from "../../../../component/form/VInput";

class SubcontractorForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.subcontractorId,

            name: null,
            phone: null,
            mail: null,
            address: null,
            dealNumber: null,

            validTriggerContainer: []
        };
    }

    componentDidMount() {
        if (this.state.id) {
            this.getSubcontractorDetails()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let id = this.props.match.params.id;

        if (id && id !== this.state.id) {
            this.setState({
                id: id
            }, () => this.getSubcontractorDetails())
        }
    }

    getSubcontractorDetails() {
        authFetch({
            endpointName: "subcontractorDetail",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                this.setState({
                    name: data.name,
                    address: data.address,
                    phone: data.phone,
                    mail: data.mail,
                    dealNumber: data.dealNumber
                })
            }
        });
    }

    addSubcontractor() {
        if (!this.isValid()) {
            notification['error']({
                message: 'Błąd',
                description: 'Nie uzupełniono wszystkich wymaganych pól formularza',
            });
        } else {

            authFetch({
                endpointName: this.state.id ? "subcontractorEdit" : "subcontractorAdd",
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        subcontractorId: this.state.id,
                        name: this.state.name,
                        mail: this.state.mail,
                        address: this.state.address,
                        phone: this.state.phone,
                        dealNumber: this.state.dealNumber
                    })
                },
                successFunction: data => {
                    this.setState({
                        id: data.id
                    })
                    notification['success']({
                        message: 'OK',
                        description: 'Udało się',
                    });

                    if (this.state.id) {
                        this.props.history.push({pathname: MakeRoute(Routes.SubcontractorEdit, {'id': data.id})})
                    }
                }
            });
        }
    }

    validTrigger() {
        for (let item of this.state.validTriggerContainer) item()
    }

    isValid() {
        let required = [
            'name', 'phone', 'mail', 'address'
        ]
        this.validTrigger()
        for (let key of required) {
            if (!this.state[key]) return false;
        }
        return true;
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    render() {
        return (
            <Card title="Dodaj podwykonawcę" bordered={true}>
                <Row>
                    <Col span={8}>Nazwa</Col>
                    <Col span={8}>Adres</Col>
                    <Col span={8}>Adres e-mail</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <VInput
                            placeholder="Nazwa podwykonawcy"
                            value={this.state.name}
                            validTriggerContainer={this.state.validTriggerContainer}
                            rules={{required: true}}
                            onChange={(e) => this.inputHandler('name', e)}/>
                    </Col>
                    <Col span={8}>
                        <VInput
                            placeholder="Adres"
                            value={this.state.address}
                            validTriggerContainer={this.state.validTriggerContainer}
                            rules={{required: true}}
                            onChange={(e) => this.inputHandler('address', e)}/>
                    </Col>
                    <Col span={8}>
                        <VInput
                            placeholder="Adres e-mail"
                            value={this.state.mail}
                            validTriggerContainer={this.state.validTriggerContainer}
                            rules={{required: true}}
                            onChange={(e) => this.inputHandler('mail', e)}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>Telefon kontaktowy</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <VInput
                            placeholder="Telefon kontaktowy"
                            value={this.state.phone}
                            validTriggerContainer={this.state.validTriggerContainer}
                            rules={{required: true}}
                            onChange={(e) => this.inputHandler('phone', e)}/>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>Numer umowy ramowej</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <VInput
                            placeholder="Numer umowy ramowej"
                            value={this.state.dealNumber}
                            validTriggerContainer={this.state.validTriggerContainer}
                            rules={{required: true}}
                            onChange={(e) => this.inputHandler('dealNumber', e)}/>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Button type="primary" onClick={() => this.addSubcontractor()}>
                            {this.state.id && "Edytuj podwykonawcę"}
                            {!this.state.id && "Dodaj podwykonawcę"}
                        </Button>
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default withRouter(SubcontractorForm);
