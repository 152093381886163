import Colors from './Colors'

export const OverviewFacilityStatus = [
    {
        value: 'CYCLICAL_OVERVIEW',
        label: 'Prz. cykliczny',
        color: Colors.green,
    },
    {
        value: 'ONE_TIME_OVERVIEW',
        label: 'Prz. jednorazowy',
        color: Colors.blue,
    },
    {
        value: 'OFFERED',
        label: 'Ofertowany',
        color: Colors.yellow,
    },
];

export const OverviewFacilityStatusToLabel = () => {
    let dict = {}
    for (let item of OverviewFacilityStatus) {
        dict[item.value] = item.label;
    }
    return dict;
}

export const GetOverviewFacilityStatusColor = () => {
    let dict = {}
    for (let item of OverviewFacilityStatus) {
        dict[item.value] = item.color;
    }
    return dict;
}