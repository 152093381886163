import React from 'react';
import ReactDOM from 'react-dom';
import './style/style.scss';
import App from './App';
import {ConfigProvider} from 'antd';
import * as serviceWorker from './serviceWorker';

import pl_PL from 'antd/lib/locale-provider/pl_PL';
import moment from 'moment';
import 'moment/locale/pl'; // important!

moment.locale('pl');  // important!

ReactDOM.render(
    <ConfigProvider locale={pl_PL}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </ConfigProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
