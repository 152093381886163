import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Breadcrumb as AntBreadcrumb} from 'antd';
import {HomeOutlined} from '@ant-design/icons';
import Routes, {MakeRoute} from "../../../config/Routes";
import {ShareState} from '../../../utils/ShareState';

class Breadcrumb extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumb: null
        };
    }

    componentDidMount() {
        ShareState.subscribe('breadcrumb', (value) => {
            this.setState({
                breadcrumb: value
            })
        });
    }

    render() {
        return (
            <AntBreadcrumb>
                <AntBreadcrumb.Item>
                    <Link to={MakeRoute(Routes.MainView, {subPath: 'overview'})}>
                        <HomeOutlined/>
                    </Link>
                </AntBreadcrumb.Item>
                <AntBreadcrumb.Item>
                    <Link to={MakeRoute(Routes.MainView, {subPath: 'overview'})}>
                        Strona główna
                    </Link>
                </AntBreadcrumb.Item>
                {
                    this.state.breadcrumb ?
                        <AntBreadcrumb.Item>
                            {this.state.breadcrumb}
                        </AntBreadcrumb.Item>
                        : ''
                }

            </AntBreadcrumb>
        );
    }
}

export default withRouter(Breadcrumb);
