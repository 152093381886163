export const TenderStatuses = [
    {
        value: "CREATED",
        label: "Utworzono",
        text: "Utworzono"
    },
    {
        value: "IN_PROGRESS",
        label: "W trakcie",
        text: "W trakcie",
    },
    {
        value: "SETTLED",
        label: "Rozstrzygnięte",
        text: "Rozstrzygnięte"
    }
]

export let GetTenderStatusFromValue = (value) => {
    for (let item of TenderStatuses) {
        if (item.value === value) {
            return item;
        }
    }
    return {};
}

export let GetTenderStatusStyle = (status) => {
    var styles = {
        height: 30,
        width: 140,
        textAlign: "center",
        lineHeight: '30px',
        color: "white",
        fontWeight: "bold",
        borderRadius: '3px'
    }
    if (status === "CREATED") {
        styles.backgroundColor = "#53c3b3"; // utworzone
    } else if (status === "IN_PROGRESS") {
        styles.backgroundColor = "#fe437e"; // w trakcie
    } else if (status === "SETTLED") {
        styles.backgroundColor = "#007198"; // rozstrzygnięte
    }

    return styles
}

export default TenderStatuses;
