export const FacilityStatus = [
    {
        value: 'NOT_ACTIVE',
        label: 'Nieaktywny',
        disabled: true
    },
    {
        value: 'SERVICED',
        label: 'Serwisowany',
    },
    {
        value: 'OFFERED',
        label: 'Ofertowany',
    },
    {
        value: 'NOT_SERVICED',
        label: 'Nieserwisowany',
    }
];

export const FacilityStatusToLabel = () => {
    let FacilityStatusToLabelDict = {}
    for (let item of FacilityStatus) {
        FacilityStatusToLabelDict[item.value] = item.label;
    }
    return FacilityStatusToLabelDict;
}

export default FacilityStatus
