import React from "react";
import {DeleteOutlined} from '@ant-design/icons';
import styles from "../style/dashboard/view/user/UserList.module.scss";
import {Modal} from "antd";

class DeleteConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }


    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
        this.props.onDelete();
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };


    render() {
        return (
            <>
                {
                    this.props.children ?
                        <div style={{display: "inline"}} onClick={() => this.showModal()}>
                            {this.props.children}
                        </div>
                        : <DeleteOutlined
                            style={{color: 'red'}}
                            className={styles.iconItem}
                            onClick={() => this.showModal()}/>
                }


                <Modal
                    title="Uwaga"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p>{this.props.confirmText}</p>
                </Modal>
            </>
        )
    }

}

export default DeleteConfirm;
