import React from "react";
import {withRouter} from 'react-router-dom';
import {ShareState} from '../../../../utils/ShareState';

import CommissionForm from './component/CommissionForm'

class CommissionEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Edytuj zlecenie')
    }

    render() {
        return (<CommissionForm isEdit={true} commissionId={this.state.id} loadInfo={true}/>);
    }
}

export default withRouter(CommissionEdit);
