export const SystemClasses = [
    {
        value: 'A',
        label: 'System klasy A',
    },
    {
        value: 'B',
        label: 'System klasy B',
    },
    {
        value: 'C',
        label: 'System klasy C',
    },
    {
        value: 'D',
        label: 'System klasy D',
    },
    {
        value: 'E',
        label: 'System klasy E',
    },
];

export default SystemClasses;
