import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, notification} from 'antd';
import {ShareState} from '../../../../utils/ShareState';
import {authFetch, getApiUrl} from "../../../../utils/ApiHelper";
import {GetUserActiveStatusFromDisabled, UserActiveStatusColor} from "../../../../config/UserActiveStatus";
import {MakeRoute, Routes} from "../../../../config/Routes";
import UserDetailsDisplay from "../../displayInfos/UserDetailsDisplay";

class WorkerDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            nick: null,
            isDeleted: null,
            activeStatus: null,
        };
    }

    deactivateWorker() {
        authFetch({
            endpointName: "managerWorkerDelete",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'delete',
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Deaktywowano brygadzistę',
                })
                this.getWorkerDetails()
            }
        })
    }

    activateWorker() {
        authFetch({
            endpointName: "managerWorkerUnDelete",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'post',
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Przywrócono brygadzistę',
                })
                this.getWorkerDetails()
            }
        })
    }

    getWorkerDetails() {
        fetch(getApiUrl("managerWorkerDetail", {id: this.state.id}), {
            headers: {
                'Authorization': 'Bearer ' + window.localStorage.getItem('auth-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    nick: data.nick,
                    phone: data.phone,
                    userType: data.userType,
                    userSubType: data.userSubType,
                    isDeleted: data.isDeleted,
                    activeStatus: GetUserActiveStatusFromDisabled(data.isDeleted)
                })
            });
    }


    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Informacje o brygadziście')
        this.getWorkerDetails()
    }

    getIsActiveStyle() {
        let statusStyle = UserActiveStatusColor(this.state.activeStatus.value);
        statusStyle['width'] = 140
        statusStyle['marginLeft'] = '15px'
        return statusStyle
    }

    render() {
        return (
            <div>
                <Card
                    title={
                        <>
                            Profil
                            {this.state.activeStatus &&
                            <div style={this.getIsActiveStyle()}>
                                {this.state.activeStatus.label}
                            </div>
                            }
                        </>
                    }
                    extra={
                        <>{!this.state.isDeleted &&
                        <Button type="danger" style={{marginRight: 10}} onClick={() => this.deactivateWorker()}>
                            Deaktywuj
                        </Button>
                        }

                            {this.state.isDeleted &&
                            <Button type="primary" style={{marginRight: 10}} onClick={() => this.activateWorker()}>
                                Aktywuj
                            </Button>
                            }

                            <Button type="primary">
                                <Link to={MakeRoute(Routes.WorkerEdit, {'id': this.state.id})}>
                                    Edytuj
                                </Link>
                            </Button>
                        </>
                    }
                    bordered={true}>
                    <UserDetailsDisplay data={this.state}/>

                </Card>
            </div>
        );
    }
}

export default withRouter(WorkerDetail);
