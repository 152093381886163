import React from "react";
import {Modal, notification, Table} from 'antd';
import TableHelper from '../../../../utils/TableHelper'
import {InfoCircleOutlined, PaperClipOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import {MakeRoute, Routes} from "../../../../config/Routes";
import styles from '../../../../style/dashboard/view/user/UserList.module.scss';
import {authFetch} from "../../../../utils/ApiHelper";
import Permissions from '../../../../config/Permissions';
import DeleteConfirm from "../../../../component/DeleteConfirm";
import VFilesEdit from "../../../../component/VFilesEdit";
import CommissionType, {CommissionTypeToLabel} from "../../../../config/CommissionType";

let dateFormat = require("dateformat");

class ServicesTable extends React.Component {
    CommissionTypeDict = CommissionTypeToLabel()
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            sortedInfo: null,
            pagination: {
                pageSize: 10,
            },
            services: [],

            isFilesModalVisible: false
        };
    }

    getDetailRouteCallback(item) {
        return this.props.detailRouteCallback ? this.props.detailRouteCallback(item) : this.detailRouteCallback(item)
    }

    componentDidMount() {
        this.getServicesList()
    }

    deleteService(id) {
        authFetch({
            endpointName: "ServiceDelete",
            endpointParameters: {serviceId: id},
            config: {
                method: 'delete'
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Usunięto Serwis',
                })
                this.getServicesList()
            }
        })
    }

    detailRouteCallback(item) {
        return MakeRoute(Routes.ServiceDetail, {'id': item.id})
    }

    getServicesList() {
        authFetch({
            endpointName: this.props.getEndpoint['endpointName'],
            endpointParameters: this.props.getEndpoint['parameters'],
            successFunction: data => {
                if (!data.services) return;
                data.services = data.services.filter(s => s.facility !== null)
                for (let service of data.services) {
                    service['facility_name'] = service.facility?.name
                    service['facility_id'] = service.facility?.id
                    service['worker_nick'] = service.worker ? service.worker.nick : "-"
                    service['subcontractor_name'] = service.subcontractor?.name ?? '-'

                    service.startDate = new Date(service.startDate)
                    service.startDateStr = service.startDate ? dateFormat(service.startDate, "dd-mm-yyyy") : '-'

                    service.endDate = service.endDate ? new Date(service.endDate) : null
                    service.endDateStr = service.endDate ? dateFormat(service.endDate, "dd-mm-yyyy") : '-'

                }
                this.setState({
                    services: data.services
                })
            }
        })
    }

    showFilesModal(item) {
        this.setState({
            isFilesModalVisible: true,
            serviceId: item.id
        });
    }

    handleOkFilesModal = e => {
        this.setState({
            isFilesModalVisible: false,
        });

    };

    handleCancelFilesModal = e => {
        this.setState({
            isFilesModalVisible: false,
        });
    };

    render() {
        let columns = [
            {
                title: 'Nazwa obiektu',
                dataIndex: 'facility_name',
                key: 'facility_name',
                width: '30%',
                sorter: {
                    compare: (a, b) => a.facility_name.localeCompare(b.facility_name),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnExcelSearchProps('facility_name', this.state.services.map(x => x.facility.name)),
                render: (data, record) =>
                    <>
                        {
                            Permissions.canDo("FacilityDetail") &&
                            <Link to={MakeRoute(Routes.FacilityDetail, {id: record.facility_id})}>
                                {data}
                            </Link>
                        }
                        {
                            !Permissions.canDo("FacilityDetail") && <div>{data}</div>
                        }
                    </>

            },

            {
                title: 'Data rozpoczęcia serwisu',
                dataIndex: 'startDateStr',
                key: 'startDateStr',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('startDateStr'),
                sorter: {
                    compare: (a, b) => b.startDate?.getTime() - a.startDate?.getTime(),
                    multiple: 1,
                },
            },
            {
                title: 'Data zakończenia serwisu',
                dataIndex: 'endDateStr',
                key: 'endDateStr',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('endDateStr'),
                sorter: {
                    compare: (a, b) => b.endDate?.getTime() - a.endDate?.getTime(),
                    multiple: 1,
                },
            },
            {
                title: 'Rodzaj zlecenia',
                dataIndex: 'commissionType',
                key: 'commissionType',
                width: '20%',
                filters: CommissionType,
                onFilter: (value, record) => value === record.commissionType,
                render: (item) =>
                    <div>
                        {this.CommissionTypeDict[item]}
                    </div>
            },
            {
                title: 'Brygadzista',
                dataIndex: 'worker_nick',
                key: 'worker_nick',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('worker_nick'),
            },
            {
                title: 'Podwykonawca',
                dataIndex: 'subcontractor_name',
                key: 'subcontractor_name',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('subcontractor_name'),
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 130,
                render: (item) => <div className={styles.iconHolder}>
                    <Link
                        className={styles.iconItem}
                        to={this.getDetailRouteCallback(item)}>
                        <InfoCircleOutlined/>
                    </Link>

                    {
                        item.hasFullAccess &&
                        <PaperClipOutlined
                            className={styles.iconItem}
                            onClick={() => this.showFilesModal(item)}
                        />

                    }

                    {item.hasFullAccess && Permissions.canDo("ServiceDelete") &&
                    <DeleteConfirm
                        onDelete={() => this.deleteService(item.id)}
                        confirmText={"Czy napewno chcesz usunać ten serwis?"}
                    />
                    }
                </div>,
            },
        ];

        return (
            <>
                <Modal
                    title="Podgląd plików"
                    visible={this.state.isFilesModalVisible}
                    onOk={this.handleOkFilesModal}
                    onCancel={this.handleCancelFilesModal}
                    width={1000}
                >
                    <VFilesEdit
                        editable={true}
                        viewEnabled={true}
                        viewName={"Service"}
                        viewId={this.state.serviceId}
                    />
                </Modal>


                <Table
                    pagination={this.state.pagination}
                    columns={columns}
                    dataSource={this.state.services}
                    scroll={{x: 1000}}
                    rowKey="id"
                />
            </>
        );
    }
}

export default ServicesTable;
