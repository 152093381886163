import React from "react";
import {DatePicker} from 'antd';
import moment from 'moment';

class VDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            rules: this.props.rules,
            placeholder: this.props.placeholder,
            isValid: true,
        };
    }

    componentDidMount() {
        if ('validTriggerContainer' in this.props) {
            this.props.validTriggerContainer.push(() => this.valid(this.props.value));
        }
    }

    inputHandler(date, dateString) {
        this.props.onChange(date, dateString)
        this.setState({
            value: date
        });
        this.valid(date);
    }

    valid(value) {
        if (!('rules' in this.state)) return;

        if (!!this.state.rules && 'required' in this.state.rules && !!this.state.rules['required']) {
            if (!value) {
                this.setState({
                    isValid: false
                })
                return;
            }
        }
        if (!this.state.isValid) {
            this.setState({
                isValid: true
            })
        }
    }

    render() {
        return (
            <div style={this.props.style}>
                <DatePicker
                    disabled={this.props.disabled}
                    className={`${this.state.isValid ? '' : 'borderErrorColor'}`}
                    // style={this.props.style}
                    placeholder={this.state.placeholder}
                    value={this.props.value ? moment(this.props.value) : null}
                    disabledDate={this.props.disabledDate}
                    onChange={(date, dateString) => this.inputHandler(date, dateString)}
                    format={this.props.format}
                />
                <div className={`inputErrorMessage ${this.state.isValid ? 'visibilityNone' : ''}`}>Pole nie może być
                    puste
                </div>
            </div>
        )
    }
}

export default VDatePicker;
