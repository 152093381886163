import React from "react";
import {Button, Col, notification, Row, Table} from "antd";
import {VSelect, VTextArea} from "../../../../../component/form/VForm";
import {authFetch} from "../../../../../utils/ApiHelper";
import TableHelper from "../../../../../utils/TableHelper";
import AssumptionsTypes from "../../../../../config/Protocol/v3/Protocol3AssumptionsTypes";
import SystemClasses from "../../../../../config/Protocol/v3/Protocol3SystemClasses";
import SolutionClasses from "../../../../../config/Protocol/v3/Protocol3SolutionClasses";
import ResultTypes from "../../../../../config/Protocol/v3/Protocol3ResultTypes";

class AddProtocol3 extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            validTriggerContainer: [],

            pagination: {
                pageSize: 20,
            },

            criteriaList: [],
            assumptions: null,
            systemClass: null,
            solutionClass: null,
            notes: null,

        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.fetchProtocol()
        }
    }

    componentDidMount() {
        this.fetchProtocol()
    }

    fetchProtocol(data = this.props.data) {
        if (data?.criteria) {
            data.criteria.sort((a, b) => a.id - b.id)
        }

        this.setState({
            assumptions: data?.assumptions,
            systemClass: data?.systemClass,
            solutionClass: data?.solutionClass,
            criteriaList: data?.criteria,
            notes: data?.notes
        })
    }

    validTrigger() {
        for (let item of this.state.validTriggerContainer) item()
    }

    setCriteriaListByIndex(index, key, value) {
        let listCopy = [...this.state.criteriaList]
        listCopy.forEach(x => {
            if (x.id === index) x[key] = value
        })

        this.setState({
            criteriaList: listCopy
        })
    }

    isProtocolValid() {
        this.validTrigger()
        for (let item of this.state.criteriaList) {
            if (!item.result) return false
        }
        return true
    }

    addProtocol() {
        if (this.isProtocolValid()) {
            authFetch({
                endpointName: this.props.protocolId ? "ProtocolSpecificVersionEdit" : "ProtocolSpecificVersionAdd",
                endpointParameters: {
                    serviceId: this.props.serviceId,
                    protocolId: this.props.protocolId,
                    version: 3
                },
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        systemType: this.props.systemTypeName,
                        serviceType: this.props.activityTypeName,
                        assumptions: this.state.assumptions,
                        systemClass: this.state.systemClass,
                        solutionClass: this.state.solutionClass,
                        notes: this.state.notes,
                        endDate: this.props.endDate,
                        scope: this.props.scope,
                        criteria: this.state.criteriaList.map(x => {
                            return {
                                id: x.id,
                                result: x.result ? x.result : null
                            }
                        })
                    })
                },
                successFunction: data => {

                    if (this.props.protocolId) {
                        notification['success']({
                            message: 'Udało się zmodyfikować protokół',
                        });

                    } else if (this.props.addSuccessFunction) {
                        this.props.addSuccessFunction({
                            protocolId: data.simpleProtocol?.protocolId
                        })
                    }
                    this.fetchProtocol(data)
                }
            })

        } else {
            notification['error']({
                message: 'Błąd',
                description: 'Najpierw uzupełnij wymagane dane',
            });
        }
    }

    renderList() {
        let columns = [
            {
                title: 'Kryterium',
                dataIndex: 'text',
                key: '',
                width: '59%',
                ...this.tableHelper.getColumnSearchProps('text'),
                render: (text, record, index) => text.split("\n")
                    .map((item, i) => <span key={"protocol3-criteria-key-" + i}>{item}<br/></span>),
            },
            {
                title: 'Wynik',
                dataIndex: 'result',
                key: '',
                width: '40%',
                ...this.tableHelper.getColumnSearchProps('result'),
                render: (text, record, index) => <VSelect
                    value={text}
                    disabled={!this.props.isActive}
                    style={{width: '100%'}}
                    rules={{required: true}}
                    validTriggerContainer={this.state.validTriggerContainer}
                    onChange={(e) => {
                        this.setCriteriaListByIndex(record.id, "result", e)
                    }}
                    options={ResultTypes}/>,
            }
        ];


        return <Table
            pagination={this.state.pagination}
            columns={columns}
            dataSource={this.state.criteriaList}
            scroll={{x: 1100}}
            rowKey="index"
        />

    }


    setAssumptions() {
        authFetch({
            endpointName: "ProtocolVersion3GetCriteria",
            config: {
                method: 'post',
                body: JSON.stringify({
                    assumptions: this.state.assumptions,
                    systemClass: this.state.systemClass,
                    solutionClass: this.state.solutionClass
                })
            },
            successFunction: data => {
                if (this.props.protocolId) {
                    notification['success']({
                        message: 'Udało się zmienić założenia',
                    });
                } else {
                    notification['success']({
                        message: 'Udało się ustawić założenia',
                    });
                }

                this.setState({
                    criteriaList: data.criteria
                })
            }
        })
    }

    render() {
        return (
            <>
                <br/>

                <Row>
                    <Col span={10}>Pomiary wykonane zgodnie z założeniami</Col>
                    {
                        this.state.assumptions === "NORM_PN_EN" &&
                        <Col span={10}>Klasa systemu</Col>
                    }
                    {
                        this.state.assumptions === "MANUAL_ITB" &&
                        <Col span={10}>Rozwiązanie</Col>
                    }
                </Row>
                <Row className="inputRow">
                    <Col span={10}>
                        <VSelect
                            value={this.state.assumptions}
                            style={{width: '80%'}}
                            rules={{required: true}}
                            disabled={!this.props.isActive}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(value) => this.setState({
                                assumptions: value,
                                systemClass: null,
                                solutionClass: null
                            })}
                            options={AssumptionsTypes}/>
                    </Col>

                    {
                        this.state.assumptions === "NORM_PN_EN" &&
                        <Col span={10}>
                            <VSelect
                                value={this.state.systemClass}
                                style={{width: '80%'}}
                                rules={{required: true}}
                                disabled={!this.props.isActive}
                                validTriggerContainer={this.state.validTriggerContainer}
                                onChange={(value) => this.setState({
                                    systemClass: value,
                                    solutionClass: null
                                })}
                                options={SystemClasses}/>
                        </Col>
                    }
                    {
                        this.state.assumptions === "MANUAL_ITB" &&
                        <Col span={10}>
                            <VSelect
                                value={this.state.solutionClass}
                                style={{width: '80%'}}
                                rules={{required: true}}
                                disabled={!this.props.isActive}
                                validTriggerContainer={this.state.validTriggerContainer}
                                onChange={(value) => this.setState({
                                    systemClass: null,
                                    solutionClass: value
                                })}
                                options={SolutionClasses}/>
                        </Col>
                    }
                </Row>

                <Row>
                    <Button
                        justify="end"
                        onClick={() => {
                            this.setAssumptions()
                        }}
                        type="primary">
                        {!this.props.protocolId &&
                        <>Wybierz założenia</>
                        }
                        {this.props.protocolId &&
                        <>Uaktualnij założenia</>
                        }
                    </Button>
                </Row>

                {this.state.criteriaList && this.state.criteriaList.length > 0 &&
                <>
                    <Row className="inputRow">
                        <Col span={10}>Uwagi</Col>
                    </Row>
                    <Row className="inputRow">
                        <Col span={10}>
                            <VTextArea
                                placeholder="Uwagi"
                                value={this.state.notes}
                                disabled={!this.props.isActive}
                                autoSize={{minRows: 1, maxRows: 3}}
                                onChange={(e) => this.setState({notes: e.target.value})}
                            />
                        </Col>
                    </Row>
                </>

                }

                {this.state.criteriaList && this.state.criteriaList.length > 0 &&
                <>
                    <Row>
                        <Col span={10}>Wyniki przeglądu:</Col>
                    </Row>


                    {this.renderList()}
                </>
                }

                <Row justify={"end"}>
                    {this.props.isActive &&
                    <Col>
                        <Button
                            justify="end"
                            onClick={() => {
                                this.addProtocol()
                            }}
                            type="primary">
                            {this.props.protocolId ? <>Uaktualnij protokół</> : <>Dodaj protokół</>}
                        </Button>
                    </Col>
                    }
                </Row>

            </>
        );
    }

}

export default AddProtocol3;
