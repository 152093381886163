export const DevicesFixPriorityTypes = [
    {
        value: 'NEXT_TIME',
        label: 'Do następnego przeglądu',
    },
    {
        value: 'DONE',
        label: 'Wykonano',
    },
    {
        value: 'RECOMMENDED',
        label: 'Zalecane',
    },
    {
        value: 'IMMEDIATELY',
        label: 'Niezwłocznie',
    },
];

export default DevicesFixPriorityTypes