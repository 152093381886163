export const DeviceEfficiencies = [
    {
        value: 'YES',
        label: 'Tak',
    },
    {
        value: 'NO',
        label: 'Nie',
    },
    {
        value: 'RETURN',
        label: 'Wróć później',
    },
];

export default DeviceEfficiencies;
