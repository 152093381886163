import React from "react";
import {Table} from 'antd';
import TableHelper from '../../../../../utils/TableHelper'
import {InfoCircleOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import {MakeRoute, Routes} from "../../../../../config/Routes";
import {Voivodeship} from "../../../../../config/Voivodeship";
import styles from '../../../../../style/dashboard/view/user/UserList.module.scss';

class ReclamationTable extends React.Component {
    tableHelper = new TableHelper(this);
    Voivodeship = Voivodeship.map(item => {
        item['text'] = item['label'];
        return item
    })
    FacilityStatus = this.props.statuses.map(item => {
        item['text'] = item['label'];
        return item
    })
    FacilityStatusDict = this.props.statusesDict

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            pagination: {
                pageSize: 10,
            },
        };
    }

    statusColor = (status) => {
        var styles = {
            height: 30,
            width: 130,
            textAlign: "center",
            lineHeight: '30px',
            color: "white",
            fontWeight: "bold",
            borderRadius: '3px'
        }
        styles.backgroundColor = this.props.statusesColor[status];
        return styles;
    }


    render() {
        let columns = [
            {
                title: 'Numer reklamacji',
                dataIndex: 'commissionNumber',
                key: 'commissionNumber',
                width: '20%',
                sorter: {
                    compare: (a, b) => a.commissionNumber.localeCompare(b.commissionNumber),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnExcelSearchProps('commissionNumber', this.props.data.map(x => x.commissionNumber)),

                render: (data, record) =>
                    <Link to={MakeRoute(Routes.CommissionDetail, {id: record.commissionId})}>{data}</Link>,
            },
            {
                title: 'Nazwa obiektu',
                dataIndex: 'name',
                key: 'name',
                width: '20%',
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnExcelSearchProps('name', this.props.data.map(x => x.name)),
                render: (data, record) =>
                    <Link to={MakeRoute(Routes.FacilityDetail, {id: record.id})}>{data}</Link>,
            },
            {
                title: 'Miasto',
                dataIndex: 'adress_city',
                key: 'adress_city',
                width: '20%',
                ...this.tableHelper.getColumnExcelSearchProps('adress_city', this.props.data.map(x => x.adress_city)),
            },
            {
                title: 'Województwo',
                dataIndex: 'adress_voivodeship',
                key: 'adress_voivodeship',
                width: '15%',
                filters: Voivodeship,
                onFilter: (value, record) => record.address.voivodeship.toLowerCase() === value,
            },
            {
                title: 'Status',
                dataIndex: 'fStatus',
                key: 'fStatus',
                width: '20%',
                filters: this.FacilityStatus,
                onFilter: (value, record) => record.fStatus === value,
                render: (item) =>
                    <div>
                        <div style={this.statusColor(item)}>
                            {this.FacilityStatusDict[item]}
                        </div>
                    </div>
            },
            {
                title: 'Podwykonwaca',
                dataIndex: 'manager_label',
                key: 'manager_label',
                width: '20%',
                ...this.tableHelper.getColumnExcelSearchProps('manager_label', this.props.data.map(x => x.manager_label)),
            },
            {
                title: 'Realizator HS',
                dataIndex: 'realizer_label',
                key: 'realizer_label',
                width: '20%',
                ...this.tableHelper.getColumnExcelSearchProps('realizer_label', this.props.data.map(x => x.realizer_label)),

            },
            {
                title: this.props.dateColumnLabel ? this.props.dateColumnLabel : "Termin następnego przeglądu",
                dataIndex: 'nextReviewDate',
                key: 'nextReviewDate',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('nextReviewDate'),
            },

            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 130,
                render: (item) => <div className={styles.iconHolder}>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.FacilityDetail, {'id': item.id})}>
                        <InfoCircleOutlined/>
                    </Link>
                </div>,
            },
        ];

        return (
            <Table
                pagination={this.state.pagination}
                columns={columns}
                dataSource={this.props.data}
                onChange={(a, b, c, dataBase) => this.props.udater(dataBase.currentDataSource)}
                scroll={{x: 1000}}/>
        );
    }
}

export default ReclamationTable;
