import React from "react";
import {authFetch} from "../../../../../utils/ApiHelper";
import ReclamationTable from './ReclamationTable';
import {
    CommissionStatus,
    CommissionStatusToLabel,
    GetCommissionStatusColor
} from "../../../../../config/CommissionStatus";
import {MakeRoute, Routes} from "../../../../../config/Routes";
import MapComponent from "../../../../../component/MapComponent";

class ReclamationTab extends React.Component {
    StatusDict = CommissionStatusToLabel()
    Colors = GetCommissionStatusColor()

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            facilities: [],
            facilitiesFiltered: [],
        };
    }

    componentDidMount() {
        authFetch({
            endpointName: "FacilityReclamationTab",
            successFunction: (data) => {
                for (let item of data.reclamationCommissions) {
                    item['adress_city'] = item.facilityAddress.city
                    item['adress_voivodeship'] = item.facilityAddress.voivodeship
                    item['facilityStatus_label'] = this.StatusDict[item.commissionStatus]
                    item['fStatus'] = item.commissionStatus
                    item['manager_label'] = item.subcontractor?.manager ? item.subcontractor.manager.firstName + " " + item.subcontractor.manager.lastName : "brak"
                    item['salesman_label'] = item.salesman ? item.salesman.firstName + " " + item.salesman.lastName : "brak"
                    item['realizer_label'] = item.realizer ? item.realizer.firstName + " " + item.realizer.lastName : "brak"
                    item['address'] = item.facilityAddress
                    item['facilityStatus'] = item.overviewFacilityStatus
                    item['name'] = item.facilityName
                    item['id'] = item.facilityId
                    item['nextReviewDate'] = item.commissionSendDate ? item.commissionSendDate : "brak"

                    // for map
                    item['text'] = item.name
                    item['linkAddress'] = MakeRoute(Routes.FacilityDetail, {'id': item.id})
                    item['latitude'] = item.address?.latitude
                    item['longitude'] = item.address?.longitude
                    item['markerColor'] = this.Colors[item.fStatus]
                }

                this.setState({
                    facilities: data.reclamationCommissions,
                    facilitiesFiltered: data.reclamationCommissions
                })
            }
        })
    }

    updateFiltered = (currentDataSource) => {
        this.setState({facilitiesFiltered: currentDataSource})
    }

    render() {
        return (<>
            <MapComponent
                data={this.state.facilitiesFiltered}
                clickable={true}
            />
            <ReclamationTable
                data={this.state.facilities}
                udater={this.updateFiltered}
                dateColumnLabel="Data wysłania zlecenia"
                statuses={CommissionStatus}
                statusesColor={this.Colors}
                statusesDict={this.StatusDict}/>
        </>);
    }
}

export default ReclamationTab;
