import React from "react";
import {Descriptions, Divider, List} from "antd";
import {FacilityStatusColor} from "../../../../config/FacilityStatusColor";
import {FacilityStatusToLabel} from "../../../../config/FacilityStatus";
import {OffertTypeToLabel} from "../../../../config/OffertType";
import FacilityInfoDetailsDisplay from "./FacilityInfoDetailsDisplay";
import {renderUser} from "../Utils";
import {FacilityCategoryToLabel} from "../../../../config/FacilityCategory";

let dateFormat = require("dateformat");

class FacilityDetailsDisplay extends React.Component {

    FacilityStatusDict = this.props.statusesDict
    facilityStatusLabels = FacilityStatusToLabel()
    facilityCategoryLabels = FacilityCategoryToLabel()
    offertTypeDict = OffertTypeToLabel()

    getByName(name) {
        return this.props.data[name]
    }

    renderAxProjectNumbers() {
        return (
            <List
                dataSource={this.getByName('axProjectNumbers')}
                renderItem={item => (
                    <List.Item>
                        {item}
                    </List.Item>
                )}
            />
        )
    }

    statusColor = (status) => {
        var styles = {
            height: 30,
            width: 130,
            textAlign: "center",
            lineHeight: '30px',
            color: "white",
            fontWeight: "bold",
            borderRadius: '3px'
        }
        styles.backgroundColor = this.props.statusesColor[status];
        return styles;
    }

    renderStatus(status) {
        let label = this.facilityStatusLabels[status]
        let color = FacilityStatusColor(status)
        return (
            status && <div style={color}>
                {label}
            </div>
        )
    }

    renderCategory() {
        let category = this.getByName('category')
        let label = this.facilityCategoryLabels[category]
        return (
            category && <div>
                {label}
            </div>
        )
    }

    renderOfferTypes() {
        let offerType = this.getByName('offerType')
        let offerDate = this.getByName('offerDate')
        let salesmanHs = this.getByName('salesmanHs')


        return (
            offerType && <>
                <Divider dashed/>
                <Descriptions title="Informacje ofertowe" bordered>

                    <Descriptions.Item
                        label="Rodzaj oferty">{this.offertTypeDict[offerType]}
                    </Descriptions.Item>


                    <Descriptions.Item
                        label="Data złożenia oferty">{dateFormat(offerDate, "dd-mm-yyyy HH:MM")}
                    </Descriptions.Item>

                    <Descriptions.Item
                        label="Handlowiec HS">{renderUser(salesmanHs)}
                    </Descriptions.Item>

                </Descriptions>

            </>
        )
    }

    renderFacilityDetails() {
        return (
            <div>
                <Descriptions title="Informacje o obiekcie" bordered column={2}>
                    <Descriptions.Item
                        label="Status">{this.renderStatus(this.getByName('status'))}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nazwa">{this.getByName('name')}</Descriptions.Item>
                    <Descriptions.Item label="Kategoria">{this.renderCategory()}</Descriptions.Item>
                    <Descriptions.Item label="Data zamknięcia projektu">{
                        this.getByName('projectFinishDate')?
                        dateFormat(this.getByName('projectFinishDate'), "dd-mm-yyyy")
                          :
                          "-"
                    }</Descriptions.Item>
                    <Descriptions.Item
                        label="Data utworzenia obiektu">{dateFormat(this.getByName('facilityCreationDate'), "dd-mm-yyyy HH:MM")}
                    </Descriptions.Item>


                    <Descriptions.Item
                        span={3}
                        label="Gwarancja / rękojmia"
                        style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                    >
                        {this.getByName('warranty')}
                    </Descriptions.Item>


                </Descriptions>

                {this.renderOfferTypes()}
                <Divider dashed/>
                <Descriptions title="Informacje dotyczące systemu AX" bordered column={3}>
                    <Descriptions.Item label="Numer obiektu AX">{this.getByName('axFacilityNumber')}</Descriptions.Item>

                    <Descriptions.Item label="Identyfikator projektu AX"
                                       span={3}>{
                        this.getByName('axProjectId') ? this.getByName('axProjectId') : '-'
                    }
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Handlowiec z AX">{
                        this.getByName('salesmanFromAx') ? this.getByName('salesmanFromAx') : '-'
                    }
                    </Descriptions.Item>

                    <Descriptions.Item
                        label="Realizator z AX" span={3}>{
                        this.getByName('realizerFromAx') ? this.getByName('realizerFromAx') : '-'
                    }
                    </Descriptions.Item>

                    <Descriptions.Item label="Projekty przypisane do obiektu AX"
                                       span={3}>{this.renderAxProjectNumbers()}</Descriptions.Item>
                </Descriptions>

                {this.getByName('status') === 'SERVICED' && <>
                    <Divider dashed/>
                    <FacilityInfoDetailsDisplay data={this.getByName('facilityInfoResponse')}/>
                </>
                }


            </div>
        )
    };

    render() {
        return (

            <div> {
                this.renderFacilityDetails()
            }

            </div>
        )
            ;
    }

}

export default FacilityDetailsDisplay;
