import {authFetch} from "./ApiHelper";
import React from "react";

export function IsFileImage(fileName) {
    let type = GetFileType(fileName)
    let validImageExtensions = ['img', 'png', 'gif', 'jpg', 'jpeg', 'bmp']
    return validImageExtensions.includes(type)
}

export function GetFileType(filename) {
    let match = /\.(\w+)$/.exec(filename);
    return match ? match[1] : null
}

export function DownloadFile(fileInfo) {
    let fileName = fileInfo.name ? fileInfo.name : fileInfo.fileName
    let isImage = IsFileImage(fileName)

    if(fileInfo.originFileObj != null) {
        downloadBlob(fileName, isImage, fileInfo.originFileObj)
        return
    }

    if (fileInfo.status !== "error") {
        fetch(fileInfo.signedUrl, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => response.blob())
            .then(_blob => {
                downloadBlob(fileName, isImage, _blob)
            })
    }
}

function downloadBlob(fileName, isImage, _blob) {
    let blobType = {}

    if (isImage) {
        blobType['type'] = "image/" + GetFileType(fileName)
    }

    var blob = new Blob([_blob], blobType);
    let url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url
    link.download = fileName
    link.click();
    link.remove()
}


export function RemoveFile(file, viewName, viewId, successFunction) {
    if (file.response && file.response.uuid) {
        file.uuid = file.response.uuid
    }

    if (file.status !== "error") {
        authFetch({
            endpointName: viewName + "FileDelete",
            endpointParameters: {id: viewId, fileId: file.uuid},
            config: {
                method: 'delete',
            },
            successFunction: data => {
                if (successFunction) successFunction(data)
            }
        });
    }
}
