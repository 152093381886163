import React from "react";
import {withRouter} from 'react-router-dom';
import styles from '../../../style/dashboard/component/TopBar.module.scss';
import '../../../style/dashboard/component/TopBar.antd.scss';
import {Button, Layout} from 'antd';
import {ShareState} from '../../../utils/ShareState';
import {PoweroffOutlined} from '@ant-design/icons';
import {GetAccountFromTypeAndSubType} from "../../../config/AccountTypesPermutation";
import {LogOut} from "../../../service/LogOutService";

const {Header} = Layout;

class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    logOut() {
        LogOut()
    }

    renderUserInfo() {
        let user = ShareState.container.user

        let userTypeStr = GetAccountFromTypeAndSubType(user.userType, user.userSubType).label;

        return (
            <div style={{float: "right", marginRight: "5px"}}>
                <b>{user.firstName + " " + user.lastName + ", " + userTypeStr}</b>
            </div>
        )
    }

    render() {
        return (

            <Header className={styles.topBar}>

                <Button
                    onClick={() => this.logOut()}
                    shape="circle"
                    className={styles.logoutBtn}
                    icon={<PoweroffOutlined/>}/>
                {
                    this.renderUserInfo()
                }
            </Header>
        )

    }
}

export default withRouter(TopBar);
