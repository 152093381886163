import Colors from "../../../../config/Colors";

let colors = {
    'ACTIVE': '#53c3b3',
    'CANCELLED': '#a3a3a3',
    'FINISHED': '#008c3f',
    'CONSIDERED': Colors.blue
}

export let StatusColor = (status) => {
    var styles = {
        height: 30,
        width: 140,
        textAlign: "center",
        lineHeight: '30px',
        color: "white",
        fontWeight: "bold",
        borderRadius: '3px'
    }
    styles.backgroundColor = colors[status];
    return styles;
}
