import React from "react";
import {withRouter} from 'react-router-dom';
import '../../../../style/dashboard/view/user/UserAdd.antd.scss';
import TenderForm from "./component/TenderForm";


class TenderEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
        };
    }

    render() {
        return (<TenderForm loadInfo={true} tenderId={this.state.id}/>)

    }

}

export default withRouter(TenderEdit);
