import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, Modal, notification, Table} from 'antd';
import {ShareState} from '../../../../utils/ShareState';
import {authFetch, getApiUrl} from "../../../../utils/ApiHelper";
import {MakeRoute, Routes} from "../../../../config/Routes";
import styles from "../../../../style/dashboard/view/user/UserList.module.scss";
import {DeleteOutlined, FormOutlined, InfoCircleOutlined} from "@ant-design/icons";
import TableHelper from "../../../../utils/TableHelper";
import UserActiveStatus, {
    GetUserActiveStatusFromDisabled,
    UserActiveStatusColor
} from "../../../../config/UserActiveStatus";
import SubcontractorDetailsDisplay from "../../displayInfos/SubcontractorDetailsDisplay";
import UserDetailsDisplay from "../../displayInfos/UserDetailsDisplay";

class SubcontractorDetail extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            name: null,
            address: null,
            mail: null,
            phone: null,
            dealNumber: null,
            manager: null,
            workerList: [],
            managerActiveStatus: null
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Informacje o podwykonawcy')
        this.getSubcontractorDetail()
    }

    getSubcontractorDetail() {
        fetch(getApiUrl("subcontractorDetail", {id: this.state.id}), {
            headers: {
                'Authorization': 'Bearer ' + window.localStorage.getItem('auth-token')
            }
        })
            .then(response => response.json())
            .then(data => {
                for (let worker of data.workers) {
                    worker['name_surname'] = worker.firstName + ' ' + worker.lastName
                    worker['activeStatus'] = GetUserActiveStatusFromDisabled(worker.isDeleted)
                }

                let managerActiveStatus = GetUserActiveStatusFromDisabled(data.manager?.isDeleted)
                this.setState({
                    name: data.name,
                    address: data.address,
                    mail: data.mail,
                    phone: data.phone,
                    manager: data.manager,
                    workerList: data.workers,
                    dealNumber: data.dealNumber,
                    managerActiveStatus: managerActiveStatus
                })
            });
    }

    renderInfoCard() {
        return (
            <Card title="Informacje o podwykonawcy" bordered={true}>
                <SubcontractorDetailsDisplay data={this.state}/>
            </Card>
        )
    }

    getIsActiveStyle() {
        let statusStyle = UserActiveStatusColor(this.state.managerActiveStatus.value);
        statusStyle['width'] = 140
        statusStyle['marginLeft'] = '15px'
        return statusStyle
    }

    renderManagerCard() {
        return (this.state.manager && <>
            <Card
                style={{marginTop: 20}}
                title={
                    <>
                        {this.state.managerActiveStatus &&
                        <>
                            Kierownik ekipy serwisowej
                            <div style={this.getIsActiveStyle()}>
                                {this.state.managerActiveStatus.label}
                            </div>
                        </>
                        }
                    </>
                }
                extra={
                    <Button type="primary" style={{marginRight: 10}}>
                        <Link to={MakeRoute(Routes.UserDetail, {'id': this.state.manager.id})}>
                            Szczegóły
                        </Link>
                    </Button>
                }>
                <UserDetailsDisplay data={this.state.manager}/>
            </Card>
        </>)
    }

    showModal = (id) => {
        this.setState({
            selectedWorkerId: id,
            visibleDeleteWorkerModal: true,
        });
    };

    handleOk = e => {
        this.setState({
            visibleDeleteWorkerModal: false,
        });
        this.deactivateWorker(this.state.selectedWorkerId);
    };

    handleCancel = e => {
        this.setState({
            visibleDeleteWorkerModal: false,
        });
    };

    deactivateWorker(id) {
        authFetch({
            endpointName: "userDelete",
            endpointParameters: {id: id},
            config: {
                method: 'delete',
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Deaktywowano użytkownika',
                })
                this.getSubcontractorDetail()
            }
        })
    }


    renderWorkerList() {
        const columns = [
            {
                title: 'Imię i nazwisko',
                dataIndex: 'name_surname',
                key: 'name_surname',
                width: '25%',
                sorter: {
                    compare: (a, b) => a.name_surname.localeCompare(b.name_surname),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnSearchProps('name_surname'),
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('email'),
            },
            {
                title: 'Nick',
                dataIndex: 'nick',
                key: 'nick',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('nick'),
            },
            {
                title: 'Aktywny',
                width: '10%',
                dataIndex: 'activeStatus',
                key: 'activeStatus',
                filters: UserActiveStatus,
                render: (item) =>
                    <div>
                        <div style={UserActiveStatusColor(item.value)}>
                            {item.label}
                        </div>
                    </div>,
                onFilter: (value, record) => record.activeStatus.value === value,
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 150,
                render: (item) => <div className={styles.iconHolder}>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.UserDetail, {'id': item.id})}>
                        <InfoCircleOutlined/>
                    </Link>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.UserEdit, {'id': item.id})}>
                        <FormOutlined/>
                    </Link>
                    {!item.isDeleted &&
                    <DeleteOutlined
                        style={{color: 'red'}}
                        className={styles.iconItem}
                        onClick={() => this.showModal(item.id)}/>
                    }
                </div>,
            },
        ];


        return (
            <Card title="Lista brygadzistów" bordered={true} style={{
                marginTop: 20
            }}>
                <Table
                    pagination={this.state.pagination}
                    columns={columns}
                    dataSource={this.state.workerList}
                    scroll={{x: 1200}}/>
            </Card>
        )
    }

    render() {
        return (
            <div>
                {this.renderInfoCard()}
                {this.renderManagerCard()}
                {this.renderWorkerList()}
                <Modal
                    title="Uwaga"
                    visible={this.state.visibleDeleteWorkerModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p>Czy napewno chcesz deaktywować tego brygadzistę?</p>
                </Modal>
            </div>
        );
    }
}

export default withRouter(SubcontractorDetail);
