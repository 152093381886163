import {authFetch} from "../utils/ApiHelper";

export const getFacilitiesListForSelect = async () => {
    return new Promise((resolve, reject) => {
        authFetch({
            endpointName: "FacilityList",
            successFunction: data => {
                let facilityList = []
                for (let item of data.facilities) {
                    facilityList.push({
                        value: item.id,
                        label: item.name,
                        facilityStatus: item.facilityStatus,
                    })
                }
                resolve(facilityList)
            },
            onFail: e => {
                reject()
            }
        });
    })
}
const facilityInfoProps = {
    id: null,
    contactName: null,
    contactSurname: null,
    contactEmail: null,
    contactPhone: null,
    note: null,
    scope: null,
    realizerId: null,
    salesmanId: null,
    subcontractorId: null
};


export const getDetailedFacility = async (facilityId) => {
    return new Promise((resolve, reject) => {
        authFetch({
            endpointName: 'FacilityDetail',
            endpointParameters: {id: facilityId},
            successFunction: data => {
                resolve(data)
            }
        })
    })
}