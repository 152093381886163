import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, notification} from 'antd';
import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";
import {MakeRoute, Routes} from "../../../../config/Routes";
import {GetUserActiveStatusFromDisabled, UserActiveStatusColor} from "../../../../config/UserActiveStatus";
import UserDetailsDisplay from "../../displayInfos/UserDetailsDisplay";
import SubcontractorDetailsDisplay from "../../displayInfos/SubcontractorDetailsDisplay";

class UserDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            firstName: null,
            lastName: null,
            userType: null,
            userSubType: null,
            email: null,
            phone: null,
            nick: null,
            voivodeships: null,
            voivodeshipsString: null,
            isDeleted: false,
            activeStatus: null,

            subcontractor: null
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Profil użytkownika')
        this.getUserDetails()
    }

    isManagerOrWorker() {
        let userType = this.state.userType
        return userType === "ROLE_WORKER" || userType === "ROLE_MANAGER"
    }

    getUserDetails() {
        authFetch({
            endpointName: "userDetail",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                this.setState({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    nick: data.nick,
                    phone: data.phone,
                    userType: data.userType,
                    userSubType: data.userSubType,
                    isDeleted: data.isDeleted,
                    voivodeships: data.voivodeships,
                    voivodeshipsString: this.voivodeshipsToString(data.voivodeships),
                    activeStatus: GetUserActiveStatusFromDisabled(data.isDeleted)
                })

                if (this.isManagerOrWorker()) {
                    this.getSubcontractorInfo()
                }
            }
        })
    }

    getSubcontractorInfo() {
        authFetch({
            endpointName: "SubcontractorGetForManagerOrWorker",
            endpointParameters: {userId: this.state.id},
            successFunction: data => {
                this.setState({
                    subcontractor: data
                })


            }
        })
    }

    voivodeshipsToString = (data) => {
        var result = "";
        for (var i = 0; i < data.length; i++) {
            result += data[i];
            if (i < data.length - 1) {
                result += ", ";
            }
        }
        return result;
    }

    getIsActiveStyle() {
        let statusStyle = UserActiveStatusColor(this.state.activeStatus.value);
        statusStyle['width'] = 140
        statusStyle['marginLeft'] = '15px'
        return statusStyle
    }

    deactivateUser() {
        authFetch({
            endpointName: "userDelete",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'delete',
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Deaktywowano użytkownika',
                })
                this.getUserDetails()
            }
        })
    }

    activateUser() {
        authFetch({
            endpointName: "UserUnDelete",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'post',
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Przywrócono urzytownika',
                })
                this.getUserDetails()
            }
        })
    }

    renderUserInfoCard() {
        let isActiveName = this.state.isDeleted ? "Nie aktywny" : "Aktywny"
        return (
            <Card title={
                <>
                    Profil
                    {this.state.activeStatus &&
                    <div style={this.getIsActiveStyle()}>
                        {isActiveName}
                    </div>
                    }
                </>
            }
                  bordered={true}
                  extra={
                      <>{!this.state.isDeleted &&
                      <Button type="danger" style={{marginRight: 10}} onClick={() => this.deactivateUser()}>
                          Deaktywuj
                      </Button>
                      }

                          {this.state.isDeleted &&
                          <Button type="primary" style={{marginRight: 10}} onClick={() => this.activateUser()}>
                              Aktywuj
                          </Button>
                          }

                          <Button type="primary">
                              <Link to={MakeRoute(Routes.UserEdit, {'id': this.state.id})}>
                                  Edytuj
                              </Link>
                          </Button>
                      </>
                  }
            >

                <UserDetailsDisplay data={this.state}/>
            </Card>
        )
    }


    renderSubcontractorCard() {
        return (<>
                {this.isManagerOrWorker() &&
                <Card
                    title="Podwykonawca"
                    bordered={true}
                    style={{marginTop: 20}}
                    extra={<Button type="primary" style={{marginRight: 10}}>
                        <Link to={MakeRoute(Routes.SubcontractorDetail, {'id': this.state.subcontractor?.id})}>
                            Szczegóły
                        </Link>
                    </Button>}
                >
                    <SubcontractorDetailsDisplay data={this.state.subcontractor}/>

                </Card>
                }
            </>
        )
    }

    render() {
        return (
            <>
                {this.renderUserInfoCard()}
                {this.renderSubcontractorCard()}
            </>
        );
    }
}

export default withRouter(UserDetail);
