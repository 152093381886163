import React from "react";
import {Input} from 'antd';

class VInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            rules: this.props.rules,
            placeholder: this.props.placeholder,
            isValid: true,
        };
    }

    componentDidMount() {
        if ('validTriggerContainer' in this.props) {
            this.props.validTriggerContainer.push(() => this.valid(this.props.value));
        }
    }

    inputHandler(event) {
        this.props.onChange(event)
        this.setState({
            value: event.target.value
        });
        this.valid(event.target.value);
    }

    valid(value) {
        if (!('rules' in this.state)) return true;

        if (!!this.state.rules && 'required' in this.state.rules && !!this.state.rules['required']) {
            if (!value) {
                this.setState({
                    isValid: false
                })
                return false;
            }
        }
        if (!this.state.isValid) {
            this.setState({
                isValid: true
            })
        }
        return true
    }

    shouldShowInvalidState() {
        return !this.state.isValid && !this.props.shouldHideInvalidClass
    }

    render() {
        return (
            <>
                <Input
                    disabled={this.props.disabled}
                    className={`${this.shouldShowInvalidState() ? 'borderErrorColor' : ''}`}
                    placeholder={this.state.placeholder}
                    value={this.props.value}
                    onChange={(e) => this.inputHandler(e)}/>
                <div className={`inputErrorMessage ${this.shouldShowInvalidState() ? '' : 'visibilityNone'}`}>Pole nie może być
                    puste
                </div>
            </>
        )
    }
}

export default VInput;
