import React from "react";
import Permissions from '../../../../../config/Permissions';
import Routes, {MakeRoute} from "../../../../../config/Routes";
import {authFetch} from "../../../../../utils/ApiHelper";
import {Link} from "react-router-dom";

function getFacilityLinkOrText(notifyData) {
    if (Permissions.isAllow("FacilityView")) {
        return (
        <Link to={MakeRoute(Routes.FacilityDetail, {id: notifyData.relatedFacility.id})}>
            {notifyData.relatedFacility.name}
        </Link>
        );
    } else {
        return <>{notifyData.relatedFacility.name}</>;
    }
}

function extendWithFacilityName(text, notifyData) {
    return (
      <>
        {text}{" - "}
        {notifyData.relatedFacility && notifyData.relatedFacility.name && getFacilityLinkOrText(notifyData)}
      </>
    );
  }

export const NotifyConfig = {

    UNREALIZED_COMMISSION: {
        type: "warning",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.CommissionDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Niezrealizowane zlecenie', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły zlecenia.';
        },
    },

    NEXT_MONTH_CYCLIC_OVERVIEW: {
        type: "warning",
        isAction: true,
        actionText: "Wystaw nowe zlecenie",
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.CommissionAddFromPreviousCommission, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Na następny miesiąc przypada cykliczne zlecenie', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Wystaw nowe zlecenie.';
        },
    },

    NOT_SERVICE_FACILITY_REMIND_NOTIFICATION: {
        type: "warning",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.FacilityDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Przypada pierwszy przegląd dla obiektu', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return '6 miesięcy od zakończenia budowy';
        },
    },

    ONE_TIME_OVERVIEW_REMIND_NOTIFICATION: {
        type: "warning",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.CommissionDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('5 miesięcy od daty wykonania przeglądu jednorazowego', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły.';
        },
    },

    SUBCONTRACTOR_NOTIFICATION_NEW_TENDER: {
        type: "success",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.TenderDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Nowy przetarg', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły.';
        },
    },

    SUBCONTRACTOR_NOTIFICATION_RESULT_TENDER_WINNER: {
        type: "success",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.TenderDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Rozstrzygnięcie przetargu - oferta wybrana', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            authFetch({
                endpointName: 'managerTenderDetail',
                endpointParameters: {id: notifyData.relatedObjectId},
                successFunction: data => {
                    asyncSetter(`Nazwa obiektu: ${data?.facility?.name}`)
                },
                onFail: () => {
                    asyncSetter('Brak dodatkowych informacji.');
                },
                notShowFailInfo: true
            })
            return 'Ładowanie informacji...';
        },
    },

    SUBCONTRACTOR_NOTIFICATION_RESULT_TENDER_LOSER: {
        type: "warning",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.TenderDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Rozstrzygnięcie przetargu - oferta nie wybrana', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            authFetch({
                endpointName: 'managerTenderDetail',
                endpointParameters: {id: notifyData.relatedObjectId},
                successFunction: data => {
                    asyncSetter(`Nazwa obiektu: ${data?.facility?.name}`)
                },
                onFail: () => {
                    asyncSetter('Brak dodatkowych informacji.');
                },
                notShowFailInfo: true
            })
            return 'Ładowanie informacji...';
        },
    },

    SUBCONTRACTOR_NOTIFICATION_UNREALIZED_COMMISSION: {
        type: "error",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.CommissionDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Niezrealizowane zlecenie', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły.';
        },
    },

    SUBCONTRACTOR_NOTIFICATION_UPCOMING_COMMISSION: {
        type: "warning",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.CommissionDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Wkrótce przypada termin realizcji zlecenia', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły.';
        },
    },

    SUBCONTRACTOR_NOTIFICATION_NEW_COMMISSION: {
        type: "success",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.CommissionDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Dostałeś nowe zlecenie', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły.';
        },
    },

    WORKER_NOTIFICATION_NEW_COMMISSION: {
        type: "success",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.CommissionDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Dostałeś nowe zlecenie', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły.';
        },
    },

    WORKER_NOTIFICATION_UPCOMING_COMMISSION: {
        type: "warning",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.CommissionDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Wkrótce przypada termin realizacji zlecenia', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły.';
        },
    },

    WORKER_NOTIFICATION_UNREALIZED_COMMISSION: {
        type: "error",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.CommissionDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Niezrealizowane zlecenie', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły.';
        },
    },


    REALIZER_NOTIFICATION_NEW_OFFER_BY_SUBCONTRACTOR: {
        type: "success",
        isAction: true,
        action: (props) => {
            props.history.push({pathname: MakeRoute(Routes.TenderDetail, {id: props.data.relatedObjectId})});
        },
        messageBuilder: (notifyData) => {
            return extendWithFacilityName('Podwykonawca złożył nową ofertę do przetargu', notifyData);
        },
        descriptionBuilder: (notifyData, asyncSetter) => {
            return 'Zobacz szczegóły.';
        },
    },
}


