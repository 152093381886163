import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, Col, Divider, Modal, notification, PageHeader, Row, Table, Tabs,} from 'antd';

import VFilesEdit from '../../../../component/VFilesEdit'

import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";
import styles from "../../../../style/dashboard/view/user/UserList.module.scss";
import {MakeRoute, Routes} from "../../../../config/Routes";
import {CopyOutlined, FormOutlined, InfoCircleOutlined} from "@ant-design/icons";
import TableHelper from "../../../../utils/TableHelper";
import SystemTypes, {GetSystemTypeFromValue} from "../../../../config/Protocol/SystemTypes";
import ActivityTypes, {GetActivityTypeFromValue} from "../../../../config/Protocol/ActivityTypes";
import ActivityList from "./ActivityList";
import AddProtocol from "./AddProtocol";
import Permissions from '../../../../config/Permissions';
import {StatusColor} from "../commission/CommissionStatus";
import {CommissionStatusToLabel} from "../../../../config/CommissionStatus";
import {VTextArea} from "../../../../component/form/VForm";
import DeleteConfirm from "../../../../component/DeleteConfirm";
import VSelect from "../../../../component/form/VSelect";
import MapComponent from "../../../../component/MapComponent";
import CommissionSimpleDisplay from "../../displayInfos/CommissionSimpleDisplay";
import FacilitySimpleDisplay from "../../displayInfos/facility/FacilitySimpleDisplay";
import AddressDisplay from "../../displayInfos/AddressDisplay";
import ServiceDetailsDisplay from "../../displayInfos/ServiceDetailDisplay";


const {TabPane} = Tabs;

let dateFormat = require("dateformat");


class ServiceDetail extends React.Component {
    commissionStatusDict = CommissionStatusToLabel()
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            fromCommissionId: props.match.params.commissionId,

            startDate: null,
            endDate: null,
            latitude: null,
            longitude: null,
            note: null,
            protocols: [],
            commission: null,

            protocolVersion: null,
            protocolId: null,

            protocolAddTitle: "Wybierz protokół i typ systemu",
            showSelectTypeScreen: true,

            commissionStatus: null,
            activityListId: null,

            addedSystemTypeName: null,
            addedActivityTypeName: null,

            hasFullAccess: null,
            showCopyService: false,

            validTriggerContainer: [],
            copyServiceNote: null,
            facility: null
        };
    }

    getServiceDetails() {
        authFetch({
            endpointName: "ServiceGet",
            endpointParameters: {id: this.state.id},
            notShowFailInfo: true,
            successFunction: data => {
                this.setGetProtocolState(data)
            },
        })
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Serwis')
        this.getServiceDetails()
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id
            || this.props.match.params.fromCommissionId !== prevProps.match.params.fromCommissionId) {
            this.setState({
                id: this.props.match.params.id,
                fromCommissionId: this.props.match.params.commissionId,
            }, () => this.getServiceDetails())
        }
    }

    setGetProtocolState(data) {
        data.protocols.forEach(x => {
            if (!x.systemType || !x.serviceType) {
                x.serviceTypeText = "Prace serwisowe"
                x.systemTypeText = "Prace serwisowe"
            } else {
                x.serviceTypeText = GetActivityTypeFromValue(x.serviceType)
                x.systemTypeText = GetSystemTypeFromValue(x.systemType)
            }


            x.creationDate = new Date(x.creationDate)
            x.creationDateStr = x.creationDate ? dateFormat(x.creationDate, "dd-mm-yyyy HH:MM") : '-'
        })

        this.setState({
            startDate: data.startDate,
            endDate: data.endDate,
            latitude: data.latitude,
            longitude: data.longitude,
            note: data.note,
            protocols: data.protocols,
            commission: data.commission,
            commissionStatus: data.commission.commissionStatus,
            protocolScope: data.commission.scope,
            hasFullAccess: data.hasFullAccess
        })
    }

    refreshProtocols() {
        authFetch({
            endpointName: "ServiceGet",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                this.setGetProtocolState(data)
            }
        })
    }

    handleAddProtocolCancel() {
        this.setState({
            isAddProtocolActivityVisible: false,

            protocolVersion: null,
            protocolAddTitle: "Wybierz protokół i typ systemu",
            showSelectTypeScreen: true,
            addedProtocolId: null,
            activityListId: null,
            protocolId: null,

            addedSystemTypeName: null,
            addedActivityTypeName: null
        })
    }

    isAddProtocolValid() {
        return this.state.addedSystemTypeName != null && this.state.addedActivityTypeName != null;
    }

    getProtocolType() {
        if (this.isAddProtocolValid()) {
            authFetch({
                endpointName: "ProtocolGetVersion",
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        systemType: this.state.addedSystemTypeName,
                        mercorServiceType: this.state.addedActivityTypeName,
                    })
                },
                successFunction: data => {
                    notification['success']({
                        message: 'OK',
                    });
                    // this.getDetailed();
                    this.setState({
                        protocolVersion: data,
                        showSelectTypeScreen: false,
                        protocolAddTitle: 'Dodaj pola protokołu',
                        protocolId: null
                    })
                }
            })
        } else {
            notification['error']({
                message: 'Niepowodzenie',
                description: 'Pola "Typ systemu" oraz "Typ czynności" nie mogą być puste.',
            });
        }
    }

    editProtocol(data) {
        this.setState({
            protocolId: data.protocolId,
            protocolVersion: data.protocolVersion,

            activityListId: data.activityList ? data.activityList.activityListId : null,

            addedSystemTypeName: data.systemType,
            addedActivityTypeName: data.serviceType,

            isAddProtocolActivityVisible: true,
            showSelectTypeScreen: false,
            protocolAddTitle: 'Edytuj protokół'
        })
    }

    deleteProtocol(data) {
        authFetch({
            endpointName: "ProtocolDelete",
            config: {method: 'delete'},
            endpointParameters: {protocolId: data.protocolId},
            successFunction: data => {
                notification['success']({
                    message: 'Udało się usunąć protokół',
                });
                this.refreshProtocols()
            }
        })
    }

    infoProtocol(data) {
        this.setState({
            protocolId: data.protocolId,
            protocolVersion: data.protocolVersion,

            activityListId: data.activityList ? data.activityList.activityListId : null,

            addedSystemTypeName: data.systemType,
            addedActivityTypeName: data.serviceType,

            isAddProtocolActivityVisible: true,
            showSelectTypeScreen: false,
            protocolAddTitle: 'Informacje'
        })
    }

    protocolAddSuccessFunction(data) {
        notification['success']({
            message: 'Udało się dodać protokół',
        });
        this.setState({
            protocolId: data.protocolId,
            activityListId: null,
        })
        this.refreshProtocols()
    }

    activityListAddSuccessFunction(data) {
        notification['success']({
            message: 'Udało się dodać listę czynności',
        });
        this.setState({
            activityListId: data.id,
        })
        this.refreshProtocols()
    }

    stopService() {
        authFetch({
            endpointName: "ServiceStop",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                notification['success']({
                    message: 'Udało się zakończyć serwis',
                });
                this.refreshProtocols()
            }
        })
    }

    cancelStopService() {
        authFetch({
            endpointName: "ServiceCancelStop",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                notification['success']({
                    message: 'Udało się anulować zakończenie serwisu',
                });
                this.refreshProtocols()
            }
        })
    }

    renderProtocolsList() {
        const protocolColumns = [
            {
                title: 'Data utworzenia protokołu',
                width: '20%',
                dataIndex: 'creationDateStr',
                key: 'creationDateStr',
                onFilter: (value, record) => record.creationDateStr === value,
                sorter: {
                    compare: (a, b) => b.creationDate.getTime() - a.creationDate.getTime(),
                    multiple: 1,
                },
            },
            {
                title: 'Typ systemu',
                dataIndex: 'systemTypeText',
                key: 'systemTypeText',
                width: '50%',
                sorter: {
                    compare: (a, b) => a.systemTypeText.localeCompare(b.systemTypeText),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnSearchProps('systemTypeText'),
            },
            {
                title: 'Typ czynności',
                dataIndex: 'serviceTypeText',
                key: 'serviceTypeText',
                width: '50%',
                sorter: {
                    compare: (a, b) => a.serviceTypeText.localeCompare(b.serviceTypeText),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnSearchProps('serviceTypeText'),
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 130,
                render: (item) =>
                    <div className={styles.iconHolder}>
                        {this.state.hasFullAccess && this.state.commissionStatus === 'ACTIVE' &&
                        <FormOutlined className={styles.iconItem}
                                      onClick={() => this.editProtocol(item)}/>
                        }
                        {this.state.hasFullAccess && this.state.commissionStatus === 'ACTIVE' &&
                        <DeleteConfirm
                            onDelete={() => this.deleteProtocol(item)}
                            confirmText={"Czy napewno chcesz usunać ten protokół?"}
                        />
                        }
                        {(this.state.commissionStatus === 'FINISHED' || !this.state.hasFullAccess) &&
                        <InfoCircleOutlined className={styles.iconItem}
                                            onClick={() => this.infoProtocol(item)}/>
                        }
                    </div>,
            },
        ];
        return (
            <div>
                <Modal
                    width={1200}
                    title={this.state.protocolAddTitle}
                    visible={this.state.isAddProtocolActivityVisible}
                    onOk={() => this.handleAddOProtocolOk()}
                    onCancel={() => this.handleAddProtocolCancel()}
                    footer={[
                        <Button key="back" onClick={() => this.handleAddProtocolCancel()}>
                            Anuluj
                        </Button>,
                        <>
                            {this.state.showSelectTypeScreen &&
                            <Button key="submit" type="primary" onClick={
                                () => this.getProtocolType()
                            }>
                                Wybierz
                            </Button>
                            }
                        </>
                    ]}>

                    {
                        this.state.showSelectTypeScreen
                        && <div>
                            <Row>
                                Typ systemu
                            </Row>
                            <Row className="inputRow">
                                <VSelect
                                    options={SystemTypes}
                                    style={{width: '100%'}}
                                    onChange={(value, systemType) => this.setState({addedSystemTypeName: value})}
                                />
                            </Row>
                            <Row>
                                Typ czynności
                            </Row>
                            <Row className="inputRow">
                                <VSelect
                                    options={ActivityTypes}
                                    style={{width: '100%'}}
                                    onChange={(value, activityType) => this.setState({addedActivityTypeName: value})}
                                />
                            </Row>
                        </div>
                    }


                    {
                        this.state.protocolVersion
                        &&
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Protokół" key="1">
                                <AddProtocol
                                    scope={this.state.protocolScope}
                                    protocolVersion={this.state.protocolVersion}
                                    serviceId={this.state.id}
                                    systemTypeName={this.state.addedSystemTypeName}
                                    protocolId={this.state.protocolId}
                                    activityTypeName={this.state.addedActivityTypeName}
                                    addSuccessFunction={(value) => this.protocolAddSuccessFunction(value)}
                                    isActive={
                                        this.state.hasFullAccess ?
                                            this.state.commissionStatus === 'ACTIVE' :
                                            false
                                    }
                                    fromCommissionId={this.state.fromCommissionId}
                                    hasFullAccess={this.state.hasFullAccess}
                                    facilityName={this.state.commission?.facility?.name}
                                />

                            </TabPane>
                            {
                                this.state.protocolId && this.state.addedSystemTypeName &&
                                <TabPane tab="Lista czynności" key="2">
                                    <ActivityList
                                        serviceId={this.state.id}
                                        systemTypeName={this.state.addedSystemTypeName}
                                        activityTypeName={this.state.addedActivityTypeName}
                                        activityListId={this.state.activityListId}
                                        protocolId={this.state.protocolId}
                                        addSuccessFunction={(value) => this.activityListAddSuccessFunction(value)}
                                        isActive={this.state.hasFullAccess ?
                                            this.state.commissionStatus === 'ACTIVE' :
                                            false
                                        }
                                        fromCommissionId={this.state.fromCommissionId}
                                        hasFullAccess={this.state.hasFullAccess}
                                        facilityName={this.state.commission?.facility?.name}
                                    />
                                </TabPane>
                            }
                        </Tabs>
                    }

                </Modal>

                <Table
                    pagination={this.state.pagination}
                    columns={protocolColumns}
                    dataSource={this.state.protocols}
                    scroll={{x: 1200}}
                    rowKey="protocolId"
                />
            </div>
        );
    }


    getCommissionStatusStyle() {
        let style = StatusColor(this.state.commissionStatus)
        style['display'] = 'inline-block'
        style['marginLeft'] = '15px'
        return style
    }

    renderFromCommissionIdInfo() {
        return (
            <PageHeader
                className="site-page-header"
                title="Tryb przeglądania, funkcje ograniczone"
            />
        )
    }

    showCopyServiceModal() {
        this.setState({
            showCopyService: true
        })
    }

    renderServiceInfo() {
        return (
            <Card title={
                <>
                    Serwis
                    {this.state.commissionStatus &&
                    <div style={this.getCommissionStatusStyle()}>
                        {this.commissionStatusDict[this.state.commissionStatus]}
                    </div>
                    }
                </>
            } bordered={true} extra={
                <>
                    {
                        this.state.fromCommissionId
                        && Permissions.canDo("ServiceCopy") &&
                        <Button style={{marginRight: 10}} type="dashed"
                                onClick={() => this.showCopyServiceModal()}>
                            Kopiuj serwis <CopyOutlined/>
                        </Button>
                    }
                    {
                        this.state.hasFullAccess &&
                        this.state.commissionStatus === 'ACTIVE' && Permissions.canDo("ServiceStop") &&
                        <Button style={{marginRight: 10}} type="danger"
                                onClick={() => this.stopService()}>
                            Zakończ serwis
                        </Button>
                    }

                    {
                        this.state.hasFullAccess &&
                        this.state.commissionStatus === 'FINISHED' && Permissions.canDo("ServiceCancelStop") &&
                        <Button style={{marginRight: 10}} type="danger"
                                onClick={() => this.cancelStopService()}>
                            Anuluj zakończenie serwisu
                        </Button>
                    }

                </>


            }>
                <ServiceDetailsDisplay data={this.state}/>
            </Card>
        )
    }

    renderServicePlaceCard() {
        return (<>
            <
                Card
                style={{
                    marginTop: 20
                }
                }
                title="Miejsce zarejestrowania serwisu">

                < Row>
                    < Col
                        span={23}
                        style={
                            {
                                paddingLeft: 25
                            }
                        }>
                        <
                            MapComponent
                            data={
                                [{
                                    latitude: this.state.latitude,
                                    longitude: this.state.longitude
                                }]
                            }
                        />
                    </Col>
                </Row>
            </Card>
        </>)
    }

    renderCommissionInfoCard() {
        return (
            this.state.commission &&

            <Card style={{marginTop: 20}} title="Informacje o zleceniu" extra={
                this.state.hasFullAccess &&
                <Button type="primary" style={{marginRight: 10}}>
                    <Link to={MakeRoute(Routes.CommissionDetail, {'id': this.state.commission.id})}>
                        Szczegóły
                    </Link>
                </Button>
            }>
                <CommissionSimpleDisplay data={this.state.commission}/>
            </Card>
        )
    }

    renderFacilityInfoCard() {
        return (
            this.state.commission?.facility &&

            <Card style={{marginTop: 20}} title="Informacje o obiekcie" extra={
                Permissions.canDo('FacilityDetail') &&
                <Button type="primary" style={{marginRight: 10}}>
                    <Link to={MakeRoute(Routes.FacilityDetail, {'id': this.state.commission?.facility?.id})}>
                        Szczegóły
                    </Link>
                </Button>
            }>
                <FacilitySimpleDisplay data={this.state.commission?.facility}/>
                <Divider dashed/>
                <AddressDisplay data={this.state.commission?.facility?.address}/>
            </Card>
        )
    }

    okCopyService() {
        authFetch({
            endpointName: "ServiceCopyWithoutLocation",
            config: {
                method: 'post',
                body: JSON.stringify({
                    serviceId: this.state.id,
                    commissionId: this.state.fromCommissionId,
                    note: this.state.copyServiceNote
                })
            },

            successFunction: data => {
                notification['success']({
                    message: 'Udało się skopiować serwis',
                });

                this.props.history.push((Routes.ServiceDetail).replace(":id", data.id));
                this.setState({
                    id: data.id,
                    fromCommissionId: null,
                    showCopyService: false
                }, () => {
                    this.getServiceDetails()
                })

            }
        })
    }

    cancelCopyService() {
        this.setState({
            showCopyService: false,
            validTriggerContainer: [],
            copyServiceNote: null
        })
    }

    renderCopyServiceModal() {
        return (
            <Modal
                width={600}
                title={"Kopiuj serwis do wybranego zlecenia"}
                visible={this.state.showCopyService}
                okText={"Kopiuj serwis"}
                cancelText={"Anuluj"}
                onOk={() => this.okCopyService()}
                onCancel={() => this.cancelCopyService()}
            >

                <VTextArea
                    value={this.state.copyServiceNote}
                    rules={{required: false}}
                    validTriggerContainer={this.state.validTriggerContainer}
                    onChange={(e) => this.setState({copyServiceNote: e.target.value})}
                    placeholder="Notatka"
                    autoSize={{minRows: 3, maxRows: 5}}/>

            </Modal>
        )
    }

    checkIfCommissionTypeForProtocol6(commission = this.state.commission) {
        let commissionType = commission?.commissionType;

        return commissionType === 'REPAIR'
            || commissionType === 'RECLAMATION'
            || commissionType === 'MODERNIZATION'
            || commissionType === 'INSPECTION';
    }

    addProtocolInit() {
        if (this.checkIfCommissionTypeForProtocol6()) {
            this.setState({
                protocolVersion: 'ProtocolVersion6',
                showSelectTypeScreen: false,
                protocolAddTitle: 'Dodaj pola protokołu',
                protocolId: null,
                isAddProtocolActivityVisible: true,
            })
        } else {
            this.setState({
                isAddProtocolActivityVisible: true,
                protocolAddTitle: "Wybierz protokół i typ systemu",
                showSelectTypeScreen: true,
                protocolId: null,
            })
        }
    }

    render() {
        return (
            <div>
                {this.state.fromCommissionId && !this.state.hasFullAccess && this.renderFromCommissionIdInfo()}
                {this.renderServiceInfo()}
                {this.renderServicePlaceCard()}
                {this.renderCommissionInfoCard()}
                {this.renderFacilityInfoCard()}
                {this.renderCopyServiceModal()}
                <br/>
                <Card title={"Protokoły i listy czynności"}>
                    {this.renderProtocolsList()}
                    <br/>
                    <Row>{this.state.hasFullAccess && this.state.commissionStatus === 'ACTIVE' &&
                    <Button style={{marginRight: 16}} type="primary"
                            onClick={() => this.addProtocolInit()}>
                        Dodaj protokół
                    </Button>
                    }
                    </Row>

                </Card>

                <br/>
                <Card title={"Pliki serwis"}>

                    <VFilesEdit
                        editable={this.state.commissionStatus === 'ACTIVE' && this.state.hasFullAccess}
                        viewEnabled={true}
                        viewName={"Service"}
                        viewId={this.state.id}
                    />
                </Card>
            </div>
        )
    }
}

export default withRouter(ServiceDetail);
