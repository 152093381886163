import React from "react";
import {Button, Checkbox, Col, Descriptions, Row, Table} from "antd";
import {authFetch} from "../../../../utils/ApiHelper";
import TableHelper from "../../../../utils/TableHelper";

class ActivityListCheckedList extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            activityValues: null,
        };

        this._isMounted = false;

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedValuesIds !== this.props.selectedValuesIds) {
            this.updateSelectedActivityValues(this.state.activityValues)
        }
        if (prevProps.activityTypeName !== this.props.activityTypeName) {
            this.fetchActivityListFields()
        }

    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.fetchActivityListFields()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchActivityListFields() {
        authFetch({
            endpointName: "ActivityListGetFields",
            config: {
                method: 'post',
                body: JSON.stringify(
                    this.props.activityTypeName
                )
            },
            successFunction: data => {
                this.updateSelectedActivityValues(data.activityValues)
            }
        })
    }

    updateSelectedActivityValues(data) {
        let activityValues = {}
        for (let key in data) {
            activityValues[key] = data[key].map((value, index) => {
                let checked = false
                if (this.props.selectedValuesIds) {
                    checked = this.props.selectedValuesIds.find(x => x === value.id) !== undefined
                }

                value.checked = checked
                value.index = index
                return value
            })
        }

        this._isMounted && this.setState({
            activityValues: activityValues
        })
    }

    validTrigger() {
        for (let item of this.state.validTriggerContainer) item()
    }

    valid() {
        this.validTrigger()
    }

    setStateByIndex(index, key, value) {
        let listItem = this.state.deviceReportList[index]
        listItem[key] = value;
        this.setState({
            deviceReportList: this.state.deviceReportList
        })
    }

    deleteReportRow(text, record, index) {
        const result = this.state.deviceReportList.filter((_, i) => i !== index);
        this.setState({
            deviceReportList: result
        })
    }

    setChecked(tableName, id, checked) {
        let allTable = this.state.activityValues[tableName];
        let found = allTable.find(x => x.id === id);
        found.checked = checked

        let newActivityValues = {}
        Object.assign(newActivityValues, this.state.activityValues);
        this.setState({
            activityValues: newActivityValues
        })
    }


    renderTable(tableName) {
        let columns = [
            {
                title: 'L.p.',
                dataIndex: 'index',
                key: '',
                width: '5%',
                render: (text, record, index) => <div>{text + 1}</div>,
            },
            {
                title: tableName,
                dataIndex: 'value',
                key: '',
                width: '26%',
                ...this.tableHelper.getColumnSearchProps('value'),
                render: (text, record, index) => <div>
                    <Checkbox
                        disabled={!this.props.isActive}
                        checked={record.checked}
                        onChange={(e) => {
                            this.setChecked(tableName, record.id, e.target.checked)
                        }}>
                        {text}
                    </Checkbox>

                </div>,
            },

        ];

        return <div>
            <Descriptions title={tableName}/>
            <Table
                columns={columns}
                pagination={false}
                dataSource={this.state.activityValues[tableName]}
                scroll={{x: 1100}}
                rowKey="id"
            />
        </div>

    }

    renderTables() {
        let tables = []
        if (this.state.activityValues !== null) {
            for (var key in this.state.activityValues) {
                tables.push(this.renderTable(key))
            }
        }

        return tables
    }

    addActivityList() {
        if (this.props.addActivityCallback) {
            let activityIds = []

            for (var key in this.state.activityValues) {
                activityIds.push(...this.state.activityValues[key]
                    .filter(val => val.checked)
                    .map(val => val.id))
            }
            this.props.addActivityCallback(activityIds)

        }
    }


    render() {
        return (
            <>
                {this.renderTables()}
                {this.props.isActive &&
                <Row justify={"end"}>
                    <Col>
                        <Button style={{paddingTop: 4}}
                                justify="end"
                                onClick={() => {
                                    this.addActivityList()
                                }}
                                type="primary">
                            {this.props.selectedValuesIds ? <>Uaktualnij listę czynności</> : <>Zapisz listę
                                czynności</>}

                        </Button>
                    </Col>
                </Row>
                }
            </>
        );
    }

}

export default ActivityListCheckedList;
