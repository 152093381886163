import React from "react";
import {Descriptions} from "antd";

class OfferDisplay extends React.Component {

    getByName(name) {
        return this.props.data ? this.props.data[name] : null
    }


    getStatusStyle(chosen) {
        let styles = {
            height: 30,
            width: 140,
            textAlign: "center",
            lineHeight: '30px',
            color: "white",
            fontWeight: "bold",
            borderRadius: '3px'
        }
        if (chosen) {
            styles.backgroundColor = "#53c3b3"; // utworzone
        } else {
            styles.backgroundColor = "#fe437e"; // w trakcie
        }
        return styles
    }


    renderStatus() {
        let chosen = this.getByName('chosen')
        let label = chosen ? " Wybrana" : " Nie wybrana"

        return <div style={this.getStatusStyle(chosen)}>{label}</div>
    }

    renderSubcontractor() {
        let subcontractor = this.getByName('subcontractor')
        return subcontractor?.name
    }

    render() {
        return (
            <Descriptions bordered column={2}>
                <Descriptions.Item
                    label="Status oferty">{this.renderStatus()}
                </Descriptions.Item>

                <Descriptions.Item
                    label="Kwota">{this.getByName('finalSalary')}
                </Descriptions.Item>

                <Descriptions.Item
                    span={this.props.renderSubcontractor ? 1 : 2}

                    label="Data realizacji">{this.getByName('realizationDate')}
                </Descriptions.Item>

                {this.props.renderSubcontractor &&
                <Descriptions.Item label="Podwykonawca">
                    {this.renderSubcontractor()}
                </Descriptions.Item>
                }

                {this.getByName('note') &&
                    <Descriptions.Item
                        span={2}
                        label="Uwagi"
                        style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                    >
                        {this.getByName('note')}
                    </Descriptions.Item>
                }

            </Descriptions>

        );
    }
}

export default OfferDisplay
