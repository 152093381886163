export const LocalizationTypes = [
    {
        value: 'Garaż',
        label: 'Garaż',
    },
    {
        value: 'Korytarz',
        label: 'Korytarz',
    },
    {
        value: 'Miejsce parkingowe',
        label: 'Miejsce parkingowe',
    },
    {
        value: 'Toaleta',
        label: 'Toaleta',
    },
    {
        value: 'Magazyn',
        label: 'Magazyn',
    },
    {
        value: 'Szacht',
        label: 'Szacht',
    },
    {
        value: 'Klatka schodowa',
        label: 'Klatka schodowa',
    },
    {
        value: 'Dach',
        label: 'Dach',
    }
];

export default LocalizationTypes;
