export const Colors = {
    'green': '#0dbf0a',
    'blue': '#0a48f5',
    'yellow': '#f5e42f',
    'orange': '#eb8510',
    'red': '#eb1010',
    'grey': '#a3a3a3',
    'fountain_blue': '#53c3b3'
}

export default Colors
