import {getApiUrl, getAuthToken} from "../../../../../utils/ApiHelper";

export async function addFile(commissionId, file) {
    const formData = new FormData();
    formData.append("file", file);

    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open("POST", getApiUrl("CommissionFileAdd",  {id: commissionId}));
        request.setRequestHeader("Authorization", getAuthToken());
        request.send(formData);

        request.onreadystatechange = function() {
            if (request.readyState === 4) {
                if (request.status === 200) {
                    resolve()
                } else {
                    reject()
                }
            }
        }


    })

}