export const DeviceTypes = [
    {
        value: 'MCR fid s',
        label: 'MCR fid s',
    },
    {
        value: 'MCR fid pro',
        label: 'MCR fid pro',
    },
    {
        value: 'MCR wip',
        label: 'MCR wip',
    },
    {
        value: 'MCR wip pro',
        label: 'MCR wip pro',
    },
    {
        value: 'MCR zipp',
        label: 'MCR zipp',
    },
    {
        value: 'MCR fs',
        label: 'MCR fs',
    },
    {
        value: 'MCR dor',
        label: 'MCR dor',
    },
    {
        value: 'MCR pld',
        label: 'MCR pld',
    },
    {
        value: 'MCR pl',
        label: 'MCR pl',
    },
    {
        value: 'Przepustnica',
        label: 'Przepustnica',
    },
];

export default DeviceTypes;
