import React from "react";
import {Link} from "react-router-dom";
import Routes, {MakeRoute} from "../../../config/Routes";
import Permissions from '../../../config/Permissions';
import styles from '../../../style/dashboard/component/Menu.module.scss';
import '../../../style/dashboard/component/Menu.antd.scss';
import {ShareState} from '../../../utils/ShareState';
import '../../../style/dashboard/component/BadgeCounter.scss';

import {EnvironmentOutlined, HomeOutlined, MenuUnfoldOutlined, MessageOutlined, UserOutlined, ReadOutlined} from '@ant-design/icons';
import {Badge, Menu as AntMenu} from 'antd';

const {SubMenu} = AntMenu;


class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {'notificationCount': 0};
    }

    componentDidMount() {
        ShareState.subscribe('user', (value) => {
            this.setState({wakeUp: 1})
        });

        ShareState.subscribe('notificationCount', (value) => {
            this.setState({
                notificationCount: value
            })
        })
    }

    render() {
        return (
            <AntMenu
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={[
                    'UserSubmenu',
                    'FacilityView',
                    'SubcontractorSubmenu',
                    'WorkerSubmenu',
                    'TenderView',
                    'CommissionSubmenu',
                    'ServiceSubmenu'
                ]}
                className={styles.menu}
            >
                {
                    Permissions.isAllow('MainView') ?
                        <AntMenu.Item key="MainView" icon={<HomeOutlined/>}>
                            <Link to={MakeRoute(Routes.MainView, {subPath: 'overview'})}>
                                Strona główna
                            </Link>
                        </AntMenu.Item>
                        : <></>
                }
                {
                    Permissions.isAllow('FacilityView') ?
                        <SubMenu
                            key="FacilityView"
                            title={
                                <span>
              <EnvironmentOutlined/>
              <span>Obiekty</span>
            </span>
                            }
                        >
                            <AntMenu.Item key="FacilityList" icon={<EnvironmentOutlined/>}>
                                <Link to={Routes.FacilityList}>
                                    Lista obiektów
                                </Link>
                            </AntMenu.Item>
                            {Permissions.canDo("FacilityAdd") &&
                            <AntMenu.Item key="FacilityAdd" icon={<EnvironmentOutlined/>}>
                                <Link to={Routes.FacilityAdd}>
                                    Dodaj obiekt
                                </Link>
                            </AntMenu.Item>
                            }
                        </SubMenu>
                        : <></>
                }
                {
                    Permissions.isAllow('TenderView') ?
                        <SubMenu
                            key="TenderView"
                            title={
                                <span>
              <MenuUnfoldOutlined/>
              <span>Przetargi</span>
            </span>
                            }
                        >
                            <AntMenu.Item key="TendersList" icon={<MenuUnfoldOutlined/>}>
                                <Link to={Routes.TendersList}>
                                    Lista przetargów
                                </Link>
                            </AntMenu.Item>
                            <AntMenu.Item key="TenderAdd" icon={<MenuUnfoldOutlined/>}>
                                <Link to={Routes.TenderAdd}>
                                    Dodaj przetarg
                                </Link>
                            </AntMenu.Item>
                        </SubMenu>
                        : <></>
                }
                {
                    Permissions.isAllow('ManagerTenderView') ?
                        <AntMenu.Item key="ManagerTenderView" icon={<MenuUnfoldOutlined/>}>
                            <Link to={Routes.ManagerTendersList}>
                                Przetargi
                            </Link>
                        </AntMenu.Item>
                        : <></>
                }
                {
                    Permissions.isAllow('CommissionView') ?
                        <SubMenu
                            key="CommissionSubmenu"
                            title={
                                <span>
              <MenuUnfoldOutlined/>
              <span>Zlecenia</span>
            </span>
                            }
                        >
                            <AntMenu.Item key="CommissionList" icon={<MenuUnfoldOutlined/>}>
                                <Link to={Routes.CommissionList}>
                                    Lista zleceń
                                </Link>
                            </AntMenu.Item>
                            <AntMenu.Item key="CommisssionAdd" icon={<MenuUnfoldOutlined/>}>
                                <Link to={Routes.CommissionAdd}>
                                    Dodaj zlecenie
                                </Link>
                            </AntMenu.Item>
                        </SubMenu>
                        : <></>
                }
                {
                    Permissions.isAllow('ManagerCommissionView') ?
                        <AntMenu.Item key="ManagerCommissionView" icon={<MenuUnfoldOutlined/>}>
                            <Link to={Routes.CommissionList}>
                                Zlecenia
                            </Link>
                        </AntMenu.Item>
                        : <></>
                }
                {
                    Permissions.isAllow('WorkerCommissionView') ?
                        <AntMenu.Item key="WorkerCommissionView" icon={<MenuUnfoldOutlined/>}>
                            <Link to={Routes.CommissionList}>
                                Zlecenia
                            </Link>
                        </AntMenu.Item>
                        : <></>
                }
                {
                    Permissions.isAllow('ServiceView') ?
                        <SubMenu
                            key="ServiceSubmenu"
                            title={
                                <span>
              <MenuUnfoldOutlined/>
              <span>Serwisy</span>
            </span>
                            }
                        >
                            <AntMenu.Item key="ServiceView" icon={<MenuUnfoldOutlined/>}>
                                <Link to={Routes.ServicesList}>
                                    Lista serwisów
                                </Link>
                            </AntMenu.Item>
                        </SubMenu>
                        : <></>
                }
                {
                    Permissions.isAllow('UserView') ?
                        <SubMenu
                            key="UserSubmenu"
                            title={
                                <span>
              <UserOutlined/>
              <span>Użytkownicy</span>
            </span>
                            }
                        >
                            <AntMenu.Item key="UserList" icon={<UserOutlined/>}>
                                <Link to={Routes.UserList}>
                                    Lista użytkowników
                                </Link>
                            </AntMenu.Item>
                            <AntMenu.Item key="UserAdd" icon={<UserOutlined/>}>
                                <Link to={Routes.UserAdd}>
                                    Dodaj użytkownika
                                </Link>
                            </AntMenu.Item>
                        </SubMenu>
                        : <></>
                }
                {
                    Permissions.isAllow('SubcontractorView') ?
                        <SubMenu
                            key="SubcontractorSubmenu"
                            title={
                                <span>
              <UserOutlined/>
              <span>Podwykonawcy</span>
            </span>
                            }
                        >
                            <AntMenu.Item key="SubcontractorList" icon={<UserOutlined/>}>
                                <Link to={Routes.SubcontractorList}>
                                    Lista podwykonawców
                                </Link>
                            </AntMenu.Item>
                            <AntMenu.Item key="SubcontractorForm" icon={<UserOutlined/>}>
                                <Link to={Routes.SubcontractorAdd}>
                                    Dodaj podwykonawcę
                                </Link>
                            </AntMenu.Item>
                        </SubMenu>
                        : <></>
                }
                {
                    Permissions.isAllow('WorkerView') ?
                        <SubMenu
                            key="WorkerSubmenu"
                            title={
                                <span>
              <UserOutlined/>
              <span>Brygadziści</span>
            </span>
                            }
                        >
                            <AntMenu.Item key="WorkerList" icon={<UserOutlined/>}>
                                <Link to={Routes.WorkerList}>
                                    Lista brygadzistów
                                </Link>
                            </AntMenu.Item>
                            <AntMenu.Item key="WorkerAdd" icon={<UserOutlined/>}>
                                <Link to={Routes.WorkerAdd}>
                                    Dodaj brygadzistę
                                </Link>
                            </AntMenu.Item>
                        </SubMenu>
                        : <></>
                }
                {
                    Permissions.isAllow('AlertView') ?
                        <AntMenu.Item key="AlertView" icon={<MessageOutlined/>}>
                            <Link to={Routes.AlertView}>
                                Alerty
                                <Badge
                                    className="site-badge-count"
                                    count={this.state.notificationCount}
                                />
                            </Link>
                        </AntMenu.Item>
                        : <></>
                }
                <AntMenu.Item key="PrivacyPolicy" icon={<ReadOutlined />}>
                    <Link to={Routes.PrivacyPolicy}>
                        Polityka prywatności
                    </Link>
                </AntMenu.Item>
            </AntMenu>
        );
    }

}

export default Menu;
