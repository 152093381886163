import React from "react";
import {withRouter} from 'react-router-dom';
import {Button, Card, Col, Input, notification, Row} from 'antd';
import {ShareState} from '../../../../utils/ShareState';
import '../../../../style/dashboard/view/user/UserAdd.antd.scss';
import {authFetch} from "../../../../utils/ApiHelper";
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';

class WorkerAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            workerNick: null,
            workerPassword: null,
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Dodaj brygadzistę')
    }

    addWorker() {
        if (!this.isValid()) {
            notification['error']({
                message: 'Błąd',
                description: 'Nie uzupełniono wszystkich wymaganych pól formularza',
            });
        } else {
            authFetch({
                endpointName: "managerWorkerAdd",
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        email: this.state.email,
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        nick: this.state.workerNick,
                        password: this.state.workerPassword,
                        phone: this.state.phone,
                    })
                },
                successFunction: data => {
                    this.clearState();
                    notification['success']({
                        message: 'OK',
                        description: 'Dodano nowego brygadzistę',
                    })
                }
            })
        }
    }

    clearState() {
        this.setState({
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            workerNick: null,
            workerPassword: null,
        });
    }

    isValid() {
        for (let key in this.state) {
            if (this.state[key] == null) return false;
        }
        return true;
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    render() {
        return (
            <Card title="Dodaj brygadzistę" bordered={true}>
                <Row>
                    <Col span={8}>Imię</Col>
                    <Col span={8}>Nazwisko</Col>
                    <Col span={8}>Adres e-mail</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <Input
                            placeholder="Imię"
                            value={this.state.firstName}
                            onChange={(e) => this.inputHandler('firstName', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Nazwisko"
                            value={this.state.lastName}
                            onChange={(e) => this.inputHandler('lastName', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Adres e-mail"
                            value={this.state.email}
                            onChange={(e) => this.inputHandler('email', e)}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>Telefon kontaktowy</Col>
                    <Col span={8}>Nick</Col>
                    <Col span={8}>Hasło</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <Input
                            placeholder="Telefon kontaktowy"
                            value={this.state.phone}
                            onChange={(e) => this.inputHandler('phone', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Nick"
                            autoComplete="false"
                            value={this.state.workerNick}
                            onChange={(e) => this.inputHandler('workerNick', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input.Password
                            placeholder="Hasło"
                            autoComplete="new-password"
                            onChange={(e) => this.inputHandler('workerPassword', e)}
                            value={this.state.workerPassword}
                            iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                        />
                    </Col>
                </Row>


                <Row>
                    <Col span={8}>
                        <Button type="primary" onClick={() => this.addWorker()}>Dodaj brygadzistę</Button>
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default withRouter(WorkerAdd);
