export const FacilityCategory = [
    {
        value: 'BUDYNEK_BIUROWY',
        label: 'Budynek biurowy',
    },
    {
        value: 'BUDYNEK_MIESZKALNY',
        label: 'Budynek mieszkalny',
    },
    {
        value: 'BUDYNEK_SAKRALNY',
        label: 'Budynek sakralny',
    },
    {
        value: 'HANDEL_I_USLUGI',
        label: 'Handel i usługi',
    },
    {
        value: 'HOTEL',
        label: 'Hotel',
    },
    {
        value: 'INNE',
        label: 'Inne',
    },
    {
        value: 'KULTURA_I_SZTUKA',
        label: 'Kultura i sztuka',
    },
    {
        value: 'LOGISTYKA',
        label: 'Logistyka',
    },
    {
        value: 'PRODUKCJA',
        label: 'Produkcja',
    },
    {
        value: 'PRZEMYSL',
        label: 'Przemysł',
    },
    {
        value: 'SLUZBA_ZDROWIA',
        label: 'Służba zdrowia',
    },
    {
        value: 'SPORT_I_REKREACJA',
        label: 'Sport i rekreacja',
    },
    {
        value: 'SPRZEDAZ',
        label: 'Sprzedaż',
    },
    {
        value: 'SPRZEDAZ_HW',
        label: 'Sprzedaż HW',
    },
    {
        value: 'SPZREDAZ_HZ',
        label: 'Sprzedaż HZ',
    },
    {
        value: 'SZKOLNICTWO_I_EDUKACJA',
        label: 'Szkolnictwo i edukacja',
    },
    {
        value: 'TRANSPORT',
        label: 'Transport',
    },
    {
        value: 'ZAKLAD_KARNY',
        label: 'Zakład karny',
    },


];


export const FacilityCategoryToLabel = () => {
    let FacilityCategoryToLabelDict = {}
    for (let item of FacilityCategory) {
        FacilityCategoryToLabelDict[item.value] = item.label;
    }
    return FacilityCategoryToLabelDict;
}

export default FacilityCategory
