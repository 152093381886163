export const SystemTypes = [
    {value: 'GRAWITACYJNY_SYSTEM_ODDYMIANIA', label: 'Grawitacyjny system oddymiania'},
    {value: 'MECHANICZNY_SYSTEM_ODDYMIANIA', label: 'Mechaniczny system oddymiania'},
    {value: 'OKIENNY_SYSTEM_ODDYMIANIA', label: 'Okienny system oddymiania'},
    {value: 'KLAPY_PRZECIWPOZAROWE', label: 'Klapy przeciwpożarowe'},
    {value: 'SYSTEM_ROZNICOWANIA_CISNIENIA', label: 'System różnicowania ciśnienia'},
    {value: 'KURTYNY', label: 'Kurtyny'},
    {value: 'INNY', label: 'Inny'},
];

export default SystemTypes

export let GetSystemTypeFromValue = (value) => {
    SystemTypes.forEach(x => {
        if (x.value === value) {
            value = x.label
        }
    })

    return value
}
