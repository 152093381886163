import React from "react";
import {withRouter} from 'react-router-dom';
import {Button, Card, Col, notification, Row, Select} from 'antd';
import '../../../../../style/dashboard/view/user/UserAdd.antd.scss';
import moment from "moment";
import {ShareState} from "../../../../../utils/ShareState";
import {authFetch} from "../../../../../utils/ApiHelper";
import TenderTypes from "../../../../../config/TenderTypes";
import Routes from "../../../../../config/Routes";
import VDatePicker from "../../../../../component/form/VDatePicker";
import VTimePicker from "../../../../../component/form/VTimePicker";
import VSelect from "../../../../../component/form/VSelect";
import VTextArea from "../../../../../component/form/VTextArea";
import MapComponent from "../../../../../component/MapComponent";
import Text from "antd/lib/typography/Text";
import {getDetailedFacility} from "../../../../../apiOperations/FacilityApiOperations";
import {isTenderTypeOverview} from "../TenderUtils";
import {getFacilityScope} from "../../facility/FacilityUtils";
import {getSubcontractorList} from "../getSubcontractorList";
import Permissions from "../../../../../config/Permissions";

class TenderAdd extends React.Component {
    reviewMonthsOptions = [
        {
            value: 6,
            label: "6 miesięcy",
        },
        {
            value: 12,
            label: "12 miesięcy"
        }
    ]

    constructor(props) {
        super(props);

        let realizerId = null
        let user = ShareState.container.user
        if (user.userType === 'ROLE_REALIZER') {
            realizerId = user.id
        }

        this.state = {
            complaintNotice: null,
            documentNumber: null,
            facilityId: null,
            note: null,
            realizationDate: null,
            realizerId: realizerId,
            nextReviewMonths: 6,
            scope: "",
            submissionBids: null,
            tenderType: null,
            topic: null,

            subcontractorsList: null,
            subcontractorsChosen: [],

            reviewDateDate: null,
            reviewDateTime: null,

            submissionBidsDate: null,
            submissionBidsTime: null,

            facilities: null,
            realizers: null,
            currentFacilityChosen: null,
            isEdit: this.props.loadInfo,
            tenderId: this.props.tenderId,
            validTriggerContainer: [],
        };
    }

    disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Dodaj przetarg')

        this.getRealizersList();
        this.getFacilityList();

        if (this.state.isEdit) {
            this.getTenderDetail()
        }

        this.getSubcontractorList()

    }

    getSubcontractorList() {
        getSubcontractorList((subcontractorsList) => {
            this.setState({
                subcontractorsList
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({
                tenderId: this.props.match.params.id,
            }, () => this.getTenderDetail())
        }
    }

    getFacilityList() {
        authFetch({
            endpointName: "FacilityList",
            successFunction: data => {
                if (!data) return;
                let facilities = []
                for (let facility of data.facilities) {
                    facility['label'] = facility.name
                    facility['value'] = facility.id
                    facilities.push(facility)

                }
                this.setState({
                    facilities: facilities
                })
            }
        })
    }

    getRealizersList() {
        authFetch({
            endpointName: "GetRealizerList",
            successFunction: data => {
                if (!data.users) return;

                var realizers = []
                for (let realizer of data.users) {
                    realizer['label'] = realizer.firstName + " " + realizer.lastName
                    realizer['value'] = realizer.id
                    realizers.push(realizer)

                }
                this.setState({
                    realizers: realizers
                })
            }
        })
    }

    getTenderDetail() {

        authFetch({
            endpointName: "tenderDetail",
            endpointParameters: {id: this.state.tenderId},
            successFunction: data => {
                this.setState({
                    currentFacilityChosen: data.facility,
                    facilityId: data.facility.id,
                    realizationDate: data.realizationDate,
                    status: data.status,
                    documentNumber: data.documentNumber,
                    salesmanId: data.facility.salesman?.id,
                    realizerId: data.realizer?.id,
                    note: data.note,
                    reviewDate: data.reviewDate,
                    topic: data.topic,
                    tenderType: data.tenderType,
                    scope: data.scope,
                    tenderStatus: data.tenderStatus,
                    complaintNotice: data.complaintNotice,
                    submissionBids: data.submissionBids,
                    submissionBidsDate: data.submissionBids.split("T")[0],
                    submissionBidsTime: data.submissionBids.split("T")[1],
                    nextReviewMonths: data.nextReviewMonths
                })
            }
        })
    }

    addTender() {
        if (!this.isValid()) {
            notification['error']({
                message: 'Błąd',
                description: 'Nie uzupełniono wszystkich wymaganych pól formularza',
            });
        } else {
            let submissionBids = this.convertToISO(this.state.submissionBidsDate, this.state.submissionBidsTime);

            let endpoint = "tenderAdd"
            let endpointMethod = 'post'
            let endpointParameters = {}
            const body = {
                complaintNotice: this.state.complaintNotice,
                facilityId: this.state.facilityId,
                note: this.state.note,
                realizationDate: this.state.realizationDate,
                realizerId: this.state.realizerId,
                scope: this.state.scope,
                submissionBids: submissionBids,
                tenderType: this.state.tenderType,
                topic: this.state.topic,
                nextReviewMonths: this.state.nextReviewMonths,
            }

            if (this.state.tenderId) {
                endpoint = "tenderEdit"
                endpointMethod = 'put'
                endpointParameters = {id: this.state.tenderId}
            } else {
                body.subcontractorsIds = this.state.subcontractorsChosen
            }



            authFetch({
                endpointName: endpoint,
                endpointParameters: endpointParameters,
                config: {
                    method: endpointMethod,
                    body: JSON.stringify(body)
                },
                successFunction: data => {
                    notification['success']({
                        message: 'OK',
                        description: this.state.tenderId ? 'Uaktualniono przetarg' : 'Dodano i wysłano nowy przetarg',
                    });
                    if (!this.state.tenderId) {
                        this.props.history.push(Routes.TendersList)
                    }
                }
            })
        }
    }

    clearState() {
        this.setState({
            complaintNotice: null,
            documentNumber: null,
            facilityId: null,
            note: null,
            realizationDate: null,
            realizerId: null,
            nextReviewMonths: 6,
            scope: null,
            submissionBids: null,
            tenderType: null,
            topic: null,

            submissionBidsDate: null,
            submissionBidsTime: null,

        });
    }

    isValid() {
        for (let item of this.state.validTriggerContainer) item()
        let required = [
            'facilityId',
            'realizerId',
            'scope',
            'tenderType',
            'topic',
            'realizationDate',
            'submissionBidsDate',
            'submissionBidsTime'
        ]


        if (this.state.tenderType === "RECLAMATION") {
            required.push('complaintNotice')
        }

        if (this.state.tenderType === "ONE_TIME_OVERVIEW" || this.state.tenderType === "CYCLICAL_OVERVIEW") {
            required.push('nextReviewMonths')
        }

        return this.checkRequired(required);
    }

    checkRequired(requiredList) {
        for (let item of requiredList) {
            if (!(item in this.state)) return false;
            if (this.state[item] === null) return false;
        }
        return true;
    }

    convertToISO(date, time) {
        return date + "T" + time + "Z";
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    datePickerHandler(date, dateString, property) {
        let state = this.state
        state[property] = dateString
        this.setState(state);
    }

    facilityOrTenderTypeChanged(detailedFacility, tenderType) {
        if (this.state.isEdit) {
            return;
        }
        if (isTenderTypeOverview(tenderType) && detailedFacility) {
            this.setState({
                scope: getFacilityScope(detailedFacility)
            });
        } else {
            this.setState({
                scope: ""
            });
        }
    }

    async chooseFacilityHandler(facility) {
        const facilityDetailedInfo = await getDetailedFacility(facility.id);
        this.setState({currentFacilityChosen: facilityDetailedInfo, facilityId: facility.id, facilityScope: facilityDetailedInfo.facilityInfoResponse?.scope});
        this.facilityOrTenderTypeChanged(facilityDetailedInfo, this.state.tenderType);
    }

    render() {
        return (
            <div>
                <Card title="Obiekt" bordered={true}>
                    <Row>
                        <Col span={16}>
                            Wybierz obiekt:
                            <div>
                                <VSelect
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={this.state.facilityId}
                                    style={{width: '100%', marginTop: 4, marginBottom: 4}}
                                    onChange={(value, facility) => this.chooseFacilityHandler(facility)}
                                    rules={{required: true}}
                                    validTriggerContainer={this.state.validTriggerContainer}
                                    options={this.state.facilities}
                                />
                            </div>

                            {this.state.currentFacilityChosen &&
                            <div>
                                <div>
                                    <b>{this.state.currentFacilityChosen.name}</b> {this.state.currentFacilityChosen.address?.address}
                                    <br/>{this.state.currentFacilityChosen.address?.city + " " + this.state.currentFacilityChosen.address?.postalCode}
                                </div>
                                {this.state.currentFacilityChosen?.facilityInfoResponse?.scope &&
                                <div style={{marginTop: "2em"}}>
                                    <b>Zakres prac z informacji serwisowych:</b><br/>

                                    <Text style={{whiteSpace: 'pre-wrap', height: 'auto'}}>
                                        {getFacilityScope(this.state.currentFacilityChosen)}
                                    </Text>
                                </div>
                                }
                            </div>
                            }
                        </Col>
                        {this.state.currentFacilityChosen &&
                        <Col span={8} style={{paddingLeft: 20}}>
                            <MapComponent
                                data={[{
                                    latitude: this.state.currentFacilityChosen.address?.latitude,
                                    longitude: this.state.currentFacilityChosen.address?.longitude
                                }]}
                            />
                        </Col>
                        }
                    </Row>
                </Card>
                <br></br>
                <Card title="Przetarg" bordered={true}>
                    <Row>
                        {this.state.documentNumber &&
                        <Col span={8}>Numer dokumentu</Col>
                        }
                        <Col span={8}>Rodzaj zlecenia</Col>
                        <Col span={8}>Realizator HS</Col>
                    </Row>
                    <Row className="inputRow">
                        {this.state.documentNumber &&
                        <Col span={8} style={{paddingRight: 16}}>
                            {this.state.documentNumber}
                        </Col>
                        }
                        <Col span={8} style={{paddingRight: 16}}>
                            <VSelect
                                value={this.state.tenderType}
                                style={{width: '100%'}}
                                rules={{required: true}}
                                validTriggerContainer={this.state.validTriggerContainer}
                                onChange={(value) => {
                                    this.facilityOrTenderTypeChanged(this.state.currentFacilityChosen, value);
                                    this.setState({tenderType: value})
                                }}
                                options={TenderTypes}
                            />
                        </Col>
                        <Col span={8}>
                            <VSelect
                                showSearch
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={this.state.realizers}
                                value={this.state.realizerId}
                                rules={{required: true}}
                                validTriggerContainer={this.state.validTriggerContainer}
                                style={{width: '100%'}}
                                onChange={(value) => this.setState({realizerId: value})}
                            />
                        </Col>
                    </Row>
                    {this.state.tenderType &&
                    <div>
                        <div style={{height: 1, backgroundColor: "#ddddff", marginTop: 30, marginBottom: 10}}/>
                        <Row>
                            <Col span={8}>Termin realizacji</Col>
                            <Col span={8}>Termin składania ofert</Col>
                            {isTenderTypeOverview(this.state.tenderType) &&
                            <Col span={8}>Termin kolejnego przeglądu</Col>}
                        </Row>
                        <Row className="inputRow">
                            <Col span={8}>
                                <VDatePicker style={{float: "left"}}
                                             format="YYYY-MM-DD"
                                             disabledDate={this.disabledDate}
                                             value={this.state.realizationDate}
                                             rules={{required: true}}
                                             validTriggerContainer={this.state.validTriggerContainer}
                                             onChange={(date, dateString) => this.datePickerHandler(date, dateString, "realizationDate")}>
                                </VDatePicker>
                            </Col>
                            <Col span={8}>
                                <VDatePicker style={{float: "left"}}
                                             format="YYYY-MM-DD"
                                             disabledDate={this.disabledDate}
                                             value={this.state.submissionBidsDate}
                                             rules={{required: true}}
                                             validTriggerContainer={this.state.validTriggerContainer}
                                             placeholder={"data"}
                                             onChange={(date, dateString) => this.datePickerHandler(date, dateString, "submissionBidsDate")}>
                                </VDatePicker>
                                <VTimePicker style={{float: "left", paddingLeft: "1px"}}
                                             format="HH:mm"
                                             value={this.state.submissionBidsTime}
                                             rules={{required: true}}
                                             placeholder={"godzina"}
                                             validTriggerContainer={this.state.validTriggerContainer}
                                             onChange={(date, dateString) => this.datePickerHandler(date, dateString + ":00", "submissionBidsTime")}>
                                </VTimePicker>
                            </Col>
                            {(this.state.tenderType === "ONE_TIME_OVERVIEW" || this.state.tenderType === "CYCLICAL_OVERVIEW") &&
                            <Col span={8}>
                                <VSelect
                                    style={{width: '100%', marginTop: 4, marginBottom: 4}}
                                    value={this.state.nextReviewMonths}
                                    rules={{required: true}}
                                    validTriggerContainer={this.state.validTriggerContainer}
                                    onChange={(value, month) => this.setState({nextReviewMonths: value})}
                                    options={this.reviewMonthsOptions}
                                />
                            </Col>
                            }
                        </Row>
                        <Row>
                            <Col span={8}>Temat</Col>
                            <Col span={8}>Zakres prac</Col>
                            <Col span={8}>Uwagi</Col>
                        </Row>
                        <Row className="inputRow">
                            <Col style={{paddingRight: 16}} span={8}>
                                <VTextArea
                                    rows="4"
                                    style={{resize: "none"}}
                                    placeholder="Temat przetargu"
                                    value={this.state.topic}
                                    rules={{required: true}}
                                    validTriggerContainer={this.state.validTriggerContainer}
                                    onChange={(e) => this.inputHandler('topic', e)}/>
                            </Col>
                            <Col style={{paddingRight: 16}} span={8}>
                                <VTextArea
                                    autoSize={{minRows: 2, maxRows: 5}}
                                    placeholder="Zakres prac przetargu"
                                    value={this.state.scope}
                                    rules={{required: true}}
                                    validTriggerContainer={this.state.validTriggerContainer}
                                    onChange={(e) => this.inputHandler('scope', e)}/>
                            </Col>
                            <Col span={8}>
                                <VTextArea
                                    rows="4"
                                    style={{resize: "none"}}
                                    placeholder="Uwagi"
                                    value={this.state.note}
                                    onChange={(e) => this.inputHandler('note', e)}/>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.tenderType === "RECLAMATION" &&
                            <Col span={16}>Treść zgłoszenia reklamacyjnego</Col>}
                        </Row>
                        <Row className="inputRow">
                            {this.state.tenderType === "RECLAMATION" &&
                            <Col style={{paddingRight: 16}} span={16}>
                                <VTextArea
                                    rows="4"
                                    style={{resize: "none"}}
                                    placeholder="Treść zgłoszenia reklamacyjnego"
                                    value={this.state.complaintNotice}
                                    rules={{required: true}}
                                    validTriggerContainer={this.state.validTriggerContainer}
                                    onChange={(e) => this.inputHandler('complaintNotice', e)}/>
                            </Col>
                            }
                        </Row>
                        <br></br>

                    </div>
                    }

                    {Permissions.canDo("TenderSendToSubcontractors") && this.state.tenderId === undefined &&<div>
                        <Row>
                            <Col span={8}>Wyślij do podwykonawców</Col>
                        </Row>
                        <Row>
                                <Col span={24}>
                                    <Select
                                        mode="multiple"
                                        value={this.state.subcontractorsChosen}
                                        style={{width: '100%'}}
                                        onChange={(value) => this.setState({subcontractorsChosen: value})}
                                        options={this.state.subcontractorsList}>
                                    </Select>
                                </Col>
                        </Row>


                    </div>}
                    <br></br>
                    <Row>
                        <Col span={8}>
                            <Button type="primary" onClick={() => this.addTender()}>
                                {this.state.tenderId ? "Uaktualnij przetarg" : "Wyślij przetarg"}
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

export default withRouter(TenderAdd);
