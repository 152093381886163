import Colors from "./Colors";

export const TenderTypes = [
    {
        value: 'MODERNIZATION',
        label: 'Modernizacja',
        text: 'Modernizacja',
        color: Colors.blue
    },
    {
        value: 'REPAIR',
        label: 'Naprawa',
        text: 'Naprawa',
        color: Colors.yellow
    },
    {
        value: 'ONE_TIME_OVERVIEW',
        label: 'Przegląd jednorazowy',
        text: 'Przegląd jednorazowy',
        color: Colors.fountain_blue
    },
    {
        value: 'CYCLICAL_OVERVIEW',
        label: 'Przegląd cykliczny',
        text: 'Przegląd cykliczny',
        color: Colors.green
    },
    {
        value: 'INSPECTION',
        label: 'Oględziny',
        text: 'Oględziny',
        color: Colors.orange
    },
    {
        value: 'RECLAMATION',
        label: 'Reklamacja',
        text: 'Reklamacja',
        color: Colors.red
    },
];

export let GetTenderTypeFromValue = (value) => {
    for (let item of TenderTypes) {
        if (item.value === value) {
            return item;
        }
    }
    return {};
}

export let GetTenderTypeStyle = (tenderType) => {
    let styles = {
        height: 30,
        width: 140,
        textAlign: "center",
        lineHeight: '30px',
        color: "white",
        fontWeight: "bold",
        borderRadius: '3px'
    }

    styles.backgroundColor = GetTenderTypeColorStyle(tenderType)

    return styles;
}

export let GetTenderTypeColorStyle = (tenderType) => {
    for (let item of TenderTypes) {
        if (item.value === tenderType) {
            return item.color
        }
    }
    return Colors.blue
}


export default TenderTypes
