import React from "react";
import {NotifyConfig} from './NotifyConfig';
import {Alert, Button, notification} from 'antd';
import {authFetch} from "../../../../../utils/ApiHelper";
import {withRouter} from 'react-router-dom';
let dateFormat = require("dateformat");

class NotifyItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifyText: "",
            notifyConfig: null,
            action: () => {
            },
            date: null,
            formattedDate: null,
            message: null,
            description: null,
        };
    }

    asyncDescriptionSetter(value) {
        this?.setState({
            description: value
        })
    }

    componentDidMount() {
        const config = NotifyConfig[this.props.data.notificationType];
        if (config) {
            this.setState({
                notifyConfig: config,
                date: new Date(this.props.data.creationDate),
                formattedDate: dateFormat(this.props.data.creationDate, "dd-mm-yyyy HH:mm"),
                message: config.messageBuilder(this.props.data),
                description: config.descriptionBuilder(this.props.data, (v) => this.asyncDescriptionSetter(v)),
                action: () => config.action(this.props),
            })
        }

    }

    markAsRead() {
        authFetch({
            endpointName: 'DeleteNotification',
            endpointParameters: {id: this.props.data.notificationId},
            config: {method: 'delete'},
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Notyfikacja oznaczona jako przeczytana',
                })
                this.props.dataRemover(this.props.data.notificationId)
            }
        });
    }

    renderRightPanel() {
        return <div style={{textAlign: "right"}}>{this.state.notifyConfig?.isAction &&
            <div>
            <Button
                size="small"
                onClick={this.state.action}
            >
                {this.state.notifyConfig?.actionText ? this.state.notifyConfig.actionText : "Szczegóły"}
            </Button>
            </div>}
            <div style={{marginTop: "10px"}}>{this.state.formattedDate}</div>
            </div>
    }

    render() {
        return (
            <Alert
                style={{marginBottom: 15}}
                message={this.state.message}
                description={this.state.description}
                type={this.state.notifyConfig?.type}
                action={this.renderRightPanel()}
                showIcon
                closable
                onClose={() => this.markAsRead()}
            />
        );
    }
}

export default withRouter(NotifyItem);
