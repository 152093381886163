import React from "react";
import {Descriptions, List} from "antd";
import {GetAccountFromTypeAndSubType} from "../../../config/AccountTypesPermutation";

class UserDetailsDisplay extends React.Component {
    getByName(name) {
        return this.props.data ? this.props.data[name] : null
    }

    voivodeshipsComponentShow() {
        return this.getByName('userType') === 'ROLE_SALES'
    }


    renderVoivodeships() {
        let voivodeships = this.getByName('voivodeships')

        return (
            this.voivodeshipsComponentShow() &&
            <Descriptions.Item span={2} label="Województwa">
                <List
                    dataSource={voivodeships}
                    renderItem={item => (
                        <List.Item>
                            {item}
                        </List.Item>
                    )}
                />
            </Descriptions.Item>
        )
    }

    renderExtra() {
        return (
            this.props.extra
        )

    }

    render() {
        return (
            <div>

                <Descriptions bordered column={2}>
                    <Descriptions.Item
                        label="Imię">{this.getByName('firstName')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Nazwisko">{this.getByName('lastName')}
                    </Descriptions.Item>
                    {!this.props.essentialOnly &&
                    <Descriptions.Item
                        label="Nick">{this.getByName('nick')}
                    </Descriptions.Item>
                    }
                    <Descriptions.Item
                        label="Telefon">{this.getByName('phone')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Email">{this.getByName('email')}
                    </Descriptions.Item>
                    {!this.props.essentialOnly &&
                    <Descriptions.Item
                        label="Typ konta">{
                        GetAccountFromTypeAndSubType(
                            this.getByName('userType'),
                            this.getByName('userSubType')
                        ).label

                    }
                    </Descriptions.Item>
                    }

                    {this.renderVoivodeships()}

                    {this.renderExtra()}

                </Descriptions>

            </div>
        );
    }
}

export default UserDetailsDisplay
