import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {ShareState} from '../../../../utils/ShareState';
import {authFetch, getApiUrl} from "../../../../utils/ApiHelper";
import {MakeRoute, Routes} from "../../../../config/Routes";
import {FacilityStatusToLabel} from "../../../../config/FacilityStatus";
import {Button, Card, Image} from 'antd';
import VFilesEdit from '../../../../component/VFilesEdit'
import ServicesTable from "../service/ServicesTable";
import Permissions from '../../../../config/Permissions';
import FacilityDetailsDisplay from "../../displayInfos/facility/FacilityDetailsDisplay";
import AddressDisplay from "../../displayInfos/AddressDisplay";


const facilityInfoProps = {
    id: null,
    contactName: null,
    contactSurname: null,
    contactEmail: null,
    contactPhone: null,
    note: null,
    scope: null,
    realizer: {
        firstName: null,
        lastName: null,
    },
    salesman: {
        firstName: null,
        lastName: null,
    },
    subcontractor: {
        firstName: null,
        lastName: null,
    }
}

class FacilityDetail extends React.Component {
    colors = {
        'DEFAULT': '#1c7ae6',
    }
    FacilityStatusToLabelDct = FacilityStatusToLabel();

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,

            address: {
                address: null,
                city: null,
                latitude: null,
                longitude: null,
                postalCode: null,
                street: null,
                voivodeship: null
            },
            axProjectNumbers: [],
            city: null,
            generalContractor: null,
            name: null,
            offerDate: null,
            offerType: null,
            projectFinishDate: null,
            realizer: {
                firstName: null,
                lastName: null,
            },
            salesman: {
                firstName: null,
                lastName: null,
            },
            salesmanHs: {
                firstName: null,
                lastName: null,
            },
            status: null,
            subcontractors: [],
            voivodeship: null,
            warranty: null,
            facilityInfoResponse: facilityInfoProps,
            userWhoCreatedFacility: null,
            facilityCreationDate: null,

            addressAsListForMapView: [{

                latitude: 52.2204303,
                longitude: 19.2573012,

            }],
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Informacje o obiekcie')

        authFetch({
            endpointName: "FacilityDetail",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                if (!data.facilityInfoResponse) data.facilityInfoResponse = facilityInfoProps
                data.addressAsListForMapView = [{
                    latitude: data.address.latitude,
                    longitude: data.address.longitude,
                }]
                this.setState(data)
            }
        });

        this.getQrCode()
    }

    getQrCode() {
        fetch(getApiUrl("FacilityQrCode", {id: this.state.id}), {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + window.localStorage.getItem('auth-token'),
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.blob())
            .then(_blob => {
                let blob = new Blob([_blob]);
                let url = window.URL.createObjectURL(blob);
                this.setState({
                    qrUrl: url
                })
            })
    }

    renderServicesTable() {
        return (
            <Card
                title={"Historia Serwisów"}
                style={{
                    marginTop: 20
                }}
            >
                <ServicesTable
                    getEndpoint={{
                        endpointName: 'ServicesListForFacility',
                        parameters: {facilityId: this.state.id}
                    }}
                    canViewFiles={true}
                />
            </Card>
        )
    }


    getUserFirstLastName(user) {
        if (!user) {
            return "-"
        }
        return user.firstName + ' ' + user.lastName
    }

    renderQrCodeCard() {
        return (
            <Card
                title="Kod QR"
                bordered={true}
                style={{marginTop: 20}}
                extra={

                    <Button
                        type="primary"
                        style={{marginRight: 10}}
                        onClick={() => {
                            var link = document.createElement('a');
                            link.href = this.state.qrUrl;
                            link.download = "kodQr.png";
                            link.click();
                        }}
                    >
                        Pobierz
                    </Button>

                }
            >
                <Image
                    width={200}
                    height={200}
                    src={this.state.qrUrl}
                />
            </Card>
        )
    }

    render() {
        return (
            <>
                <Card title="Szczegóły obiektu" bordered={true} extra={
                    <Button type="primary">
                        <Link to={MakeRoute(Routes.FacilityEdit, {'id': this.state.id})}>
                            Edytuj
                        </Link>
                    </Button>
                }>
                    <FacilityDetailsDisplay
                        data={this.state}
                    />

                </Card>

                <Card title="Adress" bordered={true} style={{marginTop: 20}}>
                    <AddressDisplay data={this.state.address}/>
                </Card>
                {this.renderQrCodeCard()}


                {Permissions.canDo('ServiceListAllForFacility') && this.renderServicesTable()}
                <br/>
                {(this.state.status === "SERVICED") &&
                <Card title={"Pliki obiekt"}>
                    {this.state.facilityInfoResponse && this.state.facilityInfoResponse.id &&
                    < VFilesEdit editable={false} viewEnabled={true} viewName={"FacilityInfo"}
                                 viewId={this.state.facilityInfoResponse.id}/>
                    }
                    {!(this.state.facilityInfoResponse && this.state.facilityInfoResponse.id) &&
                    <>Najpierw dodaj informacje serwisowe</>
                    }
                </Card>
                }
            </>
        );
    }

}

export default withRouter(FacilityDetail);
