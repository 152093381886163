import React from "react";
import {Descriptions} from "antd";
import {CommissionStatusColor, CommissionStatusToLabel} from "../../../config/CommissionStatus";
import {CommissionTypeColor, CommissionTypeToLabel} from "../../../config/CommissionType";
import {convertDate} from "./Utils";


class CommissionSimpleDisplay extends React.Component {
    statusToLabel = CommissionStatusToLabel()
    typeToLabel = CommissionTypeToLabel()

    getByName(name) {
        return this.props.data ? this.props.data[name] : null
    }

    renderCommissionStatus() {
        let status = this.getByName('commissionStatus')
        let statusText = this.statusToLabel[status]
        return (
            <div style={CommissionStatusColor(status)}>
                {statusText}
            </div>
        )
    }

    renderCommissionType() {
        let type = this.getByName('commissionType')
        let typeText = this.typeToLabel[type]
        return (
            <div style={CommissionTypeColor(type)}>
                {typeText}
            </div>
        )
    }

    isOverview() {
        let type = this.getByName('commissionType')
        return type === 'CYCLICAL_OVERVIEW'
            || type === 'ONE_TIME_OVERVIEW'
    }

    isReclamation() {
        return this.getByName('commissionType') === 'RECLAMATION'
    }


    renderScope() {
        let label = this.isReclamation() ? 'Treść zgłoszenia reklamacyjnego' : 'Zakres'

        return (
            <Descriptions.Item
                span={3}
                label={label}
                style={{whiteSpace: 'pre-wrap', height: 'auto'}}
            >
                {this.getByName('scope')}
            </Descriptions.Item>
        )
    }

    render() {
        return (
            <div>

                <Descriptions bordered column={2}>
                    <Descriptions.Item
                        label="Status">
                        {this.renderCommissionStatus()}
                    </Descriptions.Item>

                    <Descriptions.Item
                        label="Rodzaj zlecenia">
                        {this.renderCommissionType()}
                    </Descriptions.Item>


                    <Descriptions.Item
                        label="Data realizacji">
                        {convertDate(this.getByName('realizationDate'), false)}
                    </Descriptions.Item>


                    {this.isReclamation() &&
                    <Descriptions.Item
                        label="Kontakt">
                        {this.getByName('contact')}
                    </Descriptions.Item>
                    }
                    <Descriptions.Item span={3}
                                       label="Numer zlecenia">
                        {this.getByName('commissionNumber')}
                    </Descriptions.Item>


                    <Descriptions.Item
                        span={3}
                        label="Uwagi"
                        style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                    >
                        {this.getByName('note')}
                    </Descriptions.Item>
                    {this.renderScope()}


                </Descriptions>


            </div>
        );
    }
}

export default CommissionSimpleDisplay
