import Colors from './Colors'

export const FixesTabFacilityStatus = [
    {
        value: 'ACTIVE',
        label: 'Aktywny',
        color: Colors.orange,
    },
    {
        value: 'FINISHED',
        label: 'Zakończony',
        color: Colors.green,
    },
    {
        value: 'OFFERED',
        label: 'Ofertowany',
        color: Colors.yellow,
    },
];

export const FixesTabFacilityStatusToLabel = () => {
    let dict = {}
    for (let item of FixesTabFacilityStatus) {
        dict[item.value] = item.label;
    }
    return dict;
}

export const GetFixesTabFacilityStatusColor = () => {
    let dict = {}
    for (let item of FixesTabFacilityStatus) {
        dict[item.value] = item.color;
    }
    return dict;
}