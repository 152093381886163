import React from "react";
import {withRouter} from 'react-router-dom';
import {Button, Card, Col, Input, notification, Row, Typography} from 'antd';
import {ShareState} from '../../../../utils/ShareState';
import '../../../../style/dashboard/view/user/UserEdit.antd.scss'
import {authFetch} from "../../../../utils/ApiHelper";

import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';

const {Title} = Typography;

class WorkerEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            nick: null,
            password: null
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Edycja brygadzisty')

        authFetch({
            endpointName: "managerWorkerDetail",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                this.setState({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    nick: data.nick,
                    phone: data.phone,
                })
            }
        })
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    editWorker() {
        authFetch({
            endpointName: "managerWorkerEdit",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'POST',
                body: JSON.stringify({
                    id: this.state.id,
                    email: this.state.email,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    nick: this.state.nick,
                    phone: this.state.phone,
                })
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Dane brygadzisty zostały zmienione',
                })
            }
        })
    }

    changeWorkerPassword() {
        authFetch({
            endpointName: "managerWorkerEdit",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'POST',
                body: JSON.stringify({
                    id: this.state.id,
                    password: this.state.password,
                })
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Hasło brygadzisty zostało zmienione',
                });

                this.setState({
                    password: null
                })
            }
        })
    }

    render() {
        return (
            <Card title="Edycja użytkownika" bordered={true}>
                <Title level={4}>Dane konta</Title>
                <Row>
                    <Col span={8}>Imię</Col>
                    <Col span={8}>Nazwisko</Col>
                    <Col span={8}>Adres e-mail</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <Input
                            placeholder="Imię"
                            value={this.state.firstName}
                            onChange={(e) => this.inputHandler('firstName', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Nazwisko"
                            value={this.state.lastName}
                            onChange={(e) => this.inputHandler('lastName', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Adres e-mail"
                            value={this.state.email}
                            onChange={(e) => this.inputHandler('email', e)}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>Telefon kontaktowy</Col>
                    <Col span={8}>Nick</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <Input
                            placeholder="Telefon kontaktowy"
                            value={this.state.phone}
                            onChange={(e) => this.inputHandler('phone', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Nick"
                            value={this.state.nick}
                            onChange={(e) => this.inputHandler('nick', e)}/>
                    </Col>
                    <Col span={8}>
                        <Button type="primary" onClick={() => this.editWorker()}>Zapisz zmiany</Button>
                    </Col>

                </Row>

                <div className="space"></div>

                <Title level={4}>Zmiana hasła</Title>
                <Row>
                    <Col span={8}>Nowe hasło</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <Input.Password
                            placeholder="Nowe hasło"
                            autoComplete="new-password"
                            onChange={(e) => this.inputHandler('password', e)}
                            value={this.state.password}
                            iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                        />
                    </Col>
                    <Col span={8}>
                        <Button type="primary" onClick={() => this.changeWorkerPassword()}>Zmień hasło</Button>
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default withRouter(WorkerEdit);
