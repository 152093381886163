import Colors from './Colors'

export const UserActiveColors = {
    'ACTIVE': Colors.green,
    'DISABLED': Colors.grey,
}

export const UserActiveStatus = [
    {
        value: 'ACTIVE',
        label: 'Aktywny',
        text: 'Aktywny',
        color: UserActiveColors['ACTIVE'],
    },
    {
        value: 'DISABLED',
        label: 'Nie aktwyny',
        text: 'Nie aktwyny',
        color: UserActiveColors['DISABLED'],
    },
];


export const UserActiveStatusColor = (status) => {
    var styles = {
        height: 30,
        width: 95,
        textAlign: "center",
        lineHeight: '30px',
        color: "white",
        fontWeight: "bold",
        borderRadius: '3px',
        display: "inline-block"
    }
    styles.backgroundColor = UserActiveColors[status];
    return styles;
}

export let GetUserActiveStatusFromDisabled = (disabled) => {
    return disabled ? UserActiveStatus.find(x => x.value === 'DISABLED') : UserActiveStatus.find(x => x.value === 'ACTIVE')
}


export default UserActiveStatus
