import React from "react";
import {withRouter} from 'react-router-dom';
import {Button, Card, Col, Input, notification, Row, Select, Typography} from 'antd';
import {ShareState} from '../../../../utils/ShareState';
import {Voivodeship} from "../../../../config/Voivodeship";
import {AccountTypesPermutation, GetAccountFromTypeAndSubType} from "../../../../config/AccountTypesPermutation";
import '../../../../style/dashboard/view/user/UserEdit.antd.scss'
import {authFetch} from "../../../../utils/ApiHelper";

import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import {VSelect} from "../../../../component/form/VForm";

const {Title} = Typography;

class UserEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            firstName: null,
            lastName: null,
            accountType: null,
            accountSubType: null,
            accountTypesPermutation: null,
            email: null,
            phone: null,
            nick: null,
            password: null,
            voivodeships: null,
            validTriggerContainer: []
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Edycja użytkownika')

        authFetch({
            endpointName: "userDetail",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                var permutation = GetAccountFromTypeAndSubType(data.userType, data.userSubType)
                this.setState({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    nick: data.nick,
                    phone: data.phone,
                    voivodeships: data.voivodeships,
                    userType: data.userType,
                    userSubType: data.userSubType,
                    accountTypesPermutation: permutation.value,
                })
            }
        })
    }

    voivodeshipsComponentShow() {
        if (this.state.accountTypesPermutation != null) {
            if (AccountTypesPermutation[this.state.accountTypesPermutation].type === "ROLE_SALES") {
                return true;
            }
        }
        return false;
    }

    userTypeComponentShow() {
        if (this.state.accountTypesPermutation != null) {
            let userType = AccountTypesPermutation[this.state.accountTypesPermutation].type;
            if (userType === "ROLE_SALES"
                || userType === "ROLE_REALIZER") {
                return true;
            }
        }
        return false;
    }

    getAllowedAccountTypes() {
        if (this.state.accountTypesPermutation != null) {
            let userType = AccountTypesPermutation[this.state.accountTypesPermutation].type;
            if (userType === "ROLE_SALES") {
                return AccountTypesPermutation.filter(x => x.type === userType)
            }

            return AccountTypesPermutation.filter(x => x.type === "ROLE_REALIZER")
        }
        return {};
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    editUser() {
        var json = {
            id: this.state.id,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            nick: this.state.nick,
            phone: this.state.phone,
        }
        if (this.voivodeshipsComponentShow()) {
            json.voivodeships = this.state.voivodeships
        }

        if (this.userTypeComponentShow()) {
            json.userSubType = AccountTypesPermutation[this.state.accountTypesPermutation].subtype
            json.userType = AccountTypesPermutation[this.state.accountTypesPermutation].type
        }

        authFetch({
            endpointName: "userEdit",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'POST',
                body: JSON.stringify(json)
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Dane użytkownika zostały zmienione',
                });
            }
        })
    }

    changeUserPassword() {
        authFetch({
            endpointName: "userEdit",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'POST',
                body: JSON.stringify({
                    id: this.state.id,
                    password: this.state.password,
                })
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Hasło użytkownika zostało zmienione',
                });
            }
        })
    }

    render() {
        return (
            <Card title="Edycja użytkownika" bordered={true}>
                <Title level={4}>Dane konta</Title>
                <Row>
                    <Col span={8}>Imię</Col>
                    <Col span={8}>Nazwisko</Col>
                    <Col span={8}>Adres e-mail</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <Input
                            placeholder="Imię"
                            value={this.state.firstName}
                            onChange={(e) => this.inputHandler('firstName', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Nazwisko"
                            value={this.state.lastName}
                            onChange={(e) => this.inputHandler('lastName', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Adres e-mail"
                            value={this.state.email}
                            onChange={(e) => this.inputHandler('email', e)}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>Telefon kontaktowy</Col>
                    <Col span={8}>Nick</Col>
                    {this.voivodeshipsComponentShow() &&
                    <Col span={8}>Województwa</Col>
                    }
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <Input
                            placeholder="Telefon kontaktowy"
                            value={this.state.phone}
                            onChange={(e) => this.inputHandler('phone', e)}/>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Nick"
                            value={this.state.nick}
                            onChange={(e) => this.inputHandler('nick', e)}/>
                    </Col>
                    {this.voivodeshipsComponentShow() &&
                    <Col span={8}>
                        <Select
                            mode="multiple"
                            value={this.state.voivodeships}
                            style={{width: '70%'}}
                            onChange={(value) => this.setState({voivodeships: value})}
                            options={Voivodeship}>
                        </Select>
                    </Col>
                    }
                </Row>
                <Row>
                    {this.userTypeComponentShow() &&
                    <Col span={8}>Rodzaj konta</Col>
                    }
                </Row>
                <Row className="inputRow">
                    {this.userTypeComponentShow() &&
                    <Col span={8}>
                        <VSelect
                            value={this.state.accountTypesPermutation}
                            style={{width: '70%'}}
                            placeholder="Typ konta"
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(value) => this.setState({accountTypesPermutation: value})}
                            options={this.getAllowedAccountTypes()}/>
                    </Col>
                    }
                </Row>

                <Row className="inputRow">

                    <Col span={8}>
                        <Button type="primary" onClick={() => this.editUser()}>Zapisz zmiany</Button>
                    </Col>
                </Row>

                <div className="space"></div>

                <Title level={4}>Zmiana hasła</Title>
                <Row>
                    <Col span={8}>Nowe hasło</Col>
                </Row>
                <Row className="inputRow">
                    <Col span={8}>
                        <Input.Password
                            placeholder="Nowe hasło"
                            onChange={(e) => this.inputHandler('password', e)}
                            iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                        />
                    </Col>
                    <Col span={8}>
                        <Button type="primary" onClick={() => this.changeUserPassword()}>Zmień hasło</Button>
                    </Col>
                </Row>
            </Card>
        );
    }
}

export default withRouter(UserEdit);
