import React from "react";
import {Descriptions} from "antd";
import {CommissionTypeColor, CommissionTypeToLabel} from "../../../config/CommissionType";
import {convertDate} from "./Utils";


class CommissionForWorkerDisplay extends React.Component {
    typeToLabel = CommissionTypeToLabel()

    getByName(name) {
        return this.props.data ? this.props.data[name] : null
    }

    renderCommissionType() {
        let type = this.getByName('commissionType')
        let typeText = this.typeToLabel[type]
        return (
            <div style={CommissionTypeColor(type)}>
                {typeText}
            </div>
        )
    }

    isOverview() {
        let commissionType = this.getByName('commissionType')
        return commissionType === 'CYCLICAL_OVERVIEW'
            || commissionType === 'ONE_TIME_OVERVIEW'
    }

    isReclamation() {
        return this.getByName('commissionType') === 'RECLAMATION'
    }


    renderScope() {
        let label = this.isReclamation() ? 'Treść zgłoszenia reklamacyjnego' : 'Zakres'

        return (
            <Descriptions.Item
                span={3}
                label={label}
                style={{whiteSpace: 'pre-wrap', height: 'auto'}}
            >
                {this.getByName('scope')}
            </Descriptions.Item>
        )
    }

    render() {
        return (
            <div>

                <Descriptions bordered column={2}>


                    <Descriptions.Item
                        label="Rodzaj zlecenia">
                        {this.renderCommissionType()}
                    </Descriptions.Item>

                    <Descriptions.Item span={2}
                                       label="Data realizacji">
                        {convertDate(this.getByName('workerRealizationDate'), false)}
                    </Descriptions.Item>


                    {this.isReclamation() &&
                    <Descriptions.Item span={2}
                                       label="Kontakt"
                                       style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                    >
                        {this.getByName('contact')}
                    </Descriptions.Item>
                    }


                    <Descriptions.Item
                        span={3}
                        label="Uwagi"
                        style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                    >
                        {this.getByName('note')}
                    </Descriptions.Item>
                    {this.renderScope()}


                </Descriptions>


            </div>
        );
    }
}

export default CommissionForWorkerDisplay
