import React from "react";
import {Card} from 'antd';
import {withRouter} from "react-router-dom";
import {ShareState} from "../../../../utils/ShareState";
import Permissions from '../../../../config/Permissions';
import ServicesTable from "./ServicesTable";

class ServicesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        ShareState.setValue('breadcrumb', 'Lista serwisów')
    }

    allEndpointName() {
        let endpointName = "ServicesListVisibleToMe"
        if (Permissions.canDo("ServiceListAll")) {
            endpointName = "ServicesList"
        }
        return {
            'endpointName': endpointName,
            'parameters': null
        }
    }


    render() {
        return (
            <Card title="Serwisy" bordered={true}>

                <ServicesTable
                    getEndpoint={this.allEndpointName()}
                />

            </Card>
        );
    }
}

export default withRouter(ServicesList);
