import React from "react";
import {Descriptions, Divider} from "antd";
import {FacilityStatusColor} from "../../../../config/FacilityStatusColor";
import {FacilityStatusToLabel} from "../../../../config/FacilityStatus";
import FacilityInfoDetailsDisplay from "./FacilityInfoDetailsDisplay";
import {renderUser} from "../Utils";

class FacilitySimpleDisplay extends React.Component {

    FacilityStatusDict = this.props.statusesDict
    facilityStatusLabels = FacilityStatusToLabel()

    getByName(name) {
        return this.props.data ? this.props.data[name] : null
    }

    statusColor = (status) => {
        var styles = {
            height: 30,
            width: 130,
            textAlign: "center",
            lineHeight: '30px',
            color: "white",
            fontWeight: "bold",
            borderRadius: '3px'
        }
        styles.backgroundColor = this.props.statusesColor[status];
        return styles;
    }

    renderStatus(status) {
        let label = this.facilityStatusLabels[status]
        let color = FacilityStatusColor(status)
        return (
            status && <div style={color}>
                {label}
            </div>
        )
    }


    renderFacilityDetails() {
        let facilityInfoData = this.getByName('facilityInfoResponse')

        return (
            <div>
                <Descriptions title="Informacje o obiekcie" bordered column={2}>
                    <Descriptions.Item
                        label="Status">{this.renderStatus(this.getByName('facilityStatus'))}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nazwa">{this.getByName('name')}</Descriptions.Item>



                </Descriptions>

                {this.getByName('facilityStatus') === 'SERVICED' && facilityInfoData && <>
                    <Divider dashed/>
                    <FacilityInfoDetailsDisplay data={facilityInfoData}
                                                noLinks={this.props.noLinks}/>
                </>
                }
            </div>
        )
    };

    render() {
        return (

            <div> {
                this.renderFacilityDetails()
            }

            </div>
        )
            ;
    }

}

export default FacilitySimpleDisplay;
