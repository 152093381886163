import {Button, Card, Col, Descriptions, notification, Row, Tooltip} from "antd";
import {VInput, VSelect, VTextArea} from "../../../../../component/form/VForm";
import VInputNumber from "../../../../../component/form/VInputNumber";
import React, {useEffect, useState} from "react";
import VFilesEdit from "../../../../../component/VFilesEdit";
import {authFetch} from "../../../../../utils/ApiHelper";
import {FacilityStatusColor} from "../../../../../config/FacilityStatusColor";
import {FacilityStatusToLabel} from "../../../../../config/FacilityStatus";

export function FacilityInfoForm({
                                     initialState,
                                     realizerList = [],
                                     salesmanList = [],
                                     subcontractorList = [],
                                     facilityId = null,
                                     facilityStatus = null,
                                     isEdit = true,
                                     checkCanAddFunction = null,
                                     onSave = null,
                                     showFiles=true
                                 }) {

    const [id, setId] = useState(initialState?.id)
    const [contactName, setContactName] = useState(initialState?.contactName)
    const [contactSurname, setContactSurname] = useState(initialState?.contactSurname)
    const [contactEmail, setContactEmail] = useState(initialState?.contactEmail)
    const [contactPhone, setContactPhone] = useState(initialState?.contactPhone)
    const [realizerId, setRealizerId] = useState(initialState?.realizerId)
    const [salesmanId, setSalesmanId] = useState(initialState?.salesmanId)
    const [scope, setScope] = useState(initialState?.scope)
    const [note, setNote] = useState(initialState?.note)
    const [subcontractorId, setSubcontractorId] = useState(initialState?.subcontractorId)
    const [devicesNumber, setDevicesNumber] = useState(initialState?.devicesNumber)
    const [showFacilityInfoSaveTooltip, setShowFacilityInfoSaveTooltip] = useState(false)

    const [validTriggerContainer, setValidTriggerContainer] = useState([])

    const facilityStatusLabels = FacilityStatusToLabel()


    const resetState = () => {
        setId(null)
        setContactName(null)
        setContactSurname(null)
        setContactEmail(null)
        setContactPhone(null)
        setRealizerId(null)
        setSalesmanId(null)
        setScope(null)
        setNote(null)
        setSubcontractorId(null)
        setDevicesNumber(null)
    }

    useEffect(() => {
        setId(initialState?.id)
        setContactName(initialState?.contactName)
        setContactSurname(initialState?.contactSurname)
        setContactEmail(initialState?.contactEmail)
        setContactPhone(initialState?.contactPhone)
        setRealizerId(initialState?.realizerId)
        setSalesmanId(initialState?.salesmanId)
        setScope(initialState?.scope)
        setNote(initialState?.note)
        setSubcontractorId(initialState?.subcontractorId)
        setDevicesNumber(initialState?.devicesNumber)
    }, [initialState])

    const deleteFacilityInfo = () => {
        authFetch({
            endpointName: "FacilityInfoDelete",
            endpointParameters: {id: id},
            config: {method: 'delete'},
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Usunięto informacje serwisowe dotyczące obiektu',
                })
                resetState()
                if (onSave) {
                    onSave(null)
                }
            }
        })
    }

    const isStateValid = () => {
        let isValid = true
        for (let item of validTriggerContainer) {
            if (!item()) isValid = false
        }
        return isValid
    }

    const checkIfFacilityIsAdded = () => {
        if (facilityId == null) {
            notification['error']({
                message: 'Błąd',
                description: 'Najpierw utwórz obiekt, aby móc dodać informacje serwisowe',
            });
            return false;
        }
        return !(checkCanAddFunction && !checkCanAddFunction());

    }

    const editFacilityInfo = () => {
        if (!checkIfFacilityIsAdded()) return;
        authAddEditFacilityInfo("FacilityInfoEdit", 'Zaktualizowano informacje serwisowe');
    }

    const addFacilityInfo = () => {
        if (!checkIfFacilityIsAdded()) return;
        authAddEditFacilityInfo("FacilityInfoAdd", 'Zapisano informacje serwisowe');
    }

    const authAddEditFacilityInfo = (endpointName, successMessage) => {
        if (!isStateValid()) return;
        authFetch({
            endpointName: endpointName,
            config: {
                method: 'post',
                body: JSON.stringify({
                    facilityId: facilityId,
                    contactEmail,
                    contactName,
                    contactPhone,
                    contactSurname,
                    facilityInfoId: id,
                    note,
                    realizerId,
                    salesmanId,
                    scope,
                    subcontractorId,
                    devicesNumber,
                    forceChangeFacilityStatus: true
                })
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: successMessage,
                })
                setId(data.id)
                setShowFacilityInfoSaveTooltip(false)
                if (onSave) {
                    onSave(data.id)
                }
            }
        })
    }

    const renderFacilityStatusInfo = () => {

        let label = facilityStatusLabels[facilityStatus]
        let color = FacilityStatusColor(facilityStatus)


        return (
            <div style={{marginBottom: 20}}>
                <Descriptions bordered column={2}>
                    <Descriptions.Item
                        label="Status obiektu">{
                        <div style={color}>
                            {label}
                        </div>
                    }
                    </Descriptions.Item>
                </Descriptions>
            </div>

        )
    }

    const getSaveButtonLabel = () => {
        if (facilityStatus && facilityStatus !== 'SERVICED') {
            return "Zapisz i zmień status na serwisowany"
        }
        return "Zapisz"
    }

    return <div>
        <Card title="Informacje serwisowe obiektu" bordered={true} extra={
            isEdit && id ?
                <Button type="danger" onClick={() => deleteFacilityInfo()}>Usuń</Button>
                : ''
        }>

            {facilityStatus && renderFacilityStatusInfo()}

            <Row>
                <Col span={8}>Imię</Col>
                <Col span={8}>Nazwisko</Col>
                <Col span={8}>Email</Col>
            </Row>
            <Row className="inputRow">
                <Col span={8}>
                    <VInput
                        placeholder="Imię"
                        value={contactName}
                        rules={{required: true}}
                        validTriggerContainer={validTriggerContainer}
                        onChange={(e) => setContactName(e.target.value)}/>
                </Col>
                <Col span={8}>
                    <VInput
                        placeholder="Nazwisko"
                        value={contactSurname}
                        rules={{required: true}}
                        validTriggerContainer={validTriggerContainer}
                        onChange={(e) => setContactSurname(e.target.value)}/>
                </Col>
                <Col span={8}>
                    <VInput
                        placeholder="Email"
                        value={contactEmail}
                        rules={{required: true}}
                        validTriggerContainer={validTriggerContainer}
                        onChange={(e) => setContactEmail(e.target.value)}/>
                </Col>
            </Row>
            <Row>
                <Col span={8}>Telefon</Col>
                <Col span={8}>Notatka</Col>
            </Row>
            <Row className="inputRow">
                <Col span={8}>
                    <VInput
                        placeholder="Telefon"
                        value={contactPhone}
                        rules={{required: true}}
                        validTriggerContainer={validTriggerContainer}
                        onChange={(e) => setContactPhone(e.target.value)}/>
                </Col>
                <Col span={8}>
                    <VTextArea
                        value={note}
                        onChange={({target: {value}}) => setNote(value)}
                        placeholder="Notatka"
                        style={{width: '70%'}}
                        autoSize={{minRows: 3, maxRows: 5}}/>
                </Col>

            </Row>
            <Row>
                <Col span={16}>Zakres</Col>
                <Col span={8}>Liczba urządzeń</Col>
            </Row>
            <Row className="inputRow">
                <Col span={16}>
                    <VTextArea
                        value={scope}
                        onChange={({target: {value}}) => setScope(value)}
                        placeholder="Zakres"
                        style={{width: '85%'}}
                        autoSize={{minRows: 3, maxRows: 5}}/>
                </Col>
                <Col span={8}>
                    <VInputNumber
                        placeholder="Liczba urządzeń"
                        value={devicesNumber}
                        rules={{required: true}}
                        min={0}
                        validTriggerContainer={validTriggerContainer}
                        onChange={(value) => setDevicesNumber(value)}/>
                </Col>

            </Row>

            <Row>
                <Col span={8}>Realizator HS</Col>
                <Col span={8}>Handlowiec HS</Col>
                <Col span={8}>Serwisant lub ekipa serwisowa</Col>
            </Row>
            <Row className="inputRow">
                <Col span={8}>
                    <VSelect
                        value={realizerId}
                        style={{width: '70%'}}
                        onChange={(value) => setRealizerId(value)}
                        options={realizerList}/>
                </Col>
                <Col span={8}>
                    <VSelect
                        value={salesmanId}
                        style={{width: '70%'}}
                        rules={{required: true}}
                        validTriggerContainer={validTriggerContainer}
                        onChange={(value) => setSalesmanId(value)}
                        options={salesmanList}/>
                </Col>
                <Col span={8}>
                    <VSelect
                        value={subcontractorId}
                        style={{width: '70%'}}
                        onChange={(value) => setSubcontractorId(value)}
                        options={subcontractorList}/>
                </Col>
            </Row>

            <Row className="inputRow">

                <Col span={8}>
                    <Tooltip title="Najpierw zapisz zmiany"
                             visible={showFacilityInfoSaveTooltip}
                             placement="bottom">
                        {
                            isEdit && id != null ?
                                <Button type="primary" onClick={() => editFacilityInfo()}>Zapisz
                                    zmiany</Button>
                                :
                                <Button type="primary"
                                        onClick={() => addFacilityInfo()}>{getSaveButtonLabel()}</Button>
                        }
                    </Tooltip>


                </Col>
            </Row>
        </Card>

        <br/>
        {
            isEdit
            && showFiles
            &&
            <Card title={"Pliki obiekt"}>

                {id ?
                    < VFilesEdit editable={true} viewEnabled={true} viewName={"FacilityInfo"}
                                 viewId={id}/>
                    :
                    <>Najpierw dodaj informacje serwisowe</>
                }
            </Card>
        }

    </div>
}