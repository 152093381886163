export const SourceOfAerationTypes = [
    {
        value: 'BRAMY',
        label: 'Bramy',
    },
    {
        value: 'DRZWI',
        label: 'Drzwi',
    },
    {
        value: 'OKNO',
        label: 'Okno',
    },
    {
        value: 'KLAPY',
        label: 'Klapy',
    },
    {
        value: 'INNE',
        label: 'Inne',
    },
    {
        value: 'NONE',
        label: 'Żadne',
    },

];

export default SourceOfAerationTypes