import {ShareState} from '../utils/ShareState';
import {notification} from 'antd';
import {authFetch} from "../utils/ApiHelper";

let notificationServiceTimer;
const fetchedNotification = []

const notificationServiceAction = () => {
    authFetch({
        endpointName: 'GetNotifications',
        successFunction: data => {
            for (const notify of data?.notifications) {
                let hasNewNotification = false;
                if (!fetchedNotification.includes(notify.id)) {
                    fetchedNotification.push(notify.id);
                    hasNewNotification = true;
                }
                if (hasNewNotification) {
                    notification['success']({
                        message: 'Uwaga',
                        description: 'Masz nieprzeczytane powiadomienia.',
                    });
                }
            }

            if (data?.notifications) {
                ShareState.setValue('notificationCount', data.notifications.length)
            }
        }
    });
}

export const RunNotificationService = (state) => {
    if (state) {
        notificationServiceTimer = setInterval(notificationServiceAction, 1000 * 60 * 5);
        notificationServiceAction();
    } else clearInterval(notificationServiceTimer);
}

ShareState.subscribe('NotificationServiceState', RunNotificationService)
