import React from "react";
import {Descriptions} from "antd";
import {renderUser} from "../Utils";
import {Link} from "react-router-dom";
import {MakeRoute, Routes} from "../../../../config/Routes";


class FacilityInfoDetailsDisplay extends React.Component {
    getByName(name) {
        return this.props.data[name]
    }

    renderSubcontractor(subcontractor) {
        if (!subcontractor) {
            return "-";
        }

        if (this.props.noLinks) {
            return (
                <div>
                    {subcontractor.name}
                </div>
            )
        } else {
            return (
                <Link to={MakeRoute(Routes.SubcontractorDetail, {id: subcontractor.id})}>
                    {subcontractor.name}
                </Link>
            )
        }
    }

    render() {
        return (
            <Descriptions title="Informacje serwisowe" bordered column={2}>
                <Descriptions.Item
                    label="Imię">{this.getByName('contactName')}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Nazwisko">{this.getByName('contactSurname')}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Mail">{this.getByName('contactEmail')}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Telefon">{this.getByName('contactPhone')}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Realizator">{renderUser(this.getByName('realizer'), !this.props.noLinks)}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Handlowiec">{renderUser(this.getByName('salesman'), !this.props.noLinks)}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Podwykonawca" span={2}>
                    {this.renderSubcontractor(this.getByName('subcontractor'))}
                </Descriptions.Item>
                <Descriptions.Item
                    span={3}
                    label="Zakres"
                    style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                >
                    {this.getByName('scope')}
                </Descriptions.Item>
                <Descriptions.Item
                    span={3}
                    label="Notatka"
                    style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                >
                    {this.getByName('note')}
                </Descriptions.Item>

            </Descriptions>
        );
    }
}

export default FacilityInfoDetailsDisplay
