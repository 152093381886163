export const Routes = {
    Login: "/",
    PrivacyPolicy: "/polityka_prywatnosci",

    MainView: "/mainView/:subPath",

    FacilityDetail: "/objekty/szczegoly/:id",
    FacilityList: "/objekty/lista",
    FacilityEdit: "/objekty/edytuj/:id",
    FacilityAdd: "/objekty/dodaj",

    ProfileView: "/profil",

    UserDetail: "/uzytkownicy/szczegoly/:id",
    UserList: "/uzytkownicy/lista",
    UserEdit: "/uzytkownicy/edytuj/:id",
    UserAdd: "/uzytkownicy/dodaj",

    SubcontractorDetail: "/podwykonawcy/szczegoly/:id",
    SubcontractorList: "/podwykonawcy/lista",
    SubcontractorEdit: "/podwykonawcy/edytuj/:id",
    SubcontractorAdd: "/podwykonawcy/dodaj",

    TenderDetail: "/przetargi/szczegoly/:id",
    TendersList: "/przetargi/lista",
    TenderEdit: "/przetargi/edytuj/:id",
    TenderAdd: "/przetargi/dodaj",

    //tylko dla managera
    ManagerTendersList: "/przetargi/lista",
    ManagerTenderDetail: "/przetargi/szczegoly/:id",

    WorkerDetail: "/brygadzisci/szczegoly/:id",
    WorkerList: "/brygadzisci/lista",
    WorkerEdit: "/brygadzisci/edytuj/:id",
    WorkerAdd: "/brygadzisci/dodaj",

    CommissionDetail: "/zlecenia/szczegoly/:id",
    CommissionList: "/zlecenia/lista",
    CommissionEdit: "/zlecenia/edytuj/:id",
    CommissionAdd: "/zlecenia/dodaj",
    CommissionAddFromOffer: "/zlecenia/dodaj_z_oferty/:fromOfferId",
    CommissionAddFromPreviousCommission: "/zlecenia/dodaj_z_poprzedniego_zlecenia/:fromCommissionId",

    AlertView: "/alerty",

    ServicesList: "/serwisy/lista",
    ServiceDetail: "/serwisy/szczegoly/:id",
    ServiceDetailForCommissionHistory: "/serwisy/szczegoly/:id/ze_zlecenia/:commissionId",
    ServiceEdit: "/serwisy/edytuj/:id",
}

export const MakeRoute = (pattern, args) => {
    let url = pattern
    for (let key in args) {
        url = url.replace(':' + key, args[key])
    }
    return url
}

export default Routes
