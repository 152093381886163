import React from "react";
import {withRouter} from 'react-router-dom';
import {ShareState} from '../../../../../utils/ShareState';
import {authFetch, getApiUrl, getAuthToken} from "../../../../../utils/ApiHelper";
import VFilesEdit from '../../../../../component/VFilesEdit'
import {Button, Card, Col, notification, Row, Table} from 'antd';
import {DeleteOutlined, DownloadOutlined,} from '@ant-design/icons';
import CommissionType from '../../../../../config/CommissionType'
import {VDatePicker, VInput, VSelect, VTextArea} from '../../../../../component/form/VForm'
import {getRealizersListForSelect, getSalesmenListForSelect} from "../../../../../apiOperations/UsersApiOperations";
import {getSubcontractorsListForSelect} from "../../../../../apiOperations/SubcontractorApiOperations";
import {getDetailedFacility, getFacilitiesListForSelect} from "../../../../../apiOperations/FacilityApiOperations";
import {FacilityInfoForm} from "../../facility/component/FacilityInfoForm";
import {isCommissionTypeOverview} from "../CommissionUtils";
import {getFacilityScope} from "../../facility/FacilityUtils";
import Routes from "../../../../../config/Routes";
import {addFile} from "./addFile";

const plainState = {
    fromOfferId: null,

    facilityId: null,
    facilityInfo: null,
    facilityStatus: null,
    commissionSendDate: null,
    commissionType: null,
    hsProjectNumber: null,
    nextReviewMonths: null,
    note: null,
    realizationDate: null,
    realizerId: null,
    reclamationNote: null,
    salary: null,
    scope: null,
    subcontractorId: null,

    validTriggerContainer: [],
    facilityList: [],
    subcontractorList: [],
    realizerList: [],
    salesmanList: [],

    isEdit: false,
    fileList: [],
    fileListUploaded: [],
    blockForm: false,
    commissionId: null,

    localFilesList: [],

    isLoading: false
}

class CommissionForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = plainState;
        this.state['fromOfferId'] = this.props.match.params.fromOfferId
        this.state['fromPreviousCommission'] = this.props.match.params.fromCommissionId
        this.state['isEdit'] = this.props.loadInfo
        this.state['commissionId'] = this.props.commissionId

        let user = ShareState.container.user
        if (user.userType === 'ROLE_REALIZER') {
            this.state['realizerId'] = user.id
        }
    }

    async getAllLists() {
        const realizerList = await getRealizersListForSelect()
        const [salesmanList, salesmanHsList] = await getSalesmenListForSelect()
        const subcontractorList = await getSubcontractorsListForSelect()
        const facilityList = await getFacilitiesListForSelect()
        this.setState({
            realizerList: realizerList,
            salesmanList: salesmanList,
            subcontractorList: subcontractorList,
            facilityList: facilityList
        })
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Dodaj zlecenie')
        this.getAllLists()
        if (this.state.isEdit) {
            this.loadCommission()
        }
        if (this.state.fromOfferId) {
            this.loadOffer();
        }
        if (this.state.fromPreviousCommission) {
            this.loadFromPreviousCommission()
        }
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    validTrigger() {
        for (let item of this.state.validTriggerContainer) item()
    }

    valid(required) {
        this.validTrigger()
        return this.checkRequired(required)
    }

    checkRequired(requiredList) {
        for (let item of requiredList) {
            if (!(item in this.state)) return false;
            if (!this.state[item]) return false;
        }
        return true;
    }

    parseFacilityInfoResponse(facilityInfo) {
        if (facilityInfo == null) return null
        return {
            ...facilityInfo,
            salesmanId: facilityInfo.salesman?.id,
            subcontractorId: facilityInfo.subcontractor?.id,
            realizerId: facilityInfo.realizer?.id,
        }
    }

    loadCommission(id = this.state.commissionId) {
        authFetch({
            endpointName: "CommissionDetail",
            endpointParameters: {id: id},
            successFunction: data => {
                let stateData = {
                    id: data.id,
                    facilityId: data.facility.id,
                    facilityStatus: data.facility.facilityStatus,
                    facilityInfo: this.parseFacilityInfoResponse(data.facility.facilityInfoResponse),
                    commissionSendDate: data.commissionSendDate,
                    commissionType: data.commissionType,
                    commissionStatus: data.commissionStatus,
                    hsProjectNumber: data.hsProjectNumber,
                    nextReviewMonths: data.nextReviewMonths,
                    note: data.note,
                    realizationDate: null,
                    realizerId: data.realizer.id,
                    salary: data.salary,
                    scope: data.scope,
                    subcontractorId: data.subcontractor.id,
                }
                this.getFileList(data.id, stateData)
            }
        });
    }

    loadOffer() {
        authFetch({
            endpointName: "CommissionGetOffer",
            endpointParameters: {id: this.state.fromOfferId},
            successFunction: async data => {
                this.setState({
                    facilityId: data.facility.id,
                    facilityStatus: data.facility.facilityStatus,
                    facilityInfo: this.parseFacilityInfoResponse(data.facility.facilityInfoResponse),
                    commissionSendDate: data.commissionSendDate,
                    commissionType: data.commissionType,
                    hsProjectNumber: data.hsProjectNumber,
                    nextReviewMonths: data.nextReviewMonths,
                    note: data.note,
                    realizationDate: data.realizationDate,
                    realizerId: data.realizer.id,
                    reclamationNote: data.reclamationNote,
                    salary: data.finalSalary,
                    scope: data.scope,
                    subcontractorId: data.subcontractor.id
                })
                const detailedFacility = await getDetailedFacility(data.facility.id);
                this.setState({
                    detailedFacility: detailedFacility
                });
            }
        });
    }

    loadFromPreviousCommission() {
        this.loadCommission(this.state.fromPreviousCommission)
    }

    getFileList(id, stateData) {
        authFetch({
            endpointName: "CommissionFileList",
            endpointParameters: {id: id},
            successFunction: data => {
                stateData['fileListUploaded'] = data.files
                this.setState(stateData)
            }
        });
    }

    resetForm() {
        this.setState(plainState);
        this.getAllLists()
    }


    getDataObject() {
        let data = {
            facilityId: this.state.facilityId,
            commissionType: this.state.commissionType,
            scope: this.state.scope,
            realizationDate: this.state.realizationDate,
            salary: this.state.salary,
            note: this.state.note,
            subcontractorId: this.state.subcontractorId,
            realizerId: this.state.realizerId,
            hsProjectNumber: this.state.hsProjectNumber,
        }
        if (this.state.fromOfferId) {
            data['offerId'] = this.state.fromOfferId

        }
        if (this.state.commissionType === 'RECLAMATION') {
            data['contact'] = this.state.contact

        } else if (this.state.commissionType === 'ONE_TIME_OVERVIEW' || this.state.commissionType === 'CYCLICAL_OVERVIEW') {
            data['nextReviewMonths'] = this.state.nextReviewMonths
        }
        return data;
    }

    getRequiredFields() {
        let required = [
            'commissionType',
        ]

        if (this.state.commissionType === 'ONE_TIME_OVERVIEW' || this.state.commissionType === 'CYCLICAL_OVERVIEW') {
            required.push('nextReviewMonths')
        }

        if (this.state.commissionType !== 'RECLAMATION') {
            required.push('salary')
            required.push('realizationDate')
        }

        if (!this.state.fromOfferId) {
            required.push('scope')
        }
        return required;
    }


    addCommission() {
        let data = this.getDataObject()
        if (!this.valid(this.getRequiredFields())) {
            notification['error']({
                message: 'Błąd',
                description: 'Nie uzupełniono wszystkich wymaganych pól formularza',
            });
            return
        }

        this.setState({isLoading: true})

        let endpoint = this.state.fromOfferId ? "CommissionAddFromOffer" : "CommissionAdd"
        let endpointParameters = null

        if (this.state.id != null) {
            endpoint = "CommissionEdit"
            endpointParameters = {id: this.state.id}
        }

        authFetch({
            endpointName: endpoint,
            endpointParameters,
            config: {
                method: 'post',
                body: JSON.stringify(data)
            },
            successFunction: async data => {
                const files = [...this.state.localFilesList]
                this.setState({
                    commissionId: data.id,
                })

                notification['success']({
                    message: 'OK',
                    description: "Wysłano zlecenie",
                });

                try {
                    const filesUploads = files.map(file => addFile(data.id, file.originFileObj))
                    await Promise.all(filesUploads)
                    this.setState({isLoading: false})

                    this.props.history.push(Routes.CommissionList)
                    this.setState({
                        formSended: true,
                        blockForm: true
                    })
                } catch (e) {
                    notification['error']({
                        message: 'Niepowodzenie',
                        description: "Nie udało się wysłać plików. Spróbuj jeszcze raz",
                    });
                    this.setState({isLoading: false, localFilesList: [], blockForm: true})
                }
            },
            onFail: () => {
                this.setState({isLoading: false})
            }
        });
    }

    editCommission() {
        let data = this.getDataObject()
        if (!this.valid(this.getRequiredFields())) {
            notification['error']({
                message: 'Błąd',
                description: 'Nie uzupełniono wszystkich wymaganych pól formularza',
            });
            return
        }

        authFetch({
            endpointName: "CommissionEdit",
            endpointParameters: {id: this.state.id},
            config: {
                method: 'post',
                body: JSON.stringify(data)
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: "Zaktualizowane informacje o zleceniu",
                });
            }
        });
    }


    isOverview() {
        return this.state.commissionType === 'CYCLICAL_OVERVIEW'
            || this.state.commissionType === 'ONE_TIME_OVERVIEW'
    }

    renderStandardForm() {
        return (<>
            <Row className="inputRow">
                <Col span={8}>Numer projektu HS</Col>
            </Row>
            <Row>
                <Col span={8}>
                    <VInput
                        placeholder="Numer projektu HS"
                        value={this.state.hsProjectNumber}
                        onChange={(e) => this.inputHandler('hsProjectNumber', e)}/>
                </Col>
            </Row>

            <Row className="inputRow">
                <Col span={8}>Oczekiwana data realizacji</Col>
                <Col span={8}>Wynagrodzenie</Col>
            </Row>
            <Row>
                <Col span={8}>
                    <VDatePicker
                        placeholder="Oczekiwana data realizacji"
                        format="YYYY-MM-DD"
                        value={this.state.realizationDate}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={(date, dateString) => this.setState({realizationDate: dateString})}/>
                </Col>
                <Col span={8}>
                    <VInput
                        placeholder="Wynagrodzenie"
                        value={this.state.salary}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={(e) => this.inputHandler('salary', e)}/>
                </Col>
            </Row>

            <Row className="inputRow">
                <Col span={8}>Podwykonawca</Col>
                <Col span={8}>Realizator</Col>
                {this.isOverview() && <Col span={8}>Termin kolejnego przeglądu</Col>}
            </Row>
            <Row>
                <Col span={8}>
                    <VSelect
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state.subcontractorId}
                        disabled={this.state.fromOfferId}
                        style={{width: '70%'}}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={(value) => this.setState({subcontractorId: value})}
                        options={this.state.subcontractorList}/>
                </Col>
                <Col span={8}>
                    <VSelect
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state.realizerId}
                        disabled={this.state.fromOfferId}
                        style={{width: '70%'}}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={(value) => this.setState({realizerId: value})}
                        options={this.state.realizerList}/>
                </Col>
                <Col span={8}>
                    {
                        this.isOverview() &&
                        <VSelect
                            value={this.state.nextReviewMonths}
                            style={{width: '70%'}}
                            rules={{required: true}}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(value) => this.setState({nextReviewMonths: value})}
                            options={[
                                {value: 6, label: "6 miesięcy"},
                                {value: 12, label: "12 miesięcy"}
                            ]}/>
                    }
                </Col>
            </Row>

            <Row className="inputRow">
                <Col span={8}>Zakres</Col>
                <Col span={8}>Uwagi</Col>
            </Row>
            <Row>
                <Col span={8}>
                    <VTextArea
                        value={this.state.scope}
                        style={{width: '100%'}}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={({target: {value}}) => this.setState({scope: value})}
                        placeholder="Zakres"
                        autoSize={{minRows: 3, maxRows: 5}}/>
                </Col>
                <Col span={8}>
                    <VTextArea
                        value={this.state.note}
                        style={{width: '100%'}}
                        onChange={({target: {value}}) => this.setState({note: value})}
                        placeholder="Uwagi"
                        autoSize={{minRows: 3, maxRows: 5}}/>
                </Col>
            </Row>
        </>)
    }

    renderReclamationForm() {
        return (<>
            <Row className="inputRow">
                <Col span={8}>Numer reklamacji</Col>
            </Row>
            <Row>
                <Col span={8}>
                    <VInput
                        placeholder="Numer reklamacji"
                        value={this.state.hsProjectNumber}
                        onChange={(e) => this.inputHandler('hsProjectNumber', e)}/>
                </Col>
            </Row>

            <Row className="inputRow">
                <Col span={8}>Podwykonawca</Col>
                <Col span={8}>Realizator</Col>
            </Row>
            <Row>
                <Col span={8}>
                    <VSelect
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state.subcontractorId}
                        disabled={this.state.fromOfferId}
                        style={{width: '70%'}}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={(value) => this.setState({subcontractorId: value})}
                        options={this.state.subcontractorList}/>
                </Col>
                <Col span={8}>
                    <VSelect
                        showSearch
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={this.state.realizerId}
                        disabled={this.state.fromOfferId}
                        style={{width: '70%'}}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={(value) => this.setState({realizerId: value})}
                        options={this.state.realizerList}/>
                </Col>
            </Row>

            <Row className="inputRow">
                <Col span={8}>Treść zgłoszenia reklamacyjnego</Col>
                <Col span={8}>Kontakt</Col>
                <Col span={8}>Uwagi</Col>
            </Row>
            <Row>
                <Col span={8}>
                    <VTextArea
                        value={this.state.scope}
                        style={{width: '100%'}}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={({target: {value}}) => this.setState({scope: value})}
                        placeholder="Nota reklamacyjna"
                        autoSize={{minRows: 3, maxRows: 5}}/>
                </Col>
                <Col span={8}>
                    <VTextArea
                        value={this.state.contact}
                        style={{width: '100%'}}
                        onChange={({target: {value}}) => this.setState({contact: value})}
                        placeholder="Kontakt"
                        autoSize={{minRows: 3, maxRows: 5}}/>
                </Col>
                <Col span={8}>
                    <VTextArea
                        value={this.state.note}
                        style={{width: '100%'}}
                        onChange={({target: {value}}) => this.setState({note: value})}
                        placeholder="Uwagi"
                        autoSize={{minRows: 3, maxRows: 5}}/>
                </Col>
            </Row>
        </>)
    }

    renderFooterForm() {
        return (<>
            <Row className="inputRow" style={{marginTop: 30}}>
                <Col span={8}>
                    {
                        this.state.isEdit ?
                            <Button type="primary" loading={this.state.isLoading} onClick={() => this.editCommission()}>Zapisz i wyślij</Button>
                            :
                            <Button
                                loading={this.state.isLoading}
                                type="primary"
                                onClick={() => this.addCommission()}
                                disabled={this.state.blockForm}>Zapisz i wyślij</Button>
                    }
                </Col>
            </Row>
        </>)
    }

    facilityOrCommissionTypeChanged(detailedFacility, commissionType) {
        if (this.state.isEdit || this.state.fromOfferId) {
            return;
        }
        if (isCommissionTypeOverview(commissionType) && detailedFacility) {
            this.setState({
                scope: getFacilityScope(detailedFacility)
            });
        } else {
            this.setState({
                scope: ""
            });
        }
    }

    async onFacilitySelect(facilityId) {
        const detailedFacility = await getDetailedFacility(facilityId)
        this.facilityOrCommissionTypeChanged(detailedFacility, this.state.commissionType)
        this.setState({
            detailedFacility: detailedFacility,
            facilityId: detailedFacility.id,
            facilityStatus: detailedFacility.status,
            facilityInfo: this.parseFacilityInfoResponse(detailedFacility.facilityInfoResponse)
        });
    }

    onFacilityInfoSave(facilityInfoId) {
        this.setState({
            facilityStatus: 'SERVICED'
        })
    }

    onLocalFilesChanged(fileList) {
        this.setState({
            localFilesList:fileList
        })
    }

    render() {
        return (
            <>
                {(!this.state.isEdit || (this.state.isEdit && this.state.commissionStatus === "ACTIVE")) &&
                    <>
                        <Card title={this.state.isEdit ? "Edytuj zlecenie" : "Dodaj nowe zlecenie"} extra={
                            <Button onClick={() => this.resetForm()}>Reset</Button>
                        }>

                            <Row className="inputRow">
                                <Col span={8}>Obiekt</Col>
                                <Col span={8}>
                                    <VSelect
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        disabled={this.state.fromOfferId}
                                        value={this.state.facilityId}
                                        style={{width: '70%'}}
                                        rules={{required: true}}
                                        validTriggerContainer={this.state.validTriggerContainer}
                                        onChange={(value) => {
                                            this.onFacilitySelect(value)
                                        }}
                                        options={this.state.facilityList}/>
                                </Col>
                            </Row>
                        </Card>

                        {this.state.facilityId &&
                            <div style={{marginTop: 20}}>
                                <FacilityInfoForm
                                    initialState={this.state.facilityInfo}
                                    facilityId={this.state.facilityId}
                                    facilityStatus={this.state.facilityStatus}
                                    realizerList={this.state.realizerList}
                                    salesmanList={this.state.salesmanList}
                                    subcontractorList={this.state.subcontractorList}
                                    showFiles={false}
                                    onSave={(id) => this.onFacilityInfoSave(id)}
                                />
                            </div>
                        }
                        <br/>
                        <Card title={"Pliki zlecenie"}>
                            <VFilesEdit editable={true} viewEnabled={true} viewName={"Commission"}
                                        viewId={this.state.commissionId}
                                        onLocalFilesChanged={(files) => this.onLocalFilesChanged(files)}

                            />
                        </Card>
                        <br/>
                        <Card title={"Pozostałe informacje o zleceniu"} style={{marginTop: 20}}>
                            <Row className="inputRow">
                                <Col span={8} style={{display: "flex", alignItems: "center"}}>
                                    Rodzaj zlecenia
                                </Col>
                                <Col span={8}>
                                    <VSelect
                                        value={this.state.commissionType}
                                        style={{width: '70%'}}
                                        rules={{required: true}}
                                        validTriggerContainer={this.state.validTriggerContainer}
                                        onChange={(value) => {
                                            if ((this.state.commissionType === 'RECLAMATION' && value !== 'RECLAMATION')
                                                || (this.state.commissionType !== 'RECLAMATION' && value === 'RECLAMATION')) {
                                                this.setState({validTriggerContainer: [this.state.validTriggerContainer[0]]})
                                            }
                                            this.facilityOrCommissionTypeChanged(this.state.detailedFacility, value);
                                            this.setState({commissionType: value})
                                        }}
                                        options={CommissionType}/>
                                </Col>
                            </Row>
                            <div style={{height: 1, backgroundColor: "#ddddff", marginTop: 20, marginBottom: 20}}/>
                            {
                                this.state.commissionType === 'RECLAMATION'
                                && this.renderReclamationForm()
                            }
                            {
                                this.state.commissionType
                                && this.state.commissionType !== 'RECLAMATION'
                                && this.renderStandardForm()
                            }
                            {this.state.commissionType && this.renderFooterForm()}

                        </Card>


                    </>
                }
                {
                    ((this.state.commissionStatus === "CANCELLED" || this.state.commissionStatus === "FINISHED") && this.state.isEdit) &&
                    <Card>Nie mozna edytować zlecenia które nie jest aktywne.</Card>

                }
            </>
        );
    }
}

export default withRouter(CommissionForm);
