import React from "react";
import {authFetch} from "../../../../../utils/ApiHelper";
import OverviewTable from './OverviewTable';
import {
    GetOverviewFacilityStatusColor,
    OverviewFacilityStatus,
    OverviewFacilityStatusToLabel
} from "../../../../../config/OverviewFacilityStatus";
import {MakeRoute, Routes} from "../../../../../config/Routes";
import MapComponent from "../../../../../component/MapComponent";

let dateFormat = require("dateformat");

class OverviewTab extends React.Component {
    StatusDict = OverviewFacilityStatusToLabel()
    Colors = GetOverviewFacilityStatusColor()

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            facilities: [],
            facilitiesFiltered: [],
        };
    }

    componentDidMount() {
        authFetch({
            endpointName: "FacilityOverviewTab",
            successFunction: (data) => {
                for (let item of data.facilities) {
                    item['adress_city'] = item.addressResponse.city
                    item['adress_voivodeship'] = item.addressResponse.voivodeship
                    item['facilityStatus_label'] = this.StatusDict[item.overviewFacilityStatus]
                    item['fStatus'] = item.overviewFacilityStatus
                    item['manager_label'] = item.subcontractor?.manager ? item.subcontractor.manager.firstName + " " + item.subcontractor.manager.lastName : "brak"
                    item['salesman_label'] = item.salesman ? item.salesman.firstName + " " + item.salesman.lastName : "brak"
                    item['realizer_label'] = item.realizer ? item.realizer.firstName + " " + item.realizer.lastName : "brak"
                    item['address'] = item.addressResponse
                    item['facilityStatus'] = item.overviewFacilityStatus

                    item['nextReviewDate'] = item.nextReviewDate ? new Date(item.nextReviewDate) : null
                    item['nextReviewDateStr'] = item.nextReviewDate ? dateFormat(item.nextReviewDate, "mm-yyyy") : '-'
                    item['serviceEndDate'] = item.serviceEndDate ? new Date(item.serviceEndDate) : null
                    item['serviceEndDateStr'] = item.serviceEndDate ? dateFormat(item.serviceEndDate, "dd-mm-yyyy") : '-'

                    item['commissionId'] = item.commission ? item.commission.id : null
                    item['commissionStatus'] = item.commission ? item.commission.commissionStatus : null

                    // for map
                    item['text'] = item.name
                    item['linkAddress'] = MakeRoute(Routes.FacilityDetail, {'id': item.id})
                    item['latitude'] = item.address?.latitude
                    item['longitude'] = item.address?.longitude
                    item['markerColor'] = this.Colors[item.fStatus]
                }

                this.setState({
                    facilities: data.facilities,
                    facilitiesFiltered: data.facilities
                })
            }
        })
    }

    updateFiltered = (currentDataSource) => {
        this.setState({facilitiesFiltered: currentDataSource})
    }

    render() {
        return (<>
            <MapComponent
                data={this.state.facilitiesFiltered}
                clickable={true}
            />
            <OverviewTable
                data={this.state.facilities}
                udater={this.updateFiltered}
                statuses={OverviewFacilityStatus}
                statusesColor={this.Colors}
                statusesDict={this.StatusDict}/>
        </>);
    }
}

export default OverviewTab;
