export const ActivityTypes = [
    {value: 'ODDYMIANIE', label: 'Oddymianie', control: true, curtains: false},
    {value: 'KURTYNY', label: 'Kurtyny', control: false, curtains: true},
    {value: 'WENTYLATORY', label: 'Wentylatory', control: false, curtains: false},
    {value: 'NADCISNIENIE', label: 'Nadciśnienie', control: false, curtains: false},
    {value: 'KLAPY_PPOZ', label: 'Klapy przeciwpożarowe', control: false, curtains: false},
    {value: 'OMEGA', label: 'Omega', control: false, curtains: false},
    {value: 'I_XEGA_HO', label: 'I Xega HO', control: false, curtains: false},
    {value: 'PASMO', label: 'Pasmo', control: false, curtains: false},
];

export default ActivityTypes

export let GetActivityTypeFromValue = (value) => {
    ActivityTypes.forEach(x => {
        if (x.value === value) {
            value = x.label
        }
    })

    return value
}

export let DoesActivityHasControl = (value) => {
    let hasControl = false
    ActivityTypes.forEach(x => {
        if (x.value === value) {
            hasControl = x.control
        }
    })

    return hasControl
}


export let DoesActivityHasCurtains = (value) => {
    let hasCurtains = false
    ActivityTypes.forEach(x => {
        if (x.value === value) {
            hasCurtains = x.curtains
        }
    })

    return hasCurtains
}
