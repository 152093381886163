import React from "react";
import {withRouter} from 'react-router-dom';

class ProfileView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                ProfileView
            </div>
        );
    }
}

export default withRouter(ProfileView);
