import React from 'react';
import {Button, Col, Layout, List, Row, Space, Typography} from 'antd';
import styles from "../../../../style/privacyPolicy/PrivacyPolicy.module.scss"
import logo from "../../../../img/logo.png";
import {Link} from "react-router-dom";

const {Header, Content, Footer, Sider} = Layout;

const {Title, Text, Paragraph} = Typography;

export function PrivacyPolicy() {

    const accessResources = [
        'danych technicznych urządzenia - na potrzeby zainstalowania aplikacji na urządzeniu,',
        'pamięci wewnętrznej - na potrzeby zapisywania i pobierania danych na i z urządzenia',
        'lokalizacji - na potrzeby poprawnego działania mapy z punktami serwisowymi',
        'aparatu fotograficznego – na potrzeby dokumentowania wykonanych prac serwisowych',
    ];


    const reasons = [
        'umożliwienia weryfikacji tożsamości użytkowników i przesłania im spersonalizowanej informacji dotyczącej wykonywanych prac serwisowych',
        'dostarczenia adresów punktów serwisowych',
        'skanowania kodów QR',
        'dodawania przypomnień o terminach serwisów',
        'wykonywania/ dokumentowania prac serwisowych'
    ]

    function renderText() {
        return <Space direction="vertical">

            <Title level={3}>1. Informacje ogólne</Title>
            <Paragraph>Niniejsza polityka prywatności aplikacji mobilnej definiuje sposób zbierania,
                przetwarzania i
                przechowywania danych koniecznych do realizacji usług świadczonych za pośrednictwem
                aplikacji mobilnej
                „Mercor Inspector”</Paragraph>
            <Paragraph>Wszystkim użytkownikom aplikacji mobilnej „Mercor Inspector” przedstawiamy informacje
                o zakresie
                danych, w tym danych osobowych przetwarzanych przez aplikację mobilną, dostępną na
                platformach Android
                oraz iOS.</Paragraph>
            <Paragraph>Poprzez „użytkowników”, rozumiemy osoby, które zainstalowały aplikację mobilną na
                urządzeniu
                mobilnym i
                korzystają z udostępnionych w ramach aplikacji mobilnej funkcjonalności, w tym osoby
                uprawnione przez
                naszych klientów.</Paragraph>
            <Paragraph>Wydawcą aplikacji mobilnej jest spółka 3DV Risk sp. z o.o.</Paragraph>


            <Title level={3}>2. Dostęp do zasobów przez aplikację mobilną</Title>

            <Paragraph>Aplikacja mobilna może uzyskać dostęp do następujących uprawnień na urządzeniu
                mobilnym, m. in.:</Paragraph>
            <List
                size="small"
                bordered
                dataSource={accessResources}
                renderItem={item => <List.Item>{item}</List.Item>}
            />
            <Paragraph>W celu m.in.:</Paragraph>
            <List
                size="small"
                bordered
                dataSource={reasons}
                renderItem={item => <List.Item>{item}</List.Item>}
            />
            <Paragraph>
                Aplikacja mobilna zbiera dane osobowe i informacje o aktywności użytkowników, które zostają wykorzystane
                do świadczenia usług serwisowych na podstawie umowy zawartej z użytkownikiem aplikacji. Daną osobową
                jest informacja, przy pomocy której możemy zidentyfikować użytkownika, w tym: imię, nazwisko, adres
                e-mail, telefon kontaktowy.
            </Paragraph>

            <Paragraph>
                Aplikacja mobilna wykorzystuje również inne informacje zgodnie z ustawieniami systemu operacyjnego
                urządzenia użytkownika, np. dane techniczne lub koordynaty geograficzne. Użytkownik może wyłączyć
                wykorzystywanie innych informacji w ustawieniach urządzenia Szczegółowe informacje znajdują się w
                poniższych instrukcjach producentów oprogramowania Android, iOS.
            </Paragraph>


            <Title level={3}>3. Dane osobowe</Title>
            <Paragraph>
                Administratorem danych osobowych jest spółka 3DV Risk sp. z o.o. z siedzibą w
                Toruniu(adres: ul. Łubinowa 59, 87-100 Toruń),
                wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
                Rejonowy w Olsztynie, VIII Wydział Gospodarczy KRS, pod nr KRS 0000798479, NIP:
                5783138176.
            </Paragraph>
            <Paragraph>
                W sprawach związanych z przetwarzaniem i ochroną danych osobowych prosimy kontaktować
                się pod adresem e-mail: biuro@3dvrisk.pl oraz korespondencyjnie na adres administratora
                danych osobowych (z dopiskiem „dane osobowe:”).
            </Paragraph>

            <Paragraph>
                Administrator przetwarza dane osobowe użytkownika z zachowaniem wymogów Rozporządzenia
                Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie
                ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (Ogólne
                Rozporządzenie o Ochronie Danych), zwanego dalej „RODO”.
            </Paragraph>

            <Paragraph>
                W przypadku użytkowników aplikacji „Mercor Inspector” dane osobowe przetwarzane są w
                celu umożliwienia korzystania z zarejestrowanego konta użytkownika aplikacji „Mercor
                Inspector”. Podstawą prawną przetwarzania danych osobowych jest art. 6 ust. 1 lit. a)
                RODO (osoba, której dane dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych
                w jednym lub większej liczbie określonych celów).
            </Paragraph>

            <Paragraph>
                Dane osobowe mogą być ujawniane i udostępniane odbiorcom lub stronom trzecim, którymi
                mogą być podmioty, którym 3DV Risk sp. z o.o. powierza przetwarzanie danych osobowych na
                mocy i w oparciu o umowy powierzenia i zgodnie z wymogami art. 28 RODO, a także podmioty
                upoważnione z mocy przepisów prawa.
            </Paragraph>

            <Paragraph>
                Państwa dane osobowe będą przechowywane do czasu usunięcia przez Państwa konta
                użytkownika w aplikacji „Mercor Inspector” lub do momentu skorzystania przez Państwa z
                uprawnień skutkujących zaprzestaniem lub ograniczeniem przetwarzania danych, w
                szczególności wycofania zgody na przetwarzanie danych, jeżeli przetwarzanie wynika z
                udzielonej zgody, bądź wyrażenia sprzeciwu na przetwarzanie danych.
            </Paragraph>

            <Paragraph>
                W przypadku przetwarzania danych osobowych w oparciu o udzieloną zgodę, wszystkie
                udzielone zgody na przetwarzanie danych osobowych mogą być wycofane w dowolnym momencie,
                bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed
                jej cofnięciem.
            </Paragraph>

            <Paragraph>
                Użytkownikom przysługuje prawo żądania od administratora danych dostępu do treści swoich
                danych, ich sprostowania, usunięcia, ograniczenia przetwarzania, wniesienia sprzeciwu
                wobec przetwarzania, a także prawo do przenoszenia danych.
            </Paragraph>

            <Paragraph>
                Użytkownicy mają prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy
                uznają Państwo, że przetwarzanie Państwa danych osobowych narusza przepisy RODO.
            </Paragraph>

            <Paragraph>
                Podanie danych osobowych ma charakter dobrowolny, a brak ich podania skutkuje brakiem
                możliwości korzystania z aplikacji „Mercor Inspector”.
            </Paragraph>

            <Paragraph>
                Administrator zapewnienia odpowiednie środki techniczne i organizacyjne gwarantujące
                bezpieczeństwo przetwarzanych danych osobowych, w szczególności uniemożliwiających
                dostęp do nich nieuprawnionym osobom trzecim, lub ich przetwarzania z naruszeniem
                przepisów powszechnie obowiązującego prawa, zapobiegających utracie danych osobowych,
                ich uszkodzeniu lub zniszczeniu.
            </Paragraph>

            <Title level={3}>4. Postanowienia końcowe</Title>
            <Paragraph>
                Użytkownik instalując aplikację mobilną, akceptuje zasady opisane w niniejszej polityce
                prywatności.
            </Paragraph>
            <Paragraph>
                W przypadku braku zgody na niniejszą politykę prywatności użytkownik nie ma możliwości
                zainstalowania aplikacji mobilnej i korzystania z jej funkcjonalności.
            </Paragraph>
            <Paragraph>
                Administrator zastrzega sobie prawo do wprowadzania zmian w polityce prywatności,
                zapewniając, że prawa użytkownika określone w niniejszym dokumencie nie zostaną
                ograniczone bez jego zgody.
            </Paragraph>
            <Paragraph>
                Wszelkie zmiany polityki prywatności będą publikowane na stronie internetowej
                administratora.
            </Paragraph>
        </Space>

    }

    function render() {
        return <Layout className={styles.container}>
            <Header className={styles.topBar}>

                <Row>
                    <Col>
                        <Link to="/">
                            <Button type="primary">Wróć do serwisu</Button>
                        </Link>
                    </Col>
                    <Col>
                        <div className={styles.logoContainer}>
                            <img src={logo} className={styles.logo} alt="logo"/>
                        </div>
                    </Col>
                </Row>


            </Header>
            <div className={styles.textContainer}>
                {renderText()}
            </div>
        </Layout>

    }

    return render()
}
