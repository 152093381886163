import Colors from './Colors'

export const OffertType = [
    {
        value: 'FIX',
        label: 'Naprawa',
        color: Colors.orange,
    },
    {
        value: 'OVERVIEW',
        label: 'Przegląd',
        color: Colors.blue,
    },
    {
        value: 'MODERNIZATION',
        label: 'Modernizacja',
        color: Colors.yellow,
    },
];

export const OffertTypeColor = {
    'FIX': '#fe437e',
    'OVERVIEW': '#53c3b3',
    'MODERNIZATION': '#a3a3a3',
}

export const OffertTypeSquare = (status) => {
    var styles = {
        height: 30,
        width: 130,
        textAlign: "center",
        lineHeight: '30px',
        color: "white",
        fontWeight: "bold",
        borderRadius: '3px'
    }
    styles.backgroundColor = OffertTypeColor[status];
    return styles;
}

export const OffertTypeToLabel = () => {
    let dict = {}
    for (let item of OffertType) {
        dict[item.value] = item.label;
    }
    return dict;
}

export const GetOffertTypeColor = () => {
    let dict = {}
    for (let item of OffertType) {
        dict[item.value] = item.color;
    }
    return dict;
}

export default OffertType
