export const AssumptionsTypes = [
    {
        value: 'NORM_PN_EN',
        label: 'Norma PN-EN 12101-6',
    },
    {
        value: 'MANUAL_ITB',
        label: 'Instrukcja ITB nr 378/2002',
    },
    {
        value: 'OTHER',
        label: 'Inne / Mieszane',
    }
];

export default AssumptionsTypes;
