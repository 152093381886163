export class ShareState {
    static container = {
        'auth-token': null,
        "user": null,
        "breadcrumb": '',
        //just for development
        NotificationServiceState: window.localStorage.getItem('user') ? !!JSON.parse(window.localStorage.getItem('user')) : false,
    }

    static subscribers = []

    static subscribe = (key, callback) => {
        if (ShareState.subscribers[key]) ShareState.subscribers[key].push(callback)
        else {
            ShareState.subscribers[key] = [callback];
        }
    }
    /*eslint-disable no-empty*/
    static setValue = (key, value) => {
        ShareState.container[key] = value
        if (!ShareState.subscribers[key]) return;
        for (let callback of ShareState.subscribers[key]) {
            try {
                callback(value);
            } catch (e) {

            }
        }
    }
    /*eslint-enable no-empty*/
}
