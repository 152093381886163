import {Link} from "react-router-dom";
import {MakeRoute, Routes} from "../../../config/Routes";
import React from "react";

let dateFormat = require("dateformat");

export function renderUser(user, withLinks = true) {
    let userDisplay = user ? user.firstName + " " + user.lastName : '-'

    return (
        <div>

            {withLinks && user ?
                <Link to={MakeRoute(Routes.UserDetail, {id: user.id})}>
                    {userDisplay}
                </Link> : userDisplay
            }


        </div>
    )
}

export function convertDate(dateStr, withHour = true) {
    if (dateStr) {
        return withHour ? dateFormat(dateStr, "dd-mm-yyyy HH:MM") : dateFormat(dateStr, "dd-mm-yyyy")
    }
    return '-'
}
