import React from "react";
import {DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import {getApiUrl} from "../../../../utils/ApiHelper";
import styles from "../../../../style/dashboard/view/user/UserList.module.scss";
import {notification} from "antd";
import {DownloadFile} from "../../../../utils/FileUtils";

class ProtocolRowFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileResponse: props.fileResponse,
        };

        this.inputOpenFileRef = React.createRef()

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.rowId !== this.props.rowId) {
            this.setState({
                fileResponse: this.props.fileResponse
            })
        }
    }

    uploadFile(file) {
        const formData = new FormData();
        formData.append('file', file);

        let isOk = true;
        fetch(
            getApiUrl('ProtocolFileRowAdd',
                {
                    version: this.props.protocolVersion,
                    rowId: this.props.rowId,
                }),
            {
                method: 'post',
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + window.localStorage.getItem('auth-token'),
                    // 'Content-Type': 'multipart/form-data',
                },
            })
            .then(response => {
                isOk = response.ok
                const responseCopy = response.clone();
                return responseCopy.json().catch(_ => response.text());
            })
            .then(data => {
                if (isOk) {
                    notification['success']({
                        message: 'OK',
                        description: 'Dodano plik',
                    })

                    this.setState({
                        fileResponse: data
                    })

                    if (this.props.addFile) {
                        this.props.addFile(data)
                    }

                } else {
                    notification['error']({
                        message: 'Błąd',
                        description: 'Nie udało się dodać pliku',
                    })
                }
            })

    }

    shodOpenFileForm() {
        this.inputOpenFileRef.current.click()
    }


    onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];


        this.uploadFile(file)

    }


    render() {
        return (
            <>
                <input
                    ref={this.inputOpenFileRef}
                    type="file"
                    style={{display: "none"}}
                    onChange={this.onChangeFile.bind(this)}
                />


                {this.state.fileResponse && this.props.hasFullAccess &&
                <DownloadOutlined
                    className={styles.iconItem}
                    onClick={() => DownloadFile(this.state.fileResponse)}/>
                }
                {this.props.rowId && this.props.hasFullAccess && this.props.isActive &&
                < UploadOutlined
                    className={styles.iconItem}
                    onClick={() => this.shodOpenFileForm()}
                />
                }
            </>
        )
    }

}

export default ProtocolRowFile;
