import React from "react";
import {Link} from 'react-router-dom';
import styles from '../style/dashboard/component/MapComponent.module.scss'
import {MapContainer as Map, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

function ChangeView({center, zoom}) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

class MapComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    markerHtmlStyles = (color) => {
        if (!color)
            color = '#1c7ae6'
        return `
    background-color: ${color};
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
    border-radius: 20px 20px 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF`
    }

    icon = (color) => {
        return L.divIcon({
            className: "pin",
            iconAnchor: [10.5, 22],
            labelAnchor: [-5, 0],
            popupAnchor: [-1, -20],
            html: `<span style="${this.markerHtmlStyles(color)}" />`
        })

    }

    renderMarkers() {
        if (!this.props.data) return

        return this.props.data.map((item, index) => {
            return (item.latitude && item.longitude) && <Marker
                key={index}
                icon={this.icon(item.markerColor)}
                position={{
                    lat: item.latitude,
                    lng: item.longitude
                }}
                onMouseOver={(e) => {
                    if (this.props.clickable)
                        e.target.openPopup();
                }}
                onMouseOut={(e) => {
                    if (this.props.clickable)
                        e.target.closePopup();
                }}
            >
                {this.props.clickable &&
                <Popup>
                    <Link
                        className={styles.iconItem}
                        to={item.linkAddress}
                    >
                        {item.text}
                    </Link>
                </Popup>
                }
            </Marker>
        });
    }

    render() {
        let centerPosition = [52.2204303, 19.2573012]
        if (this.props.data?.length > 0) {
            centerPosition = [
                this.props.data[0].latitude ? this.props.data[0].latitude : 52.2204303,
                this.props.data[0].longitude ? this.props.data[0].longitude : 19.2573012
            ]
        }

        return (
            <div>
                <Map
                    zoomControl={false}
                    center={centerPosition}
                    zoom={6}
                    className={styles.map}
                    tap={false}
                >
                    <ChangeView center={centerPosition}/>
                    {this.renderMarkers()}
                    <TileLayer
                        attribution='<a href="//basemaps.cartocdn.com">Basemap</a> | &copy; <a href="//osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    />
                </Map>
            </div>
        );
    }
}

export default MapComponent;
