import React, {useEffect} from "react";
import {withRouter} from 'react-router-dom';
import {ShareState} from '../../../../utils/ShareState';
import {Card, Tabs} from 'antd';
import OverviewTab from './component/OverviewTab';
import FixAndModernizationTab from './component/FixAndModernizationTab';
import ReclamationTab from './component/ReclamationTab';
import OfferTab from './component/OfferTab';
import Routes, {MakeRoute} from "../../../../config/Routes";

const {TabPane} = Tabs;

const MainView = withRouter(({history, props, match}) => {

    useEffect(() => {
        ShareState.setValue('breadcrumb', 'Widok główny')
    });

    const handleTabClick = (key) => {
        history.push(MakeRoute(Routes.MainView, {subPath: key}))
    }

    return (
        <>
        {    console.log(match)}
        <Card>
            <Tabs defaultActiveKey="overview" onChange={handleTabClick} activeKey={match.params.subPath}>
                <TabPane tab="Przeglądy" key="overview">
                    <OverviewTab/>
                </TabPane>
                <TabPane tab="Naprawy" key="fixes">
                    <FixAndModernizationTab/>
                </TabPane>
                <TabPane tab="Reklamacje" key="reclamations">
                    <ReclamationTab/>
                </TabPane>
                <TabPane tab="Oferty" key="offers">
                    <OfferTab/>
                </TabPane>
            </Tabs>
        </Card>
        </>
    );

});

export default MainView