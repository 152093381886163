export const NotesTypes = [
    {
        value: 'Brak dostępu',
        label: 'Brak dostępu',
    },
    {
        value: 'Blokada mechaniczna',
        label: 'Blokada mechaniczna',
    },
    {
        value: 'Brak zasilania',
        label: 'Brak zasilania',
    },
    {
        value: 'Niezamontowany Termik',
        label: 'Niezamontowany Termik',
    },
    {
        value: 'Nie odnaleziono',
        label: 'Nie odnaleziono',
    },
    {
        value: 'Brak na dokumentach',
        label: 'Brak na dokumentach',
    },
    {
        value: '—',
        label: '—',
    },
];

export default NotesTypes;
