export const AccountTypesPermutation = [
    {
        value: '0',
        label: 'Administrator',
        text: 'Administrator',
        type: 'ROLE_ADMIN',
        subtype: null,
    },
    {
        value: '1',
        label: 'Kierownik ekipy serwisowej',
        text: 'Kierownik ekipy serwisowej',
        type: 'ROLE_MANAGER',
        subtype: null,
    },
    {
        value: '2',
        label: 'Brygadzista',
        text: 'Brygadzista',
        type: 'ROLE_WORKER',
        subtype: null,
    },
    {
        value: '3',
        label: 'Handlowiec HS',
        text: 'Handlowiec HS',
        type: 'ROLE_SALES',
        subtype: 'HS',
    },
    {
        value: '4',
        label: 'Realizator HS',
        text: 'Realizator HS',
        type: 'ROLE_REALIZER',
        subtype: 'HS',
    },
];

export let GetAccountFromTypeAndSubType = (type, subtype) => {
    for (let item of AccountTypesPermutation) {
        if (item.type === type && item.subtype === subtype) {
            return item;
        }
    }
    return {label: 'Nieznany'};
}

export let GetAccountPermutationTypeId = (accountTypeName) => {
    let index = 0;
    for (let item of AccountTypesPermutation) {
        if (item.value === accountTypeName) return index;
        index++;
    }
    return -1;
}

export default AccountTypesPermutation
