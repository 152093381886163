import {authFetch} from "../utils/ApiHelper";

export const getRealizersListForSelect = async () => {
    return new Promise((resolve, reject) => {
        authFetch({
            endpointName: "GetRealizerList",
            successFunction: data => {
                let realizerList = []
                for (let user of data.users) {
                    realizerList.push({
                        value: user.id,
                        label: user.firstName + " " + user.lastName + " (" + user.nick + ")"
                    })
                }
                resolve(realizerList)
            },
            onFail: (e) => {
                reject()
            }
        })
    })
}

export const getSalesmenListForSelect = async () => {
    return new Promise((resolve, reject) => {
        authFetch({
            endpointName: "GetSalesmanList",
            successFunction: data => {
                let salesmanList = []
                let salesmanHsList = []
                for (let user of data.users) {
                    let salesman = {
                        value: user.id,
                        label: user.firstName + " " + user.lastName
                    }
                    salesmanList.push(salesman)
                    if (user.userSubType === 'HS') {
                        salesmanHsList.push(salesman)
                    }
                }
                resolve([salesmanList, salesmanHsList])
            },
            onFail: (e) => {
                reject()
            }
        })
    })
}