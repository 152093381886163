import React from "react";
import {withRouter} from 'react-router-dom';
import {ShareState} from '../../../../utils/ShareState';

import CommissionForm from './component/CommissionForm'

class CommissionAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Dodaj zlecenie')
    }

    render() {
        return (<CommissionForm commissionId={null}/>);
    }
}

export default withRouter(CommissionAdd);
