import React from "react"
import {withRouter} from 'react-router-dom'
import {ShareState} from '../../../../utils/ShareState'
import FacilityForm from './component/FacilityForm'

class FacilityAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Dodaj nowy obiekt')
    }

    render() {
        return (<FacilityForm/>)
    }
}

export default withRouter(FacilityAdd)
