import React from "react";
import {Button, Card, Image, Modal, notification, Spin, Table, Upload} from 'antd';
import {DeleteOutlined, DownloadOutlined, UploadOutlined} from '@ant-design/icons';
import {authFetch, getApiUrl, getAuthToken} from "../utils/ApiHelper";
import {DownloadFile, IsFileImage, RemoveFile} from "../utils/FileUtils";

class VFilesEdit extends React.Component {
    filesTable = [
        {
            title: 'Nazwa',
            dataIndex: 'fileName',
            key: 'name',
        },
        {
            title: 'Akcje',
            width: "200px",
            render: (data) => {
                return (<>
                    {IsFileImage(data.fileName) &&
                    <Image
                        key={data.uuid}
                        style={{marginRight: 30}}
                        height={60}
                        width={60}
                        src={data.signedUrl}
                        placeholder={<Spin style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}/>}
                    />
                    }
                    <DownloadOutlined
                        style={{fontSize: 20, marginRight: 10, marginLeft: 10}}
                        onClick={() => this.downloadFile(data)}
                    />

                    {this.props.editable &&
                    <DeleteOutlined
                        style={{color: 'red', fontSize: 20, marginRight: 10}}
                        onClick={() => this.removeFile(data)}
                    />
                    }
                </>)
            }
        },
    ];

    constructor(props) {
        super(props);
        this._ismounted = false;
        this.state = {
            fileList: [],
            fileListUploaded: props.files,
        };
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.viewId !== this.props.viewId || prevProps.viewName !== this.props.viewName) {
            this.clearState()
        }
        if(prevState.fileList !== this.state.fileList) {
            if(this.props.onLocalFilesChanged != null) {
                this.props.onLocalFilesChanged(this.state.fileList)
            }
        }
    }

    componentDidMount() {
        this._ismounted = true;
        this.clearState();
    }

    clearState() {
        this.setState({
            fileList: [],
            fileListUploaded: this.props.files,
        }, () => this.getFileList())
    }

    getFileList() {
        if(this.props.viewId == null) {
            return
        }
        authFetch({

            endpointName: this.props.viewName + "FileList",
            endpointParameters: {id: this.props.viewId},
            successFunction: data => {
                for (let file of data.files) {
                    file['uid'] = file['uuid']
                    file['name'] = file['fileName']
                    file['status'] = 'done'
                    file['url'] = getApiUrl(this.props.viewName + "FileDetail", {
                        id: this.props.viewId,
                        fileId: file.uuid
                    })
                }

                this.setState({fileListUploaded: data.files})
            }

        });
    }

    addFile(data) {
        let fileList = [...data.fileList];
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        if ('response' in data.file) {
            if (data.file.status !== "removed") {
                if (data.file.response === undefined) {
                    notification['error']({
                        message: 'Błąd',
                        description: 'Podany plik ma zbyt wielki rozmiar.',
                    })
                } else if ('errorMessage' in data.file.response) {
                    notification['error']({
                        message: 'Błąd',
                        description: 'Niestety nie udało przesłać się pliku',
                    })
                }
            }
        }

        this.setState({
            fileList: fileList,
        })
    }

    downloadFile(fileInfo) {
        if (this._ismounted) {
            DownloadFile(fileInfo)
        }
    }

    removeFile(file) {
        function removeFromList(list, file) {
            const index = list.indexOf(file);

            if (index !== -1) {
                const newFileList = list.slice();
                newFileList.splice(index, 1);
                return newFileList
            }

            return list
        }

        if(this.props.viewId == null) {
            const newFiles = removeFromList(this.state.fileList, file)
            this.setState({fileList: newFiles})
            return
        }


        RemoveFile(file, this.props.viewName, this.props.viewId, () => {
            this.setState({fileListUploaded: removeFromList(this.state.fileListUploaded, file)})
            notification['success']({
                message: 'OK',
                description: <>{"Plik "}<b> {file.name ? file.name : file.fileName}</b>{" usunięty"}</>,
            });
        })
    }

    render() {
        return (
            <>
                {this.props.viewEnabled &&
                <>
                    <Upload
                        maxCount={10}
                        multiple
                        action={getApiUrl(this.props.viewName + "FileAdd", {id: this.props.viewId})}
                        listType="picture"
                        disabled={
                            this.props.viewName == null
                        }
                        headers={{'Authorization': getAuthToken()}}
                        onChange={data => this.addFile(data)}
                        onRemove={data => this.removeFile(data)}
                        onPreview={data => this.downloadFile(data)}
                        beforeUpload={(file) => {
                            if (this.props.viewId == null) {
                                return false
                            }
                            return true
                        }}
                        fileList={this.state.fileList}
                    >
                        {this.props.editable &&
                        <Button icon={<UploadOutlined/>}>Dodaj nowy plik</Button>
                        }
                    </Upload>

                    <Card style={{marginTop: 20}}>
                        <Image.PreviewGroup>
                            <Table dataSource={this.state.fileListUploaded} columns={this.filesTable} rowKey="uuid"/>
                        </Image.PreviewGroup>
                    </Card>
                    <Modal
                        title="Uwaga"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <p>Czy napewno chcesz usunać ten element?</p>
                    </Modal>
                </>
                }
            </>
        )
    }
}

export default VFilesEdit;
