import React from "react";
import {withRouter} from 'react-router-dom';
import {Card} from 'antd';
import {authFetch} from "../../../../utils/ApiHelper";
import NotifyItem from './component/NotifyItem';
import {ShareState} from "../../../../utils/ShareState";

class AlertView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            pagination: {
                pageSize: 10,
            },
        };
    }

    componentDidMount() {
        authFetch({
            endpointName: 'GetNotifications',
            successFunction: data => {
                this.setState({
                    notifications: data.notifications
                })

                ShareState.setValue('notificationCount', data.notifications.length)
            }
        });
    }

    removeItemFromList = (id) => {
        let notifications = this.state.notifications;
        notifications = notifications.filter(x => x.notificationId !== id)

        this.setState({
            notifications: notifications
        })
        ShareState.setValue('notificationCount', notifications.length)
    }

    renderNotifyItems = () => this.state.notifications.map(
        item => <NotifyItem data={item} dataRemover={this.removeItemFromList} key={item.notificationId}/>
    )

    render() {
        return (
            <Card title="Powiadomienia" bordered={true}>
                {this.renderNotifyItems()}
            </Card>
        );
    }
}

export default withRouter(AlertView);
