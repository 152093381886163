import React from "react";
import {Divider, Input, Select} from 'antd';
import {PlusOutlined} from "@ant-design/icons";

class VSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            rules: this.props.rules,
            placeholder: this.props.placeholder,
            style: this.props.style,
            mode: this.props.mode,
            allowClear: this.props.allowClear,
            isValid: true,
            options: this.props.options,

            canAddNew: this.props.canAddNew !== undefined ? this.props.canAddNew : false,
            newItemName: null,
        };
    }

    componentDidMount() {
        if ('validTriggerContainer' in this.props) {
            this.props.validTriggerContainer.push(() => this.valid(this.props.value));
        }
        this.createOptionForSelectedIfNotExists()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.options && this.props.options !== prevProps.options) {
            this.setState({
                options: this.props.options
            }, () => this.createOptionForSelectedIfNotExists())
        } else if (this.props.options === prevProps.options) {
            this.createOptionForSelectedIfNotExists()
        }
    }

    createOptionForSelectedIfNotExists() {
        if (this.props.value && this.state.options && this.state.canAddNew) {
            let exists = this.state.options.find(x => x.value === this.props.value)

            let newItem = {value: this.props.value, label: this.props.value}
            if (!exists) {
                this.setState({
                    options: [...this.state.options, newItem],
                })
            }
        }
    }

    inputHandler(value, option) {
        this.props.onChange(value, option)
        this.setState({
            value: value
        });
        this.valid(value);
    }

    valid(value) {
        if (!('rules' in this.state)) return true;
        if (!!this.state.rules && 'required' in this.state.rules && !!this.state.rules['required']) {
            if (value == null || (Array.isArray(value) && value.length === 0)) {
                this.setState({
                    isValid: false
                })
                return false;
            }
        }
        if (!this.state.isValid) {
            this.setState({
                isValid: true
            })
        }
        return true
    }

    addNewItemHandler() {
        let options = this.state.options
        let itemName = this.state.newItemName
        let newItem = {value: itemName, label: itemName}
        this.props.onChange(newItem.value)
        this.setState({
            newItemName: '',
            options: [...options, newItem],
            value: newItem.value
        })

        this.valid(newItem.value);
    }

    shouldShowInvalidState() {
        return !this.state.isValid && !this.props.shouldHideInvalidClass
    }

    render() {
        return (
            <>
                <Select
                    disabled={this.props.disabled}
                    className={`${this.shouldShowInvalidState() ? 'borderErrorColor' : ''}`}
                    mode={this.state.mode}
                    style={this.state.style}
                    allowClear={this.state.allowClear}
                    placeholder={this.state.placeholder}
                    value={this.props.value}
                    onChange={(value, option) => this.inputHandler(value, option)}
                    options={this.state.options}
                    showSearch={this.props.showSearch}
                    filterOption={this.props.filterOption}
                    dropdownRender={menu => (
                        <div>
                            {menu}
                            {this.state.canAddNew &&
                            <>
                                <Divider style={{margin: '4px 0'}}/>
                                <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                                    <Input style={{flex: 'auto'}} value={this.state.newItemName} onChange={
                                        (e) => this.setState({newItemName: e.target.value})
                                    }/>
                                    <PlusOutlined
                                        style={{
                                            flex: 'none', padding: '8px',
                                            display: 'block', cursor: 'pointer'
                                        }}
                                        onClick={() => this.addNewItemHandler()}
                                    />
                                </div>
                            </>
                            }
                        </div>
                    )}

                >
                </Select>
                <div className={`inputErrorMessage ${this.shouldShowInvalidState() ? '' : 'visibilityNone'}`}>Pole nie może być
                    puste
                </div>
            </>
        )
    }

}

export default VSelect;
