import React from 'react';
import {Route, Switch} from "react-router-dom";
import LoginView from './view/login/LoginView';
import Test from './view/test/Test';
import DashboardContainer from './view/dashboard/DashboardContainer';
import 'antd/dist/antd.css';
import {ShareState} from "./utils/ShareState";
import {RunNotificationService} from './service/NotificationService';
import {Router} from "react-router";
import history from "./history";
import Routes from "./config/Routes";
import {PrivacyPolicy} from "./view/dashboard/view/privacyPolicy/PrivacyPolicy";


RunNotificationService(ShareState.container.NotificationServiceState);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        const token = window.localStorage.getItem('auth-token');
        if (token) {
            const user = window.localStorage.getItem('user');
            ShareState.setValue("user", JSON.parse(user));
            ShareState.setValue("auth-token", token);
        }
    }

    render() {
        return (
            <div>
                <Router history={history}>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={(props) => {
                                return <LoginView/>;
                            }}
                        />
                        <Route
                            exact
                            path="/test"
                            render={(props) => {
                                return <Test/>;
                            }}
                        />
                        <Route
                          exact
                          path={Routes.PrivacyPolicy}
                          render={(props)=> {
                            return <PrivacyPolicy/>
                          }}
                        />
                        <DashboardContainer/>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
