import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, Table} from 'antd';
import {FormOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {MakeRoute, Routes} from "../../../../config/Routes";
import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";

import styles from '../../../../style/dashboard/view/user/UserList.module.scss';
import TableHelper from "../../../../utils/TableHelper";

class SubcontractorList extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            sortedInfo: null,
            pagination: {
                pageSize: 10,
            },
            subcontractors: [],
            visible: false,
            selectedId: null,
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Lista podwykonawców')
        this.getSubcontractorList()
    }

    getSubcontractorList() {
        authFetch({
            endpointName: "subcontractorList",
            successFunction: data => {
                if (!data.subcontractors) return;
                this.setState({
                    subcontractors: data.subcontractors
                })
            }
        });
    }

    render() {
        const columns = [
            {
                title: 'Nazwa podwykonawcy',
                dataIndex: 'name',
                key: 'name',
                width: '25%',
                sorter: {
                    compare: (a, b) => a.name.localeCompare(b.name),
                    multiple: 1,
                },
                ...this.tableHelper.getColumnSearchProps('name'),
            },
            {
                title: 'E-mail',
                dataIndex: 'mail',
                key: 'mail',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('mail'),
            },
            {
                title: 'Adres',
                dataIndex: 'address',
                key: 'address',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('address'),
            },
            {
                title: 'Telefon',
                dataIndex: 'phone',
                key: 'phone',
                width: '15%',
                ...this.tableHelper.getColumnSearchProps('phone'),
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 150,
                render: (item) => <div className={styles.iconHolder}>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.SubcontractorDetail, {'id': item.id})}>
                        <InfoCircleOutlined/>
                    </Link>
                    <Link
                        className={styles.iconItem}
                        to={MakeRoute(Routes.SubcontractorEdit, {'id': item.id})}>
                        <FormOutlined/>
                    </Link>
                </div>,
            },
        ];
        return (
            <div>
                <Card title="Lista podwykonawców" bordered={true}
                      extra={
                          <Button type="primary">
                              <Link to={Routes.SubcontractorAdd}>
                                  Dodaj podwykonawcę
                              </Link>
                          </Button>
                      }>
                    <Table
                        pagination={this.state.pagination}
                        columns={columns}
                        dataSource={this.state.subcontractors}
                        scroll={{x: 1200}}
                        rowKey="id"
                    />
                </Card>
            </div>
        );
    }
}

export default withRouter(SubcontractorList);
