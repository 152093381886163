import Colors from './Colors'

export const CommissionStatus = [
    {
        value: 'ACTIVE',
        label: 'Aktywny',
        color: Colors.orange,
    },
    {
        value: 'CANCELLED',
        label: 'Anulowany',
        color: Colors.red,
    },
    {
        value: 'FINISHED',
        label: 'Zrealizowany',
        color: Colors.green,
    },
    {
        value: 'CONSIDERED',
        label: 'Rozważany',
        color: Colors.blue,
    },
];

CommissionStatus.forEach(item => item['text'] = item['label'])

export const CommissionColors = {
    'ACTIVE': '#53c3b3',
    'CANCELLED': '#a3a3a3',
    'FINISHED': '#008c3f',
    'CONSIDERED': Colors.blue
}

export const CommissionStatusColor = (status) => {
    var styles = {
        height: 30,
        width: 130,
        textAlign: "center",
        lineHeight: '30px',
        color: "white",
        fontWeight: "bold",
        borderRadius: '3px'
    }
    styles.backgroundColor = CommissionColors[status];
    return styles;
}

export const CommissionStatusToLabel = () => {
    let dict = {}
    for (let item of CommissionStatus) {
        dict[item.value] = item.label;
    }
    return dict;
}

export const GetCommissionStatusColor = () => {
    let dict = {}
    for (let item of CommissionStatus) {
        dict[item.value] = item.color;
    }
    return dict;
}

export default CommissionStatus
