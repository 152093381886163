import React, {useMemo, useRef} from "react";
import styles from '../../../../../style/dashboard/component/MapComponent.module.scss';
import {authFetch} from "../../../../../utils/ApiHelper";
import {MapContainer as Map, Marker, TileLayer, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';

let DefaultIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: icon,
    iconRetinaUrl: iconRetina,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

function DraggableMarker(props) {
    const {defaultPosition, locationSetter} = props
    const markerRef = useRef(null)

    /* eslint-disable */
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    let position = marker.getLatLng()
                    locationSetter(position.lat, position.lng)
                }
            },
        }),
        [],
    )
    /* eslint-enable */

    return (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={defaultPosition}
            ref={markerRef}>
        </Marker>
    )
}

function ChangeView({center, zoom}) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

class MapPickerComponent extends React.Component {
    getLocation(latitude, longitude) {
        authFetch({
            endpointName: "AddressReversGeocode",
            endpointParameters: {
                latitude: latitude,
                longitude: longitude
            },
            config: {
                method: 'get'
            },
            successFunction: data => {
                data.latitude = latitude
                data.longitude = longitude

                this.props.addressChangeCallback(data)
            }
        })
    }

    render() {
        const position = [this.props.location.latitude, this.props.location.longitude]
        return (
            <div>
                <Map
                    zoomControl={false}
                    center={position}
                    zoom={6}
                    className={styles.map}
                    tap={false}
                >
                    <ChangeView center={position}/>
                    <TileLayer
                        attribution='<a href="//basemaps.cartocdn.com">Basemap</a> | &copy; <a href="//osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    />
                    <DraggableMarker
                        defaultPosition={{lat: this.props.location.latitude, lng: this.props.location.longitude}}
                        locationSetter={(latitude, longitude) => {
                            this.props.addressChangeCallback({latitude, longitude})
                        }}
                    />
                </Map>
            </div>
        );
    }
}

export default MapPickerComponent;
