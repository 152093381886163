import React from "react";
import {Route, Switch, withRouter} from 'react-router-dom';
import MainView from './view/main/MainView';
import ProfileView from './view/profile/ProfileView';

import FacilityDetail from './view/facility/FacilityDetail';
import FacilityList from './view/facility/FacilityList';
import FacilityEdit from './view/facility/FacilityEdit';
import FacilityAdd from './view/facility/FacilityAdd';

import UserDetail from './view/user/UserDetail';
import UserList from './view/user/UserList';
import UserEdit from './view/user/UserEdit';
import UserAdd from './view/user/UserAdd';

import TenderDetail from './view/tender/TenderDetail';
import TendersList from './view/tender/TendersList';
import TenderEdit from './view/tender/TenderEdit';
import TenderAdd from './view/tender/TenderAdd';

import ManagerTenderDetail from './view/managerTender/ManagerTenderDetail';
import ManagerTendersList from './view/managerTender/ManagerTendersList';

import SubcontractorDetail from './view/subcontractor/SubcontractorDetail';
import SubcontractorList from './view/subcontractor/SubcontractorList';
import SubcontractorEdit from './view/subcontractor/SubcontractorEdit';
import SubcontractorAdd from './view/subcontractor/SubcontractorAdd';

import WorkerDetail from './view/worker/WorkerDetail';
import WorkerList from './view/worker/WorkerList';
import WorkerEdit from './view/worker/WorkerEdit';
import WorkerAdd from './view/worker/WorkerAdd';

import CommissionDetail from './view/commission/CommissionDetail';
import CommissionList from './view/commission/CommissionList';
import CommissionEdit from './view/commission/CommissionEdit';
import CommissionAdd from './view/commission/CommissionAdd';

import ServicesList from './view/service/ServicesList';

import AlertView from './view/alert/AlertView';
import Menu from './component/Menu';
import Breadcrumb from './component/Breadcrumb';
import TopBar from './component/TopBar';
import styles from '../../style/dashboard/DashboardContainer.module.scss';
import '../../style/dashboard/DashboardContainer.antd.scss';
import {ShareState} from '../../utils/ShareState';
import Permissions from '../../config/Permissions';
import Routes, {MakeRoute} from "../../config/Routes";
import logo from '../../img/logo.png';

import {Layout} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import ServiceDetail from "./view/service/ServiceDetail";
import history from "../../history";

const {Sider, Content} = Layout;

class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    componentDidMount() {
        if (!ShareState.container['user']) {
            let currentPath = history.location.pathname

            this.props.history.push({
                pathname: Routes.Login,
                search: '?redirect=' + encodeURIComponent(currentPath)
            })


        }
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        if (!ShareState.container['user']) {
            return "";
        }

        return (
            <Layout className={styles.dashboardContainer}>
                <Sider width={250} trigger={null} collapsible collapsed={this.state.collapsed}
                       className={styles.menuSlide}>
                    <div style={{minHeight: 60}}
                         className={this.state.collapsed ? styles.singleLogoBar : styles.logoBar}>
                        <img src={logo} className={styles.logo} alt="logo"/>
                        <MenuOutlined className={`trigger ${styles.trigger}`} onClick={() => this.toggle()}/>
                    </div>
                    <Menu/>
                </Sider>
                <Layout className="site-layout">
                    <TopBar/>
                    <Content className={styles.contentWrapper}>
                        <Breadcrumb/>
                        <div className={styles.content}>
                            <Switch>
                                {
                                    Permissions.isAllow('MainView') ?
                                        <Route
                                            path={MakeRoute(Routes.MainView)}
                                            render={(props) => {
                                                return <MainView/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('FacilityView') ?
                                        <Route
                                            exact
                                            path={Routes.FacilityDetail}
                                            render={(props) => {
                                                return <FacilityDetail/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('FacilityView') ?
                                        <Route
                                            exact
                                            path={Routes.FacilityList}
                                            render={(props) => {
                                                return <FacilityList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('FacilityView') ?
                                        <Route
                                            exact
                                            path={Routes.FacilityEdit}
                                            render={(props) => {
                                                return <FacilityEdit/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('FacilityAdd') ?
                                        <Route
                                            exact
                                            path={Routes.FacilityAdd}
                                            render={(props) => {
                                                return <FacilityAdd/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('TenderView') ?
                                        <Route
                                            exact
                                            path={Routes.TendersList}
                                            render={(props) => {
                                                return <TendersList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('TenderView') ?
                                        <Route
                                            exact
                                            path={Routes.TenderAdd}
                                            render={(props) => {
                                                return <TenderAdd/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('TenderView') ?
                                        <Route
                                            exact
                                            path={Routes.TenderDetail}
                                            render={(props) => {
                                                return <TenderDetail/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('TenderView') ?
                                        <Route
                                            exact
                                            path={Routes.TenderEdit}
                                            render={(props) => {
                                                return <TenderEdit/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('ManagerTenderView') ?
                                        <Route
                                            exact
                                            path={Routes.ManagerTendersList}
                                            render={(props) => {
                                                return <ManagerTendersList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('ManagerTenderView') ?
                                        <Route
                                            exact
                                            path={Routes.ManagerTenderDetail}
                                            render={(props) => {
                                                return <ManagerTenderDetail/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('CommissionView')
                                    || Permissions.isAllow('ManagerCommissionView')
                                    || Permissions.isAllow('WorkerCommissionView') ?
                                        <Route
                                            exact
                                            path={Routes.CommissionDetail}
                                            render={(props) => {
                                                return <CommissionDetail/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('ManagerCommissionView') ?
                                        <Route
                                            exact
                                            path={Routes.CommissionList}
                                            render={(props) => {
                                                return <CommissionList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('WorkerCommissionView') ?
                                        <Route
                                            exact
                                            path={Routes.CommissionList}
                                            render={(props) => {
                                                return <CommissionList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('CommissionView') ?
                                        <Route
                                            exact
                                            path={Routes.CommissionList}
                                            render={(props) => {
                                                return <CommissionList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('CommissionView') ?
                                        <Route
                                            exact
                                            path={Routes.CommissionEdit}
                                            render={(props) => {
                                                return <CommissionEdit/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('CommissionAdd') ?
                                        <Route
                                            exact
                                            path={Routes.CommissionAddFromOffer}
                                            render={(props) => {
                                                return <CommissionAdd/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('CommissionAdd') ?
                                        <Route
                                            exact
                                            path={Routes.CommissionAddFromPreviousCommission}
                                            render={(props) => {
                                                return <CommissionAdd/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('ServiceDetailForCommissionHistory') ?
                                        <Route
                                            exact
                                            path={Routes.ServiceDetailForCommissionHistory}
                                            render={(props) => {
                                                return <ServiceDetail

                                                />;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('CommissionAdd') ?
                                        <Route
                                            exact
                                            path={Routes.CommissionAdd}
                                            render={(props) => {
                                                return <CommissionAdd/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('ProfileView') ?
                                        <Route
                                            exact
                                            path={Routes.ProfileView}
                                            render={(props) => {
                                                return <ProfileView/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('UserView') ?
                                        <Route
                                            exact
                                            path={Routes.UserList}
                                            render={(props) => {
                                                return <UserList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('UserView') ?
                                        <Route
                                            exact
                                            path={Routes.UserDetail}
                                            render={(props) => {
                                                return <UserDetail/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('UserView') ?
                                        <Route
                                            exact
                                            path={Routes.UserEdit}
                                            render={(props) => {
                                                return <UserEdit/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('UserView') ?
                                        <Route
                                            exact
                                            path={Routes.UserAdd}
                                            render={(props) => {
                                                return <UserAdd/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('SubcontractorView') ?
                                        <Route
                                            exact
                                            path={Routes.SubcontractorList}
                                            render={(props) => {
                                                return <SubcontractorList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('SubcontractorView') ?
                                        <Route
                                            exact
                                            path={Routes.SubcontractorDetail}
                                            render={(props) => {
                                                return <SubcontractorDetail/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('SubcontractorView') ?
                                        <Route
                                            exact
                                            path={Routes.SubcontractorEdit}
                                            render={(props) => {
                                                return <SubcontractorEdit/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('SubcontractorView') ?
                                        <Route
                                            exact
                                            path={Routes.SubcontractorAdd}
                                            render={(props) => {
                                                return <SubcontractorAdd/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('WorkerView') ?
                                        <Route
                                            exact
                                            path={Routes.WorkerList}
                                            render={(props) => {
                                                return <WorkerList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('WorkerView') ?
                                        <Route
                                            exact
                                            path={Routes.WorkerDetail}
                                            render={(props) => {
                                                return <WorkerDetail/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('WorkerView') ?
                                        <Route
                                            exact
                                            path={Routes.WorkerEdit}
                                            render={(props) => {
                                                return <WorkerEdit/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('WorkerView') ?
                                        <Route
                                            exact
                                            path={Routes.WorkerAdd}
                                            render={(props) => {
                                                return <WorkerAdd/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('AlertView') ?
                                        <Route
                                            exact
                                            path={Routes.AlertView}
                                            render={(props) => {
                                                return <AlertView/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('ServiceView') ?
                                        <Route
                                            exact
                                            path={Routes.ServicesList}
                                            render={(props) => {
                                                return <ServicesList/>;
                                            }}
                                        />
                                        : ""
                                }
                                {
                                    Permissions.isAllow('ServiceDetail') ?
                                        <Route
                                            exact
                                            path={Routes.ServiceDetail}
                                            render={(props) => {
                                                return <ServiceDetail/>;
                                            }}
                                        />
                                        : ""
                                }
                            </Switch>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default withRouter(DashboardContainer);
