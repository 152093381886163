import Permissions from '../config/Permissions';
import {GetErrorDescription} from '../config/ErrorMessages';
import {notification} from 'antd';
import {AuthKeyExpired} from "../service/LogOutService";

const ApiURL = {
    "login": () => "login",
    "usersList": () => {
        if (Permissions.canDo("UsersList")) return "user/info/list/all"
        else return "user/info/list/all"
    },
    "userDetail": (args) => {
        if (Permissions.canDo("UserDetail")) return "user/info/mercor/getUser/" + args['id']
        else return "user/info/mercor/getUser/" + args['id']
    },
    "userAdd": () => {
        if (Permissions.canDo("UserAdd")) return "user/management/admin/createUser"
        else return "user/management/admin/createUser"
    },
    "userDelete": (args) => {
        if (Permissions.canDo("UserDelete")) return "user/management/admin/deleteUser/" + args['id']
        else return "user/management/manager/deleteUser/" + args['id']
    },
    "userEdit": () => {
        if (Permissions.canDo("UserEdit")) return "user/management/admin/modifyUser"
        else return "user/management/manager/modifyUser"
    },
    "UserUnDelete": (args) => "user/management/admin/unDeleteUser/" + args['id'],

    //only for manager
    "managerWorkersList": () => {
        if (Permissions.canDo("WorkersList")) return "user/info/list/managerWorkers"
        else return "user/info/list/managerWorkers"
    },
    "managerWorkerDetail": (args) => {
        if (Permissions.canDo("WorkerDetail")) return "user/info/manager/getWorker/" + args['id']
        else return "user/info/manager/getworker/" + args['id']
    },
    "managerWorkerAdd": () => {
        if (Permissions.canDo("WorkerAdd")) return "user/management/manager/createWorker"
        else return "user/management/manager/createWorker"
    },
    "managerWorkerDelete": (args) => "user/management/manager/deleteWorker/" + args['id'],
    "managerWorkerUnDelete": (args) => "user/management/manager/unDeleteWorker/" + args['id'],

    "managerWorkerEdit": () => {
        if (Permissions.canDo("WorkerEdit")) return "user/management/manager/modifyWorker"
        else return "user/management/manager/modifyWorker"
    },

    "managerTendersList": () => {
        if (Permissions.canDo("ManagerTendersList")) return "tender/getAllForManager"
        else return "tender/getAllForManager"
    },
    "managerTenderDetail": (args) => {
        if (Permissions.canDo("ManagerTenderDetail")) return "tender/" + args['id'] + "/getDetailedForManager"
        else return "tender/" + args['id'] + "/getDetailedForManager"
    },
    "ManagerTenderAddOffer": (args) => "tender/" + args['id'] + "/addOffer",
    "ManagerTenderModifyOffer": (args) => "offer/" + args['id'] + "/modifyByManager",
    "ManagerTenderDeleteOffer": (args) => "offer/" + args['id'] + "/deleteMyOffer",
    "ManagerTenderGetMyOffer": (args) => "tender/" + args['id'] + "/getMyOffer",


    "subcontractorList": () => {
        if (Permissions.canDo("SubcontractorsList")) return "subcontractor/getAll"
        else return "subcontractor/getAll"
    },
    "subcontractorDetail": (args) => "subcontractor/getDetailed/" + args['id'],
    "subcontractorAdd": () => {
        if (Permissions.canDo("SubcontractorAdd")) return "subcontractor/add"
        else return "subcontractor/add"
    },

    "subcontractorEdit": () => "subcontractor/modify",

    "SubcontractorGetForManagerOrWorker": (args) => "subcontractor/getManagerOrWorkerSubcontractor/" + args['userId'],

    "tendersList": () => {
        if (Permissions.canDo("TendersList")) return "tender/getAll"
        else return "tender/getAll"
    },
    "tenderDetail": (args) => {
        if (Permissions.canDo("TenderDetail")) return "tender/" + args['id']
        else return "tender/" + args['id']
    },
    "tenderAdd": () => {
        if (Permissions.canDo("TenderAdd")) return "tender/add"
        else return "tender/add"
    },
    "tenderDelete": (args) => {
        if (Permissions.canDo("TenderDelete")) return "tender/" + args['id']
        else return "tender/" + args['id']
    },
    "tenderEdit": (args) => {
        if (Permissions.canDo("TenderEdit")) return "tender/" + args['id'] + "/modify"
        else return "tender/" + args['id'] + "/modify"
    },
    "TenderDetachSubcontractors": (args) => "tender/" + args['id'] + "/detachSubcontractors",
    "TenderChooseOffer": (args) => "offer/" + args['id'] + "/chooseOffer",
    "TenderDeselectOffer": (args) => "offer/" + args['id'] + "/deselectOffer",
    "TenderAddOfferForSubcontractor": (args) => "tender/" + args['tender_id'] + "/addOfferForSubcontractor/" + args["subcontractor_id"],
    "OfferModifyByRealizer": (args) => "offer/" + args['id'] + "/modifyByRealizer",
    "OfferUpdateFinalSalary": (args) => "offer/" + args['id'] + "/updateFinalSalary",

    "FacilityList": () => "facility/getAll",
    "FacilityAdd": () => "facility/add",
    "FacilityEdit": () => "facility/modify",
    "FacilityDelete": (args) => "facility/delete/" + args['id'],
    "FacilityDetail": (args) => "facility/getDetailed/" + args['id'],
    "FacilityQrCode": (args) => "facility/" + args['id'] + "/getQrCode",

    "FacilityInfoAdd": () => "facility-info/add",
    "FacilityInfoEdit": () => "facility-info/modify",
    "FacilityInfoDetail": (args) => "facility-info/getDetailed/" + args['id'],
    "FacilityInfoDelete": (args) => "facility-info/delete/" + args['id'],
    "FacilityInfoFileList": (args) => "facility-info/" + args['id'] + "/file/listAll",
    "FacilityInfoFileAdd": (args) => "facility-info/" + args['id'] + "/file/addFile",
    "FacilityInfoFileDelete": (args) => "facility-info/" + args['id'] + "/file/delete/" + args['fileId'],
    "FacilityInfoFileDetail": (args) => "facility-info/" + args['id'] + "/file/get/" + args['fileId'],

    "GetRealizerList": () => "user/info/list/realizers",
    "GetSalesmanList": () => "user/info/list/sales",
    "GetSubcontractorList": () => "subcontractor/getAll",
    "GetSubcontractorWithManagersList": () => "subcontractor/getAllWithManagers",
    "GetSubcontractorWithoutManagersList": () => "subcontractor/getAllWithoutManagers",
    "SendToSubcontractors": (args) => "tender/" + args["id"] + "/sendToSubcontractors",
    "SendToNewSubcontractors": (args) => "tender/" + args["id"] + "/addSubcontractors",
    "DetachSubcontractors": (args) => "tender/" + args["id"] + "/detachSubcontractors",

    "CommissionAdd": () => "commission/addCommission",
    "CommissionAddFromOffer": () => "commission/addCommissionFromOffer",
    "CommissionListAll": () => "commission/getAll",
    "CommissionEdit": (args) => "commission/" + args['id'] + "/modify",
    "CommissionDelete": (args) => "commission/" + args['id'] + "/delete",
    "CommissionDetail": (args) => "commission/" + args['id'],
    "CommissionDetailForWorker": (args) => "commission/" + args['id'] + "/getDetailedForWorker",
    "CommissionCancel": (args) => "commission/" + args['id'] + "/cancel",
    "CommissionRestore": (args) => "commission/" + args['id'] + "/restore",
    "CommissionFileList": (args) => "commission/" + args['id'] + "/file/listAll",
    "CommissionFileAdd": (args) => "commission/" + args['id'] + "/file/addFile",
    "CommissionFileDelete": (args) => "commission/" + args['id'] + "/file/delete/" + args['fileId'],
    "CommissionFileDetail": (args) => "commission/" + args['id'] + "/file/get/" + args['fileId'],
    "CommissionGetOffer": (args) => "commission/getCommissionFromOffer/" + args['id'],
    "CommissionWorker": (args) => "commission/" + args['id'] + "/worker",
    "CommissionAssignToWorker": (args) => "commission/" + args['id'] + "/assignToWorker",
    "CommissionListForManager": (args) => "commission/getAllForManager",
    "CommissionListForWorker": (args) => "commission/getAllForWorker",

    "FacilityOverviewTab": () => "facility/getForOverview",
    "FacilityFixesTab": () => "facility/getForFixesTab",
    "FacilityOfferedTab": () => "facility/getOffered",
    "FacilityReclamationTab": () => "commission/getReclamations",

    "ServiceAdd": () => "service/add",
    "ServiceAddWithoutLocation": () => "service/addWithoutLocation",
    "ServiceEdit": (args) => "service/" + args['id'] + "/edit",
    "ServiceGet": (args) => "service/" + args['id'] + "/get",

    "ServiceStop": (args) => "service/" + args['id'] + "/stop",
    "ServiceCancelStop": (args) => "service/" + args['id'] + "/cancelStop",
    "ServicesList": () => "service/getAll",
    "ServicesListForFacility": (args) => "service/getAllForFacility/" + args['facilityId'],
    "ServicesListForCommissionFacility": (args) => "service/getAllForCommissionFacility/" + args['commissionId'],
    "ServicesListVisibleToMe": () => "service/getServicesVisibleToMe",
    "ServiceDelete": (args) => "service/" + args['serviceId'] + "/delete",
    "ServiceFileList": (args) => "service/" + args['id'] + "/file/listAll",
    "ServiceFileAdd": (args) => "service/" + args['id'] + "/file/addFile",
    "ServiceFileDelete": (args) => "service/" + args['id'] + "/file/delete/" + args['fileId'],
    "ServiceFileDetail": (args) => "service/" + args['id'] + "/file/get/" + args['fileId'],
    "ServiceCopyWithoutLocation": () => "service/copyServiceWithoutLocation",

    "ProtocolGetVersion": () => "protocol/getProtocolVersion",
    "ProtocolDelete": (args) => "protocol/" + args['protocolId'] + "/delete",

    "ProtocolSpecificVersionAdd": (args) => "protocolVersion" + args['version'] + "/" + args['serviceId'] + "/add",
    "ProtocolSpecificVersionEdit": (args) => "protocolVersion" + args['version'] + "/" + args['protocolId'] + "/modify",
    "ProtocolSpecificVersionGet": (args) => "protocolVersion" + args['version'] + "/" + args['protocolId'] + "/get",
    "ProtocolVersion3GetCriteria": () => "protocolVersion3/getCriteria",

    "ProtocolFileRowGet": (args) => "protocolVersion" + args['version'] + "/rowFile/" + args['rowId'] + '/get',
    "ProtocolFileRowAdd": (args) => "protocolVersion" + args['version'] + "/rowFile/" + args['rowId'] + '/add',


    "ActivityListGetFields": () => "activityProtocol/getActivityListFields",
    "ActivityListAdd": (args) => "activityProtocol/" + args['protocolId'] + "/add",
    "ActivityListGet": (args) => "activityProtocol/" + args['id'] + "/get",
    "ActivityListEdit": (args) => "activityProtocol/" + args['id'] + "/modify",

    "GenerateProtocolPdf": (args) => "protocol/" + args["protocolId"] + "/generate",
    "GenerateActivityListPdf": (args) => "activityProtocol/" + args["activityListId"] + "/generate",

    "AddressGeocode": () => "address/geocode",
    "AddressReversGeocode": (args) => `address/reverseGeocode?latitude=${args['latitude']}&longitude=${args['longitude']}`,

    "GetNotifications": () => "notification/getAll",
    "DeleteNotification": (args) => "notification/" + args['id'] + '/delete',

};

const baseURL = window.REACT_APP_BACKEND_URL

export let getApiUrl = (endpointName, endpointParameters = null) => {
    return baseURL + ApiURL[endpointName](endpointParameters);
};

export let authFetch = (data) => {
    let config = {}
    let endpointName = null
    let endpointParameters = null
    let successFunction = null
    let notShowFailInfo = false
    let setContentType = true
    let onFail = (x) => {
    }

    if ('config' in data) config = data['config']
    if ('endpointName' in data) endpointName = data['endpointName']
    if ('endpointParameters' in data) endpointParameters = data['endpointParameters']
    if ('successFunction' in data) successFunction = data['successFunction']
    if ('notShowFailInfo' in data) notShowFailInfo = data['notShowFailInfo']
    if ('onFail' in data) onFail = data['onFail']
    if ('setContentType' in data) setContentType = data['setContentType']

    config['headers'] = {
        'Authorization': 'Bearer ' + window.localStorage.getItem('auth-token'),
        'Accept': 'application/json'
    }

    if(setContentType) {
        config['headers']['Content-Type'] = 'application/json'
    }

    if(data.headers != null) {
        Object.assign(config['headers'], data.headers)
    }

    let isOk = true;
    let httpCode = 0;
    fetch(getApiUrl(endpointName, endpointParameters), config)
        .then(response => {
            isOk = response.ok
            httpCode = response.status
            const responseCopy = response.clone();
            return responseCopy.json().catch(_ => response.text());
        })
        .then(data => {
            if (httpCode === 401) {
                AuthKeyExpired()
                return;
            }

            if (!data && !isOk) {
                if (!notShowFailInfo) {
                    notification['error']({
                        message: 'Błąd',
                        description: 'Nie udało wykonać się tej akcji.',
                    })
                }
                onFail(data)
                return
            }
            if (data instanceof Object && 'key' in data) {
                if (!notShowFailInfo) {
                    notification['error']({
                        message: 'Błąd',
                        description: GetErrorDescription(data),
                    })
                }
                console.log("bede wolac onFail")
                onFail(data)
                return
            }
            if (!isOk) {
                if (!notShowFailInfo) {
                    notification['error']({
                        message: 'Błąd',
                        description: 'Nieoczekiwany błąd serwera.',
                    })
                }
                onFail(data)
            } else {
                successFunction(data)
            }
        })
        .catch((e) => {
            /*eslint-disable no-console*/
            console.log(e)
            if (!notShowFailInfo) {
                notification['error']({
                    message: 'Błąd',
                    description: 'Nieoczekiwany błąd serwera.',
                })
            }
            onFail(data)
            /*eslint-enable no-console*/
        })
}

export const getAuthToken = () => 'Bearer ' + window.localStorage.getItem('auth-token')
