export const ControlTypes = [
    {
        value: 'BFL 230',
        label: 'BFL 230',
    },
    {
        value: 'BFL 24',
        label: 'BFL 24',
    },
    {
        value: 'BF 230',
        label: 'BF 230',
    },
    {
        value: 'BF 24',
        label: 'BF 24',
    },
    {
        value: 'BFN 230',
        label: 'BFN 230',
    },
    {
        value: 'BFN 24',
        label: 'BFN 24',
    },
    {
        value: 'BE 230',
        label: 'BE 230',
    },
    {
        value: 'BE 24',
        label: 'BE 24',
    },
];

export default ControlTypes;
