import React from "react"
import {withRouter} from 'react-router-dom'
import {ShareState} from '../../../../utils/ShareState'
import SubcontractorForm from "./SubcontractorForm";

class SubcontractorEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
        }
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Edytuj podwykonawcę')
    }

    render() {
        return (<SubcontractorForm subcontractorId={this.state.id}/>)
    }
}

export default withRouter(SubcontractorEdit)
