export const ProtocolResultTypes = [
    {
        value: 'Conditionally',
        label: 'Warunkowo',
    },
    {
        value: 'Yes',
        label: 'Tak',
    },
    {
        value: 'No',
        label: 'Nie',
    },
];

export default ProtocolResultTypes