import React from "react";
import {Descriptions} from "antd";
import {GetTenderStatusFromValue, GetTenderStatusStyle} from "../../../config/TenderStatuses";
import {GetTenderTypeFromValue, GetTenderTypeStyle} from "../../../config/TenderTypes";

let dateFormat = require("dateformat");

class TenderDetailsDisplay extends React.Component {

    getByName(name) {
        return this.props.data ? this.props.data[name] : null
    }

    convertDate(dateStr) {
        return dateFormat(dateStr, "dd-mm-yyyy HH:MM")
    }


    render() {
        let documentPolishName = this.getByName('tenderType') === "RECLAMATION" ? "Nr. reklamacji" : "Nr. dokumentu"
        let status = GetTenderStatusFromValue(this.getByName('status')).label
        let statusStyle = GetTenderStatusStyle(this.getByName('status'))

        let tenderType = this.getByName('tenderType')
        let tenderTypeName = GetTenderTypeFromValue(tenderType).label
        let tenderTypeStyle = GetTenderTypeStyle(tenderType)
        return (

            <div>
                <Descriptions bordered column={2}>
                    <Descriptions.Item
                        label={documentPolishName}>{this.getByName('documentNumber')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Status">
                        <div style={statusStyle}>{status}</div>
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Rodzaj przetargu">
                        <div style={tenderTypeStyle}>{tenderTypeName}</div>
                    </Descriptions.Item>

                    <Descriptions.Item
                        label="Temat">
                        {this.getByName('topic')}
                    </Descriptions.Item>

                    {(tenderType === 'ONE_TIME_OVERVIEW' || tenderType === 'CYCLICAL_OVERVIEW') &&
                    <Descriptions.Item
                        label="Data przeglądu">{this.convertDate(this.getByName('reviewDate'))}
                    </Descriptions.Item>
                    }
                    <Descriptions.Item
                        label="Data realizacji">{this.convertDate(this.getByName('realizationDate'))}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="Data składania ofert" span={3}>{this.convertDate(this.getByName('submissionBids'))}
                    </Descriptions.Item>


                    <Descriptions.Item
                        span={3}
                        label="Zakres"
                        style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                    >
                        {this.getByName('scope')}
                    </Descriptions.Item>
                    {tenderType === 'RECLAMATION' &&


                    <Descriptions.Item
                        span={3}
                        label="Treść zgłoszenia reklamacyjnego"
                        style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                    >
                        {this.getByName('complaintNotice')}
                    </Descriptions.Item>
                    }
                    <Descriptions.Item
                        span={3}
                        label="Notatka"
                        style={{whiteSpace: 'pre-wrap', height: 'auto'}}
                    >
                        {this.getByName('note')}
                    </Descriptions.Item>


                </Descriptions>

            </div>
        );
    }
}

export default TenderDetailsDisplay
