import React from "react"
import {withRouter} from 'react-router-dom'
import {ShareState} from '../../../../utils/ShareState'
import SubcontractorForm from "./SubcontractorForm";

class SubcontractorAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Dodaj podwykonawcę')
    }

    render() {
        return (<SubcontractorForm/>)
    }
}

export default withRouter(SubcontractorAdd)
