import React from "react";
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, notification, Table} from 'antd';
import {FormOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {MakeRoute, Routes} from "../../../../config/Routes";
import Permissions from "../../../../config/Permissions";
import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";
import {CommissionStatus, CommissionStatusToLabel} from '../../../../config/CommissionStatus';
import {CommissionType, CommissionTypeToLabel} from '../../../../config/CommissionType';
import styles from '../../../../style/dashboard/view/user/UserList.module.scss';
import {StatusColor} from "./CommissionStatus";
import TableHelper from "../../../../utils/TableHelper";
import DeleteConfirm from "../../../../component/DeleteConfirm";
import MapComponent from "../../../../component/MapComponent";

let dateFormat = require("dateformat");

class CommissionList extends React.Component {
    CommissionStatusDict = CommissionStatusToLabel()
    CommissionTypeDict = CommissionTypeToLabel()
    colors = {
        'MODERNIZATION': '#fe437e',
        'REPAIR': '#53c3b3',
        'ONE_TIME_OVERVIEW': '#a3a3a3',
        'CYCLICAL_OVERVIEW': '#008c3f',
        'INSPECTION': '#008c3f',
        'RECLAMATION': '#008c3f',
    }
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            sortedInfo: null,
            pagination: {
                pageSize: 10,
            },
            commissions: [],

            commissionsFiltered: []
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Lista zleceń')
        this.getCommissionsList()
    }

    getUserFirstLastName(user) {
        if (!user) {
            return "-"
        }
        return user.firstName + ' ' + user.lastName
    }

    getCommissionsList() {
        let endpointName = 'CommissionListAll'

        if (ShareState.container.user.userType === 'ROLE_MANAGER') {
            endpointName = 'CommissionListForManager'
        } else if (ShareState.container.user.userType === 'ROLE_WORKER') {
            endpointName = 'CommissionListForWorker'
        }

        authFetch({
            endpointName: endpointName,
            successFunction: data => {
                if (!data.commissions) return;
                if (!data) return;
                for (let item of data.commissions) {
                    item['adress_city'] = item.facility.address.city
                    item['adress_voivodeship'] = item.facility.address.voivodeship
                    item['commissionType_label'] = this.CommissionTypeDict[item.commissionType]
                    item['facility_name'] = item.facility ? item.facility.name : "-"
                    item['facility_id'] = item.facility ? item.facility.id : null
                    item['realizationDate'] = item.realizationDate ? new Date(item.realizationDate) : null
                    item['realizationDateStr'] = item.realizationDate ? dateFormat(item.realizationDate, "dd-mm-yyyy") : '-'
                    item['serviceEndDate'] = item.serviceEndDate ? new Date(item.serviceEndDate) : null
                    item['serviceEndDateStr'] = item.serviceEndDate ? dateFormat(item.serviceEndDate, "dd-mm-yyyy") : '-'

                    item['subcontractor_name'] = item.subcontractor?.name
                    item['subcontractor_id'] = item.subcontractor?.id

                    item['realizer_name'] = this.getUserFirstLastName(item.realizer)
                    item['realizer_id'] = item.realizer?.id

                    item['salesman_name'] = this.getUserFirstLastName(item.salesman)
                    item['salesman_id'] = item.salesman?.id


                    // for map
                    item['text'] = item.facility?.name
                    item['linkAddress'] = MakeRoute(Routes.CommissionDetail, {'id': item.id})
                    item['latitude'] = item.facility?.address?.latitude
                    item['longitude'] = item.facility?.address?.longitude
                    item['markerColor'] = this.colors[item.commissionType]
                }
                let sortedCommissions = data.commissions;
                sortedCommissions = sortedCommissions.sort(function (a, b) {
                    return b.id - a.id
                })
                this.setState({
                    commissions: data.commissions,
                    commissionsFiltered: sortedCommissions,
                })
            }
        });
    }

    removeCommission(id) {
        authFetch({
            endpointName: "CommissionDelete",
            endpointParameters: {id: id},
            config: {
                method: 'delete',
                body: JSON.stringify({
                    CommissionId: id
                })
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Usunięto zlecenie',
                })
                this.getCommissionsList()
            }
        })
    }

    render() {
        const columns = [
            {
                title: 'Nr zlecenia',
                dataIndex: 'commissionNumber',
                key: 'commissionNumber',
                width: '10%',
                sorter: {
                    compare: (a, b) => a.commissionNumber.localeCompare(b.commissionNumber)
                },
                ...this.tableHelper.getColumnExcelSearchProps('commissionNumber', this.state.commissions.map(x => x.commissionNumber)),

            },
            {
                title: 'Obiekt',
                dataIndex: 'facility_name',
                key: 'facility_name',
                width: '10%',
                sorter: {
                    compare: (a, b) => a.facility_name.localeCompare(b.facility_name)
                },
                ...this.tableHelper.getColumnExcelSearchProps('facility_name', this.state.commissions.map(x => x.facility.name)),
                render: (data, record) =>
                    Permissions.canDo("FacilityDetail") ?
                        <Link to={MakeRoute(Routes.FacilityDetail, {id: record.facility_id})}>
                            {data}
                        </Link>
                        :
                        <div>{data}</div>

            },
            {
                title: 'Status',
                dataIndex: 'commissionStatus',
                key: 'commissionStatus',
                width: '15%',
                filters: CommissionStatus,
                onFilter: (value, record) => value === record.commissionStatus,
                render: (item) =>
                    <div>
                        <div style={StatusColor(item)}>
                            {this.CommissionStatusDict[item]}
                        </div>
                    </div>
            },
            {
                title: 'Oczekiwana data realizacji',
                width: '15%',
                dataIndex: 'realizationDateStr',
                key: 'realizationDateStr',
                onFilter: (value, record) => record.realizationDateStr === value,
                ...this.tableHelper.getColumnSearchProps('realizationDateStr'),
                sorter: {
                    compare: (a, b) => a.realizationDate?.getTime() - b.realizationDate?.getTime(),
                    multiple: 1,
                },
            },
            {
                title: 'Termin zakończenia serwisu',
                width: '15%',
                dataIndex: 'serviceEndDateStr',
                key: 'serviceEndDateStr',
                onFilter: (value, record) => record.serviceEndDateStr === value,
                ...this.tableHelper.getColumnSearchProps('serviceEndDateStr'),
                sorter: {
                    compare: (a, b) => a.serviceEndDate?.getTime() - b.serviceEndDate?.getTime(),
                    multiple: 1,
                },
            },
            {
                title: 'Rodzaj zlecenia',
                dataIndex: 'commissionType',
                key: 'commissionType',
                width: '12%',
                filters: CommissionType,
                onFilter: (value, record) => value === record.commissionType,
                render: (item) =>
                    <div>
                        {this.CommissionTypeDict[item]}
                    </div>
            },
            {
                title: 'Realizator HS',
                dataIndex: 'realizer_name',
                key: 'realizer_name',
                width: '10%',
                sorter: {
                    compare: (a, b) => a.realizer_name.localeCompare(b.realizer_name)
                },
                ...this.tableHelper.getColumnExcelSearchProps('realizer_name', this.state.commissions.map(x => x.realizer_name)),

                render: (data, record) =>
                    <Link to={MakeRoute(Routes.UserDetail, {id: record.realizer_id})}>
                        {data}
                    </Link>
            },
            {
                title: 'Handlowiec HS',
                dataIndex: 'salesman_name',
                key: 'salesman_name',
                width: '10%',
                sorter: {
                    compare: (a, b) => a.salesman_name.localeCompare(b.salesman_name)
                },
                ...this.tableHelper.getColumnExcelSearchProps('salesman_name', this.state.commissions.map(x => x.salesman_name)),

                render: (data, record) =>
                    <Link to={MakeRoute(Routes.UserDetail, {id: record.salesman_id})}>
                        {data}
                    </Link>
            }


        ];
        let user = ShareState.container['user'];
        if (user['userType'] !== 'ROLE_MANAGER' && user['userType'] !== 'ROLE_WORKER') {
            columns.push({
                title: 'Podwykonawca',
                dataIndex: 'subcontractor_name',
                key: 'subcontractor_name',
                width: '10%',
                sorter: {
                    compare: (a, b) => a.subcontractor_name.localeCompare(b.subcontractor_name)
                },
                ...this.tableHelper.getColumnExcelSearchProps('subcontractor_name', this.state.commissions.map(x => x.subcontractor_name)),

                render: (data, record) =>
                    <Link to={MakeRoute(Routes.SubcontractorDetail, {id: record.subcontractor_id})}>
                        {data}
                    </Link>
            })
        }

        columns.push({
            title: 'Akcje',
            dataIndex: '',
            key: 'actions',
            fixed: 'right',
            width: 150,
            render: (item) => <div className={styles.iconHolder}>
                <Link
                    className={styles.iconItem}
                    to={MakeRoute(Routes.CommissionDetail, {'id': item.id})}>
                    <InfoCircleOutlined/>
                </Link>
                {
                    Permissions.canDo("CommissionEdit") && <>
                        <Link
                            className={styles.iconItem}
                            to={MakeRoute(Routes.CommissionEdit, {'id': item.id})}>
                            <FormOutlined/>
                        </Link>
                        <DeleteConfirm
                            onDelete={() => this.removeCommission(item.id)}
                            confirmText={"Czy na pewno chcesz usunąć to zlecenie?"}
                        />
                    </>
                }
            </div>,
        })


        return (
            <div>
                <Card title="Lista zleceń" bordered={true}
                      extra={Permissions.canDo("CommissionAdd") &&
                      <Button type="primary">
                          <Link to={Routes.CommissionAdd}>
                              Dodaj zlecenie
                          </Link>
                      </Button>
                      }>

                    <MapComponent
                        data={this.state.commissionsFiltered}
                        clickable={true}
                    />

                    <br/>
                    <Table
                        pagination={this.state.pagination}
                        columns={columns}
                        onChange={(a, b, c, dataBase) => this.setState({
                            commissionsFiltered: dataBase.currentDataSource.sort(function (a, b) {
                                return b.id - a.id
                            })
                        })}
                        dataSource={this.state.commissions}
                        scroll={{x: 1200}}
                        rowKey="id"
                    />
                </Card>

            </div>
        );
    }
}

export default withRouter(CommissionList);
