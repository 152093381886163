export const ResultTypes = [
    {
        value: 'CORRECT',
        label: 'Prawidłowy',
    },
    {
        value: 'NOT_CORRECT',
        label: 'Nie prawidłowy',
    }
];

export default ResultTypes;
