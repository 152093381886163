import React from "react";
import {Button, Col, notification, Row, Table} from "antd";
import {VSelect, VTextArea} from "../../../../../component/form/VForm";
import ProtocolResultTypes from '../../../../../config/Protocol/ProtocolResultTypes'
import SourceOfAerationTypes from '../../../../../config/Protocol/SourceOfAerationTypes'
import DevicesFixPriorityTypes from '../../../../../config/Protocol/DevicesFixPriorityTypes'
import {authFetch} from "../../../../../utils/ApiHelper";
import TableHelper from "../../../../../utils/TableHelper";
import styles from "../../../../../style/dashboard/view/user/UserList.module.scss";
import {DeleteOutlined} from "@ant-design/icons";
import ProtocolRowFile from "../ProtocolRowFile";

class AddProtocol1 extends React.Component {
    tableHelper = new TableHelper(this);

    constructor(props) {
        super(props);
        this.state = {

            deviceReportList: [],
            protocolResult: null,
            sourceOfAeration: null,

            validTriggerContainer: [],

            pagination: {
                pageSize: 10,
            },


            tableFreeIndex: 0
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.fetchProtocol()
        }
    }

    componentDidMount() {
        this.fetchProtocol()
    }

    fetchProtocol(data = this.props.data) {
        let cnt = 0

        let deviceReports = []
        if (data && data.deviceReports) {
            data.deviceReports.forEach(x => x.index = cnt++);
            deviceReports = data.deviceReports
        }

        this.setState({
            sourceOfAeration: data?.sourceOfAeration,
            protocolResult: data?.protocolResult,
            deviceReportList: deviceReports,
            tableFreeIndex: cnt
        })
    }

    validTrigger() {
        for (let item of this.state.validTriggerContainer) item()
    }

    valid() {
        this.validTrigger()
    }

    setDeviceReportListByIndex(index, key, value) {
        let listCopy = [...this.state.deviceReportList]
        listCopy.forEach(x => {
            if (x.index === index) x[key] = value
        })

        this.setState({
            deviceReportList: listCopy
        })
    }

    isProtocolValid() {
        return true
    }

    deleteReportRow(record) {
        let index = record.index;
        const result = this.state.deviceReportList.filter(x => x.index !== index);
        this.setState({
            deviceReportList: result
        })
    }

    doesHaveSourceOfAeration() {
        return this.props.systemTypeName !== 'KURTYNY' && this.props.activityTypeName !== 'KURTYNY'
    }

    addProtocol() {
        if (this.isProtocolValid()) {
            authFetch({
                endpointName: this.props.protocolId ? "ProtocolSpecificVersionEdit" : "ProtocolSpecificVersionAdd",
                endpointParameters: {
                    serviceId: this.props.serviceId,
                    protocolId: this.props.protocolId,
                    version: 1
                },
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        systemType: this.props.systemTypeName,
                        serviceType: this.props.activityTypeName,
                        sourceOfAeration: this.doesHaveSourceOfAeration() ? this.state.sourceOfAeration : null,
                        protocolResult: this.state.protocolResult,
                        deviceReportList: this.state.deviceReportList,
                        endDate: this.props.endDate,
                        scope: this.props.scope
                    })
                },
                successFunction: data => {

                    if (this.props.protocolId) {
                        notification['success']({
                            message: 'Udało się zmodyfikować protokół',
                        });

                    } else if (this.props.addSuccessFunction) {
                        this.props.addSuccessFunction({
                            protocolId: data.simpleProtocol?.protocolId
                        })
                    }
                    this.fetchProtocol(data)
                }
            })

        } else {
            notification['error']({
                message: 'Błąd',
                description: 'Najpierw uzupełnij wymagane dane',
            });
        }
    }

    renderList() {
        let columns = [
            {
                title: 'Ocena stanu urządzeń systemu',
                dataIndex: 'conditionAssessment',
                key: '',
                width: '26%',
                ...this.tableHelper.getColumnSearchProps('conditionAssessment'),
                render: (text, record, index) => <VTextArea
                    placeholder="Ocena stanu urządzeń systemu"
                    value={text}
                    disabled={!this.props.isActive}
                    autoSize={{minRows: 1, maxRows: 3}}
                    onChange={(e) => {
                        this.setDeviceReportListByIndex(record.index, "conditionAssessment", e.target.value)
                    }
                    }/>,
            },
            {
                title: 'Środki do przywrócenia stanu wymaganego',
                dataIndex: 'fixDescription',
                key: '',
                width: '26%',
                ...this.tableHelper.getColumnSearchProps('fixDescription'),
                render: (text, record, index) => <VTextArea
                    placeholder="Środki do przywrócenia stanu wymaganego"
                    value={text}
                    disabled={!this.props.isActive}
                    autoSize={{minRows: 1, maxRows: 3}}
                    onChange={(e) => {
                        this.setDeviceReportListByIndex(record.index, "fixDescription", e.target.value)
                    }
                    }/>,
            },
            {
                title: 'Priorytet',
                dataIndex: 'devicesFixPriority',
                key: '',
                width: '20%',
                ...this.tableHelper.getColumnSearchProps('devicesFixPriority'),
                render: (text, record, index) => <div>
                    <VSelect
                        value={text}
                        style={{width: '100%'}}
                        disabled={!this.props.isActive}
                        onChange={(e) => {
                            this.setDeviceReportListByIndex(record.index, "devicesFixPriority", e)
                        }}
                        options={DevicesFixPriorityTypes}/>
                </div>,
            },
            {
                title: 'Akcje',
                dataIndex: '',
                key: 'actions',
                fixed: 'right',
                width: 100,
                render: (text, record, index) =>
                    <div className={styles.iconHolder}>
                        <ProtocolRowFile
                            protocolVersion="1"
                            rowId={record.id}
                            fileResponse={record.fileInfoResponse}
                            isActive={this.props.isActive}
                            hasFullAccess={this.props.hasFullAccess}
                            addFile={(fResponse) => this.setDeviceReportListByIndex(record.index, 'fileInfoResponse', fResponse)}
                        />

                        {this.props.isActive &&

                        <DeleteOutlined
                            style={{color: 'red'}}
                            className={styles.iconItem}
                            onClick={() => this.deleteReportRow(record)}/>

                        }

                    </div>,
            }
        ];

        return <Table
            pagination={this.state.pagination}
            columns={columns}
            dataSource={this.state.deviceReportList}
            scroll={{x: 1100}}
            rowKey="index"
        />

    }

    addNewItemToList() {
        let newReports = this.state.deviceReportList.concat([{
            index: this.state.tableFreeIndex,
            conditionAssessment: null,
            devicesFixPriority: null,
            fixDescription: null
        }])

        this.setState({
            deviceReportList: newReports,
            tableFreeIndex: this.state.tableFreeIndex + 1
        })
    }

    render() {
        return (
            <>
                <br/>
                <Row>
                    {this.doesHaveSourceOfAeration() &&
                    <Col span={10}>Źródło napowietrzania</Col>
                    }
                    <Col span={10}>Stwierdzono prawidłowość działania</Col>
                </Row>
                <Row className="inputRow">
                    {this.doesHaveSourceOfAeration() &&
                    <Col span={10}>
                        <VSelect
                            value={this.state.sourceOfAeration}
                            style={{width: '80%'}}
                            rules={{required: true}}
                            mode="multiple"
                            disabled={!this.props.isActive}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(value) => this.setState({sourceOfAeration: value})}
                            options={SourceOfAerationTypes}/>
                    </Col>
                    }
                    <Col span={10}>
                        <VSelect
                            value={this.state.protocolResult}
                            style={{width: '80%'}}
                            rules={{required: true}}
                            disabled={!this.props.isActive}
                            validTriggerContainer={this.state.validTriggerContainer}
                            onChange={(value) => this.setState({protocolResult: value})}
                            options={ProtocolResultTypes}/>
                    </Col>
                </Row>


                <Row>
                    <Col span={10}>Wyniki przeglądu:</Col>
                </Row>
                {this.props.isActive &&
                <Row className="inputRow">
                    <Button
                        onClick={() => {
                            this.addNewItemToList()
                        }}
                        type="primary">
                        Dodaj element
                    </Button>
                </Row>
                }

                {this.renderList()}

                <Row justify={"end"}>
                    {this.props.isActive &&
                    <Col>
                        <Button
                            justify="end"
                            onClick={() => {
                                this.addProtocol()
                            }}
                            type="primary">
                            {this.props.protocolId ? <>Uaktualnij protokół</> : <>Dodaj protokół</>}
                        </Button>
                    </Col>
                    }
                </Row>

            </>
        );
    }

}

export default AddProtocol1;
