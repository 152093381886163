import Colors from "./Colors";

export const HasMadeOffer = [
    {
        value: true,
        label: 'Złożona',
        text: 'Złożona',
        color: Colors.green
    },
    {
        value: false,
        label: 'Nie złożona',
        text: 'Nie złożona',
        color: Colors.red
    }
];

export let GetHasMadeOfferFromValue = (value) => {
    for (let item of HasMadeOffer) {
        if (item.value === value) {
            return item;
        }
    }
    return {};
}

export let GetHasMadeOfferStyle = (hasMadeOffer) => {
    let styles = {
        height: 30,
        width: 140,
        textAlign: "center",
        lineHeight: '30px',
        color: "white",
        fontWeight: "bold",
        borderRadius: '3px'
    }

    styles.backgroundColor = GetHasMadeOfferColorStyle(hasMadeOffer)

    return styles;
}

export let GetHasMadeOfferColorStyle = (hasMadeOffer) => {
    for (let item of HasMadeOffer) {
        if (item.value === hasMadeOffer) {
            return item.color
        }
    }
    return Colors.blue
}

export default HasMadeOffer
