import React from "react";
import {withRouter} from 'react-router-dom';
import {Button, Card, Col, DatePicker, Divider, Input, notification, Row} from 'antd';
import {ShareState} from '../../../../utils/ShareState';
import {authFetch} from "../../../../utils/ApiHelper";
import moment from "moment";
import NumberFormat from 'react-number-format';
import {DeleteOutlined,} from '@ant-design/icons';
import TenderDetailDisplay from "../../displayInfos/TenderDetailDisplay";
import UserDetailsDisplay from "../../displayInfos/UserDetailsDisplay";
import FacilitySimpleDisplay from "../../displayInfos/facility/FacilitySimpleDisplay";
import OfferDisplay from "../../displayInfos/OfferDisplay";
import AddressDisplay from "../../displayInfos/AddressDisplay";

const {TextArea} = Input;


class ManagerTenderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            facility: null,
            realizationDate: null,
            status: null,
            realizer: [],
            facilityName: null,
            facilityAddress: null,
            facilityId: null,
            facilityVoivodeship: null,
            note: null,
            reviewDate: null,
            complaintNotice: null,
            submissionBids: null,
            scope: null,
            topic: null,
            tenderType: null,

            subcontractorsList: null,
            subcontractorsChoosen: [],


            offerNote: null,
            offerRealizationDate: null,
            offerSalary: null,

            showAddOffer: false,
            myOffer: false,
        };
    }

    componentDidMount() {
        ShareState.setValue('breadcrumb', 'Informacje o przetargu')
        this.getDetailed();
    }

    getDetailed() {
        authFetch({
            endpointName: "managerTenderDetail",
            endpointParameters: {id: this.state.id},
            successFunction: data => {
                this.setState({
                    facility: data.facility,
                    realizationDate: data.realizationDate,
                    salesman: data.facility.salesman,
                    realizer: data.realizer,
                    facilityVoivodeship: data.facility.address.voivodeship,
                    facilityName: data.facility.name,
                    facilityAddress: data.facility.address.address,
                    facilityCity: data.facility.address.city,
                    facilityStreet: data.facility.address.street,
                    facilityPostalCode: data.facility.address.postalCode,
                    facilityId: data.facility.id,
                    note: data.note,
                    reviewDate: data.reviewDate,
                    topic: data.topic,
                    tenderType: data.tenderType,
                    scope: data.scope,
                    status: data.tenderStatus,
                    complaintNotice: data.complaintNotice,
                    submissionBids: data.submissionBids,
                    myOffer: data.offer,
                    winningOffer: data.winningOffer,
                    documentNumber: data.documentNumber
                });
                if (data.offer) {
                    this.setState({
                        offerNote: data.offer.note,
                        offerSalary: data.offer.salary,
                        offerRealizationDate: data.offer.realizationDate
                    });
                    if (data.offer.chosen === false) {
                        this.setState({showAddOffer: true});
                    }
                } else {
                    this.setState({showAddOffer: true});
                }
            }
        });

    }


    clearState() {
        this.setState({
            subcontractorsChoosen: []
        });
    }

    convertToISO(date, time) {
        return date + "T" + time + "Z";
    }

    inputHandler(property, event) {
        let state = this.state
        state[property] = event.target.value
        this.setState(state);
    }

    handleSalaryOffer(property, event) {
        if (event.target) {
            let state = this.state
            state[property] = event.target.value
            this.setState(state);
        }
    }

    datePickerHandler(date, dateString, property) {
        let state = this.state
        state[property] = dateString
        this.setState(state);
    }

    disabledDate(current) {
        return current && current < moment().endOf('day');
    }

    addOffer() {
        if (this.isValid()) {
            authFetch({
                endpointName: "ManagerTenderAddOffer",
                endpointParameters: {id: this.state.id},
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        note: this.state.offerNote,
                        salary: this.state.offerSalary,
                        realizationDate: this.state.offerRealizationDate
                    })
                },
                successFunction: data => {
                    notification['success']({
                        message: 'OK',
                        description: 'wysłano ofertę',
                    });
                    this.getDetailed();
                }
            })
        } else {
            notification['error']({
                message: 'Niepowodzenie',
                description: 'Pola "Kwota" oraz "Data realizacji" nie mogą być puste.',
            });
        }
    }

    modifyOffer() {
        if (this.isValid()) {
            authFetch({
                endpointName: "ManagerTenderModifyOffer",
                endpointParameters: {id: this.state.myOffer.offerId},
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        note: this.state.offerNote,
                        salary: this.state.offerSalary,
                        realizationDate: this.state.offerRealizationDate
                    })
                },
                successFunction: data => {
                    notification['success']({
                        message: 'OK',
                        description: 'Zaktualizowano ofertę',
                    });
                    this.getDetailed();
                }
            })
        } else {
            notification['error']({
                message: 'Niepowodzenie',
                description: 'Pola "Kwota" oraz "Data realizacji" nie mogą być puste.',
            });
        }
    }

    removeOffer() {
        authFetch({
            endpointName: "ManagerTenderDeleteOffer",
            endpointParameters: {id: this.state.myOffer.offerId},
            config: {
                method: 'delete',
            },
            successFunction: data => {
                notification['success']({
                    message: 'OK',
                    description: 'Cofnięto wysłanie oferty',
                });
                this.getDetailed();
                this.setState({
                    offerRealizationDate: null,
                    offerNote: null,
                    offerSalary: null,
                    realizationDate: null,
                    myOffer: null
                })
            }
        })
    }

    isValid() {
        if (this.state.offerRealizationDate != null || this.state.offerSalary != null) {
            return true;
        } else {
            return false;
        }
    }

    renderWonOffer() {
        return this.state.status === "SETTLED"
            && !this.state.myOffer?.chosen
            && this.state.winningOffer && (
                <Card title="Oferta, która wygrała">

                    <OfferDisplay data={this.state.winningOffer} renderSubcontractor/>

                </Card>
            )
    }


    render() {
        return (
            <div>
                <Card title={"Przetarg "}>
                    <TenderDetailDisplay data={this.state}/>

                </Card>
                <br></br>
                <Card title="Obiekt" bordered={true}>
                    <FacilitySimpleDisplay data={this.state.facility} noLinks/>

                    <Divider dashed/>

                    <AddressDisplay data={this.state.facility?.address}/>

                </Card>
                <br></br>
                <Card title={"Realizator"}>

                    <UserDetailsDisplay data={this.state.realizer} essentialOnly/>

                </Card>
                <br></br>
                <Card title={"Twoja oferta"}>

                    {this.state.status === "IN_PROGRESS" &&

                    <div>
                        {this.state.myOffer == null &&
                        <Row>
                            <h4>Nie składałeś jeszcze żadnej oferty.</h4>
                        </Row>
                        }
                        {this.state.myOffer &&
                        <div>
                            <Row>
                                <h4>Twoja aktualnie złożona oferta:</h4>
                            </Row>
                        </div>
                        }
                        <br></br>
                        {this.state.showAddOffer &&
                        <div>
                            <Row>
                                <Col span={3}>
                                    Kwota
                                </Col>
                                <Col span={3}>
                                    Data realizacji
                                </Col>
                                <Col span={15}>
                                    Uwagi
                                </Col>
                            </Row>
                            <Row className="inputRow">
                                <Col span={3} style={{paddingRight: 16}}>
                                    <NumberFormat value={this.state.offerSalary} style={{height: "100%"}}
                                                  thousandSeparator={true}
                                                  prefix={'PLN '} customInput={Input} onValueChange={(values) => {
                                        const {floatValue} = values;
                                        this.setState({offerSalary: floatValue})
                                    }}/>
                                </Col>
                                <Col span={3} style={{paddingRight: 16}}>
                                    <DatePicker style={{float: "left", width: "100%", height: "100%"}}
                                                format="YYYY-MM-DD"
                                                defaultValue={this.state.myOffer ? moment(this.state.myOffer.realizationDate) : null}
                                                disabledDate={this.disabledDate}
                                                value={this.state.offerRealizationDate ? moment(this.state.offerRealizationDate) : null}
                                                onChange={(date, dateString) => this.datePickerHandler(date, dateString, "offerRealizationDate")}>
                                    </DatePicker>
                                </Col>
                                <Col span={14} style={{paddingRight: 16}}>
                                    <TextArea
                                        style={{width: "100%", resize: "none"}}
                                        rows="2"
                                        placeholder="Uwagi"
                                        value={this.state.offerNote}
                                        onChange={(e) => this.inputHandler('offerNote', e)}/>
                                </Col>
                                <Col span={3}>
                                    <Button onClick={() => {
                                        if (!this.state.myOffer) {
                                            this.addOffer()
                                        } else {
                                            this.modifyOffer()
                                        }
                                    }} style={{
                                        width: "100%",
                                        height: "100%"
                                    }}>{this.state.myOffer ? "Zaktualizuj ofertę" : "Złóż ofertę"}</Button>
                                </Col>
                                <Col span={1}>
                                    <Button danger disabled={!this.state.myOffer} onClick={() => {
                                        if (this.state.myOffer) {
                                            this.removeOffer()
                                        }
                                    }} style={{width: "100%", height: "100%"}}><DeleteOutlined/></Button>
                                </Col>

                            </Row>
                        </div>
                        }
                    </div>

                    }
                    {this.state.status === "SETTLED" &&

                    <div>
                        {this.state.myOffer &&
                        <OfferDisplay data={this.state.myOffer}/>
                        }


                        {!this.state.myOffer &&

                        <div>
                            Nie składałes żadnej oferty
                        </div>
                        }

                    </div>
                    }

                </Card>


                <br/>
                {this.renderWonOffer()}

            </div>
        );
    }
}

export default withRouter(ManagerTenderDetail);
