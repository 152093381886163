import React from "react";
import {Button, Col, notification, Row} from "antd";
import {VTextArea} from "../../../../../component/form/VForm";
import {authFetch} from "../../../../../utils/ApiHelper";

class AddProtocol6 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceId: props.serviceId,
            addSuccessFunction: props.addSuccessFunction,
            isActive: props.isActive,

            notes: props.notes,

            pagination: {
                pageSize: 10,
            },

            validTriggerContainer: []
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.fetchProtocol()
        }
    }

    componentDidMount() {
        this.fetchProtocol()
    }

    fetchProtocol(data = this.props.data) {
        this.setState({
            notes: data?.notes
        })
    }

    validTrigger() {
        for (let item of this.state.validTriggerContainer) item()
    }

    valid() {
        this.validTrigger()
    }


    isProtocolValid() {
        return true
    }


    addProtocol() {
        if (this.isProtocolValid()) {
            authFetch({
                endpointName: this.props.protocolId ? "ProtocolSpecificVersionEdit" : "ProtocolSpecificVersionAdd",
                endpointParameters: {
                    serviceId: this.state.serviceId,
                    protocolId: this.props.protocolId,
                    version: 6
                },
                config: {
                    method: 'post',
                    body: JSON.stringify({
                        notes: this.state.notes,
                        endDate: this.props.endDate,
                        scope: this.props.scope
                    })
                },
                successFunction: data => {

                    if (this.props.protocolId) {
                        notification['success']({
                            message: 'Udało się zmodyfikować protokół',
                        });

                    } else if (this.props.addSuccessFunction) {
                        this.props.addSuccessFunction({
                            protocolId: data.simpleProtocol?.protocolId
                        })
                    }
                    this.fetchProtocol(data)
                }
            })

        } else {
            notification['error']({
                message: 'Błąd',
                description: 'Najpierw uzupełnij wymagane dane',
            });
        }
    }


    render() {
        return (
            <>
                <br/>


                <Row>
                    <Col span={10}>Uwagi:</Col>
                </Row>
                <Row className="inputRow">
                    <VTextArea
                        value={this.state.notes}
                        style={{width: '100%'}}
                        rules={{required: true}}
                        validTriggerContainer={this.state.validTriggerContainer}
                        onChange={({target: {value}}) => this.setState({notes: value})}
                        placeholder="Uwagi"
                        autoSize={{minRows: 3, maxRows: 5}}
                        disabled={!this.props.isActive}
                    />
                </Row>

                <Row justify={"end"}>
                    {this.state.isActive &&
                    <Col>
                        <Button
                            justify="end"
                            onClick={() => {
                                this.addProtocol()
                            }}
                            type="primary">
                            {this.props.protocolId ? <>Uaktualnij protokół</> : <>Dodaj protokół</>}
                        </Button>
                    </Col>
                    }
                </Row>

            </>
        );
    }
}

export default AddProtocol6;
